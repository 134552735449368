import { SecuredApi as axios } from "../axios";
import Helper from "../../utils/Helper";

export default class Page {
    constructor(component) {
        this.component = component;
    }

    async list(params = {}) {
        try {
            const response = await axios.get('/pages' + Helper.serializeObject(params));
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data.items
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    async get(id) {
        try {
            const response = await axios.get('/pages/' + id);
            let data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message,
                pageNotFound: statusCode === 404
            });
        }
    }

    async getDesign(params = {}) {
        try {
            const response = await axios.get(`pages/${params.page}/design?retailer_id=${params.retailer}&user_type=${params.userType}`);

            // eslint-disable-next-line
            const data = await response.data.data;
            return data;
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                return { status: "Failure", message: response.data.data.Message, data: response.data.data.Errors };
            }
        }
    }

    async use(params = {}) {
        try {
            const response = await axios.get('/pages?' + Helper.serializeObject(params));
            const data = await response.data;

            this.component.setState({
                pages: data.data.Data.items
            });
        } catch ({ message, statusCode }) {
            console.error(message);
        }
    }

    async create(params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.post('/pages/', params);
            const data = await response.data;
            setSubmitting(false);

            this.component.props.history.replace('/ui/pages/update/' + data.data.Data.id);

        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    async update(id, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.put('/pages/' + id, params);

            // eslint-disable-next-line
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                variant: "success",
                message: "Page updated successfully"
            });
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete('/pages/' + id);

            // eslint-disable-next-line
            const data = await response.data;
            this.component.props.history.replace('/ui/pages');
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    variant: "danger",
                    preventUnload: true,
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }

    // Update the COMPONENT with elements ( fields / components )...
    async saveDesign(id, params = {}) {
        try {
            const response = await axios.post(`pages/${id}/design`, params);

            // // eslint-disable-next-line
            const data = await response.data.data;
            return data;
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                return { Status: "Failure", Message: response.data.data.Message, Data: response.data.data.Errors };
            }
        }
    }
}