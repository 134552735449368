import React from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import menu from "../../../../routes/menu.json";
import ThemeContext from "../../../../contexts/theme-context";

const Navbar = () => {
	const [currentPath, changePath] = React.useState(window.location.pathname);

	const history = useHistory();
	React.useEffect(() => {
		return history.listen(location => {
			changePath(location.pathname);
		});
	}, [history]);

	const toggleSubMenu = e => {
		e.preventDefault();
		const li = e.target.closest("li");
		li.querySelector("ul").classList.toggle("in");
	};

	const getSubmenuList = menu => {
		let list = [];
		menu.forEach(item => {
			list.push(item.path);
			if (item.submenu) {
				getSubmenuList(item.submenu).forEach(subitem => list.push(subitem));
			}
		});

		return list;
	}

	const renderMenu = (menu, level = 1) => {
		return menu.map((item, index) => {
			let active = false;
			if (item.submenu) {
				active = getSubmenuList(item.submenu).includes(currentPath);
			} else {
				active = currentPath === item.path;
			}

			return (
				<li key={index} className={active ? "active" : ""}>
					<NavLink to={item.path} onClick={item.onClick || (item.submenu ? toggleSubMenu : () => { })}>
						<i className={"sidebar-item-icon " + item.icon}></i>
						<span className="nav-label">{item.label}</span>

						{item.submenu && <i className="fa fa-angle-left arrow"></i>}
					</NavLink>

					{item.submenu &&
						<ul className={"nav-" + (level + 1) + "-level collapse " + (active ? "in" : "")}>
							{renderMenu(item.submenu, level + 1)}
						</ul>
					}
				</li>
			)
		})
	}

	return (
		<ul className="d-flex flex-column h-100 side-menu metismenu">
			<li className="p-2">
				<form className="navbar-search d-none" action="#">
					<div className="rel">
						<span className="search-icon">
							<i className="ti-search"></i>
						</span>
						<input
							className="form-control shadow-none"
							placeholder="Search here..."
						/>
					</div>
				</form>

				<ThemeContext.Consumer>
					{context => (
						<label className="form-checkbox">
							<input type="checkbox" onChange={e => {
								context.changeTheme(e.target.checked ? 'dark' : 'light');
							}} defaultChecked={context.theme === 'dark'} />
							Dark mode
						</label>
					)}
				</ThemeContext.Consumer>
			</li>
			{renderMenu(menu)}
		</ul>
	)
}

export default Navbar;
