import React from 'react';
import { Link } from 'react-router-dom';
import { renderPublicRoutes as Routes } from '../../routes/render';
import './index.css';

const PublicLayout = () => (
    <div className="content">
        <div className="brand">
            <Link className="link" to="/">Form Builder</Link>
        </div>
        <Routes />
    </div>
)

export default PublicLayout;