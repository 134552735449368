import React, { useState } from 'react';
import PreventUnload from '../../../components/PreventUnload';
import Helper from '../../../utils/Helper';
import SelectElement from './SelectElement';

let dragged = null;
let dropped = null;

const DragElement = (props) => {
    const actionBtnClass = "btn btn-sm shadow-none fw-bold btn";
    const [dragClone, setDragClone] = useState([]);
    const [fullScreen, setFullScreen] = React.useState({
        mode: false,
        title: 'Click to enter fullscreen mode'
    });
    
    const handleFullScreen = () => {
        if(fullScreen.mode){
            setFullScreen({
                mode: false, 
                title: 'Click to enter fullscreen mode'
            });
            Helper.fullscreen(document.getElementById('drag-fullscreen'), 'off');
        }else if(!fullScreen.mode){
            setFullScreen({
                mode: true, 
                title: 'Click to exit fullscreen mode'
            });
            Helper.fullscreen(document.getElementById('drag-fullscreen'), 'on');
            document.addEventListener('fullscreenchange', (e) => {
                if(document.fullscreenElement === null){
                    setFullScreen({ mode: false, title: 'Click to enter fullscreen mode' });
                }
            });
        }
    }

    React.useEffect(() => {
        return () => {
            setDragClone([]);
        }
    }, []);

    React.useEffect(() => {
        const draggables = document.querySelectorAll('.draggable');
        draggables.forEach(element => {
            element.addEventListener('dragover', e => {
                e.preventDefault();
            });

            element.addEventListener('dragenter', e => {
                e.preventDefault();
            });
            
            element.addEventListener('drop', e => {
                e.preventDefault();
            });
        });
    }, [dragClone]);

    React.useEffect(() => {
        const cloneCopy = JSON.parse(JSON.stringify(props.clones));
        setDragClone(cloneCopy);
    }, [props.clones]);

    const swapNodes = (nodeA, nodeB) => {
        const parentA = nodeA.parentNode;
        const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;
        nodeB.parentNode.insertBefore(nodeA, nodeB);
        parentA.insertBefore(nodeB, siblingA);
    };

    const findExactNode = e => {
        if(e.parentNode.id === "drag-root"){
            dropped = e;
        }else{
            findExactNode(e.parentNode);
        }
    }

    const dragStartHandler = e => {
        dragged = e.target;
        props.handleTouch();
    }

    const dragEndHandler = e => {
        dragged = null;
    }

    const dragStopHandler = e => {
        findExactNode(e.target);
        swapNodes(dragged, dropped);
        dragged = null;
        dropped = null;
    }

    return (
        <div className="col-8 border-start">
            <div className="card-header d-flex justify-content-between fs-5 fw-bold">
                <div className="w-50 text-truncate" title={Helper.capitalizeFirstLetter(props.data.name.replaceAll("_", " "))}>
                    {Helper.capitalizeFirstLetter(props.data.name.replaceAll("_", " "))}
                </div>
                <div className="d-flex" style={{ gap: ".25rem" }}>
                    <button 
                        disabled={dragClone.length <= 0} 
                        title="Double click to reset elements" 
                        className={`${actionBtnClass}-secondary`} 
                        onDoubleClick={() => dragClone.length > 0 && props.handleReset("reset")}
                    >Reset</button>
                    <button 
                        title="Click to see preview" 
                        disabled={dragClone.length <= 0} 
                        className={`${actionBtnClass}-info`} 
                        onClick={() => dragClone.length > 0 && props.handlePreview()}
                    >Run Preview</button>
                    <button 
                        title={fullScreen.title} 
                        onClick={() => handleFullScreen()} 
                        className={`${actionBtnClass}-info`}
                    >
                        <i className={`fa fa-${fullScreen.mode ? 'compress' : 'expand'}`} aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div className="card-body h-100" style={{ maxHeight: "675px", overflowY: "scroll" }}>
                <div 
                    id="drag-root" 
                    style={{ gap: "0.75rem" }}
                    className="d-flex flex-column" 
                >
                    {
                        dragClone.length > 0 && 
                            <SelectElement 
                                draggable={true} 
                                elements={dragClone} 
                                doubleClick={props.doubleClick} 
                                dragEndHandler={(e) => dragEndHandler(e)} 
                                dragDropHandler={(e) => dragStopHandler(e)} 
                                dragStartHandler={(e) => dragStartHandler(e)} 
                            />
                    }
                </div>
            </div>
        </div>
    );
}
 
export default DragElement;