import React, { Component } from "react";
import Box from "../../components/Box";
import List from "../../components/List";
import Alert from "../../components/Alert";
import Spinner from "../../components/Spinner";
import { FunctionService } from "../../services";

class Function extends Component {
    constructor(props) {
        super(props);
        this.services = {
            function: new FunctionService(this)
        }

        this.state = {
            data: [],
            error: null,
            isLoading: true
        }
    }

    componentDidMount() {
        this.services.function.list();
    }

    getColumns = () => [
        {
            name: "Name",
            filterKey: "name",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Type",
            filterKey: "type",
            selector: row => row.type,
            sortable: true
        }
    ];

    render() {
        return (
            <Box title='Supported functions'>
                <Spinner isLoading={this.state.isLoading}>
                    {this.state.error
                        ?
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                        : <List columns={this.getColumns()} data={this.state.data} />
                    }
                </Spinner>
            </Box>
        )
    }
}

export default Function;