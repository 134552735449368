import React, { useRef } from "react";
import { FormContext } from ".";
import { Editor as TinyMCE } from '@tinymce/tinymce-react';

export const HTMLEditor = props => {
    const editorRef = useRef(null);
    let {
        name,
        value = '',
        disabled = false,
        height = 300,
        menubar = false,
        onChange = content => { },
        plugins = [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
        ],
        toolbar = 'undo redo | formatselect fontsizeselect | bold italic underline | forecolor backcolor | alignleft aligncenter alignright alignnone alignjustify | bullist numlist | code | outdent indent | removeformat | help',
        content_style = `
            body {
                font-family: Helvetica,Arial,sans-serif; 
                font-size:14px;
            }

            .solvup-green {
                color: #4C9900;
            }
            
            .solvup-red {
                color: #990000;
            }
            
            .solvup-grey {
                color: #606060;
            }
            
            .solvup-orange {
                color: #e8632e;
            }
            
            .solvup-blue {
                color: #0066cc;
            }
            
            .solvup-font-big {
                font-size: 18px
            }
        `
    } = props;

    return (
        <TinyMCE
            onInit={(e, editor) => {
                editorRef.current = editor;
            }}
            disabled={disabled}
            textareaName={name}
            initialValue={value}
            onBlur={() => onChange(editorRef.current.getContent())}
            tinymceScriptSrc={"https://cdn.tiny.cloud/1/" + process.env.REACT_APP_TINYMCE_API_KEY + "/tinymce/5/tinymce.min.js"}
            init={{
                height,
                menubar,
                plugins,
                toolbar,
                content_style,
                branding: false,
            }}
        />
    )
}

export const Editor = props => (
    <FormContext.Consumer>
        {context => {
            let { name, value = '', ...rest } = props;
            value = name in context.values ? context.values[name] : value;

            return (
                <HTMLEditor name={name} value={value} {...rest} onChange={content => {
                    context.updateValue(name, content);
                }} />
            )
        }}
    </FormContext.Consumer>
)