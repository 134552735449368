import React, { useState } from "react";

const CopyToClipboard = ({ text, title = 'Copy to clipboard' }) => {
    const [isCopied, setIsCopied] = useState(false);

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleCopyClick = e => {
        e.stopPropagation();
        copyTextToClipboard(text).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }).catch(err => {
            console.error(err);
        });
    }

    return (
        <i className={"fa cursor-pointer " + (isCopied ? 'fa-check-circle text-success' : 'fa-clone')} onClick={handleCopyClick} title={isCopied ? 'Copied!' : title}>
        </i>
    )
}

export default CopyToClipboard;