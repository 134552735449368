import React from 'react';
import DragElement from './DragElement';
import Helper from '../../../utils/Helper';
import ChooseElement from './ChooseElement';
import PreviewClones from './PreviewClones';
import Alert from '../../../components/Alert';
import PreventUnload from '../../../components/PreventUnload';

class Design extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            clone: [], 
            touched: false, 
            active: typeof this.props.data.components !== "undefined" ? "components" : (typeof this.props.data.fields !== "undefined" ? "fields" : ""), 
            previewClone: [],
            fullScreen: {
                mode: false,
                title: 'Click to enter fullscreen mode'
            }
        }
    }

    componentDidMount(){
        // console.log(this.props.data);
        if(typeof this.props.data.components !== "undefined"){
            const items = Helper.findFieldType(this.props.data.components, "component");
            this.setState({ clone: items });
        }
        else if(typeof this.props.data.fields !== "undefined"){
            const items = Helper.findFieldType(this.props.data.fields, "field");
            this.setState({ clone: items });
        }
    }

    handleDoubleClick = (fieldData, operation) => {
        let clone = JSON.parse(JSON.stringify(this.state.clone));
        if (operation === "add") {
            const findElement = clone.findIndex(el => el.id === fieldData.id);
            if (findElement < 0) {
                clone.push(fieldData);
                this.setState({ clone, touched: true });
            }
        } else if (operation === "remove") {
            let filteredClone = clone.filter(duplicate => duplicate.id !== fieldData.id);
            this.setState({ clone: filteredClone, touched: true });
        }
        // else if (operation === "reset")
    }

    handleTouchForDrag = () => {
        if (this.state.touched === false) {
            this.setState({ touched: true });
        }
    }

    handleReset = (action) => {
        if (action === "reset") {
            this.setState({
                clone: [], 
                active: "", 
                previewClone: []
            });
        } else if (action === "edit") {
            this.setState({ previewClone: [] });
        }
    }

    handlePreview = () => {
        let previewSet = [];
        const draggables = document.querySelectorAll('.draggable');
        previewSet = Object.values(draggables).map(res => {
            let findObject = this.state.clone.filter(obj => obj.id === res.id);
            return findObject[0];
        });
        previewSet = previewSet.filter(arr => arr !== undefined && arr !== 'undefined');
        this.setState({ previewClone: previewSet });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <Alert variant="secondary">
                        <span className="text-dark fw-bold">Note:</span> Double Click to choose/remove an element.
                    </Alert>
                </div>
                <div className="row" id="drag-fullscreen">
                    {
                        this.props.data && 
                            <>
                                <div className={`col-12 card flex-row px-0 ${this.state.previewClone.length <= 0 && "d-none"}`}>
                                    <PreviewClones 
                                        {...this.props} 
                                        handleReset={this.handleReset} 
                                        previewClones={this.state.previewClone} 
                                    />
                                </div>
                                <div className={`col-12 card flex-row px-0 ${this.state.previewClone.length > 0 && "d-none"}`}>
                                    <ChooseElement 
                                        {...this.props} 
                                        active={this.state.active}
                                        clones={this.state.clone} 
                                        doubleClick={this.handleDoubleClick} 
                                    />
                                    <PreventUnload when={this.state.touched}>
                                        <DragElement 
                                            {...this.props} 
                                            clones={this.state.clone} 
                                            handleReset={this.handleReset} 
                                            handlePreview={this.handlePreview} 
                                            doubleClick={this.handleDoubleClick} 
                                            handleTouch={this.handleTouchForDrag} 
                                        />
                                    </PreventUnload>
                                </div>
                            </>
                    }
                </div>
            </div>
        );
    }
}
 
export default Design;