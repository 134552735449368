import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import CopyToClipboard from "../CopyToClipboard";

const ContentEditable = ({ disabled = false, className = '', onKeyDown = () => { }, onPaste = () => { }, children, allowCopy = true, ...props }) => {
    const ref = useRef(null);
    const childRef = useRef(null);
    const [textToCopy, updateText] = useState(null);

    useEffect(() => {
        if (allowCopy) {
            updateText(childRef.current.innerText);
        }
    }, [children]);

    const keyDownHandler = e => {
        const code = e.keyCode || e.charCode || e.which;
        if (code === 9 && e.shiftKey) {
            // Allow shit tab to work
        } else if (code === 9) {
            e.preventDefault();
            document.execCommand('insertHTML', false, '&#009');
        } else if (code === 13) {
            e.preventDefault();
            document.execCommand('insertLineBreak');
        }

        onKeyDown();
    }

    const pasteHandler = e => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        document.execCommand("insertHTML", false, text);
        onPaste();
    }

    return (
        <div
            ref={ref}
            contentEditable={!disabled}
            suppressContentEditableWarning
            onKeyDown={keyDownHandler}
            onPaste={pasteHandler}
            className={'contenteditable ' + className}
            {...props}>
            <div ref={childRef}>
                {children}
            </div>

            {allowCopy &&
                <span className="copy-to-clipboard">
                    <CopyToClipboard text={textToCopy} />
                </span>
            }
        </div>
    )
}

export default ContentEditable;