import React, { useRef, useState } from "react";
import Alert from "../Alert";

const KeyValueType = ({ variant = 'object', onUpdate = () => { }, ...props }) => {
    const types = props.types || {
        string: "String",
        number: "Number",
        boolean: "Boolean",
        null: "NULL",
        business_term: "Business Term"
    }

    const keyColumn = ['object', 'params', 'headers'].includes(variant);
    const typeColumn = ['object', 'array', 'function', 'params', 'headers'].includes(variant);
    const valueColumn = ['object', 'array', 'function', 'params', 'headers'].includes(variant);
    const descriptionColumn = ['params', 'headers'].includes(variant);

    const keyRef = useRef(null);
    const typeRef = useRef(null);
    const valueRef = useRef(null);
    const descriptionRef = useRef(null);

    const [data, updateData] = useState(props.data || []);
    const [errors, setErrors] = useState({});

    const save = () => {
        const key = keyColumn ? keyRef.current.value.trim() : null;
        const type = typeColumn ? typeRef.current.value : null;
        const value = valueColumn ? valueRef.current.value.trim() : null;
        const description = descriptionColumn ? descriptionRef.current.value.trim() : null;
        if (keyColumn) {
            if (key) {
                const exist = data.filter(param => param.key.toLowerCase() === key.toLowerCase());
                if (exist.length) {
                    setErrors({
                        key: "Key already exist"
                    });
                } else {
                    add(key, type, value, description);
                }
            } else {
                setErrors({
                    key: "Key must not be blank"
                });
            }
        } else {
            add(key, type, value, description);
        }
    }

    const add = (key, type, value, description) => {
        let obj = {};
        if (keyColumn) {
            obj['key'] = key;
        }

        if (typeColumn) {
            obj['value_type'] = type;
        }

        if (valueColumn) {
            if (type === 'boolean') {
                const v = value.toLowerCase();
                const validValue = (v === 'true' || v === 'false');
                if (!validValue) {
                    setErrors({
                        value: "Must be either boolean true or false"
                    });
                    return;
                } else {
                    obj['value'] = v;
                }
            } else if (type === 'number') {
                obj['value'] = Number(value);
                if (isNaN(obj['value'])) {
                    setErrors({
                        value: "Must be a number"
                    });
                    return;
                }
            } else if (type === 'null') {
                obj['value'] = null;
            } else {
                obj['value'] = value;
            }
        }

        if (descriptionColumn) {
            obj['description'] = description;
        }

        const [...cloneData] = data;
        cloneData.push(obj);
        updateData(cloneData);
        onUpdate(cloneData);
        reset();
    }

    const deleteHandler = arrayIndex => {
        const updatedData = data.filter((param, index) => index !== arrayIndex);
        updateData(updatedData);
        onUpdate(updatedData);
    }

    const reset = () => {
        if (keyColumn) {
            keyRef.current.value = '';
        }

        if (typeColumn) {
            typeRef.current.value = 'string';
        }

        if (valueColumn) {
            valueRef.current.value = '';
            valueRef.current.disabled = false;
        }

        if (descriptionColumn) {
            descriptionRef.current.value = ''
        }

        setErrors({});
    }

    const preventEnter = e => {
        const keyCode = e.keyCode || e.charCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            save();
        }
    }

    const renderTypes = () => {
        let data = [];
        for (const type in types) {
            data.push((
                <option key={type} value={type}>
                    {types[type]}
                </option>
            ))
        }

        return data;
    }

    return (
        <>
            {data.length > 0 &&
                <Alert variant="secondary">
                    Note: Double click <strong> Delete button </strong>to delete key
                </Alert>
            }

            <div className="table-responsive">
                <table className="table">
                    <tbody>
                        <tr>
                            {keyColumn && <th>Key</th>}
                            {typeColumn && <th>Type</th>}
                            {valueColumn && <th>Value</th>}
                            {descriptionColumn && <th>Description</th>}
                            <th width="150">Action</th>
                        </tr>

                        {data.map((param, index) => (
                            <tr key={index}>
                                {keyColumn && <td>{param.key}</td>}
                                {typeColumn && <td>{types[param.value_type]}</td>}
                                {valueColumn && <td>{String(param.value)}</td>}
                                {descriptionColumn && <td>{param.description}</td>}
                                <td>
                                    <button type="button" className="btn btn-danger" onDoubleClick={() => deleteHandler(index)}>Delete</button>
                                </td>
                            </tr>
                        ))}

                        <tr>
                            {keyColumn &&
                                <td>
                                    <div className={"form-group " + (errors.key ? 'has-error' : '')}>
                                        <input className="form-control" placeholder="Enter key" ref={keyRef} onKeyDown={preventEnter} />
                                        {errors.key && <span className="text-danger mt-1">{errors.key}</span>}
                                    </div>
                                </td>
                            }
                            {typeColumn &&
                                <td>
                                    <select className="form-control" id="type" ref={typeRef} onChange={e => {
                                        if (valueColumn) {
                                            valueRef.current.disabled = e.target.value === 'null';
                                            const { ...err } = errors;
                                            err.value = null;
                                            setErrors(err);
                                        }
                                    }}>
                                        {renderTypes()}
                                    </select>
                                </td>
                            }
                            {valueColumn &&
                                <td>
                                    <div className={"form-group " + (errors.value ? 'has-error' : '')}>
                                        <input className="form-control" placeholder="Enter value" ref={valueRef} onKeyDown={preventEnter} />
                                        {errors.value && <span className="text-danger mt-1">{errors.value}</span>}
                                    </div>
                                </td>
                            }
                            {descriptionColumn &&
                                <td>
                                    <input className="form-control" placeholder="Enter description" ref={descriptionRef} onKeyDown={preventEnter} />
                                </td>
                            }
                            <td>
                                <button type="button" className="btn btn-primary m-r-5" onClick={save}>Save</button>
                                <button type="button" className="btn btn-warning" onClick={reset}>Reset</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default KeyValueType;