import React from "react";
import styles from "./Suggestion.module.css";

const Suggestion = ({ suggestions, noOptionsMessage, handleSelectValue }, props) => {
    return (
        <ul className={styles.Menu}>
            {
                suggestions.length === 0 
                    ? <small className={styles.MenuNoItem}>{noOptionsMessage}</small>
                    : suggestions.map(suggestion => (
                        <li
                            id={suggestion} 
                            key={suggestion} 
                            onClick={handleSelectValue} 
                            className={styles.MenuItem}
                        >
                            {suggestion}
                        </li>
                    ))
            }
        </ul>
    );
}

export default Suggestion;