import React, { Component, createContext } from "react";

const TabsContext = createContext({});

class Tabs extends Component {
    static defaultProps = {
        id: "tabs-container-" + (+ new Date()),
        active: null,
        className: '',
        onChange: tab => { }
    }

    constructor(props) {
        super(props);
        const tabs = React.Children.toArray(this.props.children).map(tab => ({
            id: tab.props.id,
            title: tab.props.title,
            message: tab.props.message || null,
            className: tab.props?.className,
            disabled: Boolean(tab.props.disabled)
        }));

        this.state = {
            tabs,
            active: this.props.active
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.active !== prevProps.active) {
            this.setActive(this.props.active);
        }
    }

    // Can be called from outside as well
    setActive = id => {
        if (this.state.active !== id) {
            this.setState({
                active: id
            });

            this.props.onChange(id);
        }
    }

    setDisabled = id => {
        const tabsClone = JSON.parse(JSON.stringify(this.state.tabs));
        const filterTabs = tabsClone.map(tab => {
            if (id === "") {
                tab.disabled = false;
            } else if (tab.id !== id) {
                tab.disabled = true;
            }
            return tab;
        });
        this.setState({ tabs: filterTabs });
    }

    static Pane = ({ id, children }) => (
        <TabsContext.Consumer>
            {value => (
                <div id={id} className={"tab-pane " + (id === value.active ? "active" : "")}>
                    {children}
                </div>
            )}
        </TabsContext.Consumer>
    )

    render() {
        return (
            <div className={this.props.className} id={this.props.id}>
                <ul className="nav nav-tabs">
                    {this.state.tabs.map(tab => {
                        return (
                            <li key={tab.id} className={`nav-item ${tab.className !== undefined ? tab.className : ''}`} title={tab.message}>
                                <button
                                    type="button"
                                    disabled={tab.disabled}
                                    onClick={() => this.setActive(tab.id)}
                                    className={"nav-link " + (tab.id === this.state.active ? 'active' : '')}
                                >
                                    {tab.title}
                                </button>
                            </li>
                        )
                    })}
                </ul>
                <TabsContext.Provider value={{
                    active: this.state.active
                }}>
                    <div className="tab-content">
                        {this.props.children}
                    </div>
                </TabsContext.Provider>
            </div>
        )
    }
}

export default Tabs;