import React, { Component } from "react";
import Box from "../../../components/Box";
import Form from "../../../components/Form";
import Tabs from "../../../components/Tabs";
import Alert from "../../../components/Alert";
import NotFound from "../../../components/404";
import { ApiService } from "../../../services";
import Spinner from "../../../components/Spinner";
import Confirm from "../../../components/Confirm";
import { Validator } from "../../../utils/Validator";
import Changelog from "../../../components/Changelog";
import KeyValueType from "../../../components/KeyValueType";
import { SecuredApi as axios } from "../../../services/axios";
import conditionSchema from "../../../config/schema/condition.json";

class Add extends Component {
    constructor(props) {
        super(props);
        this.services = {
            api: new ApiService(this)
        }

        this.data = {}

        this.state = {
            id: this.props.match.params.id,
            isLoading: this.props.match.params.id !== undefined,
            error: null,
            message: null,
            variant: "danger",
            preventUnload: true,
            domains: [],
            data: {
                method: "GET",
                is_external_action: 0,
                authorization: {
                    type: "no_auth"
                }
            },
            confirmModal: {
                show: false,
                title: "Confirm Delete",
                message: "Are you sure want to delete API ?",
                onConfirm: () => this.deleteApi(),
                onCancel: () => this.closeConfirmModal()
            }
        }

        this.methods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']

        this.authorizationTypes = [
            {
                key: 'no_auth',
                value: 'No Auth'
            },
            {
                key: 'basic_auth',
                value: 'Basic Auth'
            },
            {
                key: 'bearer_token',
                value: 'Bearer Token'
            }
        ]
    }

    async componentDidMount() {
        if (this.state.id !== undefined) {
            this.services.api.get(this.state.id);
        }

        try {
            const response = await axios.get('/api/host-detail/');
            const data = await response.data;

            this.setState({
                domains: data.data.Data
            });
        } catch ({ message, statusCode }) {
            console.error(message)
        }
    }

    submitHandler = (values, setSubmitting, setErrors) => {
        const data = { ...values, ...this.data };
        if (data.authorization === 'basic_auth') {
            data.authorization = {
                type: 'basic_auth',
                data: {
                    username: data.username,
                    password: data.password,
                }
            }
        } else if (data.authorization === 'bearer_token') {
            data.authorization = {
                type: 'bearer_token',
                data: {
                    bearer_token: data.bearer_token
                }
            }
        }

        if (this.state.id !== undefined) {
            this.services.api.update(this.state.id, data, setSubmitting, setErrors);
        } else {
            this.services.api.create(data, setSubmitting, setErrors);
        }
    }

    closeConfirmModal = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: false
            }
        }));
    }

    // Click handler of delete button
    deleteHandler = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: true
            }
        }));
    }

    // Delete API finally
    deleteApi = () => {
        this.setState(prev => ({
            preventUnload: false,
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            this.services.api.delete(this.state.id);
        });
    }

    render() {
        if (this.state.pageNotFound) {
            return <NotFound />
        }

        if (this.state.error) {
            return (
                <Alert variant="danger">
                    {this.state.error}
                </Alert>
            )
        }

        return (
            <Spinner isLoading={this.state.isLoading}>
                <Box
                    allowFullScreen
                    title="Configure API"
                    buttons={[{
                        type: "button",
                        value: "Delete",
                        class: "btn btn-sm btn-danger",
                        show: this.state.id !== undefined && this.state.isLoading === false,
                        clicked: this.deleteHandler
                    }, {
                        type: "link",
                        href: "/api/add",
                        value: "Add new",
                        class: "btn btn-sm btn-info",
                        show: this.state.id !== undefined
                    }]}>
                    <Tabs active="details">
                        <Tabs.Pane id="details" title="Details">
                            <Form
                                initialValues={() => {
                                    let { ...data } = this.state.data;
                                    ['id', 'Status', 'created_at', 'updated_at'].forEach(key => delete data[key]);

                                    data.is_external_api = this.state.data.is_external_api || 0;
                                    return data;
                                }}
                                onSubmit={this.submitHandler}
                                preventUnload={this.state.preventUnload}
                                validationSchema={{
                                    name: Validator.create().required('Name is required'),
                                    method: Validator.create().required('Method is required'),
                                    is_external_action: Validator.create().required('External action question is required'),
                                    url: Validator.create().bail().required('URL is required').url('URL must be valid'),
                                    conditions: Validator.create().types(['object']),
                                    domain: Validator.create().requiredIf('is_external_api', 0, 'Domain is required'),
                                    rel_url: Validator.create().requiredIf('is_external_api', 0, 'Relative URL is required'),
                                    username: Validator.create().requiredIf('authorization', 'basic_auth', 'Username is required'),
                                    password: Validator.create().requiredIf('authorization', 'basic_auth', 'Password is required'),
                                    bearer_token: Validator.create().requiredIf('authorization', 'bearer_token', 'Bearer token is required')
                                }}>
                                {({ values }) => (
                                    <>
                                        {this.state.message &&
                                            <Alert variant={this.state.variant} dismiss={() => {
                                                this.setState({
                                                    message: null
                                                });
                                            }}>
                                                {this.state.message}
                                            </Alert>
                                        }

                                        <div className="row">
                                            <div className={this.state.id ? "col-md-6" : "col-md-12"}>
                                                <Form.Group name="name" label="Name" required>
                                                    <Form.Input type="text" placeholder="Enter name" id="name" name="name" />
                                                </Form.Group>
                                            </div>

                                            {this.state.id &&
                                                <div className="col-md-6 col-xs-12">
                                                    <Form.Group name="uid" label="Unique ID" required>
                                                        <Form.Input type="text" name="uid" disabled />
                                                    </Form.Group>
                                                </div>
                                            }
                                        </div>
                                        <div className="row m-t-10">
                                            <div className="col-md-4 col-xs-12">
                                                <Form.Group name="is_external_api" required>
                                                    <Form.Checkbox value={1} name="is_external_api" label="Is external API ?" uncheckedValue={0} valueType="number" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-4 col-xs-12">
                                                <Form.Group name="action_if_api_not_success">
                                                    <Form.Checkbox value="throw_exception" name="action_if_api_not_success" label="Throw exception if API fails" uncheckedValue={null} />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-4 col-xs-12">
                                                <Form.Group name="data_dictionary_module_name">
                                                    <Form.Checkbox value="case_creation_save_request" name="data_dictionary_module_name" label="Use save request module" uncheckedValue={null} />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2">
                                                <Form.Group name="method" label="Method" required>
                                                    <Form.Select id="method" name="method">
                                                        {this.methods.map(method => (
                                                            <option key={method}>{method}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>

                                            {values.is_external_api === 1 &&
                                                <div className="col-md-8">
                                                    <Form.Group name="url" label="URL" required>
                                                        <Form.Input type="text" placeholder="Enter URL" id="url" name="url" />
                                                    </Form.Group>
                                                </div>
                                            }

                                            {!values.is_external_api &&
                                                <>
                                                    <div className="col-md-2">
                                                        <Form.Group name="domain" label="Domain" required>
                                                            <Form.Select id="domain" name="domain">
                                                                {this.state.domains.map(domain => (
                                                                    <option key={domain}>{domain}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Group name="rel_url" label="Relative URL" required>
                                                            <Form.Input type="text" placeholder="Enter relative URL" id="rel_url" name="rel_url" />
                                                        </Form.Group>
                                                    </div>
                                                </>
                                            }

                                            <div className="col-md-2">
                                                <label htmlFor="testing" className="col-form-label">
                                                    &nbsp;
                                                </label>
                                                <div className="d-grid">
                                                    <button type="button" className="btn btn-success" disabled>
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-xs-12">
                                                <Form.Group name="isCacheEnabled" label="Cache settings">
                                                    <Form.Checkbox value={1} name="isCacheEnabled" label="Enable cache" uncheckedValue={0} valueType="number" />
                                                </Form.Group>

                                                <Form.Group name="description" label="Description">
                                                    <Form.Textarea type="textarea" name="description" placeholder="Describe here..." rows="8" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 col-xs-12">
                                                <Form.Group name="is_external_action" label="Is external action ?">
                                                    <Form.Radio name="is_external_action" values={[
                                                        {
                                                            "label": "Yes",
                                                            "value": 1
                                                        },
                                                        {
                                                            "label": "No",
                                                            "value": 0
                                                        }
                                                    ]} valueType="number" />
                                                </Form.Group>
                                                <Form.Group name="conditions" label="Conditions">
                                                    <Form.JSONEditor
                                                        schema={conditionSchema}
                                                        allowedModes={[]}
                                                        htmlElementProps={{
                                                            name: "conditions"
                                                        }} />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 mt-3">
                                                <Tabs active="authorization">
                                                    <Tabs.Pane id="authorization" title="Authorization">
                                                        <p>The authorization header will be automatically generated when you send the request.</p>
                                                        <Form.Group name="authorization" label="Type" required>
                                                            <Form.Select id="authorization" name="authorization" onChange={e => {
                                                                const { ...data } = this.state.data;
                                                                data.authorization = {
                                                                    type: e.target.value
                                                                };
                                                                this.setState({ data });
                                                            }}>
                                                                {this.authorizationTypes.map(item => <option key={item.key} value={item.key}>{item.value}</option>)}
                                                            </Form.Select>
                                                        </Form.Group>

                                                        {this.state.data.authorization.type === 'basic_auth' &&
                                                            <div className='row' id="basicAuthDetails">
                                                                <div className="col-xs-12 col-md-6">
                                                                    <Form.Group name="username" label="Username" required>
                                                                        <Form.Input type="text" placeholder="Enter username" id="username" name="username" />
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col-xs-12 col-md-6">
                                                                    <Form.Group name="password" label="Password" required>
                                                                        <Form.Input type="password" placeholder="Enter password" id="password" name="password" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.data.authorization.type === 'bearer_token' &&
                                                            <div className='row' id="bearerTokenDetails">
                                                                <div className="col-xs-12">
                                                                    <Form.Group name="bearer_token" label="Bearer Token" required>
                                                                        <Form.Input type="text" placeholder="Enter token" id="bearer_token" name="bearer_token" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        }
                                                    </Tabs.Pane>
                                                    <Tabs.Pane id="params" title="Params">
                                                        <KeyValueType
                                                            variant="params"
                                                            data={this.state.data.params || []}
                                                            onUpdate={params => {
                                                                this.data.params = params;
                                                            }} />
                                                    </Tabs.Pane>
                                                    <Tabs.Pane id="headers" title="Headers">
                                                        <KeyValueType
                                                            variant="headers"
                                                            types={{
                                                                string: "String",
                                                                number: "Number",
                                                                boolean: "Boolean"
                                                            }}
                                                            data={this.state.data.headers || []}
                                                            onUpdate={headers => {
                                                                this.data.headers = headers;
                                                            }} />
                                                    </Tabs.Pane>
                                                </Tabs>

                                            </div>
                                            <div className="col-xs-12">
                                                <Form.Submit />
                                            </div>
                                        </div>

                                        {(this.state.id && this.state.confirmModal.show) &&
                                            <Confirm {...this.state.confirmModal} />
                                        }
                                    </>
                                )}
                            </Form>
                        </Tabs.Pane>

                        {this.state.id &&
                            <Tabs.Pane id="changelog" title="Changelog">
                                <Changelog />
                            </Tabs.Pane>
                        }
                    </Tabs>
                </Box>
            </Spinner >
        )
    }
}

export default Add;