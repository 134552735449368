import React from "react";
import { SecuredApi as axios } from "../axios";

export default class Component {
    constructor(component) {
        if (component) {
            this.component = component;
        }
    }

    async list(params = {}) {
        try {
            const response = await axios.get('/components/');
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data.items
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    // Fetch a specific field information.
    async get(id) {
        try {
            const response = await axios.get('/components/' + id);
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message,
                pageNotFound: statusCode === 404
            });
        }
    }

    // Store Basic Details of a component
    async create(params = {}, setSubmiting, setErrors) {
        try {
            const response = await axios.post('/components', params);
            const data = await response.data;
            setSubmiting(false);

            this.component.props.history.replace('/ui/components/update/' + data.data.Data.id);

        } catch ({ message, statusCode, response }) {
            setSubmiting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    // Update the component with ELEMENTS...
    async update(id, params = {}, setSubmiting, setErrors) {
        try {
            const response = await axios.put('/components/' + id, params);

            // eslint-disable-next-line
            const data = await response.data;
            setSubmiting(false);

            this.component.setState({
                variant: "success",
                message: "Component updated successfully"
            });
        } catch ({ message, statusCode, response }) {
            setSubmiting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete('/components/' + id);

            // eslint-disable-next-line
            const data = await response.data;
            this.component.props.history.replace('/ui/components');
        } catch ({ message, statusCode, response }) {
            if (statusCode === 404 || statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    variant: "danger",
                    preventUnload: true,
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }

    async createOverride(componentID, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.post('/components/' + componentID + '/overrides', params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                error: null,
                timestamp: + new Date(),
                override: {
                    conditions: {},
                    return_values: {}
                }
            });

            this.component.props.onAdd(params, data.data.Data.overrides);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    error: response.data.data.Message
                });
            } else if (statusCode === 409) {
                this.component.setState({
                    error: (
                        <>
                            An override with ID <strong>{response.data.data.Errors.id}</strong> already exists with exact same conditions. <u className="cursor-pointer">Click here</u> to update that override.
                        </>
                    )
                });
            }
        }
    }

    async updateOverride(componentId, overrideId, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.put('/components/' + componentId + '/overrides/' + overrideId, params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                error: null,
                timestamp: + new Date(),
                override: {
                    conditions: {},
                    return_values: {}
                }
            });

            this.component.props.onUpdate(params, data.data.Data.overrides);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    error: response.data.data.Message
                });
            } else if (statusCode === 409) {
                this.component.setState({
                    error: (
                        <>
                            An override with ID <strong>{response.data.data.Errors.id}</strong> already exists with exact same conditions. <u className="cursor-pointer">Click here</u> to update that override.
                        </>
                    )
                });
            }
        }
    }

    async deleteOverride(componentId, overrideId) {
        try {
            const response = await axios.delete('/components/' + componentId + '/overrides/' + overrideId);
            const data = await response.data;

            this.component.setState(prev => ({
                timestamp: + new Date(),
                variant: "success",
                message: "Override deleted successfully",
                data: data.data.Data.overrides,
                confirmModal: {
                    ...prev.confirmModal,
                    isLoading: false,
                    show: false
                }
            }));
        } catch ({ message, statusCode, response }) {
            if (statusCode === 404 || statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    variant: "danger",
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }

    // Update the COMPONENT with elements ( fields / components )...
    async saveDesign(id, params = {}) {
        try {
            const response = await axios.put(`components/${id}/design`, params);

            // eslint-disable-next-line
            const data = await response.data.data;
            return data;
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                return { status: "Failure", message: response.data.data.Message, data: response.data.data.Errors };
            }
        }
    }
}