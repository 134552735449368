import React, { Component } from "react";
import { Link } from "react-router-dom";
import Box from "../../../components/Box";
import List from "../../../components/List";
import Alert from "../../../components/Alert";
import Spinner from "../../../components/Spinner";
import { ComponentService } from "../../../services";

class PageComponent extends Component {
    constructor(props) {
        super(props);
        this.services = {
            component: new ComponentService(this)
        }

        this.state = {
            data: [],
            error: null,
            isLoading: true
        }
    }

    getColummns = () => [
        {
            name: "Name",
            filterKey: "name",
            width: 200,
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Key",
            filterKey: "uid",
            width: 200,
            selector: row => row.uid,
            sortable: true
        },
        {
            name: "Type",
            filterKey: "type",
            width: 100,
            selector: row => row.type,
            sortable: true
        },
        {
            name: "Created",
            width: 130,
            title: row => new Date(row.created_at).toString(),
            selector: row => new Date(row.created_at).toDateString(),
            sortable: true
        },
        {
            name: "Updated",
            width: 130,
            title: row => new Date(row.updated_at).toString(),
            selector: row => new Date(row.updated_at).toDateString(),
            sortable: true
        },
        {
            name: "Actions",
            width: 100,
            selector: row => (
                <Link className="btn btn-default btn-xs m-r-5" to={'/ui/components/update/' + row.id}>
                    Edit
                </Link>
            )
        }
    ];

    componentDidMount() {
        this.services.component.list();
    }

    render() {
        return (
            <Box
                allowFullScreen
                title='Components'
                buttons={{
                    value: '+ Add New',
                    type: 'link',
                    class: 'btn btn-sm btn-success',
                    href: '/ui/components/add'
                }}>
                <Spinner isLoading={this.state.isLoading}>
                    {this.state.error
                        ? 
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                        : <List columns={this.getColummns()} data={this.state.data} />
                    }
                </Spinner>
            </Box>
        )
    }
}

export default PageComponent;