import React from 'react';

const SMSContent = props => {
    const [sms, setSMS] = React.useState({
        name: '', 
        content: ''
    });

    React.useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            setSMS({
                name: props.data.name, 
                content: props.data.content
            });
        }
    }, [props.data]);

    return (
        <div className="row">
            <div className="col-md-12 mb-4">
                <label htmlFor="smsName" className="form-label">SMS Name <span className="text-danger">*</span></label>
                <input 
                    type="text" 
                    id="smsName" 
                    name="smsName" 
                    value={sms.name} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setSMS({...sms, name: e.target.value})} 
                />
            </div>
            <div className="col-md-12 mb-4">
                <label htmlFor="smsName" className="form-label">SMS Content <span className="text-danger">*</span></label>
                <textarea 
                    id="smsContent" 
                    name="smsContent" 
                    className="form-control" 
                    defaultValue={sms.content} 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setSMS({...sms, content: e.target.value})}
                />
            </div>
            <div className="col-md-12 d-flex justify-content-end gap-4">
                {
                    props.mode === 'add' && 
                        <button 
                            className="btn btn-info fw-bold fs-4" 
                            onClick={() => props.submitTemplate(sms)}
                        >Add</button>
                }
                {props.children}
            </div>
        </div>
    );
}

export default SMSContent;