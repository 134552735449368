import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "../../../components/Box";
import List from "../../../components/List";
import { useProcessManagement } from "../../../hooks";
import Spinner from "../../../components/Spinner";
import Alert from "../../../components/Alert";

const columns = [
	{
		name: "Name",
		filterKey: "name",
		width: 200,
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: "Status",
		filterKey: "status",
		width: 200,
		selector: (row) => row.status,
		sortable: true,
	},
	{
		name: "Created",
		width: 130,
		title: row => new Date(row.created_at).toString(),
		selector: row => new Date(row.created_at).toDateString(),
		sortable: true
	},
	{
		name: "Updated",
		width: 130,
		title: row => new Date(row.updated_at).toString(),
		selector: row => new Date(row.updated_at).toDateString(),
		sortable: true
	},
	{
		name: "Actions",
		width: 100,
		selector: (row) => (
			<Link
				className="btn btn-default btn-xs m-r-5"
				to={"/process/update/" + row.id}
			>
				Edit
			</Link>
		),
	},
];

const ProcessConfiguration = () => {
	const [data, setData] = React.useState([]);
	const { processList } = useProcessManagement();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState();

	React.useEffect(() => {
		async function fetchData() {
			try {
				setIsLoading(true);
				const response = await processList();
				setData(response.data.Data.items);
			} catch ({ message, statusCode }) {
				setError(message);
			}
			setIsLoading(false);
		}

		fetchData();
	}, []);
	return (
		<Spinner isLoading={isLoading}>
			<Box
				allowFullScreen
				title="Process configurations"
				buttons={{
					value: "+ Add",
					type: "link",
					class: "btn btn-sm btn-success",
					href: "/process/add",
				}}
			>
				{error && (
					<Alert
						variant="danger"
						dismiss={() => {
							setError(null);
						}}
					>
						{error}
					</Alert>
				)}
				<List columns={columns} data={data} />
			</Box>
		</Spinner>
	);
};

export default ProcessConfiguration;
