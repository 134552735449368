import React, { useEffect, useState } from "react";
import Tabs from "../../components/Tabs";
import Alert from "../../components/Alert";
import Spinner from "../../components/Spinner";
import ComingSoon from "../../components/ComingSoon";
import { SecuredApi as axios } from "../../services/axios";

const CaseDetails = ({ requestId = null }) => {
    const [state, updateState] = useState({
        error: null,
        details: null,
        isLoading: true
    });

    useEffect(() => {
        if (requestId) {
            fetchDetails();
        }
    }, [requestId]);

    const fetchDetails = async () => {
        try {
            const response = await axios.get('/api/customer-portal/case/detail/' + requestId);
            const data = await response.data.data.Data.RequestData;

            updateState({
                error: null,
                isLoading: false,
                details: data.Case
            });
        } catch ({ message, statusCode }) {
            updateState({
                details: null,
                error: message,
                isLoading: false
            });
        }
    }

    return (
        <Spinner isLoading={requestId && state.isLoading}>
            {state.details &&
                <>
                    <h4>
                        <strong>Case: <u>{requestId}</u></strong>
                    </h4>
                    <Tabs active="summary" className="m-t-15">
                        <Tabs.Pane id="summary" title="Summary">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <th>Status</th>
                                        <td>{state.details.StatusText}</td>
                                    </tr>
                                    <tr>
                                        <th>Outcome</th>
                                        <td>{state.details.RequestDerivedKeyValues ? state.details.RequestDerivedKeyValues.RequestOutcome : 'Unknown'}</td>
                                    </tr>
                                    <tr>
                                        <th>Staff ID</th>
                                        <td>{state.details.StaffCode}</td>
                                    </tr>
                                    <tr>
                                        <th>Product</th>
                                        <td>{state.details.ProductDescription}</td>
                                    </tr>
                                    <tr>
                                        <th>Case type</th>
                                        <td>{state.details.RequestUserInputs ? state.details.RequestUserInputs.CaseType : 'Unknown'}</td>
                                    </tr>
                                    <tr>
                                        <th>Created on</th>
                                        <td>
                                            {new Date(state.details.Created).toString()}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Last updated</th>
                                        <td>
                                            {new Date(state.details.Updated).toString()}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Tabs.Pane>
                        <Tabs.Pane id="product_info" title="Product">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <th>Product</th>
                                        <td>{state.details.ProductDescription}</td>
                                    </tr>
                                    <tr>
                                        <th>Item No</th>
                                        <td>{state.details.ItmId}</td>
                                    </tr>
                                    <tr>
                                        <th>Model Number</th>
                                        <td>{state.details.ItmModelNumber}</td>
                                    </tr>
                                    <tr>
                                        <th>Product Group</th>
                                        <td>{state.details.ProductGroup}</td>
                                    </tr>
                                    <tr>
                                        <th>Brand</th>
                                        <td>{state.details.ProductBrand}</td>
                                    </tr>
                                    <tr>
                                        <th>Vendor</th>
                                        <td>{state.details.VendorName}</td>
                                    </tr>
                                    <tr>
                                        <th>Serial Number</th>
                                        <td>{state.details.ProductSerialNumber}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Tabs.Pane>
                        <Tabs.Pane id="fault_info" title="Fault/Paperwork">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <th>Abuse/Misuse</th>
                                        <td>{state.details.Abuse}</td>
                                    </tr>
                                    <tr>
                                        <th>Repaired previously</th>
                                        <td>{state.details.RepairedPreviously}</td>
                                    </tr>
                                    <tr>
                                        <th>Marked/Scratched</th>
                                        <td>{state.details.Marked}</td>
                                    </tr>
                                    <tr>
                                        <th>Fault report</th>
                                        <td>{state.details.Report}</td>
                                    </tr>
                                    <tr>
                                        <th>Fault code</th>
                                        <td>{state.details.FaultCode}</td>
                                    </tr>
                                    <tr>
                                        <th>Retailer can Replicate</th>
                                        <td>{state.details.FaultReplicate}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Tabs.Pane>
                        <Tabs.Pane id="proof_info" title="Proof">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <th>Purchase Date</th>
                                        <td>{new Date(state.details.DateOfPurchase).toString()}</td>
                                    </tr>
                                    <tr>
                                        <th>Purchase Price</th>
                                        <td>${state.details.OriginalPrice}</td>
                                    </tr>
                                    <tr>
                                        <th>Receipt/Tax invoice Number</th>
                                        <td>{state.details.Receipt}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Tabs.Pane>
                        <Tabs.Pane id="warranty_info" title="Warranty">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <th>Within Manufacturer's DOA</th>
                                        <td>{state.details.InDoa}</td>
                                    </tr>
                                    <tr>
                                        <th>Within warranty period</th>
                                        <td>{state.details.Warranty}</td>
                                    </tr>
                                    <tr>
                                        <th>Within ACL period</th>
                                        <td>{state.details.Acl}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Tabs.Pane>
                        <Tabs.Pane id="repairer_info" title="Service provider">
                            <ComingSoon />
                        </Tabs.Pane>
                        <Tabs.Pane id="customer_info" title="Customer">
                            <ComingSoon />
                        </Tabs.Pane>
                    </Tabs>
                </>
            }

            {!state.details &&
                <Alert variant="secondary">
                    Details will be loaded here
                </Alert>
            }
        </Spinner>
    )
}

export default CaseDetails;