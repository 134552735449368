import React, { Component } from "react";
import { Link } from "react-router-dom";
import List from "../../components/List";
import Box from "../../components/Box";
import Alert from "../../components/Alert";
import Spinner from "../../components/Spinner";
import { CategoryService } from "../../services";

class Category extends Component {
    constructor(props) {
        super(props);
        this.services = {
            category: new CategoryService(this)
        }

        this.state = {
            data: [],
            error: null,
            isLoading: true
        }
    }

    componentDidMount() {
        this.services.category.list();
    }

    getColumns = () => [
        {
            name: "Name",
            filterKey: "name",
            width: 150,
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Key",
            filterKey: "uid",
            selector: row => row.uid,
            sortable: true
        },
        {
            name: "Business Terms",
            filterKey: "business_terms",
            selector: row => {
                if (row.business_terms && typeof row.business_terms === 'string') {
                    const terms = row.business_terms.split(',');
                    return terms.map((term, index) => {
                        term = term.trim();

                        return term ? (
                            <span key={index} className="badge badge-primary badge-pill m-r-5 m-b-5"> {term} </span>
                        ) : null;
                    });
                } else {
                    return "Rules can have any Business Term";
                }
            }
        },
        {
            name: "Sort order",
            width: 110,
            selector: row => row.sort_order,
            sortable: true
        },
        {
            name: "Created",
            width: 130,
            title: row => new Date(row.created_at).toString(),
            selector: row => new Date(row.created_at).toDateString(),
            sortable: true
        },
        {
            name: "Updated",
            width: 130,
            title: row => new Date(row.updated_at).toString(),
            selector: row => new Date(row.updated_at).toDateString(),
            sortable: true
        },
        {
            name: "Actions",
            width: 150,
            selector: row => (
                <>
                    <Link className="btn btn-default btn-xs m-r-5" to={'/rules/categories/update/' + row.id}>
                        Edit
                    </Link>
                    <Link className="btn btn-default btn-xs m-r-5" to={'/rules/?category=' + row.id}>
                        View Rules
                    </Link>
                </>
            )
        }
    ];

    render() {
        return (
            <Box
                allowFullScreen
                title='Rule categories'
                buttons={{
                    value: '+ Add',
                    type: 'link',
                    class: 'btn btn-sm btn-success',
                    href: '/rules/categories/add'
                }}>
                <Spinner isLoading={this.state.isLoading}>
                    {this.state.error
                        ?
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                        : <List columns={this.getColumns()} data={this.state.data} />
                    }
                </Spinner>
            </Box>
        )
    }
}

export default Category;