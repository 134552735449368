import React from "react";

const Filter = ({ disabled = false, placeholder = 'Type and press enter to search', filterHandler = () => { } }) => {
    const keyUpHandler = e => {
        const code = e.keyCode || e.charCode || e.which;
        if (code === 13) {
            filterHandler(e.target.value.trim());
        }
    }

    return (
        <div className="d-flex justify-content-between">
            <label className="col-form-label m-r-5">
                <strong>Search:</strong>
            </label>
            <input className="form-control" type="text" placeholder={placeholder} onKeyUp={keyUpHandler} disabled={disabled} />
        </div>
    )
}

export default Filter;