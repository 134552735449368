import { SecuredApi as axios } from "../axios";
import Helper from "../../utils/Helper";

export default class SmsTemplate {
    constructor(component) {
        this.component = component;
    }

    async list(params) {
        try {
            const response = await axios.get('commpack/sms?' + Helper.serializeObject(params));
            const data = await response.data.data.Data.items;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    async get(id) {
        try {
            const response = await axios.get('commpack/sms/' + id);
            const data = await response.data.data.Data;
            this.component.setState({
                isLoading: false,
                error: null,
                data: data
            });
        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
        }
    }

    async save(fields = {}, setSubmitting) {
        try {
            const response = await axios.post('commpack/sms', Helper.serializeObject(fields));
            const data = await response.data.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data
            });

            this.component.props.history.push('/sms/');
        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
        } finally {
            setSubmitting(false);
        }
    }

    async deleteAction(id, eventId) {
        try {
            // eslint-disable-next-line
            const response = await axios.delete('commpack/sms/' + id);
            this.component.setState({
                isLoading: false,
                error: null
            });

            this.component.props.history.push('/sms/');
        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
        }
    }

    async update(id, fields = {}, setSubmitting) {
        try {
            // eslint-disable-next-line
            const response = await axios.put('commpack/sms/' + id, Helper.serializeObject(fields));
            this.component.setState({
                isLoading: false,
                toastShow: true,
                error: null,
                message: 'Sms updated successfully'
            });

        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
        } finally {
            setSubmitting(false);
        }
    }
}