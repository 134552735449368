import React from 'react';
import { HTMLEditor } from '../../../../components/Form/HTMLEditor';
import PAPERWORKContent from './PAPERWORKContent';

const EMAILContent = props => {
    const [email, setEMAIL] = React.useState({
        to: '', 
        cc: '', 
        bcc: '', 
        from: '', 
        name: '', 
        return: '', 
        subject: '', 
        to_from: '', 
        reply_to: '', 
        from_whom: '', 
        paperworks: [], 
        is_enabled: '', 
        html_content: '', 
        text_content: '', 
        show_paperwork: false, 
        is_connote_doc_use: '', 
        fallback_from_whom: '', 
        is_connote_label_use: '', 
    });

    React.useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            setEMAIL({
                to: props.data.to, 
                cc: props.data.cc, 
                bcc: props.data.bcc, 
                from: props.data.from, 
                name: props.data.name, 
                return: props.data.return, 
                subject: props.data.subject, 
                to_from: props.data.to_from, 
                reply_to: props.data.reply_to, 
                from_whom: props.data.from_whom, 
                is_enabled: props.data.is_enabled, 
                paperworks: props.data.paperworks, 
                html_content: props.data.html_content, 
                text_content: props.data.text_content, 
                show_paperwork: props.data.show_paperwork, 
                is_connote_doc_use: props.data.is_connote_doc_use, 
                fallback_from_whom: props.data.fallback_from_whom, 
                is_connote_label_use: props.data.is_connote_label_use, 
            });
        }
    }, [props.data]);

    return (
        <div className="row">
            <div className="col-md-12 mb-4">
                <label htmlFor="name" className="form-label">Name</label>
                <input 
                    type="text" 
                    id="name" 
                    name="name" 
                    value={email.name} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, name: e.target.value})} 
                />
            </div>
            <div className="col-md-12 mb-4">
                <label htmlFor="subject" className="form-label">Subject</label>
                <input 
                    type="text" 
                    id="subject" 
                    name="subject" 
                    value={email.subject} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, subject: e.target.value})} 
                />
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="emailTo" className="form-label">To</label>
                <input 
                    type="text" 
                    id="emailTo" 
                    name="emailTo" 
                    value={email.to} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, to: e.target.value})} 
                />
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="emailReplyTo" className="form-label">Reply To</label>
                <input 
                    type="text" 
                    id="emailReplyTo" 
                    name="emailReplyTo" 
                    value={email.reply_to} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, reply_to: e.target.value})} 
                />
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="emailCC" className="form-label">CC</label>
                <input 
                    type="text" 
                    id="emailCC" 
                    name="emailCC" 
                    value={email.cc} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, cc: e.target.value})} 
                />
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="emailBCC" className="form-label">BCC</label>
                <input 
                    type="text" 
                    id="emailBCC" 
                    name="emailBCC" 
                    value={email.bcc} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, bcc: e.target.value})} 
                />
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="emailToWhom" className="form-label">To Whom</label>
                <input 
                    type="text" 
                    id="emailToWhom" 
                    name="emailToWhom" 
                    value={email.to_from} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, to_from: e.target.value})} 
                />
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="emailFrom" className="form-label">From</label>
                <input 
                    type="text" 
                    id="emailFrom" 
                    name="emailFrom" 
                    value={email.from} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, from: e.target.value})} 
                />
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="emailEFromWhom" className="form-label">EFrom Whom</label>
                <input 
                    type="text" 
                    id="emailEFromWhom" 
                    name="emailEFromWhom" 
                    value={email.from_whom} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, from_whom: e.target.value})} 
                />
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="emailFallbackFromWhom" className="form-label">Fallback from Whom</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="emailFallbackFromWhom" 
                    name="emailFallbackFromWhom" 
                    value={email.fallback_from_whom} 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, fallback_from_whom: e.target.value})} 
                />
            </div>
            <div className="col-md-12 mb-4">
                <label htmlFor="emailReturn" className="form-label">Return</label>
                <input 
                    type="text" 
                    id="emailReturn" 
                    name="emailReturn" 
                    value={email.return} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, return: e.target.value})} 
                />
            </div>
            <div className="col-md-12 mb-2">
                <label htmlFor="emailTextContent" className="form-label">Text Content</label>
                <textarea 
                    rows="5" 
                    type="text" 
                    id="emailTextContent" 
                    name="emailTextContent" 
                    className="form-control" 
                    defaultValue={email.text_content} 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setEMAIL({...email, text_content: e.target.value})} 
                />
            </div>
            <div className="col-md-12 mb-2">
                <label htmlFor="emailHtmlContent" className="form-label">HTML Content</label>
                <HTMLEditor 
                    id="emailHtmlContent" 
                    name="emailHtmlContent" 
                    className="form-control" 
                    value={email.html_content} 
                    disabled={props.mode === "preview" && true} 
                    onChange={(value) => setEMAIL({...email, html_content: value})} 
                />
            </div>
            <div className="col-md-12 mb-2">
                <div className="col-12">
                    <div className="form-check form-switch">
                        <input 
                            role="switch" 
                            type="checkbox" 
                            id="integratePaperwork" 
                            className="form-check-input" 
                            checked={email.show_paperwork} 
                            onClick={e => setEMAIL({...email, show_paperwork: e.target.checked})} 
                        />
                        <label 
                            className="form-check-label" 
                            htmlFor="integratePaperwork"
                        >
                            {!email.show_paperwork ? "I" : "Do not i"}ntegrate Paperwork
                        </label>
                    </div>
                </div>
                {email.show_paperwork && <PAPERWORKContent fullOptions={props.paperworkList} assignedPaperworks={email.paperworks} pluckPaperworks={options => setEMAIL({...email, paperworks: options})} />}
            </div>
            <div className="col-md-12 d-flex justify-content-end gap-4">
                {
                    props.mode === 'add' && 
                        <button 
                            className="btn btn-info fw-bold fs-4" 
                            onClick={() => props.submitTemplate(email)}
                        >Add</button>
                }
                {props.children}
            </div>
        </div>
    );
}

export default EMAILContent;