import React from 'react';
import './style.css';

const Toast = props => {
    const { message, position, title, action, handleClick } = props;

    return (
        <div className={`notification-container ${position}`} >
            <div className={`toast ${action}`}>
                <div className="toast-header">
                    <strong className="me-auto">{title}</strong>
                    <button 
                        type="button" 
                        className="btn-close float-right" 
                        onClick={handleClick}
                    ></button>
                </div>
                <div className="toast-body">
                    {message}
                </div>
            </div>
        </div>
    );
}
export default Toast;