import React from "react";
import Modal from "../Modal";

export default ({ title = 'Confirm', message, onConfirm, onCancel, isLoading = false, isLoadingText = 'Please wait...' }) => (
    <Modal keyboard={false} backdrop="static">
        <Modal.Header close={false}>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
            <button
                type="button"
                onClick={onConfirm}
                disabled={isLoading}
                className="btn btn-success"
            >
                {isLoading ? isLoadingText : 'Yes'}
            </button>
            <button
                type="button"
                onClick={onCancel}
                disabled={isLoading}
                className="btn btn-danger"
            >No</button>
        </Modal.Footer>
    </Modal>
)