import React from "react";
import PropTypes from "prop-types";
import Suggestion from "./Suggestion";
import EditableInput from "../EditableInput";
import styles from "./InputGroup.module.css";

class Autocomplete extends React.Component {
    static propTypes = {
        searchable: PropTypes.bool, 
        inputClass: PropTypes.string, 
        parentClass: PropTypes.string, 
        url: PropTypes.string.isRequired, 
        noOptionsMessage: PropTypes.string
    }

    constructor(props) {
        super(props);

        this.state = {
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: "",
            // Whether auto-search is loading
            isLoading: false,
            // final-selected-value
            selectedValue: {}, 
        };
    }
    searchSuggestions = () => {
        console.log(this.props.url);
        if (this.state.userInput !== "") {
            const filteredSuggestions = this.props.url.filter(suggestion =>
                suggestion.toLowerCase().indexOf(this.state.userInput.toLowerCase()) > -1
            );
            this.setState({ filteredSuggestions, showSuggestions: true });
        }
    }

    handleSelectValue = e => {
        const obj = {name: e.currentTarget.id, value: e.currentTarget.innerText}
        this.setState({
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText,
            selectedValue: obj, 
        });
        this.props.onSelector(obj);
    }

    onRemoveSelected = () => {
        this.setState({ selectedValue: {} });
        this.props.onSelector({ value: ""});
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.searchable 
            && this.props.textChange 
            && Object.keys(this.state.selectedValue).length === 0 
            && this.state.userInput !== "" 
            && this.state.userInput.length >= 3 
            && prevState.userInput !== this.state.userInput 
        ) {
            this.searchSuggestions();
        }

        if (prevProps.defaultValue !== this.props.defaultValue) {
            this.setState({ 
                userInput: this.props.defaultValue, 
                selectedValue: this.props.defaultValue !== "" ? {name: this.props.defaultValue, value: this.props.defaultValue} : {}
            });
        }
    }

    componentDidMount() {
        if (this.props.defaultValue !== '') {
            this.setState({ 
                userInput: this.props.defaultValue, 
                selectedValue: {name: this.props.defaultValue, value: this.props.defaultValue}
            });
        }
    }

    render() {
        const {
            handleSelectValue,
            state: {
                filteredSuggestions,
                showSuggestions,
                userInput,
                isLoading, 
                selectedValue
            }
        } = this;

        if (!this.props.searchable) {
            return (
                <input 
                    type="text" 
                    value={userInput} 
                    disabled={this.props.disable} 
                    className={this.props.inputClass} 
                    onChange={e => {
                        this.setState({ userInput: e.currentTarget.value });
                        this.props.onSelector({ name: e.currentTarget.value, value: e.currentTarget.value });
                    }} 
                />
            );
        }

        return (
            <div className={`position-relative ${this.props.parentClass}`}>
                <div className={`${styles.InputGroup} ${this.props.groupClass}`}>
                    <EditableInput 
                        html={userInput} 
                        disabled={isLoading} 
                        selectedValue={selectedValue} 
                        onRemoveSelected={this.onRemoveSelected} 
                        detectEvent={key => key === 13 && this.searchSuggestions()} 
                        customClass={`${styles.InputElement} ${styles.Right} ${this.props.editableClass}`} 
                        onChange={userInput => userInput !== "" ? this.setState({ userInput }) : this.setState({ filteredSuggestions: [], showSuggestions: false })} 
                    />
                    <span className={styles.iconRight}>
                        <i 
                            className={`fa fa-${this.state.isLoading ? "spinner fa-spin" : "search"}`} 
                            onClick={e => !Object.keys(selectedValue).length > 0 ? this.searchSuggestions() : {}}
                        ></i>
                    </span>
                </div>
                {
                    (showSuggestions && userInput) 
                        && <Suggestion 
                            suggestions={filteredSuggestions} 
                            handleSelectValue={handleSelectValue} 
                            noOptionsMessage={this.props.noOptionsMessage} 
                        />
                }
            </div>
        );
    }
}

Autocomplete.defaultProps = {
    noOptionsMessage: "No suggestions, you're on your own!"
}

export default Autocomplete;