import React, { useRef, useContext, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { renderAuthRoutes as Routes } from '../../routes/render';
import UserContext from '../../contexts/user-context';
import ScrollToTop from '../../components/ScrollToTop';

const AuthLayout = props => {
    const defaultLogoutTime = 5 * 60 * 1000; // 5 minutes

    const autoLogout = process.env.REACT_APP_AUTO_LOGOUT === "true";
    const autoLogoutTime = process.env.REACT_APP_AUTO_LOGOUT_TIME ? process.env.REACT_APP_AUTO_LOGOUT_TIME * 60 * 1000 : defaultLogoutTime;
    const logoutTimerIdRef = useRef(null);
    const userData = useContext(UserContext);

    useEffect(() => {
        const logout = () => {
            if (document.visibilityState === 'hidden') {
                const timeOutId = window.setTimeout(userData.logout, autoLogoutTime);
                logoutTimerIdRef.current = timeOutId;
            } else {
                window.clearTimeout(logoutTimerIdRef.current);
            }
        };

        if (autoLogout === true) {
            document.addEventListener('visibilitychange', logout);
        }

        return () => {
            if (autoLogout === true) {
                document.removeEventListener('visibilitychange', logout);
            }
        };
    }, []);

    return (
        <div className="page-wrapper">
            <Header />
            <Sidebar />
            <div className="content-wrapper">
                <div className="page-content fade-in-up">
                    <ScrollToTop>
                        <Routes redirect={props.redirect} />
                    </ScrollToTop>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default AuthLayout;