import React from "react";
import "./index.css";

export const SolvupTags = [
    'DOA', 'Warranty', 'ACL', 'CGA', 'OOW', '|',
    'Regular', 'Lite', 'Ultra lite', 'Basic', '|',
    'Repairable', 'Non Repairable', '|',
    'LPCO', 'Non LPCO', '|',
    'Abused', 'Non abused', '|',
    'Simple Search', 'Docket Search', '|',
    'Receipt Verified', 'Non Receipt Verified', '|',
    'Customer Item', 'Floorstock', 'RTV', 'Damaged', 'Faulty', '|',
    'In store', 'In home', '|',
    'Replace', 'Repair', 'Refund', 'NoAction', '|',
    'Vendor', 'Consumer', 'Retailer', 'Premium Service', 'Insurer_Ext', '|',
    'Manager Override', 'No Manager Override', '|',
    'No Premium Service', 'Conceirge Premium Service', 'Gold Premium Service', '|',
    'Inhome Eligible', 'Non Inhome Eligible', '|',
    'Instore RA Eligible', 'Non Instore RA Eligible', '|',
    'Damage Assessment Eligible', 'Non Damage Assessment Eligible', '|',
    'InStore Assessment Eligible', 'Non InStore Assessment Eligible', '|'
];

export const Tags = ({ selected = '', show = true, onChange = () => { } }) => {
    let updateVisibility;
    const [list, updateList] = React.useState(SolvupTags);
    const [error, updateError] = React.useState(null);
    [show, updateVisibility] = React.useState(show);

    let updateSelection;
    [selected, updateSelection] = React.useState(selected ? selected.split(', ') : []);

    React.useEffect(() => {
        const [...copy] = list;
        for (const i of selected) {
            if (copy.indexOf(i) === -1) {
                copy.push(i);
            }
        }

        updateList(copy);
    }, []);

    const add = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            const value = e.target.value.trim();
            if (value) {
                const exist = list.find(element => element.toLowerCase() === value.toLowerCase());
                if (!exist) {
                    const [...tags] = list;
                    tags.push(value);
                    updateList(tags);

                    const [...selectedClone] = selected;
                    selectedClone.push(value);
                    updateSelection(selectedClone);

                    updateError(null);
                    onChange(selectedClone);
                    e.target.value = '';

                    updateVisibility(true);
                } else {
                    updateError('Tag already added.');
                }
            } else {
                updateError('Value can not be blank.');
            }
        }
    }

    return (
        <>
            <div className="form-group">
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Type and press enter to add" onKeyDown={add} />
                    <div className="input-group-btn">
                        <button className="btn btn-primary" type="button" onClick={e => {
                            updateVisibility(!show);
                        }}>
                            {show ? 'Hide' : 'Show'}
                        </button>
                    </div>
                </div>
            </div>

            {error && <span className="text-danger">{error}</span>}

            <div className={"tagsContainer mt-2 " + (show ? '' : 'd-none')}>
                {list.map((tag, index) => {
                    return tag === '|' ?
                        <br key={index} /> :
                        <span key={index} className={"badge m-r-5 " + (selected.includes(tag) ? 'bg-primary' : 'bg-secondary')} onClick={e => {
                            const element = e.target;
                            if (element.classList.contains('bg-secondary')) {
                                element.classList.remove('bg-secondary');
                                element.classList.add('bg-primary');
                                const [...selectedClone] = selected;
                                selectedClone.push(element.innerText)
                                updateSelection(selectedClone);
                                onChange(selectedClone);
                            } else {
                                element.classList.remove('bg-primary');
                                element.classList.add('bg-secondary');
                                const [...selectedClone] = selected;
                                const index = selectedClone.indexOf(element.innerText);
                                selectedClone.splice(index, 1);
                                updateSelection(selectedClone);
                                onChange(selectedClone);
                            }
                        }}>{tag}</span>
                })}
            </div>
        </>
    )
};