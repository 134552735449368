import React from "react";
import Form from "../../components/Form";
import RetailerContext from "../../contexts/retailer-context";

const AddBasic = ({ processId, type }) => (
	<>
		<div className="row">
			<div className="col-md-4 col-xs-12">
				<Form.Group name="name" label="Name">
					<Form.Input type="text" placeholder="Enter name" id="name" name="name" />
				</Form.Group>
			</div>
			<div className="col-md-4 col-xs-12">
				<Form.Group name="retailer_id" label="Retailer">
					<Form.Select id="retailer_id" name="retailer_id" disabled={processId}>
						<RetailerContext.Consumer>
							{value => value.map(option => (
								<option key={option.id} value={option.id}>
									{option.name}
								</option>
							))}
						</RetailerContext.Consumer>
					</Form.Select>
				</Form.Group>
			</div>
			<div className="col-md-4 col-xs-12">
				<Form.Group name="user_type" label="User Type">
					<Form.Select id="user_type" name="user_type" disabled={processId}>
						<option value="all">All</option>
						<option value="store">Store</option>
						<option value="callcentre">Callcentre</option>
						<option value="online">Online</option>
					</Form.Select>
				</Form.Group>
			</div>
		</div>
		<div className="row">
			<div className="col-md-4 col-xs-12">
				<Form.Group name="status">
					<Form.Checkbox value="published" name="status" label={`Publish this ${type} ?`} uncheckedValue="draft" />
				</Form.Group>
			</div>
		</div>

		{!processId && (
			<div className="row">
				<div className="col-md-4 col-xs-12">
					<Form.Submit />
				</div>
			</div>
		)}
	</>
)

export default AddBasic;
