import { SecuredApi as axios } from "./axios";

export default class Category {
    constructor(component) {
        this.component = component;
    }

    async list(params = {}) {
        try {
            const response = await axios.get('/rules/categories');
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data.items
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    async get(id) {
        try {
            const response = await axios.get('/rules/categories/' + id);
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message,
                pageNotFound: statusCode === 404
            });
        }
    }

    async create(params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.post('/rules/categories', params);
            const data = await response.data;
            setSubmitting(false);

            this.component.props.history.replace('/rules/categories/update/' + data.data.Data.id);

        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    async update(id, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.put('/rules/categories/' + id, params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                data: data.data.Data,
                variant: "success",
                message: "Category updated successfully"
            });
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete('/rules/categories/' + id);

            // eslint-disable-next-line
            const data = await response.data;
            this.component.props.history.replace('/rules/categories');
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    variant: "danger",
                    preventUnload: true,
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }

    async use() {
        try {
            const response = await axios.get('/rules/categories');
            const data = await response.data;

            this.component.setState({
                categories: data.data.Data.items
            });
        } catch ({ message, statusCode }) {
            console.error(message);
        }
    }
}