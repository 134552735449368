import React from "react";
import Box from "../../components/Box";
import Helper from "../../utils/Helper";
import CaseDetails from "./case-details";
import Tabs from "../../components/Tabs";
import Form from "../../components/Form";
import Alert from "../../components/Alert";
import Spinner from "../../components/Spinner";
import Timeline from "../../components/Timeline";
import { Validator } from "../../utils/Validator";
import { SecuredApi as axios } from "../../services/axios";
import PrintObject from "../../components/Solvup/PrintObject";

class Debug extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            caseId: null,
            message: null,
            collapsed: true,
            user_type: null,
            isLoading: false,
            request_id: null,
            retailer_id: null
        }
    }

    componentDidMount() {
        if (window.location.hash) {
            const caseId = window.location.hash.replace('#', '');
            this.setState({
                caseId: caseId,
                isLoading: true
            }, () => {
                this.fetchDetails(caseId);
            });

        }

        this.unlistenHistoryChange = this.props.history.listen(location => {
            if (location.pathname === '/case-creation/debug') {
                const id = location.hash.replace('#', '');
                if (id) {
                    this.setState({
                        caseId: id,
                        isLoading: true
                    }, () => {
                        this.fetchDetails(id);
                    });
                }
            }
        });
    }

    componentWillUnmount() {
        this.unlistenHistoryChange();
    }

    fetchDetails = async caseId => {
        try {
            const response = await axios.get('/api/case-debugger/' + caseId);
            const data = await response.data.data.Data;

            let state = {};
            if (!data.length) {
                state = {
                    data: [],
                    user_type: null,
                    request_id: null,
                    isLoading: false,
                    retailer_id: null,
                    message: (
                        <div className="lh-lg">
                            No records found. <strong>Possible reasons:</strong>
                            <ul>
                                <li>Incorrect case id.</li>
                                <li>Case is more than one month old and removed automatically.</li>
                            </ul>
                        </div>
                    )
                }
            } else {
                const updated = this.restructureData(data);
                state = {
                    data: updated,
                    message: null,
                    isLoading: false,
                    user_type: data[0].user_type,
                    request_id: data[0].request_id || null,
                    retailer_id: data[0].retailer_id
                }
            }

            this.setState(state);
        } catch ({ message, statusCode }) {
            this.setState({
                message,
                data: [],
                user_type: null,
                request_id: null,
                collapsed: false,
                isLoading: false,
                retailer_id: null
            });
        }
    }

    restructureData = (pageRecords) => {
        const retailerId = pageRecords[0].retailer_id;
        const userType = pageRecords[0].user_type;
        let data = pageRecords.map((item, index) => {
            let validationError = false;
            let details = {
                title: (
                    <span className={item.page_id ? 'text-primary' : 'text-success'}>
                        {item.page_id ? Helper.capitalizeFirstLetterOfSentence(item.page_id).replaceAll('_', ' ') : "Case started"}
                    </span>
                ),
                content: [
                    {
                        title: "Input key values",
                        content: (
                            index > 0
                                ? (
                                    <PrintObject data={item.input_key_values || {}} disabled />
                                ) : (
                                    <Alert variant="secondary">
                                        <i className="fa fa-info-circle"></i> &nbsp;
                                        No input values as case is just started
                                    </Alert>
                                )
                        )
                    }
                ]
            };

            if ('categories' in item) {
                Object.keys(item.categories).forEach(subitem => {
                    const category = item.categories[subitem];

                    let rules = [];
                    if (category.rules.length > 0) {
                        category.rules.forEach(rule => {
                            let content = (
                                <div className="row">
                                    <div className="col-xs-12">
                                        {category.name !== 'Scenario' &&
                                            <a href={"/rules/update/" + rule.id} target="_blank" className="btn btn-xs btn-info">
                                                Edit rule
                                            </a>
                                        }

                                        {category.name === 'Scenario' &&
                                            <>
                                                <a href={"/case-creation/scenarios/update/" + rule.id + '/' + btoa(retailerId + '-' + userType)} target="_blank" className="btn btn-xs btn-info m-r-5">
                                                    Edit scenario
                                                </a>
                                                <a href={"/rules/categories/priortize/" + btoa(retailerId + '-' + userType + '-scenario') + '#' + rule.id} target="_blank" className="btn btn-xs btn-warning">
                                                    Locate in Priortization screen
                                                </a>
                                            </>
                                        }
                                    </div>
                                    <div className="col-md-6 col-xs-12 m-t-5">
                                        <label>Conditions</label>
                                        <PrintObject data={rule.conditions && rule.conditions.length > 0 ? JSON.parse(rule.conditions) : {}} disabled />
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                        <label>Return values</label>
                                        <PrintObject data={rule.return_values || {}} disabled />
                                    </div>
                                </div>
                            )

                            rules.push({
                                content,
                                copy: {
                                    text: rule.name,
                                    title: "Copy rule name"
                                },
                                title: <span className="text-primary">{rule.name}</span>
                            });
                        });
                    }

                    details.content.push({
                        title: (
                            <span className="text-primary">{category.name}</span>
                        ),
                        copy: {
                            text: category.name,
                            title: "Copy category name"
                        },
                        content: (
                            <div>
                                {category.name === 'Scenario' &&
                                    <a href={"/case-creation/scenarios?retailer=" + item.retailer_id + "&user-type=" + item.user_type} className="btn btn-xs btn-info m-l-10" target='_blank'>View all scenarios</a>
                                }

                                {category.name !== 'Scenario' &&
                                    <a href={"/rules?retailer=" + item.retailer_id + "&user-type=" + item.user_type + "&category=" + category.id} className="btn btn-xs btn-info m-l-10" target='_blank'>View all rules</a>
                                }

                                <Timeline
                                    data={rules}
                                    className="m-t-5"
                                    collapsed={this.state.collapsed} />
                            </div>
                        )
                    });
                });
            } else {
                // Validation error
                validationError = true;
                const errors = item.validation_error ? Object.entries(item.validation_error) : [];
                details.content.push({
                    title: (
                        <span className="text-danger">Validation error</span>
                    ),
                    content: (
                        <Alert variant="danger">
                            There was an error with form submission.
                            {errors.length > 0 &&
                                <ul className="lh-lg m-t-5">
                                    {errors.map(([key, value], index) => (
                                        <li key={index}>
                                            <strong>{key}</strong>
                                            {' - ' + value}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </Alert>
                    )
                });
            }

            if (validationError === false) {
                details.content.push({
                    title: "Process rule",
                    content: (
                        <PrintObject data={item.matched_process.conditions || {}} disabled />
                    )
                });
            }

            if (details.content.length > 0) {
                details.content = (
                    <Timeline data={details.content} collapsed={this.state.collapsed} />
                )
            }

            return details;
        });

        const lastRecord = pageRecords[pageRecords.length - 1];

        data.push({
            title: (
                <div className={lastRecord.target_page_id === 'completion' ? "text-success" : (lastRecord.target_page_id ? "text-primary" : "text-danger")}>
                    {Helper.capitalizeFirstLetterOfSentence(lastRecord.target_page_id || lastRecord.page_id).replaceAll('_', ' ')}
                </div>
            ),
            collapsed: false,
            content: (
                <Alert variant="secondary">
                    <i className="fa fa-info-circle"></i> &nbsp;
                    {(lastRecord.target_page_id === 'completion' && lastRecord.request_id)
                        ? <>
                            Case created with ID &nbsp;
                            <a href={process.env.REACT_APP_SOLVUP_URL + 'requests/view/' + lastRecord.request_id} target='_blank'>{lastRecord.request_id}</a>
                        </>
                        : "User has not clicked next button from this page yet."
                    }
                </Alert>
            )
        });

        return data;
    }

    filterHandler = (values, setSubmitting) => {
        setSubmitting(false);
        if (window.location.hash !== '#' + values.temp_request_id) {
            window.location.hash = values.temp_request_id;
        } else {
            this.setState({
                isLoading: true
            }, () => {
                this.fetchDetails(this.state.caseId);
            });
        }
    }

    resetState = () => {
        this.setState({
            data: [],
            caseId: null,
            message: null,
            user_type: null,
            request_id: null,
            collapsed: false,
            isLoading: false,
            retailer_id: null
        });
    }

    render() {
        return (
            <>
                <Box title="Debug">
                    <Form
                        preventUnload={false}
                        onSubmit={this.filterHandler}
                        initialValues={() => {
                            let temp_request_id = null;
                            if (window.location.hash) {
                                temp_request_id = window.location.hash.replace('#', '');
                            }

                            return { temp_request_id };
                        }}
                        validationSchema={{
                            temp_request_id: Validator.create().required()
                        }}>
                        {({ submitting }) => (
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <Form.Group name="temp_request_id" label="Temp request id or Solvup case id" required>
                                        <Form.Input type="text" name="temp_request_id" disabled={submitting || this.state.data.length} id="temp_request_id" />
                                    </Form.Group>
                                    <Form.Group>
                                        <button
                                            type="submit"
                                            className="btn btn-success me-2"
                                            disabled={submitting || this.state.data.length}
                                        >Filter</button>
                                        {
                                            this.state.data.length > 0 &&
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                title="Double click to cancel"
                                                onDoubleClick={() => {
                                                    this.resetState();
                                                    window.location.hash = '';
                                                }}
                                            >Cancel</button>
                                        }
                                    </Form.Group>
                                </div>
                                <div className="col-xs-12 col-md-6 border-start">
                                    <Alert variant="secondary">
                                        <strong><u>Points to consider:</u></strong>
                                        <ul className="mt-2">
                                            <li className='mb-2'>Records more than 1 month will get flushed automatically.</li>
                                            <li className='mb-2'>In case you want to debug another case ID, <strong>Double click</strong> Cancel button.</li>
                                        </ul>
                                    </Alert>
                                </div>
                            </div>
                        )}
                    </Form>
                </Box>

                <Spinner isLoading={this.state.isLoading}>
                    {(this.state.message || this.state.data.length > 0) &&
                        <Box
                            id="debug-area"
                            title="History"
                            allowFullScreen
                            buttons={[
                                {
                                    type: "button",
                                    value: "Refresh",
                                    class: "btn btn-sm btn-primary",
                                    title: "Click to reload the data",
                                    show: this.state.data.length > 0,
                                    clicked: e => {
                                        this.setState({
                                            data: [],
                                            isLoading: true,
                                            collapsed: true
                                        }, () => {
                                            this.fetchDetails(this.state.caseId)
                                        });
                                    }
                                },
                                {
                                    type: "button",
                                    value: this.state.collapsed ? 'Expand all' : 'Collapse all',
                                    class: "btn btn-sm btn-info",
                                    show: this.state.data.length > 0,
                                    clicked: e => {
                                        this.setState({
                                            collapsed: !this.state.collapsed
                                        });
                                    }
                                },
                                {
                                    type: "link",
                                    external: true,
                                    value: (
                                        <>
                                            View on store front &nbsp;
                                            <i className="fa fa-external-link"></i>
                                        </>
                                    ),
                                    class: "btn btn-sm btn-success",
                                    show: this.state.request_id !== null,
                                    target: "_blank",
                                    href: process.env.REACT_APP_SOLVUP_URL + 'requests/view/' + this.state.request_id
                                }
                            ]}>
                            {this.state.message &&
                                <Alert variant="danger">
                                    {this.state.message}
                                </Alert>
                            }

                            {this.state.data.length > 0 &&
                                <Tabs active="steps">
                                    <Tabs.Pane id="steps" title="Debug info">
                                        <Timeline data={this.state.data} collapsed={this.state.collapsed} />
                                    </Tabs.Pane>
                                    <Tabs.Pane id="details" title="Case details">
                                        <CaseDetails requestId={this.state.request_id} />
                                    </Tabs.Pane>
                                </Tabs>
                            }
                        </Box>
                    }
                </Spinner>
            </>
        )
    }
}

export default Debug;