import Template from "./Template";
import BasicEvent from "./BasicEvent";
import React, { Component } from "react";
import Box from "../../../components/Box";
import Form from "../../../components/Form";
import Tabs from "../../../components/Tabs";
import Toast from '../../../components/Toast';
import { EventService } from "../../../services";
import Spinner from "../../../components/Spinner";
import { Validator } from "../../../utils/Validator";
import QueryBuilder from "../../../components/QueryBuilder"
import Helper from "../../../utils/Helper";

class AddEvent extends Component {
    constructor(props) {
        super(props);
        this.services = {
            events: new EventService(this)
        }
    }

    state = {
        id: this.props.match.params.event,
        query: {
            "uid": Helper.guid() + Helper.guid(),
            "gate": "AND",
            "rules": []
        },
        isLoading: this.props.match.params.event !== undefined,
        touched: false,
        error: null,
        data: {},
        toastShow: false,
        cleanNullValue: {},
        smsTemplates: [],
        emailTemplates: [],
        integrationTemplates: [],
        businessTerm:[]
    }

    componentDidMount() {
        if (this.state.id !== undefined) {
            this.services.events.get(this.state.id);
        }
        this.services.events.optionList();
        this.services.events.paperworkList();
    }

    submitHandler = (values, setSubmitting) => {
        if(values.smsTemplate !== undefined && values.smsTemplate.length > 0){
            values.sms_configuration = values.smsTemplate.map(sms=>sms.id);
            delete values.smsTemplate;
        }

        if(values.integrationTemplate !== undefined && values.integrationTemplate.length > 0){
            values.integration_configuration = values.integrationTemplate.map(integration=>integration.id);
            delete values.integrationTemplate;
        }

        if(values.emailTemplate !== undefined && values.emailTemplate.length > 0){
            values.email_configuration = values.emailTemplate.map(email=>email.id);
            delete values.emailTemplate;
        }

        values.conditions = this.state.query;
        delete values.action ;
        if(this.state.id !== undefined) {
            for (var value in values) {
                if (values[value] === null || values[value] === undefined) {
                  delete values[value];
                }
              }
            this.services.events.update(this.state.id, values, setSubmitting);    
        } else {
            this.services.events.save(values, setSubmitting);
        }

    }

    deleteHandler = () => {
        let confirm = window.confirm("Do you want to delete?");
        if (confirm) {
            this.services.events.deleteAction(this.state.id, this.state.data.event_id);
        }
    }

    updateToast = () => {
        this.setState({ toastShow: false });
    }

    render() {
        if (this.state.error) {
            return (
                <div className="alert alert-danger alert-bordered">
                    {this.state.error}
                </div>
            )
        }

        return (
            <Spinner isLoading={this.state.isLoading}>
                <Toast
                    position="top-right"
                    message={this.state.message}
                    handleClick={this.updateToast}
                    title={this.state.error ? 'Error' : 'Message'}
                    action={this.state.toastShow ? 'show' : false}
                />
                <Form
                    onSubmit={this.submitHandler}
                    initialValues={this.state.data}
                    validationSchema={{ event: Validator.create().required() }}
                >
                    {({ values, errors, setValues, setErrors }) => (
                        <Box
                            title="Event"
                            allowFullscreen={true}
                            buttons={[
                                {
                                    value: 'Save',
                                    type: 'submit',
                                    class: 'btn btn-sm btn-success',
                                },
                                {
                                    value: 'Delete',
                                    type: 'button',
                                    class: 'btn btn-sm btn-danger',
                                    clicked: () => this.deleteHandler()
                                }
                            ]}
                        >
                            <BasicEvent />
                            <div className="dropdown-divider"></div>
                            {/* <AddCondition /> */}
                            <QueryBuilder
                                conditionQuery={this.state.query}
                                fetchData={data => {
                                    this.setState({ query: data });
                                }}
                                businessTerm = {this.state.businessTerm}

                            />
                            <div className="dropdown-divider"></div>
                            <div className="alert rounded-2 alert-secondary alert-bordered py-2 mb-2">
                                <span className="text-dark fw-bold">Note:</span>&nbsp;
                                <small className="text-dark fw-bold fst-italic">Single</small> click to bind/unbind template.&nbsp;
                                <small className="text-dark fw-bold fst-italic">Right</small> click to see preview template content.
                            </div>
                            <Tabs active="sms">
                                <Tabs.Pane id="sms" title="SMS">
                                    <Template
                                        templateName="sms"
                                        setValues={setValues}
                                        inputName="smsTemplates"
                                        selectedTemplates={this.state.data.sms_configuration ?? []}
                                    />
                                </Tabs.Pane>
                                <Tabs.Pane id="email" title="EMAIL">
                                    <Template
                                        templateName="email"
                                        setValues={setValues}
                                        inputName="emailTemplates"
                                        selectedTemplates={this.state.data.email_configuration ?? []}
                                        paperworkList = {this.state.paperworkList}
                                    />
                                </Tabs.Pane>
                                <Tabs.Pane id="integration" title="INTEGRATION">
                                    <Template
                                        templateName="integration"
                                        setValues={setValues}
                                        inputName="integrationTemplates"
                                        selectedTemplates={this.state.data.integration_configuration ?? []}
                                    />
                                </Tabs.Pane>
                            </Tabs>
                        </Box>
                    )}
                </Form>
            </Spinner>
        )
    }
}

export default AddEvent;