import React from 'react';
import styles from './Backdrop.module.css';

const Backdrop = props => {
    const newClass = `${styles.backdrop_parent} ${props.className ? props.className : ''}`;
    return (
        <div {...props} className={newClass}>
            {props.children}
            <div className={styles.backdrop}></div>
        </div>
    );
}

export default Backdrop;