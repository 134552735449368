import React, { memo } from "react";
import { Handle, Position } from "react-flow-renderer";

const StartNode = ({
  data,
  isConnectable,
  sourcePosition = Position.Bottom
}) => (
  <>
    <div className="react-flow__label">{data.label}</div>
    <Handle id="no" type="source" position={Position.Bottom} isConnectable={isConnectable} />
  </>
)

StartNode.displayName = "StartNode";

export default memo(StartNode);