import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "../../../components/Box";
import List from "../../../components/List";
import Alert from "../../../components/Alert";
import Spinner from "../../../components/Spinner";
import { useProcessManagement } from "../../../hooks";

const columns = [
	{
		name: "Name",
		filterKey: "name",
		width: 200,
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: "Created",
		width: 130,
		title: row => new Date(row.created_at).toString(),
		selector: row => new Date(row.created_at).toDateString(),
		sortable: true
	},
	{
		name: "Updated",
		width: 130,
		title: row => new Date(row.updated_at).toString(),
		selector: row => new Date(row.updated_at).toDateString(),
		sortable: true
	},
	{
		name: "Actions",
		width: 100,
		selector: (row) => (
			<Link
				className="btn btn-default btn-xs m-r-5"
				to={"/flows/update/" + row.id}
			>
				Edit
			</Link>
		),
	},
];

const FlowConfiguration = () => {
	const [data, setData] = React.useState([]);
	const { flowList } = useProcessManagement();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState();
	React.useEffect(() => {
		async function fetchData() {
			setIsLoading(true);
			const response = await flowList();
			if (response.Status) {
				setError(response.message);
			} else {
				setData(response.data.Data.items);
			}

			setIsLoading(false);
		}

		fetchData();
	}, []);

	return (
		<Spinner isLoading={isLoading}>
			<Box
				allowFullScreen
				title="Flow configurations"
				buttons={{
					value: "+ Add",
					type: "link",
					class: "btn btn-sm btn-success",
					href: "/flows/add",
				}}
			>
				{error && <Alert variant="danger">{error}</Alert>}

				<List columns={columns} data={data} />
			</Box>
		</Spinner>
	)
}

export default FlowConfiguration;