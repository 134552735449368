import React, { Component } from "react";
import Box from "../../../components/Box";
import Spinner from "../../../components/Spinner";
import Toast from '../../../components/Toast';
import Form from "../../../components/Form";
import { SmsTemplateService } from "../../../services";
import { Validator } from "../../../utils/Validator";


class ManageSms extends Component {
    constructor(props) {
        super(props);
        this.services = {
            sms: new SmsTemplateService(this),
        }
    }

    state = {
        id: this.props.match.params.id,
        eventId: this.props.match.params.event,
        isLoading: this.props.match.params.id !== undefined,
        touched: false,
        error: null,
        data: {},
        toastShow: false
    }

    componentDidMount() {
        if (this.state.id !== undefined) {
            this.services.sms.get(this.state.id);
        }
    }


    submitHandler = (values, setSubmitting) => {
        if(values.is_enabled == 'on'){
            values.is_enabled = 1;
        }
        if (this.state.id !== undefined) {
            for (var value in values) {
                if (values[value] === null || values[value] === undefined) {
                    delete values[value];
                }
            }
            this.services.sms.update(this.state.id, values, setSubmitting);
        } else {
            this.services.sms.save(values, setSubmitting);
        }
    }

    deleteHandler = () => {
        let confirm = window.confirm("Do you want to delete?");
        if (confirm) {
            this.services.sms.deleteAction(this.state.id, this.state.data.event_id);
        }
    }

    updateToast = () => {
        this.setState({
            toastShow: false
        });
    }

    render() {
        if (this.state.error) {
            return (
                <div className="alert alert-danger alert-bordered">
                    {this.state.error}
                </div>
            )
        }

        return (
            <Spinner isLoading={this.state.isLoading}>
                <Toast
                    message={this.state.message}
                    title={this.state.error ? 'Error' : 'Message'}
                    position="top-right"
                    action={this.state.toastShow ? 'show' : false}
                    handleClick={this.updateToast}
                />
                <Form
                    initialValues={this.state.data}
                    onSubmit={this.submitHandler}
                    validationSchema={{
                        content: Validator.create().required()
                    }}>
                    <Box title="Sms Template" buttons={[{
                        type: "submit",
                        value: "Save",
                        class: "btn btn-success",
                        show: this.state.isLoading === false
                    }, {
                        type: "button",
                        value: "Delete",
                        class: "btn btn-danger",
                        show: this.state.id !== undefined && this.state.isLoading === false,
                        clicked: () => this.deleteHandler()
                    }]}>
                        <div className="row">

                            <Form.Group name="content" label="SMS Template" required>
                                <Form.Textarea rows="10" placeholder="SMS Template" id="content" name="content" />
                            </Form.Group>

                            <Form.Group name="is_enabled" label="Status" for="is_enabled" >
                                <Form.Checkbox value='1' id="is_enabled" name="is_enabled" />
                            </Form.Group>

                        </div>
                    </Box>

                </Form>

            </Spinner>
        )
    }
}

export default ManageSms;