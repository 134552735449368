import React, { Component } from "react";
import List from "../../components/List";
import Alert from "../../components/Alert";
import Confirm from "../../components/Confirm";
import { DataDictionaryService } from "../../services";

class Data extends Component {
    constructor(props) {
        super(props);
        this.services = {
            dataDictionary: new DataDictionaryService(this)
        }

        this.state = {
            message: null,
            variant: "danger",
            confirmModal: {
                show: false,
                title: "Confirm Delete",
                message: "Are you sure want to delete ?",
                onConfirm: () => this.deleteMapping(),
                onCancel: () => {
                    this.setState(prev => ({
                        confirmModal: {
                            ...prev.confirmModal,
                            show: false
                        }
                    }));
                }
            }
        }
    }

    // Click handler of delete button
    deleteHandler = key => {
        this.setState(prev => ({
            keyToDelete: key,
            confirmModal: {
                ...prev.confirmModal,
                show: true
            }
        }));
    }

    // Delete mapping finally
    deleteMapping = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            this.services.dataDictionary.delete(this.state.keyToDelete);
        });
    }

    getColumns = () => [
        {
            name: "Key",
            filterKey: "0",
            selector: row => row[0],
            sortable: true
        },
        {
            name: "Value",
            filterKey: "1",
            selector: row => row[1],
            sortable: true
        },
        {
            name: "Actions",
            width: 150,
            selector: row => (
                <>
                    <button type="button" className="btn btn-default btn-xs m-r-5" onClick={() => this.props.editHandler(row)} disabled={this.props.updating}>
                        Edit
                    </button>

                    {this.props.type === 'business' &&
                        <button type="button" className="btn btn-danger btn-xs m-r-5" onClick={() => this.deleteHandler(row[0])} disabled={this.props.updating}>
                            Delete
                        </button>
                    }
                </>
            )
        }
    ];

    render() {
        return (
            <>
                {this.props.updating &&
                    <Alert variant="secondary">
                        <strong><span className="text-danger">Note:</span> Updation in progress. Either update or cancel to enable all Edit and Delete buttons.</strong>
                    </Alert>
                }

                {this.state.message &&
                    <Alert variant={this.state.variant} dismiss={() => {
                        this.setState({
                            message: null
                        });
                    }}>
                        {this.state.message}
                    </Alert>
                }

                <List
                    key={this.props.timestamp}
                    columns={this.getColumns()}
                    data={Object.entries(this.props.data)} />

                {this.state.confirmModal.show &&
                    <Confirm {...this.state.confirmModal} />
                }
            </>
        )
    }
}

export default Data;