import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Box from '../../components/Box';
import Form from '../../components/Form';
import { Validator } from '../../utils/Validator';

class ResetPassword extends Component {
    submitHandler = values => {
        console.log(values);
    }

    render() {
        return (
            <Box title="Reset Password">
                <Form
                    preventUnload={false}
                    onSubmit={this.submitHandler}
                    validationSchema={{
                        password: Validator.create().required(),
                        repeat_password: Validator.create().bail().required().same('password')
                    }}>
                    {({ values }) => (
                        <>
                            <Form.Group name="password">
                                <div className="input-group-icon right">
                                    <div className="input-icon">
                                        <i className="fa fa-lock font-16"></i>
                                    </div>
                                    <Form.Input type="password" placeholder="New passsword" id="password" name="password" />
                                </div>
                            </Form.Group>
                            <Form.Group name="repeat_password">
                                <div className="input-group-icon right">
                                    <div className="input-icon">
                                        <i className="fa fa-lock font-16"></i>
                                    </div>
                                    <Form.Input type="password" placeholder="Repeat New passsword" id="repeat_password" name="repeat_password" />
                                </div>
                            </Form.Group>
                            <div className="form-group">
                                <button type="submit" className="btn btn-info btn-block" disabled={!(values.password && values.repeat_password)}>
                                    Submit
                                </button>
                            </div>
                            <div className="form-group d-flex justify-content-between">
                                <Link to="/login">Click here to Login</Link>
                            </div>
                        </>
                    )}
                </Form>
            </Box>
        )
    }
}

export default ResetPassword;