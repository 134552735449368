import React, { useEffect, useState, useRef } from "react";
import "./index.css";

const SeeMore = ({ maxLines = 10, children }) => {
    const lineHeight = 20;
    const ref = useRef(null);
    const totalLines = useRef(null);
    const [collapsed, toggleCollapsed] = useState(false);

    useEffect(() => {
        totalLines.current = ref.current.innerText.split(/\r\n|\r|\n/).length;
        if (totalLines.current > maxLines) {
            toggleDisplay();
        }
    }, []);

    const toggleDisplay = () => {
        ref.current.style.height = collapsed ? 'auto' : (maxLines * lineHeight) + 'px';
        toggleCollapsed(!collapsed);
    }

    return (
        <>
            <div ref={ref} className={"see-more " + (collapsed ? 'collapsed' : '')}>
                {children}
            </div>

            {(totalLines.current > maxLines) &&
                <span className="see-more-span cursor-pointer text-primary" onClick={toggleDisplay}>
                    {collapsed ? "See more" : "See less"}
                </span>
            }
        </>
    )
}

export default SeeMore;