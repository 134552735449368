import React, { Component } from "react";
import Add from "./Add";
import Data from "./Data";
import Box from "../../components/Box";
import Tabs from "../../components/Tabs";
import Alert from "../../components/Alert";
import Spinner from "../../components/Spinner";
import { DataDictionaryService } from "../../services";

class DataDictionary extends Component {
    constructor(props) {
        super(props);
        this.services = {
            dataDictionary: new DataDictionaryService(this)
        }

        this.state = {
            data: {},
            error: null,
            isLoading: true,
            recordToUpdate: {},
            timestamp: + new Date()
        }
    }

    // Update records on add/update of terms
    updateList = data => {
        const replica = { ...this.state.data };
        replica[data.type][data.key] = data.value;
        this.setState({
            data: replica,
            recordToUpdate: {},
            timestamp: + new Date()
        });
    }

    componentDidMount() {
        this.services.dataDictionary.list();
    }

    render() {
        return (
            <Spinner isLoading={this.state.isLoading}>
                {this.state.error
                    ?
                    <Alert variant="danger">
                        {this.state.error}
                    </Alert>
                    :
                    <>
                        <Box title={Object.keys(this.state.recordToUpdate).length !== 0 ? 'Update mapping' : 'Create mapping'}>
                            <Add
                                onSave={this.updateList}
                                timestamp={this.state.timestamp}
                                data={this.state.recordToUpdate}
                                cancelHandler={() => {
                                    this.setState({
                                        recordToUpdate: {}
                                    });
                                }} />
                        </Box>
                        <Box allowFullScreen title='Data dictionary'>
                            <Tabs active="systemData">
                                <Tabs.Pane id="systemData" title="System data">
                                    <Data
                                        type="system"
                                        timestamp={this.state.timestamp}
                                        data={this.state.data.system || {}}
                                        updating={Object.keys(this.state.recordToUpdate).length !== 0}
                                        editHandler={row => {
                                            this.setState({
                                                recordToUpdate: {
                                                    key: row[0].replace('system_data.', ''),
                                                    value: row[1],
                                                    type: 'system'
                                                }
                                            });
                                        }} />
                                </Tabs.Pane>
                                <Tabs.Pane id="businessData" title="Business data">
                                    <Data
                                        type="business"
                                        timestamp={this.state.timestamp}
                                        data={this.state.data.business || {}}
                                        updating={Object.keys(this.state.recordToUpdate).length !== 0}
                                        editHandler={row => {
                                            this.setState({
                                                recordToUpdate: {
                                                    key: row[0],
                                                    value: row[1],
                                                    type: 'business'
                                                }
                                            });
                                        }} />
                                </Tabs.Pane>
                            </Tabs>
                        </Box>
                    </>
                }
            </Spinner>
        )
    }
}

export default DataDictionary;