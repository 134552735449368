import { SecuredApi as axios } from "./axios";

export default class Function {
    constructor(component) {
        this.component = component;
    }

    async list() {
        try {
            const response = await axios.get('/api/supported-functions');
            const data = await response.data;

            const derivedHelpers = data.data.Data.DerivedHelper.map(name => ({
                type: 'Derived Rule',
                name: name,
            }));

            const validationHelpers = data.data.Data.ValidationHelper.map(name => ({
                type: 'Validation',
                name: name,
            }));

            this.component.setState({
                isLoading: false,
                error: null,
                data: [...derivedHelpers, ...validationHelpers]
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }
}