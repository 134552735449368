import React, { useEffect, useState } from "react";
import "./styles.css";

/* 
Check below link for list of available timezones
https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
*/

const Clock = ({ timezone = 'Australia/Melbourne' }) => {
    const zoneTime = new Date().toLocaleString("en-US", { timeZone: timezone });
    const [time, updateTime] = useState(new Date(zoneTime));

    useEffect(() => {
        const timerId = setInterval(() => {
            updateTime(new Date(zoneTime));
        }, 1000);

        return () => {
            clearInterval(timerId);
        }
    });

    return (
        <div className="clock-container">
            <div className="clock">
                <div className="hour_hand" style={{
                    transform: `rotateZ(${time.getHours() * 30}deg)`
                }} />
                <div className="min_hand" style={{
                    transform: `rotateZ(${time.getMinutes() * 6}deg)`
                }} />
                <div className="sec_hand" style={{
                    transform: `rotateZ(${time.getSeconds() * 6}deg)`
                }} />

                <span className="twelve">12</span>
                <span className="one">1</span>
                <span className="two">2</span>
                <span className="three">3</span>
                <span className="four">4</span>
                <span className="five">5</span>
                <span className="six">6</span>
                <span className="seven">7</span>
                <span className="eight">8</span>
                <span className="nine">9</span>
                <span className="ten">10</span>
                <span className="eleven">11</span>
            </div>
        </div>
    )
}

export default Clock;