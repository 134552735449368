import React from "react";
import Design from "../Design";
import Box from "../../../components/Box";
import Form from "../../../components/Form";
import Alert from "../../../components/Alert";
import { PageService } from "../../../services";
import Spinner from "../../../components/Spinner";
import { Validator } from "../../../utils/Validator";
import ErrorBoundary from "../../../components/ErrorBoundary";
import RetailerContext from "../../../contexts/retailer-context";
import { SolvupThemeProvider } from "@solvupdev/caseflow-generic-components";

class DesignPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: [],
            error: "",
            formValues: {},
            isLoaded: false,
            isLoading: false,
            pageDetails: null
        };
        this.services = {
            pages: new PageService(this)
        }
    }

    componentDidMount() {
        this.services.pages.use();
    }

    submitHandler = (values, setSubmiting) => {
        let pageDetails = {};
        pageDetails.id = values.page;
        pageDetails.name = this.state.pages.find(res => res.id === values.page && res.name).name;
        setSubmiting(false);
        this.setState({
            isLoading: true,
            formValues: values
        });

        this.services.pages.getDesign(values)
            .then(res => {
                if (res.Status === "Success") {
                    pageDetails.components = res.Data.components;
                    this.setState({ isLoaded: true, isLoading: false, pageDetails: pageDetails, error: "" });
                } else {
                    this.setState({ isLoaded: true, isLoading: false, error: res.message, pageDetails: null });
                }
            });
    }

    render() {
        return (
            <>
                <Form
                    preventUnload={false}
                    onSubmit={this.submitHandler}
                    validationSchema={{
                        retailer: Validator.create().required(),
                        userType: Validator.create().required(),
                        page: Validator.create().required()
                    }}
                >
                    <Box title="Filter pages">
                        <div className="row">
                            <div className="col-xs-12 col-md-3">
                                <Form.Group name="page" label="Page" required>
                                    <Form.Select
                                        id="page"
                                        name="page"
                                        disabled={this.state.pageDetails}
                                    >
                                        {this.state.pages.map(page => {
                                            return (
                                                <option key={page.id} value={page.id}>{page.name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-xs-12 col-md-3">
                                <Form.Group name="retailer" label="Retailer" required>
                                    <Form.Select
                                        id="retailer"
                                        name="retailer"
                                        className="form-control"
                                        disabled={this.state.pageDetails}
                                    >
                                        <RetailerContext.Consumer>
                                            {value => (
                                                value.map(option => (
                                                    <option key={option.id} value={option.id}>{option.name}</option>
                                                ))
                                            )}
                                        </RetailerContext.Consumer>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-xs-12 col-md-3">
                                <Form.Group name="userType" label="User Type" required>
                                    <Form.Select
                                        id="userType"
                                        name="userType"
                                        disabled={this.state.pageDetails}
                                    >
                                        <option value="all">All</option>
                                        <option value="store">Store</option>
                                        <option value="callcentre">Callcentre</option>
                                        <option value="online">Online</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-xs-12 col-md-3">
                                <Form.Group>
                                    <div className="col-form-label">&nbsp;</div>
                                    <button
                                        type="submit"
                                        className="btn btn-success me-2"
                                        disabled={this.state.pageDetails}
                                    >Filter</button>
                                    {
                                        this.state.pageDetails &&
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            title="Double click to cancel the Page Filter"
                                            onDoubleClick={() => this.setState({ pageDetails: null })}
                                        >Cancel</button>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                    </Box>
                </Form>

                <Spinner isLoading={this.state.isLoading}>
                    <ErrorBoundary>
                        {
                            this.state.pageDetails === null &&
                            <Alert variant="secondary">
                                <i className="fa fa-info-circle"></i>&nbsp;
                                You need to select <span className="fw-bold">Page</span>, <span className="fw-bold">Retailer</span> and <span className="fw-bold">User Type</span> first to proceed.
                            </Alert>
                        }
                        {
                            this.state.error !== "" && <Alert variant="danger">{this.state.error}</Alert>
                        }
                        {
                            (this.state.pageDetails !== null && this.state.error === "") &&
                            <React.Suspense fallback={<Spinner isLoading={true}></Spinner>}>
                                <SolvupThemeProvider>
                                    <Design
                                        isDesignType="page"
                                        data={this.state.pageDetails}
                                        formValues={this.state.formValues}
                                    />
                                </SolvupThemeProvider>
                            </React.Suspense>
                        }
                    </ErrorBoundary>
                </Spinner>
            </>
        )
    }
}

export default DesignPage;