import React, { Component } from "react";
import { Link } from "react-router-dom";
import Box from "../../components/Box";
import List from "../../components/List";
import Alert from "../../components/Alert";
import { ApiService } from "../../services";
import Spinner from "../../components/Spinner";

class API extends Component {
    constructor(props) {
        super(props);
        this.services = {
            api: new ApiService(this)
        }

        this.state = {
            data: [],
            error: null,
            isLoading: true
        }
    }

    componentDidMount() {
        this.services.api.list();
    }

    getColumns = () => [
        {
            name: "Name",
            filterKey: "name",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Key",
            filterKey: "uid",
            selector: row => row.uid,
            sortable: true
        },
        {
            name: "Method",
            filterKey: "method",
            selector: row => row.method,
            sortable: true
        },
        {
            name: "Created",
            width: 130,
            title: row => new Date(row.created_at).toString(),
            selector: row => new Date(row.created_at).toDateString(),
            sortable: true
        },
        {
            name: "Updated",
            width: 130,
            title: row => new Date(row.updated_at).toString(),
            selector: row => new Date(row.updated_at).toDateString(),
            sortable: true
        },
        {
            name: "Actions",
            selector: row => (
                <Link className="btn btn-default btn-xs m-r-5" title="Edit" to={'/api/update/' + row.id}>
                    Edit
                </Link>
            )
        }
    ];

    render() {
        return (
            <Box
                allowFullScreen
                title='API configurations'
                buttons={{
                    value: '+ Add',
                    type: 'link',
                    class: 'btn btn-sm btn-success',
                    href: '/api/add'
                }}>
                <Spinner isLoading={this.state.isLoading}>
                    {this.state.error
                        ?
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                        : <List columns={this.getColumns()} data={this.state.data} />
                    }
                </Spinner>
            </Box>
        )
    }
}

export default API;