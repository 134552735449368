import React from 'react';
import Helper from '../../utils/Helper';
import Autocomplete from '../Autocomplete';
import styles from "./Condition.module.css";

const operators = {
    'null': ['==', '!='],
    'string': ['==', '!='],
    'boolean': ['==', '!='],
    'businessTerm': ['==', '!=', '<', '>', '>=', '<='],
    'number': ['==', '!=', '<', '>', '>=', '<='],
}
const Condition = ({ uid, condition, performAction, businessTerm }) => {
    let valueComponent;
    const [state, setState] = React.useState({
        "uid": uid, 
        "condition": condition, 
        ...Helper.conditionParser(condition)
    });

    if (state.field !== "") {
        if (state.valueType === "boolean") {
            valueComponent = <select 
                value={state.value} 
                className="form-select form-select-sm h-100" 
                onChange={e => setState({...state, "value": e.target.value})}
            >
                <option value="true">True</option>
                <option value="false">False</option>
            </select>
        } else {
            valueComponent = <Autocomplete 
                textChange 
                defaultValue={state.value} 
                disable={state.valueType === "null"} 
                searchable={state.valueType === "businessTerm"} 
                inputClass="form-control form-control-sm shadow-none" 
                url={businessTerm} 
                onSelector={data => setState({...state, "value": data.value})} 
            />
        }
    }

    return (
        <div className={styles.conditionParent}>
            <div className="col-3">
                <Autocomplete 
                    textChange 
                    searchable 
                    defaultValue={state.field} 
                    url={businessTerm} 
                    onSelector={data => setState({...state, "field": data.value})} 
                />
            </div>
            <div className="col-auto">
                <select 
                    value={state.operator} 
                    className="form-select form-select-sm h-100" 
                    onChange={e => setState({...state, "operator": e.target.value})}
                >
                    {operators[state.valueType].map(item => <option value={item} key={item}>{item}</option>)}
                </select>
            </div>
            <div className="col-auto">
                <select 
                    value={state.valueType} 
                    className="form-select form-select-sm h-100" 
                    onChange={e => {
                        const valueType = e.target.value;
                        const value = valueType === "null" ? "null" : (valueType === "boolean" ? "true" : "");
                        setState({...state, "value": value, "valueType": valueType});
                    }}
                >
                    <option value="string">String</option>
                    <option value="number">Number</option>
                    <option value="boolean">Boolean</option>
                    <option value="businessTerm">Business Term</option>
                    <option value="null">Null</option>
                </select>
            </div>
            <div className="col-3">{valueComponent}</div>
            <div className="col-auto ms-auto">
                <div className="d-flex flex-row gap-2 h-100">
                    <button 
                        type="button" 
                        className="btn btn-outline-success btn-sm rounded fw-bold" 
                        onClick={() => {
                            const value = state.valueType === "string" ? `'${state.value}'` : state.value;
                            const newCondition = `${state.field} ${state.operator} ${value}`;
                            // performAction(uid, "save-condition", {"uid": uid, "condition": newCondition});
                            performAction(uid, "save-condition", {...state, "condition": newCondition});
                            setState({...state, "condition": newCondition});
                        }}
                    >
                        <i className="fa fa-save fs-4"></i>
                    </button>
                    <button 
                        type="button" 
                        onClick={() => performAction(uid, "remove-condition")} 
                        className="btn btn-outline-danger btn-sm rounded fw-bold"
                    >
                        <i className="fa fa-trash fs-4"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Condition;