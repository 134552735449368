import React from 'react';
import SelectElement from './SelectElement';
import Helper from '../../../utils/Helper';
import Tabs from '../../../components/Tabs';
import Spinner from '../../../components/Spinner';
import { SecuredApi as axios } from '../../../services/axios';

const limit = 20;
const ChooseElement = (props) => {
    let tabsRef = React.useRef();
    const fieldURL = `fields?details=full&limit=${limit}`;
    const componentURL = `components?${props.isDesignType === "component" ? `exclude=${props.data.id}&` : ""}details=full&limit=${limit}`;

    const [allElementGroup, setAllElementGroup] = React.useState({
        fields: {
            list: [],
            nextPage: 1,
            totalPage: 0,
            totalList: [],
            searchList: [],
            isLoading: false,
            paginateLoad: false
        },
        components: {
            list: [],
            nextPage: 1,
            totalPage: 0,
            totalList: [],
            searchList: [],
            isLoading: false,
            paginateLoad: false
        },
        status: {
            error: null,
            searchField: ""
        }
    });

    const findData = ({
        nextPage = 1,
        searchParam = "",
        fieldAPI = false,
        loadType = "basic",
        componentAPI = false
    }) => {
        setAllElementGroup({
            fields: {
                ...allElementGroup.fields,
                isLoading: loadType === "basic" ? true : false,
                paginateLoad: loadType === "paginate" ? true : false
            },
            components: {
                ...allElementGroup.components,
                isLoading: loadType === "basic" ? true : false,
                paginateLoad: loadType === "paginate" ? true : false
            },
            status: {
                error: null,
                searchField: searchParam
            }
        });
        Promise.all([
            fieldAPI ? axios.get(`${fieldURL}&page=${nextPage}${searchParam !== "" ? `&search=${searchParam}` : ''}`) : undefined,
            componentAPI ? axios.get(`${componentURL}&page=${nextPage}${searchParam !== "" ? `&search=${searchParam}` : ''}`) : undefined
        ]).then(result => {
            // console.log(result);
            let sample = JSON.parse(JSON.stringify(allElementGroup));
            const obj = result.map(res => {
                if (res !== undefined) {
                    // console.log(res);
                    const { items, total } = res.data.data.Data;
                    const newItems = Helper.findFieldType(items, res.config.url);
                    if (items.length > 0) {
                        if (res.config.url.indexOf('fields') > -1) {
                            sample.fields.totalList = total;
                            sample.fields.nextPage = nextPage;
                            sample.fields.totalPage = total > 0 ? Math.ceil(total / limit) : 0;

                            if (searchParam === allElementGroup.status.searchField) {
                                sample.fields.list = allElementGroup.fields.list.concat(newItems);
                                sample.fields.searchList = searchParam !== "" ? allElementGroup.fields.searchList.concat(items) : [];
                            } else {
                                sample.fields.list = newItems;
                                sample.fields.searchList = searchParam !== "" ? items : [];
                            }
                        }
                        if (res.config.url.indexOf('components') > -1) {
                            sample.components.totalList = total;
                            sample.components.nextPage = nextPage;
                            sample.components.totalPage = total > 0 ? Math.ceil(total / limit) : 0;

                            if (searchParam === allElementGroup.status.searchField) {
                                sample.components.list = allElementGroup.components.list.concat(newItems);
                                sample.components.searchList = searchParam !== "" ? allElementGroup.components.searchList.concat(items) : [];
                            } else {
                                sample.components.list = newItems;
                                sample.components.searchList = searchParam !== "" ? items : [];
                            }
                        }
                    } else {
                        if (res.config.url.indexOf('fields') > -1) {
                            sample.fields.list = searchParam === "" ? [] : sample.fields.list;
                            sample.fields.searchList = [];
                        }
                        if (res.config.url.indexOf('components') > -1) {
                            sample.components.list = searchParam === "" ? [] : sample.components.list;
                            sample.components.searchList = [];
                        }
                    }
                    sample.status.searchField = searchParam;
                    return sample;
                }
            });
            const newObj = Object.assign({}, ...obj);
            setAllElementGroup(newObj);
        }).catch(err => {
            console.log(err);
            setAllElementGroup({
                fields: {
                    ...allElementGroup.fields,
                    isLoading: false,
                    paginateLoad: false
                },
                components: {
                    ...allElementGroup.components,
                    isLoading: false,
                    paginateLoad: false
                },
                status: {
                    error: err,
                    searchField: searchParam
                }
            });
        });
    }

    const handlePagination = (channel) => {
        if (channel === "fields") {
            findData({
                fieldAPI: true,
                componentAPI: false,
                loadType: "paginate",
                nextPage: (allElementGroup.fields.nextPage + 1),
                searchParam: allElementGroup.status.searchField
            });
        } else if (channel === "components") {
            findData({
                fieldAPI: false,
                componentAPI: true,
                loadType: "paginate",
                nextPage: (allElementGroup.components.nextPage + 1),
                searchParam: allElementGroup.status.searchField
            });
        }
    }

    const handleSearch = (e) => {
        let obj = {
            nextPage: 1,
            loadType: "basic",
            componentAPI: true,
            fieldAPI: props.isDesignType === "component"
        };
        if (e.keyCode === 13) {
            // if(allElementGroup.status.searchField !== e.target.value){}
            if (allElementGroup.status.searchField !== e.target.value) {
                obj.searchParam = e.target.value;
                findData(obj);
            }
        }
        if (e.keyCode === 8 && e.target.value === '' && allElementGroup.status.searchField === e.target.value) {
            obj.searchParam = "";
            findData(obj);
        }
    }

    const handleRef = () => {
        if (tabsRef.current !== null) {
            if (props.clones.length > 0) {
                tabsRef.current.setDisabled(tabsRef.current.state.active);
            } else if (props.clones.length <= 0) {
                tabsRef.current.setDisabled("");
            }
        }
    }

    React.useEffect(() => {
        if (props.data.id !== undefined) {
            findData({
                nextPage: 1,
                searchParam: "",
                fieldAPI: props.isDesignType === "component",
                componentAPI: true,
                loadType: "basic"
            });
        }
    }, []);

    React.useEffect(() => {
        handleRef();
    }, [props.clones]);

    return (
        <div className="col-4">
            <div className="card-header fs-5 fw-bold">Choose Element</div>
            <div className="card-header p-1">
                <input
                    type="text"
                    autoComplete="off"
                    onKeyDown={(e) => handleSearch(e)}
                    placeholder="Type and press Enter to search..."
                    className="form-control form-control-sm shadow-none"
                />
            </div>
            <div className="card-body p-0">
                <Tabs
                    ref={tabsRef}
                    id="component-design"
                    active={
                        props.isDesignType !== "page"
                            ? typeof props.data.components !== "undefined"
                                ? "components"
                                : typeof props.data.fields !== "undefined" ? "fields" : "fields"
                            : "components"
                    }
                >
                    {
                        props.isDesignType === "component" &&
                        <Tabs.Pane id="fields" title="Fields" className="component-design-pane">
                            <div className="d-flex flex-column p-1" style={{ gap: "0.75rem" }}>
                                <Spinner isLoading={allElementGroup.fields.isLoading}>
                                    {
                                        (
                                            allElementGroup.status.searchField === ""
                                            && allElementGroup.fields.list !== undefined
                                            && allElementGroup.fields.list.length > 0
                                        )
                                            ||
                                            (
                                                allElementGroup.status.searchField !== ""
                                                && allElementGroup.fields.searchList !== undefined
                                                && allElementGroup.fields.searchList.length > 0
                                            )
                                            ? <>
                                                <SelectElement
                                                    tabPane={true}
                                                    doubleClick={props.doubleClick}
                                                    elements={allElementGroup.status.searchField === "" ? allElementGroup.fields.list : allElementGroup.fields.searchList}
                                                />
                                                {
                                                    allElementGroup.fields.nextPage !== allElementGroup.fields.totalPage &&
                                                    <div className="text-center mb-2">
                                                        <button
                                                            className="btn btn-sm btn-outline-default"
                                                            onClick={() => handlePagination("fields")}
                                                            disabled={allElementGroup.fields.paginateLoad}
                                                        >
                                                            {
                                                                allElementGroup.fields.paginateLoad
                                                                    ? <>Loading <i className="fa fa-spinner fa-spin" aria-hidden="true"></i></>
                                                                    : "Load More..."
                                                            }
                                                        </button>
                                                    </div>
                                                }
                                            </>
                                            : <div className="text-center p-3">
                                                No <span className="text-info">Fields</span> were found.
                                            </div>
                                    }
                                </Spinner>
                            </div>
                        </Tabs.Pane>
                    }
                    <Tabs.Pane id="components" title="Components" className="component-design-pane">
                        <div className="d-flex flex-column p-1" style={{ gap: "0.75rem" }}>
                            <Spinner isLoading={allElementGroup.components.isLoading}>
                                {
                                    (
                                        allElementGroup.status.searchField === ""
                                        && allElementGroup.components.list !== undefined
                                        && allElementGroup.components.list.length > 0
                                    )
                                        ||
                                        (
                                            allElementGroup.status.searchField !== ""
                                            && allElementGroup.components.searchList !== undefined
                                            && allElementGroup.components.searchList.length > 0
                                        )
                                        ? <>
                                            <SelectElement
                                                tabPane={true}
                                                doubleClick={props.doubleClick}
                                                elements={allElementGroup.status.searchField === "" ? allElementGroup.components.list : allElementGroup.components.searchList}
                                            />
                                            {
                                                allElementGroup.components.nextPage !== allElementGroup.components.totalPage &&
                                                <div className="text-center mb-2">
                                                    <button
                                                        className="btn btn-sm btn-outline-default"
                                                        onClick={() => handlePagination("components")}
                                                        disabled={allElementGroup.components.paginateLoad}
                                                    >
                                                        {
                                                            allElementGroup.components.paginateLoad
                                                                ? <>Loading <i className="fa fa-spinner fa-spin" aria-hidden="true"></i></>
                                                                : "Load More..."
                                                        }
                                                    </button>
                                                </div>
                                            }
                                        </>
                                        : <div className="text-center p-3">
                                            No <span className="text-info">Components</span> were found.
                                        </div>
                                }
                            </Spinner>
                        </div>
                    </Tabs.Pane>
                </Tabs>
            </div>
        </div>
    );
}

export default ChooseElement;