import React from "react";

const PerPage = ({ perPage = 10, options = [10, 25, 50, 100], disabled = false, changed }) => (
    <p>
        <strong> Show </strong>
        <select onChange={changed} value={perPage} disabled={disabled}>
            {options.map(option => {
                return <option key={option} value={option}>
                    {option}
                </option>
            })}
        </select>
        <strong> entries </strong>
    </p>
);

export default PerPage;