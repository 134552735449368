import React, { Component, createContext } from 'react';
import Alert from '../Alert';
import { Input } from './Input';
import { Group } from './Group';
import { Radio } from './Radio';
import { Debug } from './Debug';
import Confirm from '../Confirm';
import { Select } from './Select';
import { Checkbox } from './Checkbox';
import { Textarea } from './Textarea';
import { Editor } from './HTMLEditor';
import { AceEditor } from './AceEditor';
import Helper from '../../utils/Helper';
import { JSONEditor } from './JSONEditor';
import PreventUnload from '../PreventUnload';

export const FormContext = createContext({});

class Form extends Component {
    static defaultProps = {
        id: + new Date(),
        action: "",
        debug: false, // Pass this true to print values, errors on UI in JSON format
        method: "POST",
        initialValues: {},
        preventUnload: true,
        showErrorList: false,
        validate: null,
        validationSchema: {},
        validateOnBlur: true,
        confirmBeforeSubmit: false,
        onSubmit: e => {
            e.preventDefault();
            console.log('Submit handler not provided');
        }
    }

    constructor(props) {
        super(props);

        this.ref = React.createRef();

        const initialValues = typeof this.props.initialValues === 'function' ? this.props.initialValues() : this.props.initialValues;

        this.state = {
            errors: {},
            touched: {},
            initialValues,
            submitting: false,
            formTouched: false,
            formUpdated: false,
            values: initialValues,
            confirmModal: {
                show: false,
                title: "Confirm",
                message: "Are you sure want to submit the form ?",
                onConfirm: () => this.confirmSubmission(),
                onCancel: () => {
                    this.setState(prev => ({
                        confirmModal: {
                            ...prev.confirmModal,
                            show: false
                        }
                    }));
                }
            }
        }
    }

    static Group = props => <Group {...props} />

    static Input = props => <Input {...props} />

    static Textarea = props => <Textarea {...props} />

    static Checkbox = props => <Checkbox {...props} />

    static Radio = props => <Radio {...props} />

    static Select = props => <Select {...props} />

    static HTMLEditor = props => <Editor {...props} />

    static AceEditor = props => <AceEditor {...props} />

    static JSONEditor = props => <JSONEditor {...props} />

    static Submit = ({ label = 'Save', className = 'btn btn-success' }) => (
        <FormContext.Consumer>
            {context => (
                <button type="submit" className={className} >
                    {label}
                </button>
            )}
        </FormContext.Consumer>
    )

    confirmSubmission = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            this.submitFinally();
        });
    }

    submitHandler = e => {
        e.preventDefault();
        let validationErrors = {};
        const { ...values } = this.state.values;

        // Validate. 1st priority: Custom validation code
        if (this.props.validate !== null) {
            validationErrors = this.props.validate(values);
        } else if (!Helper.isEmptyObject(this.props.validationSchema)) {
            for (const field in this.props.validationSchema) {
                const element = this.ref.current.querySelector('[name="' + field + '"]');

                // Check if element is present in DOM, only then apply validations
                if (element !== null) {
                    const valueType = element.getAttribute('data-value-type') || 'string';
                    const errors = this.props.validationSchema[field].run(field, values, valueType);
                    if (errors) {
                        validationErrors[field] = errors;
                    }
                }
            }
        }

        if (!Helper.isEmptyObject(validationErrors)) {
            this.setErrors(validationErrors);
        } else {
            if (this.props.confirmBeforeSubmit === true) {
                this.setState(prev => ({
                    confirmModal: {
                        ...prev.confirmModal,
                        show: true
                    }
                }));
            } else {
                this.submitFinally();
            }
        }
    }

    submitFinally = () => {
        const { ...values } = this.state.values;
        this.setSubmitting(true);
        this.props.onSubmit(values, this.setSubmitting, this.setErrors);
    }

    setSubmitting = status => {
        this.setState(prev => ({
            formTouched: false,
            formUpdated: false,
            submitting: status,
            confirmModal: {
                ...prev.confirmModal,
                show: status === false ? false : prev.confirmModal.show,
                isLoading: status === false ? false : prev.confirmModal.isLoading
            }
        }));
    }

    // For setting errors after API call
    setErrors = (errors = {}) => {
        this.setState({ errors });
    }

    // For setting values using ref after component is mounted
    setValues = (values = {}, overrride = false) => {
        const updated = overrride ? values : { ...this.state.values, ...values };
        this.setState({ values: updated });
    }

    getValues = () => this.state.values;

    getErrors = () => this.state.errors;

    reset = () => {
        this.setState({ values: this.state.initialValues });
    }

    handleValueType = (value, valueType = 'string') => {
        if (typeof value === valueType) {
            return value;
        }

        switch (valueType) {
            case "string":
                return String(value);
            case "number":
                return Number(value);
            case "boolean":
                return Boolean(value);
            default:
                return value;
        }
    }

    render() {
        return (
            <PreventUnload when={this.props.preventUnload && this.state.formUpdated}>
                <FormContext.Provider value={{
                    values: this.state.values,
                    errors: this.state.errors,
                    submitting: this.state.submitting,
                    formUpdated: this.state.formUpdated,
                    validationSchema: this.props.validationSchema,
                    updateFormState: values => {
                        this.setState({
                            values: { ...values, ...this.state.values }
                        });
                    },
                    updateValue: (name, value, valueType = 'string') => {
                        const { ...values } = this.state.values;
                        const { ...errors } = this.state.errors;
                        const { ...touched } = this.state.touched;

                        touched[name] = true;
                        values[name] = this.handleValueType(value, valueType);

                        if (this.props.validateOnBlur === true && (name in this.props.validationSchema)) {
                            const errorsFound = this.props.validationSchema[name].run(name, values, valueType);
                            if (errorsFound) {
                                errors[name] = errorsFound;
                            } else {
                                delete errors[name];
                            }
                        }

                        this.setState({
                            values,
                            errors,
                            touched,
                            formTouched: true,
                            formUpdated: !Helper.areEqualObjects(values, this.state.initialValues)
                        });

                        // Return updated value (according to type) again
                        return values[name];
                    },
                    deleteValue: name => {
                        const { ...values } = this.state.values;
                        const { ...errors } = this.state.errors;
                        delete errors[name];
                        delete values[name];

                        this.setState({
                            values,
                            errors,
                            formUpdated: !Helper.areEqualObjects(values, this.state.initialValues)
                        });
                    },
                    hasError: name => name in this.state.errors,
                    addError: (name, value) => {
                        const { ...errors } = this.state.errors;
                        const { ...touched } = this.state.touched;
                        errors[name] = value;
                        touched[name] = true;
                        this.setState({ errors, touched });
                    },
                    removeError: name => {
                        if (name in this.state.errors) {
                            const { ...errors } = this.state.errors;
                            delete errors[name];
                            this.setState({ errors });
                        }
                    }
                }}>
                    <form
                        ref={this.ref}
                        id={this.props.id}
                        action={this.props.action}
                        method={this.props.method}
                        onSubmit={this.submitHandler}
                        className={this.props.className + (this.state.submitting ? ' spinner submitting' : '')}
                        onChange={e => {
                            if (this.state.formTouched === false) {
                                this.setState({
                                    formTouched: true
                                });
                            }
                        }}>

                        {(this.props.showErrorList && !Helper.isEmptyObject(this.state.errors)) &&
                            <Alert variant="danger">
                                <strong>There was an error with your submission.</strong>
                                <ul className='mt-2'>
                                    {Object.entries(this.state.errors).map(([key, value]) => (
                                        <li className='mb-2' key={key}>{value}</li>
                                    ))}
                                </ul>
                            </Alert>
                        }

                        {this.props.debug &&
                            <Debug initialValues={this.props.initialValues} values={this.state.values} errors={this.state.errors} />
                        }

                        {/* Disable fieldset while submitting to disable all internal fields at once */}
                        <fieldset disabled={this.state.submitting}>
                            {typeof this.props.children === 'function'
                                ? this.props.children({
                                    ...this.state,
                                    reset: this.reset,
                                    setValues: this.setValues,
                                    setErrors: this.setErrors,
                                    setSubmitting: this.setSubmitting
                                })
                                : this.props.children
                            }
                        </fieldset>

                        {this.state.submitting &&
                            <div className="preloader-backdrop submitting">
                                <div className="page-preloader">Submitting...</div>
                            </div>
                        }

                        {(this.props.confirmBeforeSubmit && this.state.confirmModal.show) &&
                            <Confirm {...this.state.confirmModal} />
                        }
                    </form>
                </FormContext.Provider>
            </PreventUnload>
        )
    }
}

export default Form;
