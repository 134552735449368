import React, { Component } from "react";
import { Link } from "react-router-dom";
import List from "../../../components/List";
import Box from "../../../components/Box";
import Spinner from "../../../components/Spinner";
import { PaperworkService } from "../../../services";

class Paperwork extends Component {
    constructor(props) {
        super(props);
        this.services = {
            paperwork: new PaperworkService(this)
        }

        this.state = {
            data: [],
            error: null,
            isLoading: true,
            event: this.props.match.params.event,
            perPage: 1000
        }
    }

    componentDidMount() {
        let params = { "limit": this.state.perPage, "offset": 0 };
        if (this.state.event !== undefined) {
            params['event_id'] = this.state.event;
        }
        this.services.paperwork.list(params);
    }

    getColumns = () => [
        {
            name: "Content",
            width: 50,
            selector: row => row.html_content,
            sortable: true
        },
        {
            name: "Status",
            width: 80,
            selector: row => row.is_enabled ? <i className='fa fa-check text-success'></i> : <i className='fa fa-times text-danger'></i>
        },
        {
            name: "Last updated",
            width: 130,
            selector: row => new Date(row.updated_at).toDateString(),
            sortable: true
        },
        {
            name: "Actions",
            width: 80,
            selector: row => (
                <Link className="btn btn-default btn-xs m-r-5" to={'/paperwork/update/' + row.id}>
                    Edit
                </Link>
            )
        }
    ];

    filterHandler = (search) => {
        this.setState({ isLoading: true });
        let filterParam = { "html_content": search, "limit": this.state.perPage, "offset": 0 };
        if (this.state.event !== undefined) {
            filterParam['event_id'] = this.state.event;
        }
        this.services.paperwork.list(filterParam);
    }

    render() {
        return (
            <Box
                allowFullScreen
                title='Sms Configuration'
                buttons={{
                    value: '+ Add',
                    type: 'link',
                    class: 'btn btn-sm btn-success',
                    href: '/paperwork/add/:event?'
                }}>
                <Spinner isLoading={this.state.isLoading}>
                    {this.state.error
                        ? <div className="alert alert-danger alert-bordered">
                            {this.state.error}
                        </div>
                        : <List columns={this.getColumns()} data={this.state.data} onFilter={this.filterHandler} />
                    }
                </Spinner>
            </Box>
        );
    }
}

export default Paperwork;