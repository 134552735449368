import React from "react";
import { SecuredApi as axios } from "../axios";

export default class Field {
    constructor(component) {
        this.component = component;
    }

    async list(params = {}) {
        try {
            const response = await axios.get('/fields/');
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data.items
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    async get(id) {
        try {
            const response = await axios.get('/fields/' + id);
            let data = await response.data;
            data = data.data.Data;
            data.conditions = data.conditions || {};

            this.component.setState({
                isLoading: false,
                error: null,
                data: data
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message,
                pageNotFound: statusCode === 404
            });
        }
    }

    async create(params = {}, setSubmiting, setErrors) {
        try {
            const response = await axios.post('/fields', params);
            const data = await response.data;
            setSubmiting(false);

            this.component.props.history.replace('/ui/fields/update/' + data.data.Data.id);

        } catch ({ message, statusCode, response }) {
            setSubmiting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    async update(id, params = {}, setSubmiting, setErrors) {
        try {
            const response = await axios.put('/fields/' + id, params);

            // eslint-disable-next-line
            const data = await response.data;
            setSubmiting(false);

            this.component.setState({
                variant: "success",
                message: "Field updated successfully"
            });
        } catch ({ message, statusCode, response }) {
            setSubmiting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete('/fields/' + id);

            // eslint-disable-next-line
            const data = await response.data;
            this.component.props.history.replace('/ui/fields');
        } catch ({ message, statusCode, response }) {
            if (statusCode === 404 || statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    variant: "danger",
                    preventUnload: true,
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }

    async createOverride(fieldId, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.post('/fields/' + fieldId + '/overrides', params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                error: null,
                timestamp: + new Date(),
                override: {
                    conditions: {},
                    return_values: {}
                }
            });

            this.component.props.onAdd(params, data.data.Data.overrides);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    error: response.data.data.Message
                });
            } else if (statusCode === 409) {
                this.component.setState({
                    error: (
                        <>
                            An override with ID <strong>{response.data.data.Errors.id}</strong> already exists with exact same conditions. <u className="cursor-pointer">Click here</u> to update that override.
                        </>
                    )
                });
            }
        }
    }

    async updateOverride(fieldId, overrideId, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.put('/fields/' + fieldId + '/overrides/' + overrideId, params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                error: null,
                timestamp: + new Date(),
                override: {
                    conditions: {},
                    return_values: {}
                }
            });

            this.component.props.onUpdate(params, data.data.Data.overrides);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    error: response.data.data.Message
                });
            } else if (statusCode === 409) {
                this.component.setState({
                    error: (
                        <>
                            An override with ID <strong>{response.data.data.Errors.id}</strong> already exists with exact same conditions. <u className="cursor-pointer">Click here</u> to update that override.
                        </>
                    )
                });
            }
        }
    }

    async deleteOverride(fieldId, overrideId) {
        try {
            const response = await axios.delete('/fields/' + fieldId + '/overrides/' + overrideId);
            const data = await response.data;

            this.component.setState(prev => ({
                timestamp: + new Date(),
                variant: "success",
                message: "Override deleted successfully",
                data: data.data.Data.overrides,
                confirmModal: {
                    ...prev.confirmModal,
                    isLoading: false,
                    show: false
                }
            }));
        } catch ({ message, statusCode, response }) {
            if (statusCode === 404 || statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    variant: "danger",
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }
}