import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const colourOptions = [
    { value: 'ocean', label: 'Ocean'},
    { value: 'purple', label: 'Purple'},
    { value: 'red', label: 'Red'},
    { value: 'orange', label: 'Orange'},
    { value: 'yellow', label: 'Yellow'},
    { value: 'green', label: 'Green'},
    { value: 'forest', label: 'Forest'},
    { value: 'slate', label: 'Slate'},
    { value: 'silver', label: 'Silver'},
]

const animatedComponents  = (props) => makeAnimated(props);

export default function AnimatedMulti(props) {
    
    const [paperwork, setPaperwork] = React.useState({
        paperworkList : [],
        filterSelectPaperwork:[]
    })
    React.useEffect(() => setPaperwork({...paperwork, paperworkList: props.paperworkList}), [props.paperworkList]);

    React.useEffect(() => setPaperwork({...paperwork, filterSelectPaperwork: paperwork.paperworkList}), [paperwork.paperworkList]);
    console.log(paperwork)

    return (
        <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={[colourOptions[4], colourOptions[5]]}
            isMulti
            options={colourOptions}
        />
    );
}
