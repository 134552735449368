import React from "react";
import { FormContext } from ".";

export const Radio = ({ name, values = [], value = '', valueType = 'string', inline = true, variant = 'primary', ...props }) => (
    <FormContext.Consumer>
        {context => {
            value = name in context.values ? context.values[name] : value;
            const changeHandler = e => {
                context.updateValue(e.target.name, e.target.value, valueType);
            }

            const radios = values.map((radio, index) => (
                <label key={index} className={"ui-radio ui-radio-" + variant + (inline ? " ui-radio-inline" : "")}>
                    <input type="radio" name={name} value={radio.value} defaultChecked={String(radio.value) === String(value)} {...props} onChange={changeHandler} data-value-type={valueType} />
                    <span className="input-span"></span>
                    <span className="text-span">{radio.label}</span>
                </label>
            ));

            return (
                <div className={inline ? "radio-list" : "check-list"}>
                    {radios}
                </div>
            )
        }}
    </FormContext.Consumer>
)