import React from 'react';
import Condition from './Condition.jsx';
import styles from './Group.module.css';

const Group = ({uid, gate, rules, children, performAction, businessTerm}) => {
    return (
        <div className="card">
            <div className={styles.GroupHead}>
                <div className="">
                    <select 
                        name="gate" 
                        value={gate} 
                        className="form-select form-select-sm h-100 fs-5" 
                        onChange={e => performAction(uid, "change-gate", e.target.value)}
                    >
                        <option value="AND">AND</option>
                        <option value="OR">OR</option>
                    </select>
                </div>
                <button 
                    type="button" 
                    className="btn btn-info" 
                    onClick={() => performAction(uid, "add-condition")}
                >Add Condition</button>
                <button 
                    type="button" 
                    className="btn btn-primary" 
                    onClick={() => performAction(uid, "add-group")}
                >Add Group</button>
                {children}
            </div>
            <div className={styles.GroupBody}>
                {
                    rules.map(query => {
                        let component;
                        if (query.rules !== undefined) {
                            component = <Group key={query.uid} {...query} performAction={performAction} businessTerm={businessTerm}>
                                <button 
                                    type="button" 
                                    className="btn btn-danger" 
                                    onClick={() => performAction(query.uid, "remove-group")}
                                >Remove Group</button>
                            </Group>
                        } else if (query.condition !== undefined) {
                            component = <Condition key={query.uid} {...query} performAction={performAction} businessTerm={businessTerm} />
                        }
                        return component;
                    })
                }
            </div>
        </div>
    );
}

export default Group;