import React from "react";

export const retailers = [
    {
        id: 1,
        name: 'JBHiFi'
    },
    {
        id: 2,
        name: 'Myer'
    },
    {
        id: 3,
        name: 'Bunnings'
    },
    {
        id: 4,
        name: 'The Warehouse Limited'
    },
    {
        id: 5,
        name: 'Kogan'
    },
    {
        id: 12,
        name: 'TGG'
    },
    {
        id: 20,
        name: 'JBNZ'
    },
    {
        id: 23,
        name: 'Officeworks'
    },
    {
        id: 26,
        name: 'Noel Leeming'
    },
    {
        id: 27,
        name: 'Square Trade'
    }
];

export const retailerList = (() => {
    const list = {};
    retailers.forEach(item => {
        list[item.id] = item.name;
    });

    return list;
})();

export default React.createContext(retailers);