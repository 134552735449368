import { SecuredApi as axios } from "../axios";
import Helper from "../../utils/Helper";

export default class EmailTemplate {
    constructor(component) {
        this.component = component;
    }

    async list(params) {
        try {
            const response = await axios.get('commpack/email?' + Helper.serializeObject(params));
            const data = await response.data.data.Data.items;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    async get(id) {
        try {
            // eslint-disable-next-line
            const response = await axios.get('commpack/email/' + id);
            const data = await response.data.data.Data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data
            });
        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
        }
    }

    async optionList(params = { "limit": 1000, "offset": 0 }) {
        try {
            const response = await axios.get('commpack/paperwork?' + Helper.serializeObject(params));
            const data = await response.data.data.Data;
            
            this.component.setState({
                paperworkList: data
            });
        } catch ({ message, statusCode }) {
            console.error(message);
        }
    }

    async save(fields = {}, setSubmitting) {
        try {
            // eslint-disable-next-line
            const response = await axios.post('commpack/email', Helper.serializeObject(fields));
            const data = await response.data.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data
            });
            this.component.props.history.push('/email/');

        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
            setSubmitting(false);
        }
    }

    async deleteAction(id, eventId) {
        try {
            // eslint-disable-next-line
            const response = await axios.delete('commpack/email/' + id);
            this.component.setState({
                isLoading: false,
                error: null
            });
            this.component.props.history.push('/email/');
        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
        }
    }

    async update(id, fields = {}, setSubmitting) {
        try {
            // eslint-disable-next-line
            const response = await axios.put('commpack/email/' + id, Helper.serializeObject(fields));
            this.component.setState({
                isLoading: false,
                toastShow: true,
                error: null,
                message: 'Email Template updated successfully'
            });
        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
        } finally {
            setSubmitting(false);

        }
    }
}