import ComingSoon from "../components/ComingSoon";
import AddRule from "../containers/Rule/Add";
import Rule from "../containers/Rule/List";
import AddScenario from "../containers/Scenario/Add";
import Category from "../containers/Category/List";
import AddCategory from "../containers/Category/Add";
import PriortizeRules from "../containers/Category/PriortizeRules";
import OnscreenText from "../containers/OnscreenText";
import Debug from "../containers/Debug";
import DataDictionary from "../containers/DataDictionary";
import Event from "../containers/Communication/Event/List";
import EventAdd from "../containers/Communication/Event/Add";
import EmailTemplate from "../containers/Communication/Email/List";
import ManageEmail from "../containers/Communication/Email/Add";
import SmsTemplate from "../containers/Communication/Sms/List";
import ManageSms from "../containers/Communication/Sms/Add";
import Integration from "../containers/Communication/Integration/List";
import ManageIntegration from "../containers/Communication/Integration/Add";
import Paperwork from "../containers/Communication/Paperwork/List";
import ManagePaperwork from "../containers/Communication/Paperwork/Add";
import Login from "../containers/Login";
import ForgotPassword from "../containers/ForgotPassword";
import ResetPassword from "../containers/ResetPassword";
import ChangePassword from "../containers/User/ChangePassword";
import API from "../containers/API";
import Function from "../containers/Function";
import AddAPI from "../containers/API/Add";
import Page from "../containers/UI/Page";
import AddPage from "../containers/UI/Page/Add";
import DesignPage from "../containers/UI/Page/DesignPage";
import Component from "../containers/UI/Component";
import AddComponent from "../containers/UI/Component/Add";
import Field from "../containers/UI/Field";
import AddField from "../containers/UI/Field/Add";
import ProcessConfiguration from "../pages/process-configuration/process";
import AddProcessFlow from '../pages/process-configuration/process/add';
import FlowConfiguration from "../pages/process-configuration/flow";

export const publicRoutes = [
    {
        path: '/login',
        component: Login,
        title: 'Login',
        name: 'user.login'
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        title: "Forgot Password",
        name: 'user.forgot-password'
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        title: "Reset Password",
        name: 'user.reset-password'
    }
];

export const route = name => {
    const route = authRoutes.find(item => item.name === name);
    return route ? route.path : null;
}

export const authRoutes = [
    {
        path: '/dashboard',
        component: ComingSoon,
        title: "Dashboard",
        name: "dashboard"
    },
    {
        path: '/profile',
        component: ComingSoon,
        title: "My profile",
        name: 'user.profile'
    },
    {
        path: '/change-password',
        component: ChangePassword,
        title: "Change password",
        name: 'user.change-password'
    },
    {
        path: '/rules/categories',
        component: Category,
        title: "Rule categories",
        name: 'rules.categories'
    },
    {
        path: '/rules/categories/add',
        component: AddCategory,
        title: "Save rule category",
        name: 'rules.categories.add'
    },
    {
        path: '/rules/categories/update/:id',
        component: AddCategory,
        title: "Save rule category",
        name: 'rules.categories.update'
    },
    {
        path: '/rules/categories/priortize/:secret?',
        component: PriortizeRules,
        title: "Priortize rules",
        name: 'rules.priortize'
    },
    {
        path: '/rules/onscreen-texts',
        component: OnscreenText,
        title: "Onscreen texts",
        name: 'rules.onscreen-texts'
    },
    {
        path: '/rules',
        component: Rule,
        title: "Rules",
        name: 'rules'
    },
    {
        path: '/case-creation/scenarios',
        component: Rule,
        title: "Scenarios",
        name: 'scenarios'
    },
    {
        path: '/case-creation/scenarios/add/:secret?',
        component: AddScenario,
        title: "Add scenario",
        name: 'scenarios.add'
    },
    {
        path: '/case-creation/scenarios/update/:id/:secret',
        component: AddScenario,
        title: "Update scenario",
        name: 'scenarios.update'
    },
    {
        path: '/case-creation/scenarios/replicate/:id/:secret',
        component: AddScenario,
        title: "Replicate scenario",
        name: 'scenarios.replicate'
    },
    {
        path: '/rules/add',
        component: AddRule,
        title: "Add rule",
        name: 'rules.add'
    },
    {
        path: '/rules/update/:id',
        component: AddRule,
        title: "Update rule",
        name: 'rules.update'
    },
    {
        path: '/case-creation/debug',
        component: Debug,
        title: "Debug",
        name: 'debug'
    },
    {
        path: '/rules/functions',
        component: Function,
        title: "Supported functions",
        name: 'supported-functions'
    },
    {
        path: '/event/list',
        component: Event,
        title: "Events",
        name: 'events'
    },
    {
        path: '/event/add',
        component: EventAdd,
        title: "Add event",
        name: 'events.add'
    },
    {
        path: '/event/:event',
        component: EventAdd,
        title: "Update event",
        name: 'events.update'
    },
    {
        path: '/email/:event?',
        component: EmailTemplate,
        title: "Email templates",
        name: 'events.email'
    },
    {
        path: '/email/add/:event',
        component: ManageEmail,
        title: "Add email template",
        name: 'events.email.add'
    },
    {
        path: '/email/update/:email',
        component: ManageEmail,
        title: "Update email template",
        name: 'events.email.update'
    },
    {
        path: '/sms/:event?',
        component: SmsTemplate,
        title: "SMS Templates",
        name: 'events.sms'
    },
    {
        path: '/sms/add/:event?',
        component: ManageSms,
        title: "Add SMS template",
        name: 'events.sms.add'
    },
    {
        path: '/sms/update/:id',
        component: ManageSms,
        title: "Update SMS template",
        name: 'events.sms.update'
    },
    {
        path: '/paperwork/:event?',
        component: Paperwork,
        title: "Paperwork",
        name: 'events.paperwork'
    },
    {
        path: '/paperwork/add/:event',
        component: ManagePaperwork,
        title: "Add paperwork",
        name: 'events.paperwork.add'
    },
    {
        path: '/paperwork/update/:id',
        component: ManagePaperwork,
        title: "Update paperwork",
        name: 'events.paperwork.update'
    },
    {
        path: '/integration/:event?',
        component: Integration,
        title: "Integration",
        name: 'events.integration'
    },
    {
        path: '/integration/add/:event',
        component: ManageIntegration,
        title: "Configure integration",
        name: 'events.integration.add'
    },
    {
        path: '/integration/update/:id',
        component: ManageIntegration,
        title: "Configure integration",
        name: 'events.integration.update'
    },
    {
        path: '/data-dictionary',
        component: DataDictionary,
        title: "Data dictionary",
        name: 'data-dictionary'
    },
    {
        path: '/ui/pages',
        component: Page,
        title: "Pages",
        name: 'pages'
    },
    {
        path: '/ui/pages/add',
        component: AddPage,
        title: "Add page",
        name: 'pages.add'
    },
    {
        path: '/ui/pages/update/:id',
        component: AddPage,
        title: "Update page",
        name: 'pages.update'
    },
    {
        path: '/ui/pages/design',
        component: DesignPage,
        title: "Design Page",
        name: 'pages.design'
    },
    {
        path: '/ui/components',
        component: Component,
        title: "Components",
        name: 'components'
    },
    {
        path: '/ui/components/add',
        component: AddComponent,
        title: "Add component",
        name: 'components.add'
    },
    {
        path: '/ui/components/update/:id',
        component: AddComponent,
        title: "Update component",
        name: 'components.update'
    },
    {
        path: '/ui/fields',
        component: Field,
        title: "Fields",
        name: 'fields'
    },
    {
        path: '/ui/fields/add',
        component: AddField,
        title: "Add field",
        name: 'fields.add'
    },
    {
        path: '/ui/fields/update/:id',
        component: AddField,
        title: "Update field",
        name: 'fields.update'
    },
    {
        path: '/process',
        component: ProcessConfiguration,
        title: "Process configurations",
        name: 'process'
    },
    {
        path: '/process/add',
        component: AddProcessFlow,
        title: "Configure process",
        name: 'process.add'
    },
    {
        path: '/process/update/:id',
        component: AddProcessFlow,
        title: "Configure process",
        name: 'process.update'
    },
    {
        path: '/flows',
        component: FlowConfiguration,
        title: "Flow configurations",
        name: 'flows'
    },
    {
        path: '/flows/add',
        component: AddProcessFlow,
        title: "Configure flow",
        name: 'flows.add'
    },
    {
        path: '/flows/update/:id',
        component: AddProcessFlow,
        title: "Configure flow",
        name: 'flows.update'
    },
    {
        path: '/api',
        component: API,
        title: "API configurations",
        name: 'apis'
    },
    {
        path: '/api/add',
        component: AddAPI,
        title: "Configure API",
        name: 'apis.add'
    },
    {
        path: '/api/update/:id',
        component: AddAPI,
        title: "Configure API",
        name: 'apis.update'
    },
    {
        path: '/case-mgt',
        component: ComingSoon,
        title: "Case Mgt",
        name: 'case-mgt'
    },
    {
        path: '/courier-mgt',
        component: ComingSoon,
        title: "Courier Mgt",
        name: 'courier-mgt'
    },
    {
        path: '/repairer-locks',
        component: ComingSoon,
        title: "Repairer locks",
        name: 'repairer-locks'
    },
    {
        path: '/data-dictionary',
        component: ComingSoon,
        title: "Data dictionary",
        name: 'data-dictionary'
    }
];
