import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../../components/Alert';
import Box from '../../components/Box';
import Form from '../../components/Form';
import UserContext from '../../contexts/user-context';
import { Validator } from '../../utils/Validator';
import { UserService } from '../../services';

class Login extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.services = {
            user: new UserService(this)
        }

        this.state = {
            error: null
        }
    }

    submitHandler = (values, setSubmitting) => {
        values['grant_type'] = 'caseflow';
        this.services.user.login(values, setSubmitting);
    }

    render() {
        return (
            <Form
                id="loginForm"
                preventUnload={false}
                onSubmit={this.submitHandler}
                validationSchema={{
                    username: Validator.create().required(),
                    password: Validator.create().required()
                }}>
                {({ values }) => (
                    <Box title="Login">
                        {this.state.error &&
                            <Alert variant="danger">
                                {this.state.error}
                            </Alert>
                        }

                        <Form.Group name="username">
                            <div className="input-group-icon right">
                                <div className="input-icon">
                                    <i className="fa fa-envelope"></i>
                                </div>
                                <Form.Input type="text" placeholder="Enter your email/username" id="username" name="username" />
                            </div>
                        </Form.Group>
                        <Form.Group name="password">
                            <div className="input-group-icon right">
                                <div className="input-icon">
                                    <i className="fa fa-lock font-16"></i>
                                </div>
                                <Form.Input type="password" placeholder="Enter your passsword" id="password" name="password" />
                            </div>
                        </Form.Group>
                        <div className="form-group d-flex justify-content-between">
                            <Form.Checkbox name="remember" label="Remember me" valueType="number" value={1} uncheckedValue={null} />
                            <Link to="/forgot-password">Forgot password?</Link>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-info btn-block" type="submit" disabled={!(values.username && values.password)}>
                                Login
                            </button>
                        </div>
                    </Box>
                )}
            </Form>
        )
    }
}

export default Login;