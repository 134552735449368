import React from 'react';
import SelectElement from './SelectElement';
import Toast from '../../../components/Toast';
import Confirm from '../../../components/Confirm';
import { ComponentService, PageService } from '../../../services';

const fieldOrComponent = clone => {
    let obj = {};
    if (clone.length > 0) {
        if (clone[0].isWhat === "field") {
            obj.fields = clone.map(element => element.id);
        }
        if (clone[0].isWhat === "component") {
            obj.components = clone.map(element => element.id);
        }
    }
    return obj;
}

function reducer(state, action) {
    switch (action.type) {
        case "confirmation":
            return { ...state, modalShow: true };
        case "modalClosure":
            return { ...state, modalShow: false };
        case "runAPI":
            return { ...state, loading: true };
        case "showResult":
            return {
                ...state,
                loading: false,
                toastShow: true,
                modalShow: false,
                toastTitle: "Component Status",
                toastMessage: action.message
            };
        case "totalClosure":
            return {
                ...state,
                title: "",
                message: "",
                toastShow: false
            };
        default:
            throw new Error();
    }
}

const PreviewClones = (props) => {
    const actionBtnClass = "btn btn-sm shadow-none fw-bold btn";

    const initialState = {
        loading: false,

        modalShow: false,
        modalTitle: "Confirm Action",
        modalMessage: "Are you sure about this?",
        onConfirm: async (clones) => {
            dispatch({ type: "runAPI" });

            let result;
            if (props.isDesignType === "component") {
                const componentService = new ComponentService();
                const parsedData = fieldOrComponent(clones);
                result = await componentService.saveDesign(props.data.id, parsedData);
            } else if (props.isDesignType === "page") {
                const pageService = new PageService();
                const parsedData = {
                    "retailer_id": parseInt(props.formValues.retailer),
                    "user_type": props.formValues.userType,
                    "status": "published",
                    ...fieldOrComponent(clones)
                };
                result = await pageService.saveDesign(props.data.id, parsedData);
            }
            dispatch({ type: "showResult", message: result.Message });
        },

        error: "",
        toastTitle: "",
        toastMessage: "",
        toastShow: false
    };

    const [state, dispatch] = React.useReducer(reducer, initialState);

    React.useEffect(() => {
        return () => {
            return;
        }
    }, [state]);

    return (
        <div className="col-12">
            <div className="card-header d-flex justify-content-between fs-5 fw-bold">
                Preview
                <div className="d-flex" style={{ gap: ".25rem" }}>
                    <button
                        className={`${actionBtnClass}-secondary`}
                        onClick={() => props.handleReset("edit")}
                    >Edit</button>
                    <button
                        className={`${actionBtnClass}-info`}
                        onClick={() => dispatch({ type: "confirmation" })}
                    >Save</button>
                </div>
            </div>
            <div className="card-body">
                <SelectElement
                    preview={true}
                    key="previewClones"
                    elements={props.previewClones}
                />
            </div>

            {
                (state.toastShow && props.previewClones.length > 0) &&
                <Toast
                    message={state.toastMessage}
                    title={state.error ? state.error : state.toastTitle}
                    position="top-right"
                    action={state.toastShow ? 'show' : false}
                    handleClick={() => dispatch({ type: "totalClosure" })}
                />
            }

            {
                (state.modalShow && props.previewClones.length > 0) &&
                <Confirm
                    title={state.modalTitle}
                    isLoading={state.loading}
                    message={state.modalMessage}
                    onConfirm={() => state.onConfirm(props.previewClones)}
                    onCancel={() => dispatch({ type: "modalClosure" })}
                />
            }
        </div>
    );
}

export default PreviewClones;