import React, { Component } from "react";
import Form from "../../components/Form";
import Alert from "../../components/Alert";
import Confirm from "../../components/Confirm";
import { Validator } from "../../utils/Validator";
import { DataDictionaryService } from "../../services";

class Add extends Component {
    static defaultProps = {
        data: {}
    }

    constructor(props) {
        super(props);
        this.services = {
            dataDictionary: new DataDictionaryService(this)
        }

        this.state = {
            message: null,
            variant: "danger",
            data: this.props.data,
            confirmModal: {
                show: false,
                title: "Confirm",
                message: "Are you sure want to clear ?",
                onConfirm: () => this.clearCache(),
                onCancel: () => {
                    this.setState(prev => ({
                        confirmModal: {
                            ...prev.confirmModal,
                            show: false
                        }
                    }));
                }
            }
        }
    }

    clearCache = () => {
        this.setState(prev => ({
            message: null,
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true,
            }
        }), () => {
            this.services.dataDictionary.clearCache();
        });
    }

    submitHandler = (values, setSubmitting, setErrors) => {
        if (values.key.includes('system_data.')) {
            setSubmitting(false);
            setErrors({
                key: 'Invalid key'
            });

            return;
        }

        if (this.props.data.key) {
            this.services.dataDictionary.update(values, setSubmitting, setErrors);
        } else {
            this.services.dataDictionary.create(values, setSubmitting, setErrors);
        }
    }

    render() {
        return (
            <>
                {this.state.message &&
                    <Alert variant={this.state.variant} dismiss={() => {
                        this.setState({
                            message: null
                        });
                    }}>
                        {this.state.message}
                    </Alert>
                }

                <Alert variant="secondary">
                    <strong className="text-danger">Note:</strong> No need to add <strong>system_data.</strong> in key while adding/updating system mapping. <br /><br />

                    <button type="button" className="btn btn-sm btn-danger" onClick={() => {
                        this.setState(prev => ({
                            confirmModal: {
                                ...prev.confirmModal,
                                show: true
                            }
                        }));
                    }}>
                        Clear cache
                    </button> Click this button to clear <strong>Process service cache</strong> after you perform any operation on this screen.
                </Alert>

                <Form
                    key={this.props.data.key || this.props.timestamp}
                    initialValues={{
                        key: this.props.data.key,
                        value: this.props.data.value,
                        type: this.props.data.type || 'business'
                    }}
                    onSubmit={this.submitHandler}
                    validationSchema={{
                        key: Validator.create().required('Key is required'),
                        type: Validator.create().required('Type is required'),
                        value: Validator.create().required('Value is required'),
                    }}
                >
                    <div className="row">
                        <div className="col-md-2 col-xs-12">
                            <Form.Group name="type" label="Type">
                                <Form.Select name="type" placeholder={false} disabled={this.props.data.key}>
                                    <option value="business">Business</option>
                                    <option value="system">System</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-md-4 col-xs-12">
                            <Form.Group name="key" label="Key">
                                <Form.Input type="text" name="key" placeholder="Enter key" disabled={this.props.data.key} />
                            </Form.Group>
                        </div>
                        <div className="col-md-4 col-xs-12">
                            <Form.Group name="value" label="Value">
                                <Form.Input type="text" name="value" placeholder="Enter value" />
                            </Form.Group>
                        </div>
                        <div className={this.props.data.key ? "col-md-1" : "col-md-2"}>
                            <label htmlFor="testing" className="col-form-label">&nbsp;</label>
                            <div className="d-grid">
                                <Form.Submit label={this.props.data.key ? "Update" : "Save"} />
                            </div>
                        </div>

                        {this.props.data.key &&
                            <div className="col-md-1">
                                <label htmlFor="testing" className="col-form-label">&nbsp;</label>
                                <div className="d-grid">
                                    <button type="button" className="btn btn-secondary" onClick={this.props.cancelHandler}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </Form>

                {this.state.confirmModal.show &&
                    <Confirm {...this.state.confirmModal} />
                }
            </>
        )
    }
}

export default Add;