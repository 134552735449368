import { SecuredApi as axios } from "./axios";

export default class DataDictionary {
    constructor(component) {
        this.component = component;
    }

    async list() {
        try {
            const response = await axios.get('/data-dictionary');
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    async create(params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.post('/data-dictionary/' + params.type, params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                data: data.data.Data,
                variant: "success",
                message: "Mapping created successfully"
            });

            this.component.props.onSave(params);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 409 || statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    async update(params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.put('/data-dictionary/' + params.type + '/' + params.key, params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                data: data.data.Data,
                variant: "success",
                message: "Mapping updated successfully"
            });

            this.component.props.onSave(params);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    async delete(key) {
        try {
            const response = await axios.delete('/data-dictionary/' + key);

            // eslint-disable-next-line
            const data = await response.data;

            this.component.setState(prev => ({
                confirmModal: {
                    ...prev.confirmModal,
                    show: false,
                    isLoading: false
                },
                variant: "success",
                message: "Mapping deleted successfully"
            }));
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    variant: "danger",
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }

    async clearCache() {
        try {
            const response = await axios.get('/api/cache-clean');
            const data = await response.data;

            this.component.setState(prev => ({
                confirmModal: {
                    ...prev.confirmModal,
                    show: false,
                    isLoading: false
                },
                variant: "success",
                message: "Cache cleared successfully"
            }));
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    confirmModal: {
                        ...prev.confirmModal,
                        show: false,
                        isLoading: false
                    },
                    variant: "danger",
                    message: response.data.data.Message
                }));
            }
        }
    }
}