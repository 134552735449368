import React from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import * as Routes from "./index";
import NotFound from "../components/404";

const renderRoutes = (routes = []) => {
    return routes.map((route, index) => {
        const { name, title, component, path, exact = true, ...rest } = route;

        return (
            <Route
                key={index}
                path={path}
                exact={exact}
                {...rest}
                render={props => {
                    document.title = title ? title + ' - ' + process.env.REACT_APP_NAME : process.env.REACT_APP_NAME;
                    return React.createElement(component, props);
                }}
            />
        )
    });
}

export const renderPublicRoutes = () => (
    <Switch>
        {renderRoutes(Routes.publicRoutes)}
        <Route render={props => {
            const pathname = props.location.pathname;
            const path = (pathname === '/' || pathname === '/logout') ? '/login' : '/login?redirect=' + pathname + props.location.hash;
            return <Redirect to={path} />
        }} />
    </Switch>
)

export const renderAuthRoutes = ({ redirect = '/dashboard' }) => (
    <Switch>
        {/* Redirect all public pages to Dashboard */}
        {Routes.publicRoutes.map((route, index) => (
            <Route exact path={route.path} key={index}>
                <Redirect to={redirect} />
            </Route>
        ))}

        {renderRoutes(Routes.authRoutes)}

        {/* This page is 404 Not Found and should always be last */}
        <Route component={NotFound} />
    </Switch>
)