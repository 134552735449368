import React, { useState, useEffect } from "react";
import { FormContext } from ".";

export const LocalInput = ({ value = '', onChange = () => { }, className = 'form-control', ...props }) => {
    const [localValue, changeValue] = useState(value);
    useEffect(() => {
        changeValue(value);
    }, [value]);

    return <input value={localValue} className={className} onChange={e => changeValue(e.target.value)} onBlur={onChange} onKeyDown={e => {
        const code = e.keyCode || e.charCode || e.which;
        if (code === 13) {
            onChange(e);
        }
    }} {...props} />
}

export const Input = ({ name, value = '', valueType = 'string', className = 'form-control', ...props }) => (
    <FormContext.Consumer>
        {context => {
            value = name in context.values ? context.values[name] : value;
            const changeHandler = e => {
                const newValue = e.target.value.trim();
                if (newValue !== value) {
                    context.updateValue(e.target.name, newValue, valueType);
                }
            }

            return (
                <input name={name} value={value} className={className} data-value-type={valueType} onChange={changeHandler} {...props} />
            )
        }}
    </FormContext.Consumer>
)