import React from "react";
import Helper from "../utils/Helper";
import { Link } from "react-router-dom";
import { SecuredApi as axios } from "./axios";

export default class Scenario {
    constructor(component) {
        this.component = component;
    }

    async list(query = '') {
        try {
            query = typeof query === 'object' ? Helper.serializeObject(query) : query;
            const response = await axios.get('/case-creation/scenarios?' + query);
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data.items
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    async get(id, params = {}) {
        try {
            const response = await axios.get('/case-creation/scenarios/' + id + '?' + Helper.serializeObject(params));
            let data = await response.data;
            data = data.data.Data;

            const values = {
                id: data.id,
                user_type: data.user_type,
                retailer_id: Number(data.retailer_id),
                is_sandbox_mode: data.is_sandbox_mode,
                replicate: this.component.state.replicate,
                ...data.configuration
            }

            this.component.getValues(data.tags, values);

            this.component.setState({
                message: null,
                isLoading: false,
                variant: "success",
                created_at: data.created_at,
                updated_at: data.updated_at,
                managerOverride: data.managerOverride,
                initialValues: { ...this.component.state.initialValues, ...values }
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                message,
                isLoading: false,
                variant: "danger",
                pageNotFound: statusCode === 404
            });
        }
    }

    async create(params = {}, setSubmitting, setErrors) {
        const secret = btoa(params.retailer_id + '-' + params.user_type);

        try {
            const response = await axios.post('/case-creation/scenarios/', params);
            const data = await response.data;
            setSubmitting(false);

            this.component.props.history.replace('/case-creation/scenarios/update/' + data.data.Data.id + '/' + secret);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            } else if (statusCode === 409) {
                this.component.setState({
                    variant: "danger",
                    message: (
                        <>
                            Unable to add. A scenario already exists with exact same conditions. &nbsp;
                            <Link to={'/case-creation/scenarios/update/' + response.data.data.Errors.id + '/' + secret}>
                                Click here &nbsp;
                            </Link>
                            to open that scenario.
                        </>
                    )
                });
            }
        }
    }

    async update(id, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.put('/case-creation/scenarios/' + id, params);

            // eslint-disable-next-line
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                variant: "success",
                message: "Scenario updated successfully"
            });
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            } else if (statusCode === 409) {
                this.component.setState({
                    variant: "danger",
                    message: (
                        <>
                            Unable to add. A scenario already exists with exact same conditions. &nbsp;
                            <Link to={'/case-creation/scenarios/update/' + response.data.data.Errors.id}>
                                Click here &nbsp;
                            </Link>
                            to open that scenario.
                        </>
                    )
                });
            }
        }
    }

    async delete(id, params = {}) {
        try {
            const response = await axios.delete('/case-creation/scenarios/' + id + '?' + Helper.serializeObject(params));

            // eslint-disable-next-line
            const data = await response.data;
            this.component.props.history.replace('/case-creation/scenarios');
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }
}