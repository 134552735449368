import React from 'react';
import styles from "./CompletedValue.module.css";

function getCaret(el) {
    let caretAt = 0;
    const sel = window.getSelection();
    
    if ( sel.rangeCount === 0 ) { return caretAt; }
  
    const range = sel.getRangeAt(0);    
    const preRange = range.cloneRange();
    preRange.selectNodeContents(el);
    preRange.setEnd(range.endContainer, range.endOffset);
    caretAt = preRange.toString().length;
  
    return caretAt;   
}

function setCaret(el, offset) {
    let sel = window.getSelection();
    let range = document.createRange();
    if (el.childNodes.length > 0) {
        range.setStart(el.childNodes[0], offset);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
    }
}
  
const EditableInput = (props) => {  
    const contentRef = React.useRef();
    const caretPos = React.useRef();
    const [state, setState] = React.useState("");
    const [final, setFinal] = React.useState({});
  
    React.useEffect(() => {
        if (state.length !== 0) {
            setCaret(contentRef.current, caretPos.current);
        }
        if (state !== "") {
            contentRef.current.focus();
        }
    }, [state, final]);

    React.useEffect(() => {
        setFinal(props.selectedValue);
        setState("");
    }, [props.selectedValue]);

    const emitEvents = e => {
        if (e.charCode === 13) {
            e.preventDefault();
            props.detectEvent(13);
        }
    }

    const emitChange = e => {
        caretPos.current = getCaret(contentRef.current);
        setState(e.target.textContent);
        props.onChange(e.target.textContent);
    }

    return (
        <div 
            ref={contentRef} 
            onInput={emitChange} 
            onKeyPress={emitEvents} 
            className={props.customClass} 
            suppressContentEditableWarning={true} 
            contentEditable={Object.keys(final).length <= 0 ? true : false} 
        >
            {
                Object.keys(final).length > 0 
                    ? <span className={styles.Completed}>
                        <span className={styles.Content}>{final.name}</span> 
                        <span className={styles.ContentClose} onClick={props.onRemoveSelected}>
                            <i className="fa fa-close"></i>
                        </span>
                    </span>
                    : state
            }
        </div>
    )
  }

export default EditableInput;