import status from "../../../config/status.json";
import { Validator } from "../../../utils/Validator";

const config = [
    {
        type: "radio",
        name: "RequestOutcome",
        label: "Request outcome",
        validations: Validator.create().required("Request outcome is required"),
        values: [
            {
                label: "Repair",
                value: "Repair"
            },
            {
                label: "Replace",
                value: "Replace"
            },
            {
                label: "Refund",
                value: "Refund"
            },
            {
                label: "NoAction",
                value: "NoAction"
            },
            {
                label: "Replace Assessment",
                value: "ReplaceAssessment"
            }
        ]
    },
    {
        type: "radio",
        name: "liability",
        label: "Liability",
        validations: Validator.create().required("Liability is required"),
        values: [
            {
                label: "Vendor",
                value: "Vendor"
            },
            {
                label: "Consumer",
                value: "Consumer"
            },
            {
                label: "Retailer",
                value: "Retailer"
            },
            {
                label: "Premium service",
                value: "PremiumService"
            },
            {
                label: "Vendor or Retailer",
                value: "Acl_Vendor_or_Retailer"
            }
        ]
    },
    {
        type: "select",
        name: "status",
        label: "Status",
        validations: Validator.create().required("Status is required"),
        values: Object.entries(status).map(([key, value]) => ({
            label: value,
            value: key
        }))
    },
    {
        type: "radio",
        name: "closed",
        label: "Closed",
        valueType: "number",
        validations: Validator.create().required("Closed is required"),
        values: [
            {
                label: "Yes",
                value: 1
            },
            {
                label: "No",
                value: 0
            }
        ]
    },
    {
        type: "html-editor",
        layout: "full",
        name: "CaseSummaryText",
        label: "Case summary text",
        validations: Validator.create().required('Case summary text is required')
    },
    {
        type: "html-editor",
        layout: "full",
        name: "CaseCompletionText",
        label: "Case completion text",
        validations: Validator.create().required('Case completion text is required'),
        hint: (
            <>
                <strong className="text-danger">Note: </strong>
                Use SOLVUP_CASE_ID as placeholder for using the final Solvup case ID in text
            </>
        )
    }
]

export default config;