import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Box from '../../components/Box';
import Form from '../../components/Form';
import { Validator } from '../../utils/Validator';

class ForgotPassword extends Component {
    submitHandler = values => {
        console.log(values);
    }

    render() {
        return (
            <Box title="Forgot Password">
                <Form
                    preventUnload={false}
                    onSubmit={this.submitHandler}
                    validationSchema={{
                        email: Validator.create().required().email().bail()
                    }}>
                    {({ values }) => (
                        <>
                            <Form.Group name="email" label="Enter your email address below and we'll send you password reset instructions" required>
                                <div className="input-group-icon right">
                                    <div className="input-icon">
                                        <i className="fa fa-envelope"></i>
                                    </div>
                                    <Form.Input type="text" placeholder="Enter your email" id="email" name="email" />
                                </div>
                            </Form.Group>
                            <div className="form-group">
                                <button type="submit" className="btn btn-info btn-block" disabled={!values.email}>
                                    Submit
                                </button>
                            </div>
                            <div className="form-group d-flex justify-content-between">
                                <Link to="/login">Click here to Login</Link>
                            </div>
                        </>
                    )}
                </Form>
            </Box>
        )
    }
}

export default ForgotPassword;