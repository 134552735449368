import React from "react";
import PrintObject from "../Solvup/PrintObject";

export const Debug = ({ initialValues = {}, values = {}, errors = {} }) => (
    <div className='row m-b-10'>
        <div className='col-md-4 col-xs-12 border-end'>
            <strong>Initial values:</strong>
            <PrintObject data={initialValues} disabled />
        </div>
        <div className='col-md-4 col-xs-12 border-end'>
            <strong>Current values:</strong>
            <PrintObject data={values} disabled />
        </div>
        <div className='col-md-4 col-xs-12'>
            <strong>Errors:</strong>
            <PrintObject data={errors} disabled />
        </div>
    </div>
)