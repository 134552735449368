import React, { useEffect, useState } from "react";
import "./styles.css";
import ContentEditable from "../../components/ContentEditable";

const RuleList = ({ rules, selected, onClick = () => { } }) => {
    const [data, updateData] = useState({
        items: rules,
        filterTerm: null
    });

    useEffect(() => {
        updateData({
            items: rules,
            filterTerm: data.filterTerm
        });
    }, [rules]);

    const search = e => {
        const code = e.keyCode || e.charCode || e.which;
        if (code === 13) {
            const value = e.target.value.trim().toLowerCase();
            if (value !== data.filterTerm) {
                const filtered = rules.filter(item => item.name.toLowerCase().includes(value));
                updateData({
                    items: filtered,
                    filterTerm: value
                });
            }
        }
    }

    return (
        <>
            <div className="card-header p-1">
                <input type="text" autoComplete="off" placeholder="Type and press enter to search..." className="form-control shadow-none" onKeyDown={search} />
            </div>
            <div className="accordion" id="rule-list">
                {data.items.length === 0 &&
                    <p className="p-3 fw-bold">
                        No rules found.
                    </p>
                }

                {data.items.map(rule => (
                    <div className="accordion-item" key={rule.id}>
                        <p className="accordion-header">
                            <button
                                type="button"
                                className={"accordion-button " + (selected.id === rule.id ? '' : 'collapsed')}
                                onClick={() => onClick(rule)}>
                                {rule.name}
                            </button>
                        </p>
                        <div className={"accordion-collapse collapse " + (selected.id === rule.id ? 'show' : '')}>
                            <div className="accordion-body">
                                <div className="d-flex justify-content-between text-muted mt-1 fs-6">
                                    <div title={new Date(rule.created_at).toString()}>
                                        <b>Created:</b> <br />
                                        {new Date(rule.created_at).toDateString()}
                                    </div>
                                    <div title={new Date(rule.updated_at).toString()}>
                                        <b>Last modified:</b> <br />
                                        {new Date(rule.updated_at).toDateString()}
                                    </div>
                                </div>
                                <ContentEditable disabled>
                                    <pre>
                                        {JSON.stringify(rule.conditions, null, 2)}
                                    </pre>
                                </ContentEditable>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default RuleList;