export default {
    inputs: {
        CaseType: function () {
            this.tags = {
                "rtv_item": "RTV",
                "damaged": "Damaged",
                "com": "Change of mind",
                "floor_stock_item": "Floorstock",
                "customers_item": "Customer Item",
            }

            this.getCondition = value => {
                return [this.tags[value], `CaseType == '${value}'`];
            }
        },
        ProductLocation: function () {
            this.tags = {
                "at_store": "In store",
                "at_home": "In home"
            }

            this.getCondition = (value, values) => {
                if (values.CaseType !== 'customers_item') {
                    return null;
                }

                return [this.tags[value], `ProductLocation == '${value}'`];
            }
        },
        FloorStockSoldBefore: function () {
            this.tags = {
                "1": "Floorstock sold before",
                "0": "Floorstock not sold before"
            }

            this.getCondition = (value, values) => {
                if (values.CaseType !== 'floor_stock_item') {
                    return null;
                }

                return [this.tags[value], `FloorStockSoldBefore == '${value}'`];
            }
        },
        ProductInHomeEligible: function () {
            this.tags = {
                "Yes": "Inhome Eligible",
                "No": "Non Inhome Eligible"
            }

            this.getCondition = (value, values) => {
                if (values.user_type === 'callcentre' || values.ProductLocation === 'at_home') {
                    return [this.tags[value], `ProductInHomeEligible == '${value}'`];
                }

                return null;
            }
        },
        ItemDamaged: function () {
            this.tags = {
                "Yes": "Item Damaged",
                "No": "Item Not Damaged"
            }

            this.getCondition = (value, values) => {
                if (values.retailer_id !== 12) {
                    return null;
                }

                return [this.tags[value], `ItemDamaged == '${value}'`];
            }
        },
        ProductPremiumService: function () {
            this.tags = {
                gold_service: "Gold Premium Service",
                no_premium_service: "No Premium Service",
                concierge_service: "Conceirge Premium Service"
            }

            this.getCondition = (value, values) => {
                if (values.retailer_id !== 12) {
                    return null;
                }

                return [this.tags[value], `ProductPremiumService == '${value}'`];
            }
        },
        ProductSearch: function () {
            this.tags = {
                simple: "Simple Search",
                docket: "Docket Search"
            }

            this.conditions = {
                simple: "IsDocketSearch == false",
                docket: "IsDocketSearch == true"
            }

            this.getCondition = (value, values) => {
                if (values.retailer_id === 12 && values.CaseType === 'customers_item' && (values.ProductPremiumService === 'concierge_service' || values.ProductPremiumService === 'gold_service')) {
                    return [this.tags[value], this.conditions[value]];
                }

                return null;
            }
        },
        PremiumServicePriceCap: function () {
            this.tags = {
                'gold <= 500': "Price <= 500",
                'gold > 500': "Price > 500",
                'concierge <= 250': "Price <= 250",
                'concierge > 250': "Price > 250"
            }

            this.conditions = {
                'gold <= 500': "ReceiptOriginalPrice <= 500",
                'gold > 500': "ReceiptOriginalPrice > 500",
                'concierge <= 250': "ReceiptOriginalPrice <= 250",
                'concierge > 250': "ReceiptOriginalPrice > 250"
            }

            this.getCondition = (value, values) => {
                if (values.retailer_id === 12 && values.CaseType === 'customers_item') {
                    if (values.ProductPremiumService === 'concierge_service' && (value === 'concierge <= 250' || value === 'concierge > 250')) {
                        return [this.tags[value], this.conditions[value]];
                    } else if (values.ProductPremiumService === 'gold_service' && (value === 'gold <= 500' || value === 'gold > 500')) {
                        return [this.tags[value], this.conditions[value]];
                    }
                }

                return null;
            }
        },
        InsurancePlan: function () {
            this.tags = {
                'not started': 'Insurance Plan Not Started',
                'started': 'Insurance Plan Started',
                'ended': 'Insurance Plan Ended',
            }

            this.getCondition = (value, values) => {
                if (values.retailer_id == 12 && values.CaseType === 'customers_item' && values.ProductSearch === 'docket' && (values.ProductPremiumService === 'concierge_service' || values.ProductPremiumService === 'gold_service')) {
                    switch (value) {
                        case 'not started':
                            return [this.tags[value], "InsurancePlanStarted == false"]
                        case 'started':
                            return [
                                this.tags[value],
                                [
                                    "InsurancePlanStarted == true",
                                    "InsurancePlanEnded == false"
                                ]
                            ];
                        case 'ended':
                            return [this.tags[value], "InsurancePlanEnded == true"];
                    }
                }

                return null;
            }
        },
        CaseReportDaysSincePurchase: function () {
            this.tags = {
                'DOP <= 2': 'DOP <= 2 Years',
                '2 < DOP <= 3': '2 Years < DOP <= 3 Years',
                '3 < DOP <= 5': '3 Years < DOP <= 5 Years',
                'DOP > 5': 'DOP > 5 Years',
            }

            this.conditions = {
                'DOP <= 2': 'CaseReportDaysSincePurchase <= YEARS_2',
                '2 < DOP <= 3': [
                    "CaseReportDaysSincePurchase > YEARS_2",
                    "CaseReportDaysSincePurchase <= YEARS_3"
                ],
                '3 < DOP <= 5': [
                    "CaseReportDaysSincePurchase > YEARS_3",
                    "CaseReportDaysSincePurchase <= YEARS_5"
                ],
                'DOP > 5': 'CaseReportDaysSincePurchase > YEARS_5',
            }

            this.getCondition = (value, values) => {
                if (values.retailer_id === 12 && values.CaseType === 'customers_item' && values.ProductSearch === 'simple' && (values.ProductPremiumService === 'concierge_service' || values.ProductPremiumService === 'gold_service')) {
                    return [this.tags[value], this.conditions[value]];
                }

                return null;
            }
        },
        ProductLodgementType: function () {
            this.tags = {
                lite: "Lite",
                ultra_lite: "Ultra lite",
                basic: "Basic",
                regular: "Regular"
            }

            this.getCondition = value => {
                return [this.tags[value], `ProductLodgementType == '${value}'`];
            }
        },
        ProductWarrantyType: function () {
            this.tags = {
                repair: "Repairable",
                exchange: "Non Repairable"
            }

            this.getCondition = (value, values) => {
                if (values.ProductLodgementType !== 'regular') {
                    return null;
                }

                return [this.tags[value], `ProductWarrantyType == '${value}'`];
            }
        },
        LPCO: function () {
            this.tags = {
                'yes': "LPCO",
                'no': "Non LPCO"
            }

            this.conditions = {
                'yes': 'ReceiptOriginalPrice <= RetailerReplacePriceCutoff',
                'no': 'ReceiptOriginalPrice > RetailerReplacePriceCutoff',
            }

            this.getCondition = (value, values) => {
                if (values.ProductLodgementType === 'regular' && values.ProductWarrantyType === 'repair' && values.ProductPremiumService !== 'concierge_service' && values.ProductPremiumService !== 'gold_service') {
                    return [this.tags[value], this.conditions[value]];
                }

                return null;
            }
        },
        Warranty: function () {
            this.tags = {
                'doa': null,
                'warranty': 'Warranty',
                'acl': 'ACL',
                'oow': 'OOW',
            }

            this.getCondition = value => {
                switch (value) {
                    case 'doa':
                        return null;
                    case 'warranty':
                        return [
                            this.tags[value],
                            [
                                "CaseReportInDoa == 0",
                                "CaseReportInWarranty == 1"
                            ]
                        ]
                    case 'acl':
                        return [
                            this.tags[value],
                            [
                                "CaseReportInWarranty == 0",
                                "CaseReportInAcl == 1"
                            ]
                        ];
                    case 'oow':
                        return [this.tags[value], "CaseReportInAcl == 0"];
                }
            }
        },
        DoaWarranty: function () {
            this.tags = {
                'doa': 'DOA',
                'vendor': 'Vendor DOA',
                'retailer': 'Retailer DOA'
            }

            this.getCondition = (value, values) => {
                if (values.Warranty !== 'doa') {
                    return null;
                } else if (value === 'vendor') {
                    return [this.tags[value], "CaseReportVendorInDoa == 1"]
                } else if (value === 'retailer') {
                    return [
                        this.tags[value],
                        [
                            "CaseReportVendorInDoa == 0",
                            "CaseReportRetailerInDoa == 1"
                        ]
                    ]
                } else {
                    return ['DOA', "CaseReportInDoa == 1"]
                }
            }
        },
        FaultAbuse: function () {
            this.tags = {
                "1": "Abused",
                "0": "Non Abused"
            }

            this.getCondition = (value, values) => {
                return [this.tags[value], `FaultAbuse == ${value}`];
            }
        },
        TroubleshootingSuccess: function () {
            this.tags = {
                "yes": "Solved By Troubleshooting",
                "no": null
            }

            this.getCondition = (value, values) => {
                if (value === 'yes') {
                    return [this.tags[value], "TroubleshootingSuccess == true"];
                }

                return null;
            }
        },
        ProductInStoreAssessmentEligible: function () {
            this.tags = {
                "true": "InStore Assessment Eligible",
                "false": "Non InStore Assessment Eligible"
            }

            this.getCondition = value => {
                return [this.tags[value], `ProductInStoreAssessmentEligible == ${value}`];
            }
        },
        ProductDamageAssessmentEligible: function () {
            this.tags = {
                "true": "Damage Assessment Eligible",
                "false": "Non Damage Assessment Eligible"
            }

            this.getCondition = value => {
                return [this.tags[value], `ProductDamageAssessmentEligible == ${value}`];
            }
        }
    },
    outputs: [
        'closed',
        'status',
        'liability',
        'RequestOutcome',
        'CaseSummaryText',
        'CaseCompletionText'
    ]
}