export default class Helper {
    // Convert every first alphabet of each word as Uppercase & rest as Lowercase
    static capitalizeFirstLetter = string => {
        if (typeof string === "string") {
            return string.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
        } else {
            return string;
        }
    }

    static capitalizeFirstLetterOfSentence = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Convert object into query string that can be used in URL
    static serializeObject = obj => Object.keys(obj).map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    }).join('&');

    // Check if object is empty or not ?  
    static isEmptyObject = obj => Object.keys(obj).length === 0;

    // Check if date is valid or not
    static isValidDate = string => new Date(string).toString() !== 'Invalid Date';

    // Check if text is valid JSON or not ?
    static isValidJSON = text => {
        if (['boolean', 'number', 'array', 'object', 'null'].includes(typeof text)) {
            return true;
        }

        try {
            JSON.parse(text);
        } catch (error) {
            return false;
        }

        return true;
    }

    // Check if text is valid URL or not ?
    static isValidURL = string => {
        let URL;
        try {
            URL = new window.URL(string);
        } catch (e) {
            return false;
        }

        return URL.protocol === "http:" || URL.protocol === "https:";
    }

    static areEqualObjects = function (obj1, obj2) {
        const obj1Length = Object.keys(obj1).length;
        const obj2Length = Object.keys(obj2).length;

        if (obj1Length === obj2Length) {
            return Object.keys(obj1).every(key => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]);
        }

        return false;
    }

    // Check if email is valid or not ?
    static isValidEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    // Check if IP is valid or not ?
    static isValidIP = string => {
        const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;

        return regexExp.test(string);
    }

    // Responsible for fullscreen of any element
    static fullscreen = (element, mode) => {
        if (mode === 'on') {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            }
        } else if (mode === 'off') {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Mozilla */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            }
        }
    }

    // Responsible for managing sidebar visual via localStorage
    static handleSideBar = e => {
        e.preventDefault();
        let sidebarStorage = localStorage.getItem('sidebar');
        if (sidebarStorage === null) {
            localStorage.setItem('sidebar', 'sidebar-mini');
            document.body.classList.add('sidebar-mini');
        } else {
            if (sidebarStorage === "sidebar-mini") {
                localStorage.removeItem('sidebar');
                document.body.classList.remove('sidebar-mini');
                localStorage.setItem('sidebar', 'full-close');
                document.body.classList.add('full-close');
            } else if (sidebarStorage === "full-close") {
                localStorage.removeItem('sidebar');
                document.body.classList.remove('full-close');
            } else {
                localStorage.removeItem('sidebar');
                document.body.classList.remove('sidebar-mini');
            }
        }
    }

    // Used mainly for Design module to find the type of object, i.e whether object contain component or field
    static findFieldType = (items, type) => {
        items = items.filter(n => n);
        items = items.map((res, index) => {
            if (res !== null) {
                if (typeof res.components !== "undefined" && res.components.length > 0) {
                    const repeatLengthCheck = res.components.filter(n => n);
                    if (repeatLengthCheck.length > 0) {
                        res.isWhat = "component";
                        Helper.findFieldType(res.components, type);
                    }
                }
                else if (typeof res.fields !== "undefined" && res.fields.length > 0) {
                    const repeatLengthCheck = res.fields.filter(n => n);
                    if (repeatLengthCheck.length > 0) {
                        res.isWhat = "component";
                        Helper.findFieldType(res.fields, type);
                    }
                }
                else {
                    if (res.type !== "inline" && res.type !== "popup") {
                        res.isWhat = "field";
                    }
                }
                return res;
            }
        });
        return items;
    }

    static guid = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

    static conditionParser = condition => {
        let [field, operator = '==', value = ''] = condition.split(' ');
        // console.log(`Key: ${field}, Operator: ${operator}, Value: ${value}, ValueType: `, value !== 'undefined');
        let valueType = 'string';
        if (value !== '') {
            if (value[0] == "'" && value[value.length - 1] == "'") {
                value = value.slice(1, -1);
                valueType = 'string';
            } else if (value === 'true' || value === 'false') {
                valueType = 'boolean';
            } else if (value === "null") {
                valueType = 'null';
            } else if (isNaN(Number(value))) {
                valueType = 'businessTerm';
            } else {
                valueType = 'number';
            }
        }
        if (operator === "") {
            operator = "==";
        }

        return { field, operator, valueType, value }
    }
}