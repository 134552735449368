import React, { Component } from "react";
import Alert from "../../../../../components/Alert";
import Form from "../../../../../components/Form";
import { ComponentService } from "../../../../../services";
import Helper from "../../../../../utils/Helper";
import { Validator } from "../../../../../utils/Validator";
import componentSchema from "../../../../../config/schema/component.json";
import conditionSchema from "../../../../../config/schema/condition.json";

class Add extends Component {
    static defaultProps = {
        onAdd: (action, overrides) => { },
        onUpdate: (action, overrides) => { }
    }

    constructor(props) {
        super(props);
        this.services = {
            component: new ComponentService(this)
        }

        this.returnValueRef = null;

        this.state = {
            error: null,
            timestamp: + new Date(),
            override: {
                conditions: {},
                return_values: {}
            }
        }

        // Allow conditions to be updated in return values
        componentSchema['properties']['conditions'] = conditionSchema;
    }

    // Call this using ref from outside
    setOverrideForUpdate = (data = {}) => {
        const { ...override } = data;
        this.setState({ override });
    }

    submitHandler = (values, setSubmitting, setErrors) => {
        if (Helper.isEmptyObject(values.return_values)) {
            setSubmitting(false);
            setErrors({
                return_values: "At least one return value required to override"
            });

            return;
        }

        if (this.state.override.id) {
            this.services.component.updateOverride(this.props.componentId, this.state.override.id, values, setSubmitting, setErrors);
        } else {
            this.services.component.createOverride(this.props.componentId, values, setSubmitting, setErrors);
        }
    }

    render() {
        return (
            <Form
                id="addOverrideForm"
                key={this.state.override.id || this.state.timestamp}
                initialValues={{
                    conditions: this.state.override.conditions,
                    component_id: this.state.override.component_id,
                    return_values: this.state.override.return_values
                }}
                onSubmit={this.submitHandler}
                validationSchema={{
                    conditions: Validator.create().required().types(['object']),
                    return_values: Validator.create().required().types(['object'])
                }}>

                {this.state.override.id !== undefined &&
                    <Alert variant="secondary">
                        <strong className="text-danger">Note: </strong> You are editing override &nbsp;
                        <span className="badge bg-secondary"> {this.state.override.id}</span>
                        <span> Double click <strong>Cancel Editing</strong> button to cancel.</span>
                    </Alert>
                }

                {this.state.error &&
                    <Alert variant="danger" dismiss={() => {
                        this.setState({
                            error: null
                        });
                    }}>
                        {this.state.error}
                    </Alert>
                }

                <div className="row">
                    {this.props.parentComponents.length > 0 &&
                        <div className="col-xs-12">
                            <Form.Group name="component_id" label="Parent component">
                                <Form.Select name="component_id">
                                    {this.props.parentComponents.map(item => (
                                        <option key={item.uid} value={item.uid}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </div>
                    }

                    <div className="col-xs-12 col-md-6">
                        <Form.Group name="conditions" label="Conditions" required>
                            <Form.JSONEditor
                                schema={conditionSchema}
                                htmlElementProps={{
                                    name: "conditions"
                                }} />
                        </Form.Group>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Form.Group name="return_values" label="Return values" required>
                            <Form.JSONEditor
                                onLoad={editor => {
                                    this.returnValueRef = editor;
                                }}
                                schema={componentSchema}
                                htmlElementProps={{
                                    name: "return_values"
                                }} />
                        </Form.Group>
                    </div>
                    <div className="col-xs-12">
                        <Form.Submit className="btn btn-success m-r-5" />
                        {this.state.override.id &&
                            <button type="button" className="btn btn-danger" onDoubleClick={() => {
                                this.setState({
                                    error: null,
                                    override: {
                                        conditions: {},
                                        return_values: {}
                                    }
                                });
                            }}>
                                Cancel Editing
                            </button>
                        }
                    </div>
                </div>
            </Form>
        );
    }
}

export default Add;