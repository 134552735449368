import React, { useState } from "react";
import Box from "../../../components/Box";
import Form from "../../../components/Form";
import Alert from "../../../components/Alert";
import { Validator } from "../../../utils/Validator";
import UserContext from "../../../contexts/user-context";
import { SecuredApi as axios } from "../../../services/axios";

const ChangePassword = () => {
    const [state, setState] = useState({
        message: null,
        variant: "success"
    });

    const submitHandler = async (values, setSubmitting, setErrors) => {
        try {
            const response = await axios.put('/api/change-password', values);
            setSubmitting(false);

            setState({
                variant: "success",
                message: "Password changed successfully"
            });
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                setState({
                    message: response.data.Message,
                    variant: "danger"
                });
            }
        }
    }

    return (
        <Box title="Change password">
            <Form
                initialValues={{}}
                onSubmit={submitHandler}
                validationSchema={{
                    current_pass: Validator.create().required(),
                    password: Validator.create().required(),
                    password_confirmation: Validator.create().required()
                }}>

                {state.message &&
                    <Alert variant={state.variant} dismiss={() => {
                        setState({ message: null });
                    }}>
                        {state.message}
                    </Alert>
                }

                <div className="row">
                    <div className="col-xs-12">
                        <UserContext.Consumer>
                            {context => (
                                <p>
                                    <strong>Current user: </strong>
                                    {context.user.username}
                                </p>
                            )}
                        </UserContext.Consumer>
                        <Form.Group name="current_pass" label="Current password" required>
                            <Form.Input type="password" name="current_pass" placeholder="Enter current password" autoComplete="off" />
                        </Form.Group>

                        <Form.Group name="password" label="New password" required>
                            <Form.Input type="password" name="password" placeholder="Enter new password" autoComplete="off" />
                        </Form.Group>

                        <Form.Group name="password_confirmation" label="Confirm password" required>
                            <Form.Input type="password" name="password_confirmation" placeholder="Confirm password" autoComplete="off" />
                        </Form.Group>

                        <button type="submit" className="btn btn-success m-r-5">Save</button>
                    </div>
                </div>
            </Form>
        </Box>
    )
}

export default ChangePassword;