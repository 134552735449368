import React, { Component } from "react";
import ReactDOM from "react-dom";
import ModalContext from "../../contexts/modal-context";

class Modal extends Component {
    state = {
        show: true
    }

    static defaultProps = {
        keyboard: true,
        backdrop: false,
        onDismiss: () => { }
    }

    static Header = ({ children, style, close = true }) => (
        <ModalContext.Consumer>
            {context => (
                <div className="modal-header" style={style}>
                    {children}
                    {close === true && <button type="button" className="btn-close" title="Close" aria-label="Close" onClick={context.dismiss}></button>}
                </div>
            )}
        </ModalContext.Consumer>
    )

    static Title = ({ children, style }) => (
        <div className="modal-title h4" style={style}>
            <strong>{children}</strong>
        </div>
    );

    static Body = ({ children, style }) => (
        <div className="modal-body" style={style}>
            {children}
        </div>
    );

    static Footer = ({ children, style }) => (
        <div className="modal-footer" style={style}>
            {children}
        </div>
    );

    // Close Modal and call parent's onDismiss handler
    dismissModal = () => {
        this.setState({
            show: false
        });
        this.props.onDismiss();
        if (this.props.keyboard === true) {
            document.body.removeEventListener('keydown', this.closeOnEscKeyDown);
        }
    }

    closeOnEscKeyDown = e => {
        const code = e.keyCode || e.charCode || e.which;
        if (code === 27) {
            this.dismissModal();
        }
    }

    componentDidMount() {
        if (this.props.keyboard === true) {
            document.body.addEventListener('keydown', this.closeOnEscKeyDown);
        }
    }

    componentWillUnmount() {
        if (this.props.keyboard === true) {
            document.body.removeEventListener('keydown', this.closeOnEscKeyDown);
        }
    }

    render() {
        let extraCSS = {
            dialog: ""
        }

        if (this.props.modalSize) {
            extraCSS.dialog += ` modal-${this.props.modalSize}`;
        }

        if (this.props.verticalCenter) {
            extraCSS.dialog += " modal-dialog-centered";
        }
        
        if (this.props.scrollContent) {
            extraCSS.dialog += " modal-dialog-scrollable";
        }
        
        if (this.props.fullScreen) {
            extraCSS.dialog += " modal-fullscreen";
        }

        if (this.props.smFullScreen) {
            extraCSS.dialog += " modal-fullscreen-sm-down";
        }

        if (this.props.mdFullScreen) {
            extraCSS.dialog += " modal-fullscreen-md-down";
        }

        if (this.props.lgFullScreen) {
            extraCSS.dialog += " modal-fullscreen-lg-down";
        }

        if (this.state.show === false) {
            return null;
        }

        return ReactDOM.createPortal(
            <ModalContext.Provider value={{
                show: true,
                dismiss: this.dismissModal
            }}>
                <div className="modal-backdrop show"></div>
                <div className="modal d-block" tabIndex="-1" onClick={() => this.props.backdrop === 'static' || this.dismissModal()}>
                    <div onClick={e => e.stopPropagation()} className={`modal-dialog${extraCSS.dialog}`}>
                        <div className="modal-content rounded-3">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </ModalContext.Provider>,
            document.getElementById('modal-root')
        );
    }
}

export default Modal;