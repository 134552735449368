import React from "react";
import Alert from "../Alert";

class ErrorBoundary extends React.Component {
    static defaultProps = {
        error: (
            <Alert variant="danger">Something went wrong.</Alert>
        )
    }

    state = {
        hasError: false
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            return this.props.error;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;