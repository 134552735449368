import React from 'react';
import Element from './Element';
import Helper from '../../../utils/Helper';
import Backdrop from '../../../components/Backdrop';

const SelectElement = ({
    repeat,
    preview,
    tabPane,
    elements,
    draggable,
    doubleClick,
    dragEndHandler,
    dragDropHandler,
    repeatElementId,
    dragStartHandler,
}) => {

    if (draggable !== undefined) {
        return (
            elements.map((element, index) => {
                let checkElement;
                switch (element.isWhat) {
                    case "field":
                        if (repeat !== undefined && repeat === true) {
                            checkElement = <div className="mb-2" key={element.id}>
                                <Element key={element.id} {...element} />
                            </div>;
                        } else {
                            checkElement = <Backdrop
                                draggable
                                id={element.id}
                                key={element.id}
                                className="draggable"
                                onDrop={(e) => dragDropHandler(e)}
                                onDragEnd={(e) => dragEndHandler(e)}
                                onDragStart={(e) => dragStartHandler(e)}
                                title={element.name}
                                onDoubleClick={() => doubleClick(element, "remove")}
                            >
                                <div>
                                    <Element key={element.id} {...element} />
                                </div>
                            </Backdrop>;
                        }
                        break;
                    case "component":
                        if (element.fields !== undefined && element.fields.length > 0) {
                            if (repeat !== undefined && repeat === true) {
                                checkElement = <div id={element.id} key={element.id}>
                                    <SelectElement
                                        repeat={true}
                                        draggable={true}
                                        elements={element.fields}
                                    />
                                </div>;
                            } else {
                                checkElement = <Backdrop
                                    draggable
                                    id={element.id}
                                    key={element.id}
                                    className="draggable"
                                    onDrop={(e) => dragDropHandler(e)}
                                    onDragEnd={(e) => dragEndHandler(e)}
                                    onDragStart={(e) => dragStartHandler(e)}
                                    title={element.name}
                                    onDoubleClick={() => doubleClick(element, "remove")}
                                >
                                    <div>
                                        <SelectElement
                                            repeat={true}
                                            draggable={true}
                                            elements={element.fields}
                                        />
                                    </div>
                                </Backdrop>;
                            }
                        }
                        if (element.components !== undefined && element.components.length > 0) {
                            checkElement = <Backdrop
                                draggable
                                id={element.id}
                                key={element.id}
                                className="draggable"
                                onDrop={(e) => dragDropHandler(e)}
                                onDragEnd={(e) => dragEndHandler(e)}
                                onDragStart={(e) => dragStartHandler(e)}
                                title={element.name}
                                onDoubleClick={() => doubleClick(element, "remove")}
                            >
                                <div>
                                    <SelectElement
                                        repeat={true}
                                        draggable={true}
                                        key={element.id}
                                        repeatElementId={element}
                                        elements={element.components}
                                    />
                                </div>
                            </Backdrop>;
                        }
                        break;
                    default:
                        break;
                }
                return checkElement;
            })
        );
    }

    if (preview !== undefined) {
        return (
            elements.map((element, index) => {
                let checkElement;
                switch (element.isWhat) {
                    case "field":
                        if (repeat !== undefined && repeat === true) {
                            checkElement = <div className="form-group mb-2" key={index}>
                                <Element key={index} previewDisabled="false" {...element} />
                            </div>;
                        } else {
                            checkElement = <div className="col-12" id={element.id} key={index}>
                                <div className="form-group mb-2">
                                    <Element key={index} previewDisabled="false" {...element} />
                                </div>
                            </div>;
                        }
                        break;
                    case "component":
                        if (element.fields !== undefined && element.fields.length > 0) {
                            let repeatOrElement;
                            if (repeatElementId !== undefined && repeatElementId !== "") {
                                repeatOrElement = repeatElementId;
                            } else {
                                repeatOrElement = element;
                            }
                            checkElement = <div className="col-12" key={index}>
                                <SelectElement
                                    repeat={true}
                                    preview={true}
                                    elements={element.fields}
                                    key={index}
                                />
                            </div>;
                        }
                        if (element.components !== undefined && element.components.length > 0) {
                            checkElement = <SelectElement
                                repeat={true}
                                preview={true}
                                key={index}
                                repeatElementId={element}
                                elements={element.components}
                            />;
                        }
                        break;
                    default:
                        break;
                }
                return checkElement;
            })
        );
    }

    if (tabPane !== undefined) {
        return (
            elements.map((element, index) => {
                let checkElement;
                switch (element.isWhat) {
                    case "field":
                        if (repeat !== undefined && repeat === true) {
                            checkElement = <div className="mb-2" key={index}>
                                <Element key={element.id} {...element} />
                            </div>;
                        } else {
                            checkElement = <Backdrop key={index} title={element.name} onDoubleClick={() => doubleClick(element, "add")}>
                                <div className="w-75 text-truncate">
                                    <code>{Helper.capitalizeFirstLetter(element.name.replaceAll("_", " "))}</code>
                                </div>
                                <hr className="my-2" />
                                <Element key={element.id} {...element} />
                            </Backdrop>;
                        }
                        break;
                    case "component":
                        if (element.fields !== undefined && element.fields.length > 0) {
                            if (repeat !== undefined && repeat === true) {
                                checkElement = <div className="mb-2" key={index}>
                                    <SelectElement
                                        key={index}
                                        repeat={true}
                                        tabPane={true}
                                        elements={element.fields}
                                    />
                                </div>;
                            } else {
                                checkElement = <Backdrop key={index} title={element.name} onDoubleClick={() => doubleClick(element, "add")}>
                                        <div className="w-75 text-truncate">
                                            <code>{Helper.capitalizeFirstLetter(element.name.replaceAll("_", " "))}</code>
                                        </div>
                                        <hr className="my-1" />
                                        <SelectElement
                                            key={index}
                                            repeat={true}
                                            tabPane={true}
                                            elements={element.fields}
                                        />
                                </Backdrop>;
                            }
                        }
                        if (element.components !== undefined && element.components.length > 0) {
                            checkElement = <Backdrop key={index} title={element.name} onDoubleClick={() => doubleClick(element, "add")}>
                                    {
                                        element.isWhat === "component" &&
                                        <>
                                            <div className="w-75 text-truncate">
                                                <code>{Helper.capitalizeFirstLetter(element.name.replaceAll("_", " "))}</code>
                                            </div>
                                            <hr className="my-2" />
                                        </>
                                    }
                                    <SelectElement
                                        repeat={true}
                                        tabPane={true}
                                        repeatElementId={element}
                                        elements={element.components}
                                        key={index}
                                    />
                            </Backdrop>;
                        }
                        break;
                    default:
                        break;
                }
                return checkElement;
            })
        );
    }

    return null;
}

export default SelectElement;