import React from 'react';
import Form from "../../../components/Form";

const BasicEvent = () => {
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4">
                <Form.Group 
                    required 
                    name="event" 
                    label="Event Name" 
                >
                    <Form.Input type="text" placeholder="Event name" id="event" name="event" />
                </Form.Group>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
                <Form.Group 
                    name="tags" 
                    label="Tags" 
                    hint="Enter comma separated list. For eg: Business Terms or JIRA ticket numbers"
                >
                    <Form.Input type="text" placeholder="Enter tags" name="tags" />
                </Form.Group>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
                <Form.Group name="source" label="Source">
                    <Form.Select 
                        name="source" 
                        placeholder="--- Select Source ---" 
                    >
                        <option value="formbuilder.casecreation">Case Creation</option>
                    </Form.Select>
                </Form.Group>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
                <Form.Group name="description" label="Description">
                    <Form.Textarea 
                        type="textarea" 
                        name="description" 
                        placeholder="Type a description for the event..." 
                    />
                </Form.Group>
            </div>
        </div>
    );
}

export default BasicEvent;
