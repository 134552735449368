import React, { useRef, useState } from "react";
import Fullscreen from "../Fullscreen";
import { Link } from "react-router-dom";

const Box = ({ id = '', className = '', title = '', variant = '', collapse = false, buttons = [], allowFullScreen = false, children, ...props }) => {
    const ref = useRef(null);
    const [collapsed, toggleCollapse] = useState(props.collapsed || false);

    React.useEffect(() => {
        if (collapsed !== props.collapsed) {
            toggleCollapse(props.collapsed);
        }
    }, [props.collapsed]);

    const renderButtons = () => {
        buttons = Array.isArray(buttons) ? buttons : [buttons];
        return buttons.map((button, index) => {
            if (button.show === false) {
                return null;
            }

            let element = null;
            if (button.type === 'link') {
                if (button.external === true) {
                    element = (
                        <a
                            key={index}
                            href={button.href}
                            title={button.title}
                            target={button.target}
                            className={button.class}>
                            {button.value}
                        </a>
                    )
                } else {
                    element = (
                        <Link
                            key={index}
                            to={button.href}
                            title={button.title}
                            target={button.target}
                            className={button.class}>
                            {button.value}
                        </Link>
                    )
                }
            } else {
                element = (
                    <button
                        key={index}
                        type={button.type}
                        title={button.title}
                        className={button.class}
                        onClick={button.clicked}
                        disabled={button.disabled}>
                        {button.value}
                    </button>
                )
            }

            return element;
        });
    }

    return (
        <div id={id} ref={ref} className={"ibox " + className + (collapsed ? ' closed' : ' open') + (variant ? '  ibox-' + variant : '')} {...props}>
            <div className="ibox-head">
                <div className="ibox-title">
                    <strong>{title}</strong>
                </div>
                <div className="ibox-links">
                    {renderButtons()}

                    {allowFullScreen && <Fullscreen element={ref.current} />}

                    {collapse !== false &&
                        <button type="button" className="ibox-collapse btn btn-default btn-sm" onClick={() => toggleCollapse(!collapsed)}>
                            <i className={collapsed ? 'fa fa-plus' : 'fa fa-minus'}></i>
                        </button>
                    }
                </div>
            </div>
            <div className={"ibox-body " + ((collapse === false || collapsed === false) ? '' : 'd-none')}>
                {children}
            </div>
        </div>
    )
}

export default Box;