import React from "react";
import Avatar from '../../../../assets/img/avatar.png';
import UserContext from "../../../../contexts/user-context";
import Helper from "../../../../utils/Helper";

const UserDetails = () => (
    <UserContext.Consumer>
        {context => (
            <div className="admin-block d-flex">
                <div>
                    <img src={Avatar} width="45px" alt="Avatar" />
                </div>
                <div className="admin-info">
                    <div className="font-strong">{Helper.capitalizeFirstLetter(context.user.name)}</div>
                    <small>{Helper.capitalizeFirstLetter(context.user.role)}</small>
                </div>
            </div>
        )}
    </UserContext.Consumer>
)

export default UserDetails;