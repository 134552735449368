import React from "react";
import UserDetails from "./UserDetails";
import Navbar from "./Navbar";

const Sidebar = () => (
    <nav className="page-sidebar h-100" id="sidebar">
        <div className="h-100" id="sidebar-collapse">
            <UserDetails />
            <Navbar />
        </div>
    </nav>
);

export default Sidebar;