import React, { useState, useCallback, useEffect, Suspense } from "react";
import ReactFlow, {
	addEdge,
	Controls,
	Position,
	Background,
	// useNodesState,
	// useEdgesState,
	useReactFlow,
	applyNodeChanges,
	applyEdgeChanges,
	ReactFlowProvider,
} from "react-flow-renderer";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Form from "../../../components/Form";
import Confirm from "../../../components/Confirm";
import { Validator } from "../../../utils/Validator";
import { schema } from "../../../config/schema";
import { useProcessManagement } from "../../../hooks";
import Spinner from "../../../components/Spinner";
import Alert from "../../../components/Alert";
import Tabs from "../../../components/Tabs";
import ButtonEdge from "../../../components/ProcessConfiguration/button-edge";
import Changelog from "../../../components/Changelog";
import PreventUnload from "../../../components/PreventUnload";
import MagicConstants from "../../../components/MagicConstants";

import "../styles.css";
import StartNode from "../../../components/ProcessConfiguration/start-node";
import EndNode from "../../../components/ProcessConfiguration/end-node";
import AddBasic from "../../../components/ProcessConfiguration/add-basic";
const ComponentNode = React.lazy(() =>
	import("../../../components/ProcessConfiguration/component-node")
);
const ConditionNode = React.lazy(() =>
	import("../../../components/ProcessConfiguration/condition-node")
);
const Sidebar = React.lazy(() =>
	import("../../../components/ProcessConfiguration/Sidebar")
);
const Box = React.lazy(() => import("../../../components/Box"));

const onLoad = (reactFlowInstance) => {
	setTimeout(() => reactFlowInstance.fitView(), 1);
};

const initBgColor = "#f1f1f1";
const nodeTypes = {
	componentNode: ComponentNode,
	conditionNode: ConditionNode,
	startNode: StartNode,
	endNode: EndNode,
};

const edgeTypes = {
	buttonedge: ButtonEdge,
};

const AddProcessFlow = ({ process }) => {
	const history = useHistory();
	const processId = process.match.params.id;
	const { pathname } = process.location;
	const [nodes, setNodes] = useState([]);
	const [y, setY] = useState(0);
	const [x, setX] = useState(0);
	const [edges, setEdges] = useState([]);
	const [bgColor, setBgColor] = useState(initBgColor);
	const [name, setName] = useState("");
	const [status, setStatus] = useState("draft");
	const [retailer_id, setRetailerId] = useState("");
	const [title, setTitle] = useState("");
	const [user_type, setUserType] = useState("");
	const [cid, setConditionId] = useState(1);
	const [isLoading, setIsLoading] = useState(true);

	const {
		create,
		pageList,
		externalActionsList,
		getProcess,
		update,
		remove,
	} = useProcessManagement();
	const [rfInstance, setRfInstance] = useState(null);
	const { setViewport } = useReactFlow();
	const [pages, setPages] = useState([]);
	const [selectedPageId, setSelectedPageId] = useState();
	const [externalActions, setExternalActions] = useState([]);
	const [sheetActive, setSheetActive] = useState(false);
	const [selectedCId, setSelectedCid] = useState("");
	const [message, setMessage] = useState("");
	const [variant, setVariant] = useState("");
	const [showConfirm, setShowConfirm] = useState(false);
	const [touched, setTouched] = useState(false);
	const FLOW_ADD = "/flows/add";
	const FLOW_UPDATE = `/flows/update/${processId}`;
	const PROCESS_ADD = "/process/add";
	const PROCESS_UPDATE = `/process/update/${processId}`;

	const onNodesChange = useCallback((changes) => {
		setNodes((ns) => applyNodeChanges(changes, ns));
		setTouched(true);
	}, []);
	const onEdgesChange = useCallback((changes) => {
		setEdges((es) => applyEdgeChanges(changes, es));
		setTouched(true);
	}, []);

	const processType = [PROCESS_ADD, PROCESS_UPDATE].includes(pathname)
		? "process"
		: "flows";
	const confirmModal = {
		show: false,
		title: "Confirm",
		message: "Are you sure want to delete this?",
		onConfirm: () => {
			setTouched(false);
			remove(processId, processType);
			history.replace(`/${processType}`);
			setShowConfirm(false);
		},
		onCancel: () => {
			setTouched(false);
			setShowConfirm(false);
		},
	};

	const handleSubmit = async (formData, submitting) => {
		setTouched(false);
		try {
			let process = {};
			if (rfInstance) {
				const flow = rfInstance.toObject();
				formData.flow = flow;
				process = flow.edges.filter(function (eg) {
					if (
						eg.source.indexOf("c_") >= 0 &&
						eg.target.indexOf("c_") >= 0
					) {
						throw new Error(
							"Condition node cannot be connected to an another condition node"
						);
					}
					if (eg.source.indexOf("c_") >= 0) {
						return false; // skip
					}
					return true;
				}).map(function (eg) {
					let source_page_id = eg.source === "start" ? null : eg.source;
					let target_value = {};
					let conditions = {};
					let description = "";
					if (eg.target.indexOf("c_") >= 0) {
						const cnode = flow.nodes.find(n => n.id === eg.target);
						if (processType === "process") {
							conditions = cnode ? cnode.data.conditions : {};
						} else {
							description = formData[eg.target];
							cnode.data.description = description;
							delete formData[eg.target];
						}

						const end = flow.edges.find(e => e.source === eg.target);

						target_value.go_to_page = end.target;
						if (Array.isArray(cnode.data.externalActions)) {
							target_value.external_actions = {};
							cnode.data.externalActions.forEach(ea => {
								target_value.external_actions[ea.keyword] = ea.id;
							});
						}
					} else {
						target_value.go_to_page = eg.target === 'end' ? null : eg.target;
					}

					if (processType === "process") {
						return {
							source_page_id,
							target_value,
							conditions,
						};
					} else {
						return {
							source_page_id,
							target_value,
							description,
						};
					}
				});
			}
			if (processType === "process") {
				formData.process = process;
			} else {
				formData.flows = process;
			}

			formData.status = formData.status || "draft";
			const response = processId
				? await update(processId, formData, processType)
				: await create(formData, processType);
			if (response.Status && response.Status === "Success") {
				history.replace(`/${processType}/update/${response.Data.id}`);
				setVariant(getVariant(response));
				setMessage(response.Message);
			}
			submitting(false);
		} catch ({ message, statusCode, response }) {
			setVariant("danger");
			submitting(false);
			if (statusCode === 409) {
				setMessage(
					<>
						Unable to create. Record already exists for this
						retailer and user type. &nbsp;
						<Link
							to={
								`/${processType}/update/` +
								response.data.data.Errors.id
							}
						>
							Click here &nbsp;
						</Link>
						to open it.
					</>
				);
			} else {
				setMessage(message);
			}
		}
	};

	const getVariant = (response) => {
		switch (response.Status) {
			case "Success":
				return "success";
			case "Error":
				return "danger";
			default:
				return "success";
		}
	};

	const addEndNode = () => {
		const endNode = {
			id: "end",
			type: "endNode",
			data: {
				label: "END",
			},
			position: { x, y: y + 250 },
			targetPosition: Position.Left,
		};
		setNodes((nds) => nds.concat(endNode));
	};

	const addNode = () => {
		const newNode = {
			id: `c_${cid}`,
			type: "conditionNode",
			data: {
				label: "CONDITIONS",
				id: `c_${cid}`,
				type: pathname,
				externalActions: [],
				onAdd: setSheetActive,
				onSelect: setSelectedCid,
				description: "",
				onNodeDelete: onNodeDelete,
			},
			position: { x, y: y + 250 },
			sourcePosition: Position.Top,
		};
		setNodes([...nodes, newNode]);
		setConditionId(cid + 1);
	};
	const onNodeDragStop = (event, node) => {
		setY(node.position.y);
		setX(node.position.x);
		setTouched(true);
	};

	const setPageTitle = () => {
		switch (pathname) {
			case FLOW_ADD:
				setTitle("Create flow");
				return;
			case PROCESS_ADD:
				setTitle("Create process");
				return;
			case FLOW_UPDATE:
				setTitle("Update flow");
				return;
			case PROCESS_UPDATE:
				setTitle("Update process");
				return;
			default:
				setTitle("Create process");
				return;
		}
	};

	useEffect(() => {
		async function fetchData() {
			setPageTitle();
			try {
				let x = 0;
				const startNode = {
					id: "start",
					type: "startNode",
					data: {
						label: "START",
					},
					position: { x, y: 0 },
					targetPosition: Position.Left,
				};
				setNodes([startNode]);
				if (processId) {
					const processResponse = await getProcess(
						processId,
						processType
					);

					if (processResponse.Status) {
						setVariant(getVariant(processResponse));
						setMessage(processResponse.Message);
					}

					const { flow, name, retailer_id, user_type, status } =
						processResponse.data.Data;
					if (flow?.nodes) {
						const lastCondition = flow.nodes.slice().reverse().find((n) => n.id.includes("c_"));

						if (lastCondition) {
							const ids = lastCondition.id.slice("c_");
							setConditionId(ids[1] + 1);
						}
					}

					setName(name);
					setRetailerId(retailer_id);
					setUserType(user_type);
					setStatus(status);

					if (flow?.viewport) {
						const { x = 0, y = 0, zoom = 1 } = flow.viewport;
						if (flow.nodes) {
							const newNodes = flow.nodes.map((node) => {
								if (node.id.indexOf("c_") >= 0) {
									return {
										...node,
										data: {
											...node.data,
											onAdd: setSheetActive,
											onSelect: setSelectedCid,
											onNodeDelete: onNodeDelete,
										},
									};
								}
								return {
									...node,
									data: {
										...node.data,
										id: node.id,
										onNodeDelete: onNodeDelete,
									},
								};
							});
							setNodes(newNodes);
						} else {
							setNodes([]);
						}
						if (flow.edges) {
							const newEdges = flow.edges.map((e) => {
								return {
									...e,
									data: {
										onEdgeClick: onEdgeClick,
										edges: flow.edges,
									},
								};
							});
							setEdges(newEdges);
						} else {
							setEdges([]);
						}
						setViewport({ x, y, zoom });
					}
				}

				if (processId) {
					if (pages.length === 0) {
						const response = await pageList();
						let pagesList = response.data.Data.items;
						setPages(pagesList);
						pagesList.sort(function (a, b) {
							if (a.name < b.name) {
								return -1;
							}
							if (a.name > b.name) {
								return 1;
							}
							return 0;
						});
					}
					if (externalActions.length === 0) {
						const eaResponse = await externalActionsList();
						setExternalActions(eaResponse.data.Data.items);
					}
				}
			} catch ({ message, statusCode, response }) {
				setVariant("danger");
				setMessage(message);
			}

			setIsLoading(false);
		}

		fetchData();
	}, []);

	const onConnect = useCallback(
		(params) => {
			setTouched(true);
			setEdges((els) =>
				addEdge(
					{
						...params,
						animated: false,
						style: { stroke: "#0041d0" },
						type: "buttonedge",
						data: {
							onEdgeClick: onEdgeClick,
						},
					},
					els
				)
			);
		},
		[setEdges]
	);

	const onNodeDelete = (id) => {
		setTouched(true);
		setNodes((nds) => nds.filter((node) => node.id !== id));
		setEdges((eds) => {
			return eds.filter((e) => e.target !== id);
		});
	};

	const onEdgeClick = (ev, edgeId, list) => {
		setTouched(true);
		setEdges((eds) => {
			return eds.filter((e) => e.id !== edgeId);
		});
	};

	const onAddPage = useCallback(() => {
		const selectedPage = pages.find((pg) => pg.uid === selectedPageId);
		// Check whether page already exist
		const existingNode = nodes.find((n) => n.id === selectedPageId);

		if (selectedPage && !existingNode) {
			const newNode = {
				id: selectedPageId,
				type: "componentNode",
				data: {
					label: selectedPage.name,
					onNodeDelete: onNodeDelete,
					id: selectedPageId,
				},
				position: { x: 100, y: y + 250 },
			};
			setNodes((nds) => nds.concat(newNode));
		}
	}, [setNodes, pages, selectedPageId]);

	const handleConditionChange = data => {
		let extActions = [];
		if (data) {
			Object.entries(data).map(e => {
				if (
					e[0].includes(`external_actions_${selectedCId}`) &&
					e[1] !== 0
				) {
					const ea = e[0].split(`external_actions_${selectedCId}_`);
					const ex = externalActions.find((e) => e.id === ea[1]);
					const keyword = data[`external_actions_keyword_${selectedCId}_${ea[1]}`] ?? "";

					if (ex) {
						extActions.push({ id: e[1], name: ex.name, keyword });
					}
				}
			});
		}

		setNodes(nds =>
			nds.map(node => {
				if (node.id === selectedCId) {
					return {
						...node,
						data: {
							...node.data,
							conditions: data[`conditions_${selectedCId}`],
							externalActions: extActions,
							description: data[`description_${selectedCId}`],
						},
					};
				}

				return node;
			})
		);

		setTouched(true);
		setSheetActive(false);
	};

	const getDescription = selectedCId => {
		if (selectedCId) {
			const node = nodes.find((n) => n.id === selectedCId);
			return node.data.description;
		}
	};

	const getConditions = selectedCId => {
		if (selectedCId) {
			const node = nodes.find((n) => n.id === selectedCId);
			return node.data.conditions;
		}
	};

	const prepareExternalActions = selectedCId => {
		let initialValues = {
			[`description_${selectedCId}`]: getDescription(selectedCId),
			[`conditions_${selectedCId}`]: getConditions(selectedCId),
		};

		if (selectedCId) {
			const node = nodes.find(n => n.id === selectedCId);
			if (node.data.externalActions) {
				externalActions.forEach(ea => {
					const ex = node.data.externalActions.find(e => e.id === ea.id);
					if (ex) {
						initialValues[`external_actions_${selectedCId}_${ea.id}`] = ea.id;
						initialValues[`external_actions_keyword_${selectedCId}_${ea.id}`] = ex.keyword;
					}
				});
			}
		}

		return initialValues;
	}

	const deleteHandler = () => {
		setShowConfirm(true);
	}

	return (
		<Suspense fallback={<div>Loading.....</div>}>
			<Spinner isLoading={isLoading}>
				<PreventUnload when={touched}>
					{message && (
						<Alert variant={variant} dismiss={() => {
							setMessage(null);
						}}>
							{message}
						</Alert>
					)}

					{showConfirm && <Confirm {...confirmModal} />}

					<Form
						initialValues={{
							name,
							retailer_id,
							user_type,
							status,
						}}
						onSubmit={handleSubmit}
						validationSchema={{
							name: Validator.create().required(),
							retailer_id: Validator.create().required(),
							user_type: Validator.create().required(),
						}}
					>
						<Box
							allowFullScreen
							title={title}
							buttons={[
								{
									type: "submit",
									value: "Update",
									class: "btn btn-sm btn-success",
									show:
										processId !== undefined &&
										isLoading === false,
								},
								{
									type: "button",
									value: "Delete",
									class: "btn btn-sm btn-danger",
									show:
										processId !== undefined &&
										isLoading === false,
									clicked: deleteHandler,
								}
							]}
						>
							<Tabs active="retailerDetails">
								<Tabs.Pane id="retailerDetails" title="Retailer Details">
									<AddBasic processId={processId} type={processType === 'process' ? 'process' : 'flow'} />
								</Tabs.Pane>
								<Tabs.Pane id="processCreation" title="Design" disabled={!processId}>
									{processId && (
										<div id="process-design">
											<div className="row">
												<div className="col-12">
													<Sidebar
														pages={pages}
														addNode={addNode}
														setSelectedPageId={setSelectedPageId}
														onAddPage={onAddPage}
														addEndNode={addEndNode}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-12" style={{
													height: 750
												}}>
													<ReactFlow
														nodes={nodes}
														edges={edges}
														snapToGrid={true}
														nodeTypes={nodeTypes}
														edgeTypes={edgeTypes}
														onConnect={onConnect}
														onInit={setRfInstance}
														onEdgesChange={onEdgesChange}
														onNodesChange={onNodesChange}
														style={{ background: bgColor }}
														onNodeDragStop={onNodeDragStop}
														attributionPosition="top-right"
													>
														<Background color="#aaa" gap={20} />
														<Controls />
													</ReactFlow>
												</div>
											</div>
										</div>
									)}
								</Tabs.Pane>

								<Tabs.Pane id="magicConstants" title="Magic constants" disabled={!processId}>
									<MagicConstants />
								</Tabs.Pane>

								{processId &&
									<Tabs.Pane id="changelog" title="Changelog">
										<Changelog />
									</Tabs.Pane>
								}
							</Tabs>
						</Box>
					</Form>
				</PreventUnload>
			</Spinner>

			{sheetActive && (
				<Form
					initialValues={prepareExternalActions(selectedCId)}
					onSubmit={handleConditionChange}
					validationSchema={{}}
				>
					{sheetActive && (
						<div className="sheet">
							<div className="row">
								<div className="col-11">
									<h5>Add details</h5>
								</div>
								<div className="col-1">
									<button className="btn text-danger pull-right" onClick={() => {
										setSheetActive(false);
										setTouched(true);
									}}>
										<i className="fa fa-close"></i>
									</button>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<Form.Group name={`description_${selectedCId}`} label="Description">
										<Form.Textarea type="textarea" name={`description_${selectedCId}`} placeholder="Describe here..." rows="2" />
									</Form.Group>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<Form.Group name={`conditions_${selectedCId}`} label="Conditions">
										<Form.JSONEditor
											schema={schema.conditions}
											htmlElementProps={{
												name: `conditions_${selectedCId}`,
											}}
										/>
									</Form.Group>
								</div>
							</div>
							<h6>External Actions</h6>
							<div className="row">
								<div className="col-12">
									{externalActions.map(ea => (
										<div className="external-actions" key={`${selectedCId}_${ea.id}`}>
											<div className="row">
												<div className="col-7">
													<Form.Group name={`external_actions_${selectedCId}_${ea.id}`}>
														<Form.Checkbox value={ea.id} id={`${selectedCId}_${ea.id}`} name={`external_actions_${selectedCId}_${ea.id}`} label={ea.name} uncheckedValue={null} />
													</Form.Group>
												</div>
												<div className="col-5">
													<Form.Group name={`external_actions_keyword_${selectedCId}_${ea.id}`}>
														<Form.Input type="text" placeholder="Enter keyword" name={`external_actions_keyword_${selectedCId}_${ea.id}`} />
													</Form.Group>
												</div>
											</div>
										</div>
									))}
								</div>

								<div className="col-12">
									<button type="submit" className="btn btn-sm btn-success m-r-5">
										Done
									</button>
									<button type="button" className="btn btn-sm btn-secondary" onClick={() => setSheetActive(false)}>
										Cancel
									</button>
								</div>
							</div>
						</div>
					)}
				</Form>
			)}
		</Suspense>
	);
};

export default process => (
	<ReactFlowProvider>
		<AddProcessFlow process={process} />
	</ReactFlowProvider>
);