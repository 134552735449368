import React from 'react';
import Group from './Group.jsx';
import Modal from '../Modal';
import SeeMore from '../SeeMore';
import Helper from '../../utils/Helper';
import ContentEditable from '../ContentEditable';

const QueryBuilder = ({ conditionQuery, fetchData, businessTerm }) => {
    const [query, setQuery] = React.useState(conditionQuery);
    const [modal, setModal] = React.useState({
        show: false, 
        message: {}
    });

    const performAction = (id, action, dataPack) => {
        const queryClone = [{...query}];
        const recursiveFinder = cloner => {
            cloner.forEach((arrParse, arrParseIndex) => {
                if (arrParse !== undefined && arrParse.rules !== undefined) {
                    const idFinder = arrParse.rules.findIndex(obj => obj.uid === id);
                    if (idFinder !== -1) {
                        if (action === "remove-condition" || action === "remove-group") {
                            cloner[arrParseIndex].rules.splice(idFinder, 1);
                            // If that GROUP after removing that condition has no other conditions left 
                            // & group is not the root then delete that GROUP
                            (
                                arrParse.rules.length === 0 
                                && action === "remove-condition" 
                                && cloner[0].uid !== query.uid
                            ) && cloner.splice(arrParseIndex, 1);
                        }
                        if (action === "add-condition" || action === "add-group") {
                            let newConditionOrGroup = {"uid": Helper.guid() + Helper.guid(), "condition": " ", "field": "", "operator": "==", "value": "", "valueType": "string"};
                            if (action === "add-group") 
                                newConditionOrGroup = {"uid": Helper.guid() + Helper.guid(), "gate": "AND", "rules": [newConditionOrGroup]};
                            cloner[arrParseIndex].rules[idFinder].rules.push(newConditionOrGroup);
                        }
                        if (action === "change-gate") {
                            cloner[arrParseIndex].rules[idFinder].gate = dataPack;
                        }
                        if (action === "save-condition") {
                            cloner[arrParseIndex].rules[idFinder] = dataPack;
                        }
                    } else if (arrParse.uid === id) {
                        if (action === "add-condition" || action === "add-group") {
                            let newConditionOrGroup = {"uid": Helper.guid() + Helper.guid(), "condition": " ", "field": "", "operator": "==", "value": "", "valueType": "string"};
                            if (action === "add-group") 
                                newConditionOrGroup = {"uid": Helper.guid() + Helper.guid(), "gate": "AND", "rules": [newConditionOrGroup]};
                            cloner[arrParseIndex].rules.push(newConditionOrGroup);
                        } else if (action === "change-gate") {
                            cloner[arrParseIndex].gate = dataPack;
                        }
                    } else {
                        recursiveFinder(arrParse.rules);
                    }
                }
            });
            return cloner;
        }
        const newQuery = recursiveFinder(queryClone)[0];
        setQuery(newQuery);
        fetchData(newQuery);
    }

    const saveJSONQuery = () => {
        // console.log("JSON: ", query);
        const queryClone = [JSON.parse(JSON.stringify(query))];
        const recursiveParser = cloner => {
            cloner.forEach((arrParse, arrParseIndex) => {
                if (arrParse.rules !== undefined) {
                    cloner[arrParseIndex] = {[arrParse.gate]: [...arrParse.rules]};
                    recursiveParser(cloner[arrParseIndex][arrParse.gate]);
                } else if (arrParse.condition !== undefined) {
                    const conditionState = Helper.conditionParser(arrParse.condition);
                    if (!(Object.values(conditionState).some(val => String(val) === ""))) {
                        cloner[arrParseIndex] = arrParse.condition;
                    } else if (Object.values(conditionState).some(val => String(val) === "")) {
                        // Delete that empty condition
                        cloner.splice(arrParseIndex, 1);
                        // Tell the finder to go again with the same array again as original array is already hindered
                        recursiveParser(cloner);
                    }
                }
            });
            return cloner;
        }
        const newQuery = recursiveParser(queryClone)[0];
        // console.log(newQuery);
        setModal({show: true, message: newQuery});
    }
    
    return (
        <Group key={query.uid} {...query} performAction={performAction} businessTerm={businessTerm} root >
            {
                modal.show && <Modal keyboard={false} backdrop="static" onDismiss={() => setModal({show: false, message: {}})} scrollContent>
                    <Modal.Header close={true}>
                        <Modal.Title>Preview Query</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContentEditable disabled>
                            <SeeMore>
                                <pre>
                                    {JSON.stringify(modal.message, null, 2)}
                                </pre>
                            </SeeMore>
                        </ContentEditable>
                    </Modal.Body>
                </Modal>
            }
            <button 
                type="button" 
                onClick={saveJSONQuery} 
                className="btn btn-outline-warning ms-auto"
            >
                <i className="fa fa-cloud-download fs-4"></i>
            </button>
        </Group>
    );
}

export default QueryBuilder;