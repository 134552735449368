import React from "react";
import SeeMore from "../../SeeMore";
import ContentEditable from "../../ContentEditable";

const PrintObject = ({ data = {}, maxLines = 10, disabled = false }) => {
    if (data === null || (Array.isArray(data) && data.length === 0)) {
        data = {};
    }

    return (
        <ContentEditable disabled={disabled}>
            <SeeMore maxLines={maxLines}>
                <pre>
                    {JSON.stringify(data, null, 2)}
                </pre>
            </SeeMore>
        </ContentEditable>
    )
}

export default PrintObject;