import React, { Component } from "react";
import { Link } from "react-router-dom";
import Box from "../../../components/Box";
import List from "../../../components/List";
import Alert from "../../../components/Alert";
import { PageService } from "../../../services";
import Spinner from "../../../components/Spinner";

class Page extends Component {
    constructor(props) {
        super(props);
        this.services = {
            page: new PageService(this)
        }

        this.state = {
            data: [],
            error: null,
            isLoading: true
        }
    }

    getColummns = () => [
        {
            name: "Name",
            filterKey: "name",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Key",
            filterKey: "uid",
            selector: row => row.uid,
            sortable: true
        },
        {
            name: "Created",
            width: 130,
            title: row => new Date(row.created_at).toString(),
            selector: row => new Date(row.created_at).toDateString(),
            sortable: true
        },
        {
            name: "Updated",
            width: 130,
            title: row => new Date(row.updated_at).toString(),
            selector: row => new Date(row.updated_at).toDateString(),
            sortable: true
        },
        {
            name: "Actions",
            width: 100,
            selector: row => (
                <Link className="btn btn-default btn-xs m-r-5" to={'/ui/pages/update/' + row.id}>
                    Edit
                </Link>
            )
        }
    ];

    componentDidMount() {
        this.services.page.list();
    }

    render() {
        return (
            <Box
                title='Pages'
                allowFullScreen
                buttons={[{
                    value: 'Design Page',
                    type: 'link',
                    class: 'btn btn-sm btn-primary',
                    href: '/ui/pages/design'
                },
                {
                    value: '+ Add',
                    type: 'link',
                    class: 'btn btn-sm btn-success',
                    href: '/ui/pages/add'
                }]}>
                <Spinner isLoading={this.state.isLoading}>
                    {this.state.error
                        ?
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                        : <List columns={this.getColummns()} data={this.state.data} />
                    }
                </Spinner>
            </Box>
        )
    }
}

export default Page;