import React from "react";
import { Link } from "react-router-dom";
import Helper from "../../../utils/Helper";
import Avatar from '../../../assets/img/avatar.png';
import UserContext from "../../../contexts/user-context";

// TODO: Use redux and remove localstorage  
export const toggleView = () => {
    if (localStorage.getItem('sidebar')) {
        localStorage.removeItem('sidebar');
        document.body.classList.remove('sidebar-mini');
    } else {
        localStorage.setItem('sidebar', 'sidebar-mini');
        document.body.classList.add('sidebar-mini');
    }
}

document.body.classList.add(localStorage.getItem('sidebar'));

const Header = () => (
    <UserContext.Consumer>
        {context => (
            <header className="header">
                <div className="page-brand d-md-inline-flex d-none">
                    <Link className="link" to="/dashboard">
                        <span className="brand">Form Builder</span>
                        <span className="brand-mini">FB</span>
                    </Link>
                </div>
                <div className="flexbox flex-1">
                    <ul className="nav navbar-toolbar">
                        <li>
                            <a
                                href="!#"
                                onClick={Helper.handleSideBar}
                                className="nav-link sidebar-toggler js-sidebar-toggler"
                            >
                                <i className="fa fa-align-justify"></i>
                            </a>
                        </li>
                    </ul>
                    <ul className="nav navbar-toolbar">
                        <li className="dropdown dropdown-user">
                            <input id="menu-toggle" type="checkbox" />
                            <label id="menu-label" htmlFor="menu-toggle">
                                <img src={Avatar} alt="Avatar" />
                                <div className="user-information">
                                    <span className="user-name">{Helper.capitalizeFirstLetter(context.user.name)}</span>
                                    <span className="user-role">{Helper.capitalizeFirstLetter(context.user.role)}</span>
                                </div>
                                <span></span>
                                <i className="fa fa-angle-down ms-2 my-auto"></i>
                            </label>
                            <ul className="dropdown-menu dropdown-menu-right">
                                <Link className="dropdown-item" to="/profile">
                                    <i className="fa fa-user"></i> Profile
                                </Link>
                                <Link className="dropdown-item" to="/change-password">
                                    <i className="fa fa-cog"></i> Change Password
                                </Link>
                                <li className="dropdown-divider"></li>
                                <Link className="dropdown-item" to="/logout" onClick={context.logout}>
                                    <i className="fa fa-power-off"></i> Logout
                                </Link>
                            </ul>
                        </li>
                    </ul>
                </div>
            </header>
        )}
    </UserContext.Consumer>
)

export default Header;