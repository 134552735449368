import React from "react";
import RuleList from "./RuleList";
import TextForm from "./TextForm";
import Box from "../../components/Box";
import Tabs from "../../components/Tabs";
import Form from "../../components/Form";
import Alert from "../../components/Alert";
import { RuleService } from "../../services";
import Spinner from "../../components/Spinner";
import { Validator } from "../../utils/Validator";
import { SecuredApi as axios } from "../../services/axios";
import RetailerContext from "../../contexts/retailer-context";

class OnscreenText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rules: [],
            filters: {},
            message: null,
            categoryId: null,
            isLoading: false,
            selectedRule: {},
            variant: "secondary",
            loaderText: "Fetching records"
        };

        this.services = {
            rule: new RuleService(this)
        }
    }

    componentDidMount() {
        // Get all categories and then find out category id of on screen text category
        (async () => {
            try {
                const message = "Unable to fetch onscreen category details. Try reloading the page. If the problem persists then ask your Solvup assistence to get this sorted.";

                const response = await axios.get('/rules/categories');
                const data = await response.data;
                const categories = data.data.Data.items;
                const details = categories.find(item => item.uid === 'scenario');
                this.setState(prev => {
                    return details ? {
                        categoryId: details.id
                    } : {
                        message,
                        variant: "danger"
                    }
                });
            } catch ({ message, statusCode }) {
                this.setState({
                    message,
                    variant: "danger"
                });
            }
        })();
    }

    // Update rule list after adding or updating on screen text
    updateRuleList = rule => {
        const copy = this.state.rules.map(item => item.id === rule.id ? rule : item);
        this.setState({
            rules: copy,
            selectedRule: rule
        });
    }

    filterHandler = (values, setSubmitting) => {
        setSubmitting(false);
        this.setState({
            isLoading: true,
            filters: values
        }, () => {
            values.return_values = "CaseSummaryText,CaseCompletionText";
            this.services.rule.filter(values);
        });
    }

    render() {
        return (
            <>
                <Box title="Filter onscreen texts">
                    <Form
                        preventUnload={false}
                        onSubmit={this.filterHandler}
                        validationSchema={{
                            retailer: Validator.create().required(),
                            "user-type": Validator.create().required()
                        }}
                    >
                        {({ submitting }) => (
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <Form.Group name="retailer" label="Retailer" required>
                                        <Form.Select
                                            id="retailer"
                                            name="retailer"
                                            className="form-control"
                                            disabled={this.state.pageDetails}
                                        >
                                            <RetailerContext.Consumer>
                                                {value => (
                                                    value.map(option => (
                                                        <option key={option.id} value={option.id}>{option.name}</option>
                                                    ))
                                                )}
                                            </RetailerContext.Consumer>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group name="user-type" label="User Type" required>
                                        <Form.Select
                                            id="user-type"
                                            name="user-type"
                                            disabled={this.state.pageDetails}
                                        >
                                            <option value="all">All</option>
                                            <option value="store">Store</option>
                                            <option value="callcentre">Callcentre</option>
                                            <option value="online">Online</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group>
                                        <button
                                            type="submit"
                                            className="btn btn-success me-2"
                                            disabled={submitting || this.state.rules.length || !this.state.categoryId}
                                        >Filter</button>
                                        {
                                            this.state.rules.length > 0 &&
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                title="Double click to cancel"
                                                onDoubleClick={() => {
                                                    this.setState({
                                                        rules: [],
                                                        message: null,
                                                        touched: false,
                                                        selectedRule: {},
                                                        variant: "secondary"
                                                    });
                                                }}
                                            >Cancel</button>
                                        }
                                    </Form.Group>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <Alert variant="secondary">
                                        <strong><u>Steps to follow:</u></strong>
                                        <ol className="mt-2">
                                            <li className='mb-2'>Filter rules by selecting Retailer and user type.</li>
                                            <li className='mb-2'>Click on left hand side rule to open conditions below and Onscreen texts on right side.</li>
                                            <li className='mb-2'>Make changes in Onscreen texts.</li>
                                            <li className='mb-2'>Click save and choose another.</li>
                                            <li className='mb-2'>In case you want to filter rules for another retailer, <strong>Double click</strong> Cancel button.</li>
                                        </ol>
                                    </Alert>
                                </div>
                            </div>
                        )}
                    </Form>
                </Box>

                <Spinner isLoading={this.state.isLoading} placeholder={this.state.loaderText}>
                    {this.state.message &&
                        <Alert variant={this.state.variant}>
                            {this.state.message}
                        </Alert>
                    }

                    {this.state.rules.length > 0 &&
                        <div className="col-12 card flex-row px-0">
                            <div className="col-4">
                                <div className="card-header fs-3 p-2 fw-bold">Rules</div>
                                <div className="card-body p-0">
                                    <RuleList
                                        rules={this.state.rules}
                                        selected={this.state.selectedRule}
                                        onClick={rule => {
                                            if (this.state.selectedRule.id != rule.id) {
                                                this.setState({
                                                    selectedRule: rule
                                                });
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-8 border-start">
                                <div className="card-header p-2 d-flex justify-content-between">
                                    <div className="fs-3 text-truncate fw-bold" title={this.state.selectedRule.name || "Onscreen text"}>
                                        {this.state.selectedRule.name || "Onscreen text"}
                                    </div>
                                    {this.state.selectedRule.id &&
                                        <div>
                                            <a href={"/case-creation/scenarios/update/" + this.state.selectedRule.id + '/' + btoa(this.state.filters.retailer + '-' + this.state.filters['user-type'])} className="btn btn-info btn-sm" target="_blank" title="Click to open in a new tab">
                                                Open scenario
                                                <i className="fa fa-external-link"></i>
                                            </a>
                                        </div>
                                    }
                                </div>
                                <div className="card-body">
                                    {Object.keys(this.state.selectedRule).length &&
                                        <Tabs active="case-summary-text">
                                            <Tabs.Pane id="case-summary-text" title="Case summary text">
                                                <TextForm
                                                    type="CaseSummaryText"
                                                    category_id={this.state.categoryId}
                                                    retailer_id={this.state.filters.retailer} user_type={this.state.filters["user-type"]}
                                                    rule={this.state.selectedRule}
                                                    updateRuleList={this.updateRuleList} />
                                            </Tabs.Pane>
                                            <Tabs.Pane id="case-completion-text" title="Case completion text">
                                                <TextForm
                                                    type="CaseCompletionText"
                                                    category_id={this.state.categoryId}
                                                    retailer_id={this.state.filters.retailer} user_type={this.state.filters["user-type"]}
                                                    rule={this.state.selectedRule}
                                                    updateRuleList={this.updateRuleList} />
                                            </Tabs.Pane>
                                        </Tabs>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </Spinner>
            </>
        )
    }
}

export default OnscreenText;