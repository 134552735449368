import React from "react";
import { Link } from "react-router-dom";
import { SecuredApi as axios } from "./axios";
import Helper from "../utils/Helper";

export default class Rule {
    constructor(component) {
        this.component = component;
    }

    async list(query = '') {
        try {
            query = typeof query === 'object' ? Helper.serializeObject(query) : query;
            const response = await axios.get('/rules?' + query);
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data.items
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    async get(id) {
        try {
            const response = await axios.get('/rules/' + id);
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message,
                pageNotFound: statusCode === 404
            });
        }
    }

    async create(params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.post('/rules/', params);
            const data = await response.data;
            setSubmitting(false);

            this.component.props.history.replace('/rules/update/' + data.data.Data.id);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            } else if (statusCode === 409) {
                this.component.setState({
                    variant: "danger",
                    message: (
                        <>
                            Unable to add. A rule already exists with exact same conditions. &nbsp;
                            <Link to={'/rules/update/' + response.data.data.Errors.id}>
                                Click here &nbsp;
                            </Link>
                            to open that rule.
                        </>
                    )
                });
            }
        }
    }

    async update(id, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.put('/rules/' + id, params);

            // eslint-disable-next-line
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                variant: "success",
                message: "Rule updated successfully"
            });
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            } else if (statusCode === 409) {
                this.component.setState({
                    variant: "danger",
                    message: (
                        <>
                            Unable to add. A rule already exists with exact same conditions. &nbsp;
                            <Link to={'/rules/update/' + response.data.data.Errors.id}>
                                Click here &nbsp;
                            </Link>
                            to open that rule.
                        </>
                    )
                });
            }
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete('/rules/' + id);

            // eslint-disable-next-line
            const data = await response.data;
            this.component.props.history.replace('/rules');
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }

    async createReturnValue(ruleID, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.post('/rules/' + ruleID + '/return-values', params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                error: null,
                action: {
                    value_type: "string",
                    is_sandbox_mode: 0
                },
                timestamp: + new Date(),
                actions: data.data.Data.return_values
            });

            this.component.props.onAdd(params, data.data.Data.return_values);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 409 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    error: response.data.data.Message
                });
            }
        }
    }

    async updateReturnValue(ruleID, returnValueID, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.put('/rules/' + ruleID + '/return-values/' + returnValueID, params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                error: null,
                action: {
                    value_type: "string",
                    is_sandbox_mode: 0
                },
                timestamp: + new Date(),
                actions: data.data.Data.return_values
            });

            this.component.props.onUpdate(params, data.data.Data.return_values);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 409 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    error: response.data.data.Message
                });
            }
        }
    }

    async deleteReturnValue(ruleID, returnValueID) {
        try {
            const response = await axios.delete('/rules/' + ruleID + '/return-values/' + returnValueID);
            const data = await response.data;

            this.component.setState(prev => ({
                timestamp: + new Date(),
                variant: "success",
                message: "Return value deleted successfully",
                actions: data.data.Data.return_values,
                confirmModal: {
                    ...prev.confirmModal,
                    isLoading: false,
                    show: false
                }
            }));

            this.component.props.onDelete(returnValueID, data.data.Data.return_values);
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    variant: "danger",
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }

    async filter(params = {}) {
        try {
            const { ...query } = params;
            const response = await axios.get('/rules?' + Helper.serializeObject(query));

            this.component.setState({
                loaderText: "Loading"
            });

            const data = await response.data;

            let message = null;
            let variant = "success";
            const rules = data.data.Data.items;
            if (rules.length === 0) {
                variant = "danger";
                message = "No rules found";
            }

            this.component.setState({
                rules,
                message,
                variant,
                touched: false,
                isLoading: false,
                selectedRule: rules[0],
                loaderText: "Fetching records"
            }, () => {
                if (this.component.state.highlightedRule) {
                    const element = document.getElementById('rule-' + this.component.state.highlightedRule);
                    if (element) {
                        element.scrollIntoView();
                    }
                }

                this.component.addEventListeners && this.component.addEventListeners();
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                message,
                isLoading: false,
                variant: "danger"
            });
        }
    }

    async priortize(params = {}) {
        try {
            const response = await axios.post('/rules/priortize', params);

            // eslint-disable-next-line
            const data = await response.data;
            this.component.setState(prev => ({
                rules: [],
                variant: "success",
                message: "Order updated successfully",
                confirmModal: {
                    ...prev.confirmModal,
                    isLoading: false,
                    show: false
                }
            }));
        } catch ({ message, statusCode, response }) {
            this.component.setState(prev => ({
                variant: "danger",
                message: response.data.data.Message,
                confirmModal: {
                    ...prev.confirmModal,
                    isLoading: false,
                    show: false
                }
            }));
        }
    }

    async saveOnscreenText(ruleID, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.post('/rules/' + ruleID + '/return-values', params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                variant: "success",
                message: "Saved successfully"
            });

            this.component.updateRule(data.data.Data);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 409 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }

    async updateOnscreenText(ruleID, returnValueID, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.put('/rules/' + ruleID + '/return-values/' + returnValueID, params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                variant: "success",
                message: "Saved successfully"
            });

            this.component.updateRule(data.data.Data);
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 409 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message
                });
            }
        }
    }
}