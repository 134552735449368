import React from "react";

/*
Possible variants: primary, secondary, success, danger, warning, info, light, dark
*/
const Alert = ({ variant = 'primary', border = true, ...props }) => (
    <div className={"alert rounded-2 alert-" + variant + (props.dismiss ? " alert-dismissible" : '') + (border ? " alert-bordered" : '')}>
        {props.children}
        {props.dismiss &&
            <button type="button" className="btn-close btn-transparent" title="Close" onClick={props.dismiss}>
                <i className="fa fa-times"></i>
            </button>
        }
    </div>
)

export default Alert;