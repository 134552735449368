import React, { Component } from "react";
import { Link } from "react-router-dom";
import Box from "../../components/Box";
import List from "../../components/List";
import Form from "../../components/Form";
import { Tags } from "../../components/Tags";
import Spinner from "../../components/Spinner";
import { SecuredApi as axios } from "../../services/axios";
import { retailers } from "../../contexts/retailer-context";
import PrintObject from "../../components/Solvup/PrintObject";
import { RuleService, CategoryService } from "../../services";
import RetailerContext from "../../contexts/retailer-context";

class Rule extends Component {
    constructor(props) {
        super(props);
        this.tagsRef = React.createRef();
        this.filterFormRef = React.createRef();

        this.services = {
            rule: new RuleService(this),
            category: new CategoryService(this)
        }

        this.state = {
            data: [],
            error: null,
            isLoading: true,
            scenario: props.match.path === '/case-creation/scenarios'
        }

        this.retailers = {};
        retailers.forEach(item => {
            this.retailers[item.id] = item.name;
        });

        this.populateFilters();
    }

    componentDidMount() {
        this.services.rule.list(this.state.scenario ? { type: "scenario", ...this.filters } : this.filters);
        axios.get('/rules/categories').then(response => {
            this.setState({
                categories: response.data.data.Data.items
            });
        });

        this.unlistenHistoryChange = this.props.history.listen(location => {
            if (location.pathname === '/rules/') {
                this.setState({
                    error: null,
                    isLoading: true
                });

                this.populateFilters();
                this.filterFormRef.current.setValues(this.filters, true);
                this.services.rule.list(this.filters);
            }
        });
    }

    componentWillUnmount() {
        this.unlistenHistoryChange();
    }

    populateFilters() {
        let data = {};
        const params = new URLSearchParams(window.location.search);
        for (const [key, value] of params.entries()) {
            if (value) {
                data[key] = value;
            }
        }

        if ('tags' in data) {
            this.tagsRef.current = data.tags.split(', ');
        }

        this.filters = data;
    }

    getColumns = () => [
        {
            name: "Name",
            filterKey: "name",
            width: 250,
            sortable: true,
            omit: this.state.scenario,
            selector: row => (
                <Link to={this.state.scenario ? '/case-creation/scenarios/update/' + row.id : '/rules/update/' + row.id}>
                    {row.name}
                </Link>
            )
        },
        {
            name: "Tags",
            selector: row => {
                if (row.tags && typeof row.tags === 'string') {
                    const variants = ['info', 'danger', 'warning', 'success', 'primary'];
                    const tagsArray = row.tags.split(',');
                    return tagsArray.map((tag, index) => {
                        tag = tag.trim();
                        const variant = variants[Math.floor(Math.random() * variants.length)];

                        return tag ? (
                            <span key={index} className={"badge badge-pill m-r-5 m-b-5 bg-" + variant}> {tag} </span>
                        ) : null;
                    });
                }
            }
        },
        {
            name: "Return values",
            width: 150,
            omit: this.state.scenario,
            selector: row => row.return_values_count || 0
        },
        {
            name: "Sandbox",
            width: 100,
            omit: this.state.scenario,
            selector: row => <i className={`fa fa-${row.is_sandbox_mode ? 'check-circle text-success' : 'times-circle text-danger'}`}></i>
        },
        {
            name: "Created",
            width: 130,
            title: row => new Date(row.created_at).toString(),
            selector: row => new Date(row.created_at).toDateString(),
            sortable: true
        },
        {
            name: "Updated",
            width: 130,
            title: row => new Date(row.updated_at).toString(),
            selector: row => new Date(row.updated_at).toDateString(),
            sortable: true
        },
        {
            name: "Actions",
            width: 160,
            selector: row => (
                <>
                    {this.state.scenario === true &&
                        row.retailer_info.map((item, index) => (
                            <Link key={index} className="btn btn-success btn-xs m-r-5 m-b-5" to={'/case-creation/scenarios/update/' + row.id + '/' + btoa(item.retailer_id + '-' + item.user_type)}>
                                {this.retailers[item.retailer_id] + ' ' + item.user_type}
                            </Link>
                        ))
                    }

                    {this.state.scenario === false &&
                        <>
                            <Link className="btn btn-default btn-xs m-r-5" to={this.state.scenario ? '/case-creation/scenarios/update/' + row.id : '/rules/update/' + row.id}>
                                Edit
                            </Link>
                            <button className="btn btn-default btn-xs m-r-5" onClick={e => {
                                e.target.closest('td').querySelector('.conditions').classList.toggle('d-none')
                            }}>
                                View conditions
                            </button>
                            <div className="conditions mt-2 d-none">
                                <PrintObject data={row.conditions} disabled />
                            </div>
                        </>
                    }
                </>
            )
        }
    ];

    filter = (values, setSubmitting) => {
        setSubmitting(false);
        if (this.tagsRef.current) {
            values.tags = (this.tagsRef.current.join(', '));
        }

        const currentQuery = new URLSearchParams(window.location.search).toString();
        const newQuery = new URLSearchParams(values).toString();
        if (currentQuery !== newQuery) {
            this.setState({
                isLoading: true
            });

            this.props.history.push({
                search: newQuery
            });
        }
    }

    render() {
        return (
            <>
                <Box title="Advanced search" collapse={!this.state.scenario} collapsed variant='grey'>
                    <Form
                        id="rulesFilterForm"
                        preventUnload={false}
                        onSubmit={this.filter}
                        ref={this.filterFormRef}
                        initialValues={this.filters}>
                        <div className="row">
                            {this.state.scenario === false &&
                                <div className="col-md-4 col-xs-12">
                                    <Form.Group name="category" label="Category">
                                        <Form.Select id="category" name="category">
                                            {this.state.categories && this.state.categories.map(category => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            }
                            <div className="col-md-4 col-xs-12">
                                <Form.Group name="retailer" label="Retailer">
                                    <Form.Select id="retailer" name="retailer">
                                        <RetailerContext.Consumer>
                                            {value => (
                                                value.map(option => (
                                                    <option key={option.id} value={option.id}>{option.name}</option>
                                                ))
                                            )}
                                        </RetailerContext.Consumer>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <Form.Group name="user-type" label="User Type">
                                    <Form.Select id="user-type" name="user-type">
                                        <option value="all">All</option>
                                        <option value="store">Store</option>
                                        <option value="callcentre">Callcentre</option>
                                        <option value="online">Online</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <Form.Group name="tags" label="Tags">
                                    <Tags show={false} selected={this.filters.tags} onChange={tags => {
                                        this.tagsRef.current = tags;
                                    }} />
                                </Form.Group>
                            </div>

                            {this.state.scenario === false &&
                                <>
                                    <div className="col-md-4 col-xs-12">
                                        <Form.Group name="search" label="Search">
                                            <Form.Input type="text" name="search" id="search" placeholder="Enter search term" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <Form.Group name="is_sandbox_mode" label="Sandbox Mode">
                                            <Form.Radio name="is_sandbox_mode" values={[
                                                {
                                                    "label": "No",
                                                    "value": 0
                                                },
                                                {
                                                    "label": "Yes",
                                                    "value": 1
                                                },
                                                {
                                                    "label": "Both",
                                                    "value": ""
                                                }
                                            ]} />
                                        </Form.Group>
                                    </div>
                                </>
                            }

                            <div className="clearfix"></div>
                            <div className="col-xs-12">
                                <button type="submit" className="btn btn-primary m-r-5">Filter</button>
                            </div>
                        </div>
                    </Form>
                </Box>
                <Box
                    allowFullScreen
                    title={this.state.scenario ? 'Scenarios' : 'Rules'}
                    buttons={{
                        value: '+ Add',
                        type: 'link',
                        class: 'btn btn-sm btn-success',
                        href: this.state.scenario ? '/case-creation/scenarios/add' : '/rules/add'
                    }}>
                    <Spinner isLoading={this.state.isLoading}>
                        {this.state.error
                            ? <div className="alert alert-danger alert-bordered">
                                {this.state.error}
                            </div>
                            : <List columns={this.getColumns()} data={this.state.data} />
                        }
                    </Spinner>
                </Box>
            </>
        );
    }
}

export default Rule;