import React from "react";
import Alert from "../Alert";
import constants from "./contants.json";

const MagicConstants = () => (
    <>
        <Alert variant="secondary">
            <i className="fa fa-info-circle"></i>
            <strong> Note:</strong> Below constants will be available automatically on Store front in Process API response and user can not update these from return values.
        </Alert>
        <table className="table table-striped">
            <tbody>
                <tr className="info">
                    <th>#</th>
                    <th>Name</th>
                    <th>Description</th>
                </tr>

                {constants.map((item, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <th>{item.name}</th>
                        <td>{item.description}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </>
)

export default MagicConstants;