import React, { Component } from "react";
import Alert from "../../../../components/Alert";
import Box from "../../../../components/Box";
import Confirm from "../../../../components/Confirm";
import NotFound from "../../../../components/404";
import Spinner from "../../../../components/Spinner";
import Tabs from "../../../../components/Tabs";
import BasicDetails from "./Basic";
import Overrides from "./Override";
import AddOverride from './Override/Add';
import { ComponentService } from "../../../../services";
import Changelog from "../../../../components/Changelog";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import MagicConstants from "../../../../components/MagicConstants";
import { SolvupThemeProvider } from "@solvupdev/caseflow-generic-components";

class Add extends Component {
    constructor(props) {
        super(props);
        this.tabsRef = React.createRef();
        this.listRef = React.createRef();
        this.addOverrideRef = React.createRef();

        this.services = {
            component: new ComponentService(this)
        }

        this.state = {
            id: this.props.match.params.id,
            isLoading: this.props.match.params.id !== undefined,
            error: null,
            message: null,
            data: {
                conditions: {},
                type: 'inline',
                show_fieldset: true,
                show_close_icon: true
            },
            preventUnload: true,
            confirmModal: {
                show: false,
                message: "Are you sure want to delete component ?",
                onConfirm: () => this.deleteComponent(),
                onCancel: () => this.closeConfirmModal()
            }
        }
    }

    componentDidMount() {
        if (this.state.id !== undefined) {
            this.services.component.get(this.state.id);
        }
    }

    closeConfirmModal = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: false
            }
        }));
    }

    // Click handler of delete button
    deleteHandler = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: true
            }
        }));
    }

    // Delete component finally
    deleteComponent = () => {
        this.setState(prev => ({
            preventUnload: false,
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            this.services.component.delete(this.state.id);
        });
    }

    editHandler = data => {
        this.tabsRef.current.setActive('addOverride');

        // Update override only if another override is selected for update  
        if (this.addOverrideRef.current.state.override.id !== data.id) {
            this.addOverrideRef.current.setOverrideForUpdate({ ...data });
        }
    }

    render() {
        const Design = React.lazy(() => import('../../Design'));

        if (this.state.pageNotFound) {
            return <NotFound />
        }

        if (this.state.error) {
            return (
                <Alert variant="danger">
                    {this.state.error}
                </Alert>
            )
        }

        return (
            <Spinner isLoading={this.state.isLoading}>
                <Box
                    allowFullScreen
                    title={this.state.id !== undefined ? "Update Component" : "Add Component"} buttons={[{
                        type: "button",
                        value: "Delete",
                        class: "btn btn-sm btn-danger",
                        show: this.state.id !== undefined && this.state.isLoading === false,
                        clicked: this.deleteHandler
                    }, {
                        type: "link",
                        href: "/ui/components/add",
                        value: "Add new",
                        class: "btn btn-sm btn-info",
                        show: this.state.id !== undefined
                    }]}>
                    {this.state.message &&
                        <Alert variant="danger" dismiss={() => {
                            this.setState({
                                message: null
                            });
                        }}>
                            {this.state.message}
                        </Alert>
                    }

                    <Tabs active="basicDetails" ref={this.tabsRef}>
                        <Tabs.Pane id="basicDetails" title="Basic Details">
                            <BasicDetails
                                id={this.state.id}
                                data={this.state.data}
                                preventUnload={this.state.preventUnload} />
                        </Tabs.Pane>
                        <Tabs.Pane id="design" title="Design" disabled={!this.state.id}>
                            <ErrorBoundary>
                                {
                                    (this.state.data && typeof this.state.data.id !== "undefined") &&
                                    <React.Suspense fallback={<Spinner isLoading={true}></Spinner>}>
                                        <SolvupThemeProvider>
                                            <Design data={this.state.data} isDesignType="component" />
                                        </SolvupThemeProvider>
                                    </React.Suspense>
                                }
                            </ErrorBoundary>
                        </Tabs.Pane>
                        <Tabs.Pane id="overrides" title="Overrides" disabled={!this.state.id}>
                            {this.state.data &&
                                <Overrides
                                    ref={this.listRef}
                                    componentId={this.state.id}
                                    data={this.state.data.overrides || []}
                                    editHandler={this.editHandler} />
                            }
                        </Tabs.Pane>
                        <Tabs.Pane id="addOverride" title="Add override" disabled={!this.state.id}>
                            {this.state.id &&
                                <AddOverride
                                    ref={this.addOverrideRef}
                                    componentId={this.state.id}
                                    parentComponents={this.state.data.parent_components}
                                    onAdd={(action, overrides) => {
                                        this.listRef.current.updateOverrides(overrides, 'Override added successfully');
                                        this.tabsRef.current.setActive('overrides');
                                    }}
                                    onUpdate={(action, overrides) => {
                                        this.listRef.current.updateOverrides(overrides, 'Override updated successfully');
                                        this.tabsRef.current.setActive('overrides');
                                    }} />
                            }
                        </Tabs.Pane>

                        <Tabs.Pane id="magicConstants" title="Magic constants" disabled={!this.state.id}>
                            <MagicConstants />
                        </Tabs.Pane>

                        {this.state.id &&
                            <Tabs.Pane id="changelog" title="Changelog">
                                <Changelog />
                            </Tabs.Pane>
                        }
                    </Tabs>

                    {(this.state.id && this.state.confirmModal.show) &&
                        <Confirm {...this.state.confirmModal} />
                    }
                </Box>
            </Spinner >
        )
    }
}

export default Add;