import React, { Component } from "react";
import Box from "../../components/Box";
import Form from "../../components/Form";
import List from "../../components/List";
import Alert from "../../components/Alert";
import NotFound from "../../components/404";
import Confirm from "../../components/Confirm";
import Spinner from "../../components/Spinner";
import { Validator } from "../../utils/Validator";
import PreventUnload from "../../components/PreventUnload";
import { SecuredApi as axios } from "../../services/axios";
import { RuleService, CategoryService } from "../../services";
import RetailerContext from "../../contexts/retailer-context";
import PrintObject from "../../components/Solvup/PrintObject";

const returnLemonFormatRule = rule => {
    let obj = {
        conditions: Array.isArray(rule.conditions) ? {} : rule.conditions,
        return_value: {},
        return_value_function: {}
    };

    rule.return_values.forEach(item => {
        if (item.value_type == 'api') {
            obj.return_value_function[item.key_column] = "func__apiCall(" + item.value_column + ")";
        } else if (item.value_type == 'function') {
            obj.return_value_function[item.key_column] = renderValue(item);
        } else {
            obj.return_value[item.key_column] = renderValue(item);
        }
    });

    if (Object.keys(obj.return_value_function).length === 0) {
        delete obj.return_value_function;
    }

    return obj;
}

const renderValue = item => {
    switch (item.value_type.toLowerCase()) {
        case 'twig':
        case 'html':
        case 'number':
        case 'string':
        case 'boolean':
            return item.value_column;
        case 'business_term':
            return "{{ " + item.value_column + " }}";
        case 'object':
            let data = {};
            if (Array.isArray(item.value_column) && item.value_column.length) {
                item.value_column.forEach(obj => {
                    const type = obj.value_type;
                    if (type === 'number') {
                        data[obj.key] = Number(obj.value);
                    } else if (type === 'boolean') {
                        data[obj.key] = obj.value === 'true';
                    } else if (type === 'business_term') {
                        data[obj.key] = "{{ " + obj.value + " }}";
                    } else {
                        data[obj.key] = obj.value;
                    }
                });
            }
            return data;
        case 'array':
            let array = [];
            if (Array.isArray(item.value_column) && item.value_column.length) {
                item.value_column.forEach(obj => {
                    const type = obj.value_type;
                    if (type === 'number') {
                        array.push(Number(obj.value));
                    } else if (type === 'boolean') {
                        array.push(obj.value === 'true');
                    } else {
                        array.push(obj.value);
                    }
                });
            }
            return array;
        case 'function':
            let params = [];
            let val = "func__" + item.value_column + "(";
            if ('func_args' in item) {
                item.func_args.forEach(subitem => {
                    let value = subitem.value;
                    if (subitem.value_type === 'string') {
                        value = "'" + value + "'";
                    } else if (subitem.value_type.toLowerCase === 'null') {
                        value = null;
                    }

                    params.push(value);
                });
            }

            return val + params.join(', ') + ')';
    }
}

class PriortizeRules extends Component {
    constructor(props) {
        super(props);
        this.services = {
            rules: new RuleService(this),
            categories: new CategoryService(this)
        }

        this.sortedRules = [];

        this.state = {
            rules: [],
            filters: {},
            categories: [],
            touched: false,
            collapsed: true,
            isLoading: false,
            pageNotFound: false,
            variant: "secondary",
            timestamp: + new Date(),
            secret: this.props.match.params.secret,
            message: 'You need to filter rules first',
            highlightedRule: this.props.location.hash.replace('#', ''),
            confirmModal: {
                show: false,
                title: "Confirm",
                message: "Are you sure want to save the order ?",
                onConfirm: () => this.saveOrder(),
                onCancel: () => {
                    this.setState(prev => ({
                        confirmModal: {
                            ...prev.confirmModal,
                            show: false
                        }
                    }));
                }
            }
        }
    }

    getColummns = () => [
        {
            name: "Rule",
            filterKey: "name",
            selector: row => {
                if (row.type == 'scenario' && false) {
                    const variants = ['info', 'danger', 'warning', 'success', 'primary'];
                    const tagsArray = row.tags.split(',');
                    return tagsArray.map((tag, index) => {
                        tag = tag.trim();
                        const variant = variants[Math.floor(Math.random() * variants.length)];

                        return tag ? (
                            <span key={index} className={"badge badge-pill m-r-5 m-b-5 bg-" + variant}> {tag} </span>
                        ) : null;
                    });
                } else {
                    return (
                        <a target="_blank" rel="noopener noreferrer" href={row.type === 'scenario' ? '/case-creation/scenarios/update/' + row.id + '/' + this.state.retailerHash : "/rules/update/" + row.id}>
                            {row.name}
                        </a>
                    )
                }
            }
        }
    ];

    async componentDidMount() {
        try {
            const response = await axios.get('/rules/categories');
            const data = await response.data;

            const categories = data.data.Data.items.sort((a, b) => a.sort_order - b.sort_order);

            if (this.state.secret) {
                const [retailerId, userType, category] = atob(this.state.secret).split('-');
                const scenarioCategory = categories.find(item => item.uid === category);
                if (scenarioCategory) {
                    const values = {
                        retailer: retailerId,
                        'user-type': userType,
                        category: scenarioCategory.id
                    };

                    this.setState({
                        categories,
                        initialValues: values,
                        timestamp: + new Date(),
                        scenarioCategoryId: scenarioCategory.id
                    }, () => {
                        this.filter(values);
                    });
                }
            } else {
                this.setState({
                    categories
                });
            }
        } catch ({ message, statusCode }) {
            this.setState({
                message,
                variant: "danger"
            });
        }
    }

    filter = values => {
        this.setState({
            isLoading: true,
            filters: values,
            retailerHash: btoa(values.retailer + '-' + values['user-type'])
        }, () => {
            values.return_values = 'all';
            values.type = this.state.scenarioCategoryId === values.category ? 'scenario' : 'rule';
            this.services.rules.filter(values);
        });
    }

    saveOrder = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            this.services.rules.priortize({
                ...this.state.filters,
                data: this.sortedRules
            });
        });
    }

    render() {
        if (this.state.pageNotFound) {
            return <NotFound />
        }

        return (
            <Box
                title="Priortize rules"
                allowFullScreen={this.state.isLoading === false && this.state.rules.length > 0}
                buttons={[
                    {
                        type: "button",
                        value: "Save",
                        class: "btn btn-sm btn-success",
                        clicked: () => {
                            this.setState(prev => ({
                                confirmModal: {
                                    ...prev.confirmModal,
                                    show: true
                                }
                            }));
                        },
                        show: this.state.isLoading === false && this.state.rules.length > 0
                    },
                    {
                        type: "button",
                        value: this.state.collapsed ? "Expand all" : "Collapse all",
                        class: "btn btn-sm btn-primary",
                        clicked: () => {
                            this.setState(prev => ({
                                collapsed: !prev.collapsed
                            }));
                        },
                        show: this.state.isLoading === false && this.state.rules.length > 0
                    }]}>
                <div className="row">
                    <div className="col-xs-12">
                        <Form
                            preventUnload={false}
                            key={this.state.timestamp}
                            onSubmit={(values, setSubmitting) => {
                                setSubmitting(false);
                                this.filter(values);
                            }}
                            initialValues={this.state.initialValues}
                            validationSchema={{
                                retailer: Validator.create().required(),
                                "user-type": Validator.create().required(),
                                category: Validator.create().required()
                            }}>
                            {({ submitting }) => (
                                <>
                                    <div className="row">
                                        <div className="col-md-3 col-xs-12">
                                            <Form.Group name="retailer" label="Retailer" required>
                                                <Form.Select id="retailer" name="retailer" disabled={submitting || this.state.rules.length} valueType="number">
                                                    <RetailerContext.Consumer>
                                                        {value => (
                                                            value.map(option => (
                                                                <option key={option.id} value={option.id}>{option.name}</option>
                                                            ))
                                                        )}
                                                    </RetailerContext.Consumer>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3 col-xs-12">
                                            <Form.Group name="user-type" label="User Type" required>
                                                <Form.Select id="user-type" name="user-type" disabled={submitting || this.state.rules.length}>
                                                    <option value="all">All</option>
                                                    <option value="store">Store</option>
                                                    <option value="callcentre">Callcentre</option>
                                                    <option value="online">Online</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3 col-xs-12">
                                            <Form.Group name="category" label="Category" required>
                                                <Form.Select id="category" name="category" disabled={submitting || this.state.rules.length}>
                                                    {this.state.categories.map(category => {
                                                        return (
                                                            <option key={category.id} value={category.id}>{category.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2 col-xs-12">
                                            <label htmlFor="testing" className="col-form-label">&nbsp;</label>
                                            <div className="d-grid">
                                                <button type="submit" className="btn btn-success" disabled={submitting || this.state.rules.length}>Filter</button>
                                            </div>
                                        </div>
                                        {this.state.rules.length > 0 &&
                                            <div className="col-md-1 col-xs-12">
                                                <label htmlFor="testing" className="col-form-label">&nbsp;</label>
                                                <div className="d-grid">
                                                    <button type="button" className="btn btn-danger" title="Double click to cancel" onDoubleClick={() => {
                                                        this.setState({
                                                            rules: [],
                                                            touched: false,
                                                            variant: "secondary",
                                                            message: 'You need to filter rules first'
                                                        });
                                                    }}>Cancel</button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                    <div className="col-xs-12 mt-3">
                        {(this.state.message && !this.state.isLoading) &&
                            <Alert variant={this.state.variant}>
                                {this.state.message}
                            </Alert>
                        }

                        <Spinner isLoading={this.state.isLoading}>
                            <PreventUnload when={this.state.touched}>
                                <>
                                    {this.state.rules.length > 0 &&
                                        <List
                                            draggable
                                            expandableRows
                                            pagination={false}
                                            data={this.state.rules}
                                            columns={this.getColummns()}
                                            expandableRowsExpanded={this.state.collapsed}
                                            onSort={rules => {
                                                this.sortedRules = rules.map(rule => rule.id);
                                            }}
                                            expandableRowsComponent={row => (
                                                <PrintObject data={returnLemonFormatRule(row)} disabled />
                                            )}
                                        />
                                    }

                                    {this.state.confirmModal.show &&
                                        <Confirm {...this.state.confirmModal} />
                                    }
                                </>
                            </PreventUnload>
                        </Spinner>
                    </div>
                </div>
            </Box>
        )
    }
}

export default PriortizeRules;