import React from "react";
import { FormContext } from ".";

export const Select = ({ name, value = '', valueType = 'string', placeholder = true, className = 'form-control', onChange = () => { }, children, ...props }) => (
    <FormContext.Consumer>
        {context => {
            value = name in context.values ? context.values[name] : value;
            const changeHandler = e => {
                context.updateValue(e.target.name, e.target.value, valueType);
                onChange(e);
            }

            return (
                <select name={name} className={className} onChange={changeHandler} value={value} data-value-type={valueType} {...props}>
                    {placeholder && <option value="">{placeholder === true ? '---Select---' : placeholder}</option>}
                    {children}
                </select>
            )
        }}
    </FormContext.Consumer>
)