import React from 'react';
import Helper from '../../../utils/Helper';
import Alert from '../../../components/Alert';
import ErrorBoundary from '../../../components/ErrorBoundary';
import {
    Tabs,
    Table,
    Button,
    Calendar,
    Checkbox,
    Dropdown,
    TextArea,
    Paragraph,
    InputField,
    Navigation,
    OrderedList,
    Autocomplete,
    IntervalTimer,
    BarcodeScanner,
    MultiFileUpload,
    RadioButtonGroup,
    DigitalSignature,
    GoogleAutoComplete,
    SingleSelectionList,
    MultiSelectMultiTypeList,
    MultiCheckboxListWithParagraph
} from '@solvupdev/caseflow-generic-components';

const Element = ({ id, name, type, isWhat, ...props }) => {
    let renderFormElement;
    const { ...newProps } = props;

    // Remove unwanted keys from props and pass to Generic components as it is wherever possible
    ['uid', 'visible', 'conditions', 'conditions_config', 'conditions_details', 'mandatory', 'description', 'validations', 'created_at', 'updated_at'].forEach(key => delete newProps[key]);

    switch (type) {
        case "text":
        case "email":
        case "number":
            renderFormElement = <InputField {...newProps}>{newProps.label}</InputField>;
            break;
        case "password":
            renderFormElement = <InputField {...newProps}>{newProps.label}</InputField>;
            break;
        case "hidden":
            renderFormElement = (
                <InputField readOnly {...newProps} type="text">
                    <span className='text-danger'>
                        [Hidden Input]
                    </span>
                    &nbsp; {name}
                </InputField>
            )
            break;
        case "select":
            renderFormElement = (
                <Dropdown options={newProps.values} {...newProps} className="mw-100">
                    {newProps.label}
                </Dropdown>
            )
            break;
        case "list":
            renderFormElement = <OrderedList className="ms-4" values={newProps.values} />;
            break;
        case "button":
            renderFormElement = (
                <Button {...newProps} className="w-100 mx-0 float-none">
                    {Helper.capitalizeFirstLetter(newProps.label.replaceAll("_", " "))}
                </Button>
            )
            break;
        case "tab":
            renderFormElement = <Tabs tabs={newProps.values} />;
            break;
        case "radio":
            renderFormElement = <RadioButtonGroup {...newProps} />;
            break;
        case "checkbox":
            renderFormElement = <Checkbox {...newProps}>{newProps.label}</Checkbox>;
            break;
        case "navigation":
            renderFormElement = <Navigation steps={newProps.values} />;
            break;
        case "textarea":
            renderFormElement = <TextArea {...newProps}>{newProps.label}</TextArea>
            break;
        case "paragraph":
            renderFormElement = <Paragraph content={newProps.content} />;
            break;
        case "date-picker":
            renderFormElement = <Calendar {...newProps} />;
            break;
        case "file-upload":
            renderFormElement = <MultiFileUpload label={newProps.label} {...newProps.fileUploadConfig} />;
            break;
        case "interval-timer":
            renderFormElement = <IntervalTimer {...newProps} />
            break;
        case "barcode-scanner":
            renderFormElement = <BarcodeScanner {...newProps} />;
            break;
        case "google-address-auto-complete":
            renderFormElement = (
                <GoogleAutoComplete
                    {...newProps}
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY} />
            )
            break;
        case "digital-signature":
            renderFormElement = <DigitalSignature {...newProps} />;
            break;
        case "table":
            renderFormElement = <Table {...newProps} />;
            break;
        case "autocomplete":
            renderFormElement = <Autocomplete {...newProps} />;
            break;
        case "single-selection-list":
            renderFormElement = <SingleSelectionList {...newProps} />;
            break;
        case "multi-checkbox-list":
            renderFormElement = <MultiCheckboxListWithParagraph {...newProps} />;
            break;
        case "multi-select-multi-type-list":
            renderFormElement = <MultiSelectMultiTypeList {...newProps} />;
            break;
        default:
            renderFormElement = <p>Name {name} Type: {type} is missing.</p>;
            break;
    }

    const error = (
        <Alert variant='danger'>
            Unable to load {isWhat} named <strong>'{name}'</strong> and of <strong>'{type}'</strong> type

            {(type === 'select' || type === 'list' || type === 'multi-select-multi-type-list') &&
                <>
                    <br /> <br />
                    <b>Note:</b> Loading dynamic data is not supported yet. Will be added in future. You can still add this {isWhat} to design and will be perfectly rendered on Store front.

                    <u><br /> <br />View with dummy data:<br /> <br /></u>

                    {type === 'select' &&
                        <Dropdown
                            className="mw-100"
                            value=""
                            options={[
                                {
                                    label: "-- Please Select --",
                                    value: ""
                                },
                                {
                                    label: "Dummy value 1",
                                    value: "test 1"
                                },
                                {
                                    label: "Dummy value 2",
                                    value: "test 2"
                                }
                            ]}>
                            {newProps.label}
                        </Dropdown>
                    }

                    {type === 'list' &&
                        <OrderedList
                            className="ms-4"
                            values={[
                                "Dummy value 1",
                                "Dummy value 2",
                                "Dummy value 3"
                            ]} />
                    }

                    {type === 'multi-select-multi-type-list' &&
                        <MultiSelectMultiTypeList
                            values={[
                                {
                                    "is_mandatory": 0,
                                    "name": "ts_description_0",
                                    "type": "paragraph",
                                    "content": "This is a dummy constant data."
                                },
                                {
                                    "is_mandatory": 0,
                                    "label": "http:\\/\\/h10032.www1.hp.com\\/ctg\\/Manual\\/c03009437.pdf",
                                    "name": "ts_links_0",
                                    "type": "checkbox",
                                    "checkbox_collection": true,
                                    "keep_in_array": true,
                                    "array_name": "ts_links_collection",
                                    "set_value": "http:\\/\\/h10032.www1.hp.com\\/ctg\\/Manual\\/c03009437.pdf"
                                }
                            ]} />
                    }
                </>
            }
        </Alert >
    )

    return (
        <ErrorBoundary error={error}>
            {renderFormElement}
        </ErrorBoundary>
    )
}

export default Element;