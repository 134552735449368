import React, { useEffect, useState } from "react";
import "./styles.css";
import CopyToClipboard from "../CopyToClipboard";

const Timeline = ({ data = [], className = '', collapsible = true, ...props }) => {
    const [collapsed, updateCollapsed] = useState(props.collapsed);

    useEffect(() => {
        if (collapsible) {
            updateCollapsed(props.collapsed);
        }
    }, [props.collapsed]);

    return (
        <div className={"container-fluid " + className}>
            <ul className="timeline">
                {data.map((item, index) => (
                    <li key={index} className="timeline-item">
                        <span className="timeline-icon">
                            <i className="fas fa-rocket text-primary fa-sm fa-fw"></i>
                        </span>
                        <h5 className="timeline-title" onClick={e => {
                            if (collapsible) {
                                const elem = e.target.closest('.timeline-title');
                                elem.classList.toggle('open');
                                elem.nextSibling.classList.toggle('d-none');
                            }
                        }}>
                            {item.title}

                            {item.copy &&
                                <span className="copy-span pull-right">
                                    <CopyToClipboard text={item.copy.text} title={item.copy.title} />
                                </span>
                            }
                        </h5>
                        <div className={"timeline-content " + (collapsible && collapsed ? 'd-none' : '')}>
                            {item.content}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Timeline;