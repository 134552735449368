import React, { useState, useEffect } from "react";
import { FormContext } from ".";

export const LocalTextarea = ({ value = '', onChange = () => { }, rows = 5, className = 'form-control', ...props }) => {
    const [localValue, changeValue] = useState(value);

    useEffect(() => {
        changeValue(value);
    }, [value]);

    return <textarea value={localValue} rows={rows} className={className} onChange={e => changeValue(e.target.value)} onBlur={onChange} {...props} />
}

export const Textarea = ({ name, value = '', rows = 5, className = 'form-control', ...props }) => (
    <FormContext.Consumer>
        {context => {
            value = name in context.values ? context.values[name] : value;
            const changeHandler = e => {
                const newValue = e.target.value.trim();
                if (newValue !== value) {
                    context.updateValue(e.target.name, newValue);
                }
            }

            return (
                <textarea name={name} value={value} rows={rows} className={className} onChange={changeHandler} {...props} />
            )
        }}
    </FormContext.Consumer>
)