import React, { Component } from "react";
import { withRouter } from "react-router";
import Form from "../../../../components/Form";
import Alert from "../../../../components/Alert";
import { Validator } from "../../../../utils/Validator";
import { ComponentService } from "../../../../services";
import ContentEditable from "../../../../components/ContentEditable";
import conditionSchema from "../../../../config/schema/condition.json";

class BasicDetails extends Component {
    constructor(props) {
        super(props);
        this.services = {
            component: new ComponentService(this)
        }

        this.conditionsRef = null;

        this.state = {
            message: null,
            variant: "danger"
        }
    }

    submitHandler = (values, setSubmitting, setErrors) => {
        if (this.props.id) {
            this.services.component.update(this.props.id, values, setSubmitting, setErrors);
        } else {
            this.services.component.create(values, setSubmitting, setErrors);
        }
    }

    render() {
        return (
            <Form
                initialValues={{
                    uid: this.props.data.uid,
                    name: this.props.data.name,
                    type: this.props.data.type,
                    title: this.props.data.title,
                    conditions: this.props.data.conditions,
                    description: this.props.data.description,
                    show_fieldset: this.props.data.show_fieldset,
                    show_close_icon: this.props.data.show_close_icon
                }}
                onSubmit={this.submitHandler}
                preventUnload={this.props.preventUnload}
                validationSchema={{
                    name: Validator.create().required(),
                    type: Validator.create().required(),
                    show_fieldset: Validator.create().requiredIf('title', 'inline'),
                    show_close_icon: Validator.create().requiredIf('title', 'popup'),
                    conditions: Validator.create().required().types(['object'])
                }}>
                {({ values }) => (
                    <>
                        {!this.props.id &&
                            <Alert variant="secondary">
                                <strong>Note:</strong> You need to add basic details first. After that, you will be able to design component as well.
                            </Alert>
                        }

                        {this.state.message &&
                            <Alert variant={this.state.variant} dismiss={() => {
                                this.setState({
                                    message: null
                                });
                            }}>
                                {this.state.message}
                            </Alert>
                        }

                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <Form.Group name="name" label="Name">
                                    <Form.Input type="text" placeholder="Enter name" id="name" name="name" />
                                </Form.Group>

                                {this.props.id &&
                                    <Form.Group name="uid" label="Unique ID">
                                        <Form.Input type="text" name="uid" disabled />
                                    </Form.Group>
                                }

                                <Form.Group name="title" label="Title">
                                    <Form.Input type="text" placeholder="Title will be displayed on front end UI" id="title" name="title" />
                                </Form.Group>

                                <Form.Group name="type" label="Type" hint={
                                    <>
                                        <strong className="text-danger">Note:</strong>
                                        Type can't be updated once saved
                                    </>
                                }>
                                    <Form.Radio name="type" values={[
                                        {
                                            "label": "Inline",
                                            "value": "inline"
                                        },
                                        {
                                            "label": "Popup",
                                            "value": "popup"
                                        }
                                    ]} disabled={this.props.id} />
                                </Form.Group>

                                {values.type == 'inline' &&
                                    <Form.Group name="show_fieldset" label="Show Border">
                                        <Form.Checkbox value={true} name="show_fieldset" label="Show Border" uncheckedValue={false} valueType="boolean" />
                                    </Form.Group>
                                }

                                {values.type == 'popup' &&
                                    <Form.Group name="show_close_icon" label="Show close icon">
                                        <Form.Checkbox value={true} name="show_close_icon" label="Show icon" uncheckedValue={false} valueType="boolean" />
                                    </Form.Group>
                                }

                                <Form.Group name="description" label="Description">
                                    <Form.Textarea type="textarea" name="description" placeholder="Describe here..." />
                                </Form.Group>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <Form.Group name="conditions" label="Conditions" required hint={
                                    <>
                                        <strong className="text-danger">Note: </strong>
                                        To show component always, just leave conditions as empty object {"{}"} and in case you never want to show component then &nbsp;
                                        <span className="text-primary cursor-pointer"
                                            onClick={() => {
                                                const editor = this.conditionsRef.jsonEditor;
                                                editor.update({
                                                    "AND": [
                                                        "1 == 0"
                                                    ]
                                                });

                                                this.conditionsRef.focus();
                                            }}>
                                            Click here
                                        </span>
                                    </>
                                }>
                                    <Form.JSONEditor
                                        onLoad={editor => {
                                            this.conditionsRef = editor;
                                        }}
                                        schema={conditionSchema}
                                        htmlElementProps={{
                                            name: "conditions"
                                        }} />
                                </Form.Group>
                                <Form.Group name="js_conditions" label="Javascript Format Conditions" hint={
                                    <>
                                        <strong className="text-danger">Note: </strong>
                                        You can't edit this section because Conditions added in above section will be changed to Javascript format and pre populated here after you click save.
                                    </>
                                }>
                                    <ContentEditable disabled id="js_conditions">
                                        <pre className="white-space-normal">
                                            {this.props.data.visible ? JSON.stringify(this.props.data.visible.js, null, 2) : 'No conditions added'}
                                        </pre>
                                    </ContentEditable>
                                </Form.Group>
                            </div>
                            <div className="col-xs-12">
                                <Form.Submit />
                            </div>
                        </div>
                    </>
                )}
            </Form >
        )
    }
}

export default withRouter(BasicDetails);