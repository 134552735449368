import React from "react";
import { FormContext } from ".";
import Editor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-gherkin";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

export const AceEditor = props => {
    let innerRef = React.useRef(null);

    /*
    For full list of ace editor options, check below link:
    https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
    */

    let {
        name,
        value = '',
        mode = "gherkin",
        minLines = 10,
        maxLines = 100,
        fontSize = 15,
        readOnly = false,
        useWorker = false,
        wrapEnabled = true,
        enableSnippets = true,
        enableLiveAutocompletion = true,
        enableBasicAutocompletion = true,
        onLoad = () => { },
        ...rest
    } = props;

    return (
        <FormContext.Consumer>
            {context => {
                value = name in context.values ? context.values[name] : value;

                const changeHandler = (e, editor) => {
                    const value = editor.getValue();
                    context.removeError(name);
                    context.updateValue(name, value);
                }

                return (
                    <Editor
                        name={name}
                        mode={mode}
                        value={value}
                        width={"100%"}
                        ref={innerRef}
                        onLoad={() => onLoad(innerRef)}
                        onBlur={changeHandler}
                        minLines={minLines}
                        maxLines={maxLines}
                        fontSize={fontSize}
                        readOnly={readOnly}
                        useWorker={useWorker}
                        wrapEnabled={wrapEnabled}
                        enableSnippets={enableSnippets}
                        enableLiveAutocompletion={enableLiveAutocompletion}
                        enableBasicAutocompletion={enableBasicAutocompletion}
                        editorProps={{
                            $blockScrolling: true
                        }}
                        {...rest}
                    />
                )
            }}
        </FormContext.Consumer>
    )
}