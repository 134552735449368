import React from "react";
import NotFoundImage from "../../assets/img/404.jpeg";

const NotFound = () => {
    React.useEffect(() => {
        document.title = `404 Not Found - ${process.env.REACT_APP_NAME}`;
    }, []);

    return <img src={NotFoundImage} width='100%' alt="404" />
}

export default NotFound;