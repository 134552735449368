import React from 'react';
import KeyValue from '../KeyValue';
import Tabs from '../../../../components/Tabs';

const INTEGRATIONContent = props => {
    let authorisationElement;
    const [integration, setIntegration] = React.useState({
        url: "", 
        name: "", 
        method: "", 
        domain: "", 
        params: [], 
        headers: [], 
        bearerToken: "", 
        authorization_type: "no-auth", 
        basic: {username: "", password: ""}
    });

    React.useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            setIntegration({...props.data});
        }
    }, [props.data]);

    const saveKeyValue = (mode, dataPack) => {
        if (mode === "params") {
            setIntegration({...integration, params: dataPack});
        } else if(mode === "headers") {
            setIntegration({...integration, headers: dataPack});
        }
    }

    if (integration.authorization_type === 'basic-auth') {
        authorisationElement = <>
            <div className="col-xs-12 col-sm-6 mb-2">
                <label htmlFor="username" className="form-label">Username</label>
                <input 
                    name="url" 
                    type="text" 
                    id="username" 
                    className="form-control" 
                    value={integration.basic.username} 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setIntegration({...integration, basic: {...integration.basic, username: e.target.value}})} 
                />
            </div>
            <div className="col-xs-12 col-sm-6 mb-2">
                <label htmlFor="password" className="form-label">Password</label>
                <input 
                    id="password" 
                    type="password" 
                    name="password" 
                    className="form-control" 
                    value={integration.basic.password} 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setIntegration({...integration, basic: {...integration.basic, password: e.target.value}})} 
                />
            </div>
        </>
    } else if (integration.authorization_type === 'bearer-token') {
        authorisationElement = <div className="col-md-12 mb-2">
            <label htmlFor="bearerToken">Bearer Token</label>
            <input 
                type="text" 
                id="bearerToken" 
                name="bearerToken" 
                className="form-control" 
                value={integration.bearerToken} 
                readOnly={props.mode === "preview" && true} 
                onChange={(e) => setIntegration({...integration, bearerToken: e.target.value})} 
            />
        </div>
    }

    return (
        <div className="row">
            <div className="col-md-12 mb-4">
                <label htmlFor="name" className="form-label">Name</label>
                <input 
                    type="text" 
                    id="name" 
                    name="name" 
                    value={integration.name} 
                    className="form-control" 
                    readOnly={props.mode === "preview" && true} 
                    onChange={(e) => setIntegration({...integration, name: e.target.value})} 
                />
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="method" className="form-label">Method</label>
                <select 
                    value={integration.method} 
                    disabled={props.mode === "preview" && true} 
                    className="form-select form-select-lg shadow-none" 
                    onChange={e => setIntegration({...integration, method: e.target.value})}
                >
                    <option value="get">GET</option>
                    <option value="post">POST</option>
                    <option value="put">PUT</option>
                    <option value="patch">PATCH</option>
                    <option value="delete">DELETE</option>
                </select>
            </div>
            <div className="col-md-6 mb-4">
                <label htmlFor="domain" className="form-label">Domain</label>
                <select 
                    value={integration.domain} 
                    disabled={props.mode === "preview" && true} 
                    className="form-select form-select-lg shadow-none" 
                    onChange={e => setIntegration({...integration, domain: e.target.value})}
                >
                    <option value="">Select</option>
                    <option value="solvup">Solvup</option>
                    <option value="customer-portal">Customer Portal</option>
                    <option value="eucalyptus">Eucalyptus</option>
                    <option value="ironwood">Ironwood</option>
                    <option value="teak">Teak</option>
                    <option value="koda">Koda</option>
                    <option value="neem">Neem</option>
                    <option value="other">Other</option>
                </select>
            </div>
            {
                integration.domain !== "" 
                    && <div className="col-md-12 mb-4">
                        <label htmlFor="url" className="form-label">{integration.domain === "other" ? "Absolute" : "Relative"} URL</label>
                        <input 
                            id="url" 
                            name="url" 
                            type="text" 
                            value={integration.url} 
                            className="form-control" 
                            readOnly={props.mode === "preview" && true} 
                            onChange={(e) => setIntegration({...integration, url: e.target.value})}  
                        />
                    </div>
            }
            <div className="col-md-12 mb-4">
                <Tabs active="authorization">
                    <Tabs.Pane id="authorization" title="Authorization">
                        <p>The authorization header will be automatically generated when you send the request.</p>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <select 
                                    value={integration.authorization_type} 
                                    disabled={props.mode === "preview" && true} 
                                    className="form-select form-select-lg shadow-none" 
                                    onChange={e => setIntegration({...integration, authorization_type: e.target.value})}
                                >
                                    <option value="no-auth">No Auth</option>
                                    <option value="basic-auth">Basic Auth</option>
                                    <option value="bearer-token">Bearer Token</option>
                                </select>
                            </div>
                            {authorisationElement}
                        </div>
                    </Tabs.Pane>
                    <Tabs.Pane id="params" title="Params">
                        <KeyValue mode="params" saveKeyValue={saveKeyValue} values={integration.params} />
                    </Tabs.Pane>
                    <Tabs.Pane id="headers" title="Headers">
                        <KeyValue mode="headers" saveKeyValue={saveKeyValue} values={integration.headers} />
                    </Tabs.Pane>
                </Tabs>
            </div>
            <div className="col-md-12 d-flex justify-content-end gap-4">
                {
                    props.mode === 'add' && 
                        <button 
                            className="btn btn-info fw-bold fs-4" 
                            onClick={() => props.submitTemplate(integration)} 
                            disabled={integration.name === "" && integration.method === "" && integration.domain === "" && integration.url === ""}
                        >Add</button>
                }
                {props.children}
            </div>
        </div>
    );
}

export default INTEGRATIONContent;