import React from 'react';
import { SecuredApi as axios } from "../../../services/axios";

import Form from '../../../components/Form';
import Spinner from '../../../components/Spinner';
import AddOrPreviewTemplate from './AddOrPreviewTemplate';
import styles from '../../../assets/css/tooltip.module.css';
// import axios from 'axios';

const DisplayContent = ({ 
    children, 
    clickFunc, 
    contextFunc, 
    templateName, 
    noListCondition, 
    listOfTemplates, 
    noListMessage="Not found!", 
}) => {
    return (
        <div className="d-flex flex-column p-2 gap-2">
            {
                noListCondition 
                    ? <>
                        {
                            listOfTemplates.map(tempDetail => (
                                <div 
                                    key={tempDetail.id} 
                                    onClick={() => clickFunc(tempDetail)} 
                                    onContextMenu={() => contextFunc(tempDetail, 'preview')} 
                                    className="border rounded p-2 cursor-pointer bg-light text-truncate"
                                    title={templateName === 'sms' ? tempDetail.content : tempDetail.subject} 
                                >{templateName === 'sms' ? tempDetail.content : (templateName === 'email' ? tempDetail.subject : tempDetail.configuration_class)}</div>
                            ))
                        }
                        {children}
                    </>
                    : <div className="text-center p-3">{noListMessage}</div>
            }
        </div>
    );
}

const limit = 20;
const Template = ({ inputName, templateName, selectedTemplates, setValues, paperworkList = [] }) => {
    const [modal, setModal] = React.useState({
        show: false, 
        data: {},
        mode: 'add', 
        templateName: templateName
    });
    const [template, setTemplate] = React.useState({
        name: templateName, 
        selected: selectedTemplates, 
        totalList: [], 
        list: [], 
        searchList: [],
        isLoading: false, 
        paginateLoad: false, 
        inputName: inputName, 
        status: {
            error: null, 
            searchField: ""
        },
        tempList: []
    });

    const fetchTemplates = React.useCallback(({
        nextPage = 1,
        searchParam = "",
        loadType = "basic",
    }) => {
        let URL = 'commpack/';
        URL += template.name;
        URL += `?limit=${limit}`;
        URL += `&nextPage=${nextPage}`;
        URL += searchParam !== "" ? `&search=${searchParam}` : '';
        setTemplate({
            ...template, 
            isLoading: loadType === "basic" ? true : false, 
            paginateLoad: loadType === "paginate" ? true : false
        });

        axios.get(URL)
        .then(result => {
            let sample = JSON.parse(JSON.stringify(template));

            const { items , count } = result.data.data.Data;
            if (items.length > 0) {
                sample.tempList = items;
                
                sample.totalList = count;
                sample.nextPage = nextPage;
                sample.totalPage = count > 0 ? Math.ceil(count / limit) : 0;
                if (searchParam === template.status.searchField) {
                    sample.list = items.filter(function(objOne) {
                        return !sample.selected.some(function(objTwo) {
                            return objOne.id == objTwo.id;
                        });
                    });
                    sample.searchList = searchParam !== "" ? template.searchList.concat(items) : [];
                } else {
                    sample.list = items.filter(function(objOne) {
                        return !sample.selected.some(function(objTwo) {
                            return objOne.id == objTwo.id;
                        });
                    });
                    sample.searchList = searchParam !== "" ? items : [];
                }
            } else {
                sample.list = searchParam === "" ? [] : sample.list;
                sample.searchList = [];
            }
            setTemplate(sample);
            if (templateName === "sms") {
                setValues({smsTemplate: template.selected});
            } else if (templateName === "email") {
                setValues({emailTemplate: template.selected});
            } else if (templateName === "integration") {
                setValues({integrationTemplate: template.selected});
            }
        })
        .catch(err => {
            console.log(err);
            setTemplate({
                ...template, 
                isLoading: false, 
                paginateLoad: false, 
                status: {
                    error: err, 
                    searchField: searchParam
                }
            });
        });
    }, [template, setValues]);

    const handlePagination = () => {
        fetchTemplates({
            loadType: "paginate", 
            nextPage: (template.fields.nextPage + 1), 
            searchParam: template.status.searchField
        });
    }

    const handleSearch = (e) => {
        let obj = {
            nextPage: 1,
            loadType: "basic",
        };
        if (e.keyCode === 13) {
            // Handle input on User's Enter
            if (template.status.searchField !== e.target.value) {
                obj.searchParam = e.target.value;
                fetchTemplates(obj);
            }
        }
        if (e.keyCode === 8 && e.target.value === '' && template.status.searchField === e.target.value) {
            obj.searchParam = "";
            fetchTemplates(obj);
        }
    }

    const showPreview = (template, mode) => {
        setModal({...modal, mode, show: true, data: template});
    }

    const addTemplate = (eventTemp) => {
        const filterSelectedTemplate = template.list.filter(temp => temp.id !== eventTemp.id);
        const addToChoosed = [...template.selected, eventTemp];

        setTemplate({
            ...template, 
            list: filterSelectedTemplate, 
            selected: addToChoosed
        });
        if (templateName === "sms") {
            setValues({smsTemplate: addToChoosed});
        } else if (templateName === "email") {
            setValues({emailTemplate: addToChoosed});
        } else if (templateName === "integration") {
            setValues({integrationTemplate: addToChoosed});
        }
    }

    const removeTemplate = (eventTemp) => {
        const filterSelectedTemplate = template.selected.filter(temp => temp.id !== eventTemp.id);
        let addToChoosed = [...template.list, eventTemp];
        
        setTemplate({
            ...template, 
            list: addToChoosed, 
            selected: filterSelectedTemplate
        });
        if (templateName === "sms") {
            setValues({smsTemplate: filterSelectedTemplate});
        } else if (templateName === "email") {
            setValues({emailTemplate: filterSelectedTemplate});
        } else if (templateName === "integration") {
            setValues({integrationTemplate: filterSelectedTemplate});
        }
    }

    const appendTemplate = addedTemplate => {
        setTemplate({...template, list: [addedTemplate, ...template.list], tempList: [addedTemplate, ...template.tempList]});
        setModal({show: false, data: {}, mode: 'add', templateName: templateName});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => fetchTemplates({nextPage: 1, searchParam: "", loadType: "basic"}), []);

    return (
        <div className="container-fluid">
            <Form.Input type="hidden" name={template.inputName} />
            {
                modal.show && 
                    <AddOrPreviewTemplate 
                        {...modal} 
                        appendTemplate={appendTemplate}
                        paperworkList = {paperworkList}
                    >
                        <button 
                            type="button" 
                            className="btn btn-secondary fw-bold fs-4" 
                            onClick={() => setModal({ ...modal, mode: 'add', show: false, data: {} })}
                        >Close</button>
                    </AddOrPreviewTemplate>
            }
            <div className="row">
                <div className="col-12 card flex-row px-0 false">
                    <div className="col-4">
                        <div className="card-header d-flex justify-content-between fs-4 fw-bold">
                            <div 
                                style={{ height: 'min-content' }} 
                                className="w-50 text-truncate my-auto" 
                                onClick={() => fetchTemplates({nextPage: 1, searchParam: "", loadType: "basic"})}
                            >Choose Template</div>
                            <button 
                                type="button" 
                                onClick={() => showPreview({}, 'add')} 
                                className="btn btn-sm shadow-none fw-bold btn-info py-1"
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className="card-header p-1">
                            <input 
                                type="text" 
                                autoComplete="off" 
                                onKeyDown={(e) => handleSearch(e)} 
                                placeholder="Type and press Enter to search..." 
                                className="form-control form-control-sm shadow-none" 
                            />
                        </div>
                        <div className="card-body p-0" style={{ maxHeight: "300px", overflowY: "scroll" }}>
                            <Spinner isLoading={template.isLoading}>
                                <DisplayContent 
                                    clickFunc={addTemplate} 
                                    contextFunc={showPreview} 
                                    templateName={template.name} 
                                    listOfTemplates={template.list} 
                                    noListMessage="No Templates were found." 
                                    noListCondition={
                                        (template.status.searchField === "" && template.list !== undefined && template.list.length > 0) 
                                        || (template.status.searchField !== "" && template.searchList !== undefined && template.searchList.length > 0)
                                    }
                                >
                                    {
                                        (template.nextPage !== template.totalPage) 
                                            && <div className="text-center mb-2">
                                                <button 
                                                    type="button" 
                                                    disabled={template.paginateLoad} 
                                                    onClick={() => handlePagination()} 
                                                    className="btn btn-sm btn-outline-default"
                                                >{template.paginateLoad ? "Loading..." : "Load More"}</button>
                                            </div>
                                    }
                                </DisplayContent>
                            </Spinner>
                        </div>
                    </div>
                    <div className="col-8 border-start">
                        <div className="card-header d-flex justify-content-between fs-4 fw-bold">
                            <div className="w-50 text-truncate my-auto" style={{ height: 'min-content' }}>Selected Template</div>
                            <div className="d-flex">
                                <button 
                                    type="button" 
                                    datatooltip="Double click me to reset to default" 
                                    className={`btn btn-sm shadow-none fw-bold btn-secondary py-1 ${styles.leftTooltip}`} 
                                    onDoubleClick={() => setTemplate({...template, list: template.tempList, selected: selectedTemplates})}
                                >Reset</button>
                            </div>
                        </div>
                        <div className="card-body p-0 h-100">
                            <DisplayContent 
                                contextFunc={showPreview} 
                                clickFunc={removeTemplate} 
                                templateName={template.name} 
                                listOfTemplates={template.selected} 
                                noListMessage="No Templates were added yet." 
                                noListCondition={template.selected.length > 0} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Template;