import { SecuredApi as axios } from "../axios";
import Helper from "../../utils/Helper";

export default class Event {
    constructor(component) {
        this.component = component;
    }

    async list(params = { "limit": 10, "offset": 0 }) {
        try {
            const response = await axios.get('commpack/event?' + Helper.serializeObject(params));
            const data = await response.data.data.Data.items;
            this.component.setState({
                isLoading: false,
                error: null,
                data: data
            });

        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    async optionList(params = { "limit": 1000, "offset": 0 }) {
        try {
            const response = await axios.get('commpack/business_term?' + Helper.serializeObject(params));
            const data = await response.data.data.Data.mapping;

            this.component.setState({
                businessTerm: data
            });
            
        } catch ({ message, statusCode }) {
            console.error(message);
        }
    }

    async paperworkList(params = { "limit": 1000, "offset": 0 }) {
        try {
            const response = await axios.get('commpack/paperwork?' + Helper.serializeObject(params));
            const data = await response.data.data.Data;

            if(data.items.length > 0){
                const res = data.items.reduce(function(s,values){
                    const obj = {};
                    obj.value = values.id;
                    obj.label = values.html_content;
                    s.push(obj);
                    return s;
                  }, []);

                  data.defaultList = res;
            }else{
                data.defaultList = [];
            }
            this.component.setState({
                paperworkList: data
            });
            
        } catch ({ message, statusCode }) {
            console.error(message);
        }
    }

    async get(id) {
        try {
            const response = await axios.get('commpack/event/' + id);
            const data = await response.data.data.Data;
            let query = {};
            if (data.conditions !== undefined && data.conditions.rules.length > 0) {
                query = data.conditions;
            }else{
                query = {
                    "uid": Helper.guid() + Helper.guid(),
                    "gate": "AND",
                    "rules": []
                }
            }

            this.component.setState({
                isLoading: false,
                error: null,
                data: data,
                query: query
            });
        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
        }
    }

    async save(fields = {}, setSubmitting) {
        try {
            const response = await axios.post('commpack/event', fields);
            const data = await response.data.data.Data;
            this.component.props.history.push('/event/' + data.id );
        } catch ({ message, statusCode }) {
            console.error(message);
            setSubmitting(false);
        }
    }

    async deleteAction(id) {
        try {
            // eslint-disable-next-line
            const response = await axios.delete('commpack/event/' + id);
            this.component.setState({
                isLoading: false,
                error: null
            });
            this.component.props.history.push('/event/list');
        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
        }
    }

    async update(id, fields = {}, setSubmitting) {
        try {
            // eslint-disable-next-line
            const response = await axios.put('commpack/event/' + id, fields);
            this.component.setState({
                isLoading: false,
                toastShow: true,
                error: null,
                message: 'Event updated successfully'
            });

        } catch ({ message, statusCode }) {
            console.error(message);
            this.component.setState({
                isLoading: false
            });
        } finally {
            setSubmitting(false);
        }
    }
}