import React, { Component } from "react";
import { withRouter } from "react-router";
import Alert from "../../../../components/Alert";
import ContentEditable from "../../../../components/ContentEditable";
import Form from "../../../../components/Form";
import { FieldService } from "../../../../services";
import { Validator } from "../../../../utils/Validator";
import { schema } from "../../../../config/schema";

class BasicDetails extends Component {
    constructor(props) {
        super(props);
        this.services = {
            field: new FieldService(this)
        }

        this.conditionsRef = null;
        this.configurationRef = null;

        this.state = {
            message: null,
            variant: "danger",
            schema: this.props.data.type ? schema[this.props.data.type] : {}
        }

        this.types = {
            "text": "Text",
            "radio": "Radio",
            "select": "Select",
            "hidden": "Hidden",
            "checkbox": "Checkbox",
            "password": "Password",
            "textarea": "Textarea",
            "button": "Button",
            "tab": "Tab",
            "list": "List",
            "table": "Table",
            "number": "Number",
            "paragraph": "Paragraph",
            "navigation": "Navigation",
            "date-picker": "Datepicker",
            "file-upload": "File upload",
            "autocomplete": "Autocomplete",
            "interval-timer": "Interval timer",
            "barcode-scanner": "Barcode scanner",
            "copy-to-clipboard": "Copy to clipBoard",
            "digital-signature": "Digital signature",
            "inline-radio-group": "inline radio group",
            "multi-checkbox-list": "Multi checkbox list",
            "single-selection-list": "Single selection list",
            "google-address-auto-complete": "Google address auto complete",
            "multi-select-multi-type-list": "Multi Select Multi Type List"
        }

        this.typesArray = Object.entries(this.types).map(([value, label]) => ({
            value, label
        }));
    }

    submitHandler = (values, setSubmitting, setErrors) => {
        if (this.props.id) {
            this.services.field.update(this.props.id, values, setSubmitting, setErrors);
        } else {
            this.services.field.create(values, setSubmitting, setErrors);
        }
    }

    render() {
        return (
            <Form
                initialValues={{
                    uid: this.props.data.uid,
                    name: this.props.data.name,
                    type: this.props.data.type,
                    conditions: this.props.data.conditions,
                    description: this.props.data.description,
                    configuration: this.props.data.configuration
                }}
                onSubmit={this.submitHandler}
                preventUnload={this.props.preventUnload}
                validationSchema={{
                    name: Validator.create().required("Name is required"),
                    type: Validator.create().required("Type is required"),
                }}>

                {!this.props.id &&
                    <Alert variant="secondary">
                        <strong>Note:</strong> You need to add basic details first. After that, you will be able to override.
                    </Alert>
                }

                {this.state.message &&
                    <Alert variant={this.state.variant} dismiss={() => {
                        this.setState({
                            message: null
                        });
                    }}>
                        {this.state.message}
                    </Alert>
                }

                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <Form.Group name="name" label="Name" required>
                            <Form.Input type="text" placeholder="Enter name" id="name" name="name" />
                        </Form.Group>

                        {this.props.id &&
                            <Form.Group name="uid" label="Unique ID" required>
                                <Form.Input type="text" name="uid" disabled />
                            </Form.Group>
                        }

                        <Form.Group name="conditions" label="Conditions" required hint={
                            <>
                                <strong className="text-danger">Note: </strong>
                                To show component always, just leave conditions as empty object {"{}"} and in case you never want to show component then &nbsp;
                                <span className="text-primary cursor-pointer"
                                    onClick={() => {
                                        const editor = this.conditionsRef.jsonEditor;
                                        editor.update({
                                            "AND": [
                                                "1 == 0"
                                            ]
                                        });

                                        this.conditionsRef.focus();
                                    }}>
                                    Click here
                                </span>
                            </>
                        }>
                            <Form.JSONEditor
                                onLoad={editor => {
                                    this.conditionsRef = editor;
                                }}
                                schema={schema.conditions}
                                htmlElementProps={{
                                    name: "conditions"
                                }} />
                        </Form.Group>
                        <Form.Group name="js_conditions" label="Javascript Format Conditions" hint={
                            <>
                                <strong className="text-danger">Note: </strong>
                                You can't edit this section because Conditions added in above section will be changed to Javascript format and pre populated here after you click save.
                            </>
                        }>
                            <ContentEditable disabled id="js_conditions">
                                <pre className="white-space-normal">
                                    {this.props.data.visible ? JSON.stringify(this.props.data.visible.js, null, 2) : 'No conditions added'}
                                </pre>
                            </ContentEditable>
                        </Form.Group>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Form.Group name="type" label="Type" required>
                            <Form.Select
                                id="type"
                                name="type"
                                disabled={this.props.id}
                                onChange={e => {
                                    this.configurationRef.jsonEditor.setSchema(schema[e.target.value]);
                                }}>
                                {this.typesArray.map(data => (
                                    <option value={data.value} key={data.value}>
                                        {data.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group name="configuration" label="Configuration" required>
                            <Form.JSONEditor
                                onLoad={editor => {
                                    this.configurationRef = editor;
                                }}
                                schema={this.state.schema}
                                htmlElementProps={{
                                    name: "configuration"
                                }} />
                        </Form.Group>
                        <Form.Group name="description" label="Description">
                            <Form.Textarea type="textarea" name="description" placeholder="Describe here..." rows="3" />
                        </Form.Group>
                    </div>
                    <div className="col-xs-12">
                        <Form.Submit />
                    </div>
                </div>
            </Form>
        )
    }
}

export default withRouter(BasicDetails);