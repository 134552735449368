import React, { Component } from 'react';
import { Route } from 'react-router';
import "./App.css";
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import PublicLayout from './layouts/Public';
import UserContext from './contexts/user-context';
import ThemeContext from './contexts/theme-context';

class App extends Component {
	constructor(props) {
		super(props);

		let redirect = '/dashboard';
		if (window.location.search || window.location.hash) {
			redirect = window.location.search.replace('?redirect=', '') + window.location.hash;
		}

		this.state = {
			redirect,
			theme: localStorage.getItem('theme') || 'light',
			isLoggedIn: Boolean(localStorage.getItem('isLoggedIn'))
		}

		this.changeTheme(this.state.theme);
	}

	changeTheme = theme => {
		this.setState({ theme });
		localStorage.setItem('theme', theme);

		const opposite = theme === 'light' ? 'dark' : 'light';
		document.body.classList.add(theme);
		document.body.classList.remove(opposite);
	}

	render() {
		return (
			<>
				<Route exact path="/">
					<Redirect to={this.state.isLoggedIn ? "/dashboard" : "/login"} />
				</Route>

				<UserContext.Provider value={{
					isLoggedIn: this.state.isLoggedIn,
					user: JSON.parse(localStorage.getItem('user')),
					login: (redirect = this.state.redirect) => {
						this.setState({
							redirect,
							isLoggedIn: true
						});
					},
					logout: () => {
						localStorage.removeItem('isLoggedIn');
						localStorage.removeItem('token');
						localStorage.removeItem('user');
						this.setState({
							isLoggedIn: false
						});
					}
				}}>
					<ThemeContext.Provider value={{
						theme: this.state.theme,
						changeTheme: this.changeTheme
					}}>
						{this.state.isLoggedIn
							? <AuthLayout redirect={this.state.redirect} />
							: <PublicLayout />}
					</ThemeContext.Provider>
				</UserContext.Provider>
			</>
		)
	}
}

export default App;
