import React, { Component } from "react";
import List from "../../../../../components/List";
import Alert from "../../../../../components/Alert";
import { FieldService } from "../../../../../services";
import Confirm from "../../../../../components/Confirm";
import PrintObject from "../../../../../components/Solvup/PrintObject";

class Overrides extends Component {
    static defaultProps = {
        access: true,
        data: []
    }

    constructor(props) {
        super(props);
        this.services = {
            field: new FieldService(this)
        }

        this.state = {
            message: null,
            variant: "success",
            timestamp: + new Date(),
            data: this.props.data,
            confirmModal: {
                show: false,
                message: "Are you sure want to delete the override ?",
                onConfirm: () => { },
                onCancel: () => this.closeConfirmModal()
            }
        }
    }

    // Call this using ref from outside
    updateOverrides = (data = [], message = null) => {
        this.setState({
            data,
            message,
            variant: "success",
            timestamp: + new Date()
        });
    }

    closeConfirmModal = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: false
            }
        }));
    }

    deleteHandler = id => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: true,
                onConfirm: () => this.deleteOverride(id)
            }
        }));
    }

    // Delete override finally
    deleteOverride = id => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            this.services.field.deleteOverride(this.props.fieldId, id);
        });
    }

    render() {
        return (
            <>
                {this.state.message &&
                    <Alert variant={this.state.variant} dismiss={() => {
                        this.setState({
                            message: null
                        });
                    }}>
                        {this.state.message}
                    </Alert>
                }

                <List
                    key={this.state.timestamp}
                    data={this.state.data}
                    columns={[
                        {
                            name: "Parent component",
                            width: 130,
                            selector: row => row.component_id || "N/A"
                        },
                        {
                            name: "Conditions",
                            selector: row => <PrintObject data={row.conditions} disabled />
                        },
                        {
                            name: "Overrides",
                            selector: row => <PrintObject data={row.return_values} disabled />
                        },
                        {
                            name: "Created",
                            width: 130,
                            title: row => new Date(row.created_at).toString(),
                            selector: row => new Date(row.created_at).toDateString(),
                            sortable: true
                        },
                        {
                            name: "Updated",
                            width: 130,
                            title: row => new Date(row.updated_at).toString(),
                            selector: row => new Date(row.updated_at).toDateString(),
                            sortable: true
                        },
                        {
                            name: "Actions",
                            width: 130,
                            selector: row => (
                                <>
                                    <button className="btn btn-default btn-xs m-r-5" title="Edit" onClick={e => this.props.editHandler(row)}>
                                        Edit
                                    </button>
                                    <button className="btn btn-danger btn-xs m-r-5" title="Delete" onClick={() => this.deleteHandler(row.id)}>
                                        Delete
                                    </button>
                                </>
                            )
                        }
                    ]} />

                {this.state.confirmModal.show &&
                    <Confirm {...this.state.confirmModal} />
                }
            </>
        )
    }
}

export default Overrides;