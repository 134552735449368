import React from "react";
import { FormContext } from ".";

export const Checkbox = ({ name, label, value, uncheckedValue = '', valueType = 'string', className = '', ...props }) => (
    <FormContext.Consumer>
        {context => {
            const formValue = name in context.values ? context.values[name] : null;
            const changeHandler = e => {
                if (e.target.checked) {
                    context.updateValue(e.target.name, e.target.value, valueType);
                } else if (uncheckedValue !== null) {
                    context.updateValue(e.target.name, uncheckedValue, valueType);
                } else {
                    context.deleteValue(e.target.name);
                }
            }

            return (
                <label className="form-checkbox">
                    <input
                        name={name}
                        type="checkbox"
                        value={value}
                        className={className}
                        onChange={changeHandler}
                        data-value-type={valueType}
                        defaultChecked={value === formValue}
                        {...props} />
                    {label}
                </label>
            )
        }}
    </FormContext.Consumer>
)