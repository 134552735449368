import React from 'react';
import Select from 'react-select';
import Helper from '../../../utils/Helper';
import businessTerms from './businessTerms.json';
import styles from '../../../assets/css/tooltip.module.css';

const KeyValue = props => {
    const [values, setValues] = React.useState([]);
    const [terms, setTerms] = React.useState({
        selectedTerms: [], 
        searchedTerms: [], 
        loading: false, 
        searchInput: "", 
        loadingMessage: "Searching business terms...", 
        noOptionsMessage: "No business terms available", 
    });
    const [pack, setPack] = React.useState({
        key: "", 
        type: "", 
        value: ""
    });

    const saveValues = () => {
        pack.id = Helper.guid() + Helper.guid();
        setValues([...values, pack]);
        setPack({key: "", type: "", value: ""});
        setTerms({...terms, searchedTerms: [], selectedTerms: []});
    }

    const actionTerm = (selectedOptions, reason) => {
        if (reason.action === 'select-option') {
            let filterSearch = terms.searchedTerms.filter(val => val.value !== selectedOptions.value);
            if (terms.selectedTerms.length > 0) {
                filterSearch = [...filterSearch, terms.selectedTerms[0]];
            }
            // setTerms({...terms, searchedTerms: filterSearch, selectedTerms: [selectedOptions], searchInput: ''});
            setTerms({...terms, searchedTerms: [], selectedTerms: [selectedOptions], searchInput: ''});
            setPack({...pack, value: selectedOptions.value});
        } else if (reason.action === 'clear') {
            // setTerms({...terms, searchedTerms: [...terms.searchedTerms, reason.removedValues[0]], selectedTerms: []});
            setTerms({...terms, selectedTerms: []});
            setPack({...pack, value: ""});
        }
    }

    const deleteValue = dataIndex => {
        const dataPacks = values.filter(val => val.id !== dataIndex);
        setValues(dataPacks);
    }

    React.useEffect(() => {
        if (props.values !== undefined && props.values.length > 0) {
            setValues(props.values);
        }
    }, [props.values]);

    React.useEffect(() => {
        // console.log(terms.searchInput, businessTerms);
        const debounceSearch = setTimeout(() => {
            if (terms.searchInput.trim() !== "" && terms.searchInput.length > 2) {
                setTerms({...terms, loading: true});
                // Axios call to SEARCH terms templates by Names
                const response = new Promise(resolve => {
                    setTimeout(() => {
                        const filterSearch = businessTerms.filter(val => val.value.toLocaleLowerCase().match(terms.searchInput.toLocaleLowerCase()));
                        resolve(filterSearch);
                    }, 3000);
                });

                response
                // axios.get(URL)
                .then(res => {
                    // console.log(res);
                    setTerms({...terms, searchedTerms: res, loading: false});
                })
                .catch(err => {
                    // console.log(err);
                    setTerms({...terms, searchedTerms: [], loading: false});
                });
            }
        }, 1000);

        return () => clearTimeout(debounceSearch);
    }, [terms.searchInput]);

    return (
        <div className="table-responsive" style={{ overflow: "unset" }}>
            <table className="table caption-top mb-0">
                {
                    values.length > 0 && 
                        <caption>
                            <small>
                                Want to save the final-list?&nbsp;
                                <span onClick={() => props.saveKeyValue(props.mode, values)} className="text-primary cursor-pointer">Click here</span>
                            </small>
                        </caption>
                }
                <thead className="table-light">
                    <tr>
                        <th>Key</th>
                        <th>Type</th>
                        <th>Value</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        values.map(val => {
                            return (
                                <tr key={val.id}>
                                    <td>{val.key}</td>
                                    <td>{val.type}</td>
                                    <td>{val.value}</td>
                                    <td>
                                        <button 
                                            datatooltip="Delete" 
                                            onDoubleClick={() => deleteValue(val.id)} 
                                            className={`btn btn-sm btn-danger ${styles.topTooltip}`}
                                        >
                                            <i className="fa fa-trash fs-4"></i>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <td className="col-4">
                            <input 
                                value={pack.key} 
                                placeholder="Enter key" 
                                className="form-control" 
                                onChange={e => setPack({...pack, key: e.target.value})}
                            />
                        </td>
                        <td className="col-3">
                            <select 
                                value={pack.type} 
                                className="form-select form-select-lg" 
                                onChange={e => setPack({...pack, type: e.target.value})}
                            >
                                <option value="">Select Type</option>
                                <option value="string">String</option>
                                <option value="number">Number</option>
                                <option value="boolean">Boolean</option>
                                <option value="business-term">Business Term</option>
                            </select>
                        </td>
                        <td className="col-4">
                            {
                                pack.type !== "business-term" 
                                    ? <input 
                                        value={pack.value} 
                                        placeholder="Enter value" 
                                        className="form-control" 
                                        onChange={e => setPack({...pack, value: e.target.value})}
                                    />
                                    : <Select 
                                        isClearable={true} 
                                        name="businessTerm" 
                                        onChange={actionTerm} 
                                        isLoading={terms.loading} 
                                        value={terms.selectedTerms} 
                                        options={terms.searchedTerms} 
                                        inputValue={terms.searchInput} 
                                        loadingMessage={() => terms.loadingMessage} 
                                        isSearchable={terms.selectedTerms.length === 0} 
                                        noOptionsMessage={() => terms.noOptionsMessage} 
                                        onInputChange={(value, reason) => {
                                            if (
                                                reason.action === "set-value" ||
                                                reason.action === "input-blur" ||
                                                reason.action === "menu-close"
                                            ) {
                                                return;
                                            }
                                            setTerms({...terms, searchInput: value});
                                        }} 
                                    />
                            }
                        </td>
                        <td className="col-1">
                            <button 
                                datatooltip="Save" 
                                onClick={saveValues} 
                                disabled={Object.values(pack).some(val => val === '')} 
                                className={`btn btn-sm btn-primary ${styles.topTooltip}`}
                            >
                                <i className="fa fa-save fs-4"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default React.memo(KeyValue);
