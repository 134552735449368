import { PublicApi as axios } from "./axios";

export default class User {
    constructor(component) {
        this.component = component;
    }

    async login(params = {}, setSubmitting) {
        try {
            // eslint-disable-next-line
            const response = await axios.post('/login', params);
            const data = await response.data;

            localStorage.setItem('isLoggedIn', true);
            localStorage.setItem('token', data.data.access_token);

            // TODO: Need API to get user details
            localStorage.setItem('user', JSON.stringify({
                name: "Solvup User",
                username: params.username,
                role: "Administrator"
            }));

            setSubmitting(false);

            let redirect = null;
            if (window.location.search.includes('redirect')) {
                redirect = window.location.search.replace('?redirect=', '') + window.location.hash;
            }

            this.component.context.login(redirect);
        } catch ({ message, statusCode }) {
            const error = statusCode === 400 ? 'Invalid username or password' : message;
            setSubmitting(false);
            this.component.setState({ error });
        }
    }

    // TODO: not sure we need this or it can be done by simply clearing localstorage. 
    async logout(data = {}) {

    }
}