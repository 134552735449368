import React, { useEffect } from "react";
import Ajv from "ajv";
import { FormContext } from ".";
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from "ace-builds/src-min-noconflict/ace";

export const JSONEditor = props => {
    let innerRef = null;

    let {
        value = {},
        mode = "code",
        onLoad = () => { },
        allowedModes = ['view', 'code'],
        htmlElementProps = {},
        aceEditorOptions = {
            maxLines: 30,
            readOnly: false,
            useWorker: false,
            enableSnippets: true,
            enableLiveAutocompletion: true,
            enableBasicAutocompletion: true
        },
        ...rest
    } = props;

    /*
        For full list of ace editor options, check below link:
        https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
    */

    // On mount, send ref back to parent for further uses directly from there
    useEffect(() => {
        innerRef.jsonEditor.aceEditor.setOptions(aceEditorOptions);
        onLoad(innerRef);
    }, []);

    return (
        <FormContext.Consumer>
            {context => {
                const { name } = htmlElementProps;
                if (name in context.values) {
                    value = context.values[name];
                    if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
                        value = {};
                    }
                }

                let schemaError = false;
                const changeHandler = e => {
                    try {
                        const value = innerRef.jsonEditor.get();
                        if (schemaError === false) {
                            context.removeError(name);
                            context.updateValue(name, value, 'object');
                        }
                    } catch (e) {
                        context.addError(name, 'Must be a valid JSON');
                    }
                }

                return (
                    <JsonEditor
                        ace={ace}
                        mode={mode}
                        value={value}
                        onBlur={changeHandler}
                        allowedModes={allowedModes}
                        htmlElementProps={htmlElementProps}
                        ref={element => {
                            if (innerRef === null) {
                                innerRef = element;
                            }
                        }}
                        ajv={Ajv({
                            $data: true,
                            verbose: true,
                            allErrors: true,
                            jsonPointers: false
                        })}
                        onValidationError={errors => {
                            const hasError = context.hasError(name);
                            if (errors.length) {
                                schemaError = true;
                                !hasError && context.addError(name, 'Must be a valid JSON');
                            } else {
                                schemaError = false;
                                hasError && context.removeError(name);
                            }
                        }}
                        {...rest}
                    />
                )
            }}
        </FormContext.Consumer>
    )
}