import { Validator } from "../../../utils/Validator";
import { retailers } from "../../../contexts/retailer-context";

const config = [
    {
        type: "alert",
        layout: "full",
        variant: "secondary",
        content: (
            <>
                <strong>Note: </strong>
                Inputs can't be updated once saved. So check carefully before saving.
            </>
        )
    },
    {
        type: "tab",
        active: "requiredFields",
        layout: "full",
        values: [
            {
                id: "requiredFields",
                title: "Required",
                useRenderer: true,
                config: [
                    {
                        type: "select",
                        label: "Retailer",
                        name: "retailer_id",
                        valueType: "number",
                        disabled: values => values.id !== undefined && values.replicate === false,
                        validations: Validator.create().required("Retailer is required"),
                        values: retailers.map(item => ({
                            value: item.id,
                            label: item.name
                        }))
                    },
                    {
                        type: "select",
                        name: "user_type",
                        label: "User type",
                        disabled: values => values.id !== undefined && values.replicate === false,
                        validations: Validator.create().required("User type is required"),
                        values: [
                            {
                                label: "Store",
                                value: "store"
                            },
                            {
                                label: "Callcentre",
                                value: "callcentre"
                            },
                            {
                                label: "Online",
                                value: "online"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        name: "CaseType",
                        label: "Case type",
                        valueType: "string",
                        visible: values => Boolean(values.retailer_id),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        validations: Validator.create().required('Case type is required'),
                        values: values => {
                            switch (values.retailer_id) {
                                case 12:
                                    return [
                                        {
                                            label: "Customer item",
                                            value: "customers_item"
                                        },
                                        {
                                            label: "Floor stock",
                                            value: "floor_stock_item"
                                        }
                                    ]
                                case 5:
                                    return [
                                        {
                                            label: "Faulty",
                                            value: "faulty"
                                        },
                                        {
                                            label: "Change of mind",
                                            value: "com"
                                        },
                                        {
                                            label: "Damaged",
                                            value: "damaged"
                                        }
                                    ]
                                default:
                                    return [
                                        {
                                            label: "Customer item",
                                            value: "customers_item"
                                        }
                                    ]
                            }
                        }
                    },
                    {
                        type: "radio",
                        name: "ProductLocation",
                        label: "Product location",
                        validations: Validator.create().requiredIf('CaseType', 'customers_item'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        visible: values => values.CaseType === 'customers_item',
                        values: [
                            {
                                label: "At store",
                                value: "at_store"
                            },
                            {
                                label: "At home",
                                value: "at_home"
                            },
                            {
                                label: "Any",
                                value: "any"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "any",
                        name: "FloorStockSoldBefore",
                        label: "Floorstock item sold before",
                        validations: Validator.create().requiredIf('CaseType', 'floor_stock_item'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        visible: values => values.CaseType === 'floor_stock_item',
                        values: [
                            {
                                label: "Yes",
                                value: "1"
                            },
                            {
                                label: "No",
                                value: "0"
                            },
                            {
                                label: "Any",
                                value: "any"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "any",
                        name: "ProductInHomeEligible",
                        label: "Product in home eligible",
                        visible: values => Boolean(values.retailer_id),
                        validations: Validator.create().required('In home eligible is required'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "Yes",
                                value: "Yes"
                            },
                            {
                                label: "No",
                                value: "No"
                            },
                            {
                                label: "Any",
                                value: "any"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "any",
                        name: "ItemDamaged",
                        label: "Item damaged",
                        visible: values => values.retailer_id === 12,
                        disabled: values => values.id !== undefined && values.replicate === false,
                        validations: Validator.create().requiredIf('retailer_id', 12, 'Item damaged is required when Retailer is TGG'),
                        values: [
                            {
                                label: "Yes",
                                value: "Yes"
                            },
                            {
                                label: "No",
                                value: "No"
                            },
                            {
                                label: "Any",
                                value: "any"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "any",
                        name: "ProductPremiumService",
                        label: "Product premium service",
                        visible: values => values.retailer_id === 12 && values.CaseType === 'customers_item',
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "No service",
                                value: "no_premium_service"
                            },
                            {
                                label: "Concierge",
                                value: "concierge_service"
                            },
                            {
                                label: "Gold",
                                value: "gold_service"
                            },
                            {
                                label: "N/A",
                                value: "any"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "any",
                        name: "ProductSearch",
                        label: "Product search",
                        visible: values => values.retailer_id === 12 && values.CaseType === 'customers_item' && (values.ProductPremiumService === 'gold_service' || values.ProductPremiumService === 'concierge_service'),
                        validations: Validator.create().required('Product search is required'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "Docket",
                                value: "docket"
                            },
                            {
                                label: "Simple",
                                value: "simple"
                            },
                            {
                                label: "Any",
                                value: "any"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "any",
                        label: "Price cap",
                        inline: false,
                        name: "PremiumServicePriceCap",
                        visible: values => values.retailer_id === 12 && values.CaseType === 'customers_item' && (values.ProductPremiumService === 'gold_service' || values.ProductPremiumService === 'concierge_service'),
                        validations: Validator.create().required('Product search is required'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: values => {
                            if (values.ProductPremiumService === 'gold_service') {
                                return [
                                    {
                                        label: "Receipt price <= 500",
                                        value: "gold <= 500"
                                    },
                                    {
                                        label: "Receipt price > 500",
                                        value: "gold > 500"
                                    },
                                    {
                                        label: "Don't apply",
                                        value: "any"
                                    }
                                ];
                            } else {
                                return [
                                    {
                                        label: "Receipt price <= 250",
                                        value: "concierge <= 250"
                                    },
                                    {
                                        label: "Receipt price > 250",
                                        value: "concierge > 250"
                                    },
                                    {
                                        label: "Don't apply",
                                        value: "any"
                                    }
                                ];
                            }
                        }
                    },
                    {
                        type: "radio",
                        inline: false,
                        name: "InsurancePlan",
                        label: "Insurance plan",
                        visible: values => values.retailer_id === 12 && values.CaseType === 'customers_item' && values.ProductSearch === 'docket' && (values.ProductPremiumService === 'gold_service' || values.ProductPremiumService === 'concierge_service'),
                        validations: Validator.create().required('Insurance plan is required'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "No plan or not started yet",
                                value: "not started"
                            },
                            {
                                label: "Plan started but not ended",
                                value: "started",
                            },
                            {
                                label: "Plan ended",
                                value: "ended"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        inline: false,
                        name: "CaseReportDaysSincePurchase",
                        label: "Days of purchase",
                        visible: values => values.retailer_id === 12 && values.CaseType === 'customers_item' && values.ProductSearch === 'simple' && (values.ProductPremiumService === 'gold_service' || values.ProductPremiumService === 'concierge_service'),
                        validations: Validator.create().required('Days of purchase is required'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "<= 2 years",
                                value: "DOP <= 2"
                            },
                            {
                                label: "2 years > DOP <= 3 years",
                                value: "2 < DOP <= 3",
                            },
                            {
                                label: "3 years > DOP <= 5 years",
                                value: "3 < DOP <= 5"
                            },
                            {
                                label: "> 5 years",
                                value: "DOP > 5"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        multiple: true,
                        valueType: "string",
                        name: "ProductLodgementType",
                        label: "Product lodgement type",
                        visible: values => Boolean(values.retailer_id),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        validations: Validator.create().required('Lodgement type is required'),
                        values: [
                            {
                                label: "Regular",
                                value: "regular"
                            },
                            {
                                label: "Lite/Ultra",
                                value: "lite"
                            },
                            {
                                label: "Basic",
                                value: "basic"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "any",
                        name: "ProductWarrantyType",
                        label: "Product warranty type",
                        visible: values => values.ProductLodgementType === 'regular',
                        validations: Validator.create().required('Warranty is required'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "Exchange",
                                value: "exchange"
                            },
                            {
                                label: "Repair",
                                value: "repair"
                            },
                            {
                                label: "Any",
                                value: "any"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "any",
                        label: "Low price cut off",
                        name: "LPCO",
                        visible: values => values.ProductLodgementType === 'regular' && values.ProductWarrantyType === 'repair' && values.ProductPremiumService !== 'concierge_service' && values.ProductPremiumService !== 'gold_service',
                        validations: Validator.create().required('LPCO is required'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "LPCO",
                                value: "yes"
                            },
                            {
                                label: "Non LPCO",
                                value: "no"
                            },
                            {
                                label: "Any",
                                value: "any"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        inline: false,
                        name: "Warranty",
                        label: "Warranty",
                        visible: values => Boolean(values.retailer_id),
                        validations: Validator.create().required('Warranty is required'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "Inside DOA period",
                                value: "doa"
                            },
                            {
                                label: "Ouside DOA but inside warranty",
                                value: "warranty",
                            },
                            {
                                label: "Outside warranty but inside ACL",
                                value: "acl"
                            },
                            {
                                label: "Out of all warranty periods",
                                value: "oow"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        inline: false,
                        value: "both",
                        name: "DoaWarranty",
                        label: "Blue brouchure DOA",
                        visible: values => values.Warranty === 'doa',
                        validations: Validator.create().requiredIf('Warranty', 'doa'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "Inside vendor DOA period",
                                value: "vendor"
                            },
                            {
                                label: "Inside Retailer DOA period",
                                value: "retailer",
                            },
                            {
                                label: "Any",
                                value: "both"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "any",
                        label: "Abused",
                        name: "FaultAbuse",
                        valueType: "number",
                        visible: values => Boolean(values.retailer_id),
                        validations: Validator.create().required('Abused is required'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "Yes",
                                value: 1
                            },
                            {
                                label: "No",
                                value: 0
                            },
                            {
                                label: "Any",
                                value: "any"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "no",
                        label: "Solved by Troubleshooting",
                        name: "TroubleshootingSuccess",
                        visible: values => Boolean(values.retailer_id),
                        validations: Validator.create().required('Troubleshooting question is required'),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "Yes",
                                value: 'yes'
                            },
                            {
                                label: "No",
                                value: 'no'
                            }
                        ]
                    }
                ]
            },
            {
                id: "optionalFields",
                title: "Optional Fields",
                useRenderer: true,
                config: [
                    {
                        type: "alert",
                        layout: "full",
                        variant: "secondary",
                        visible: values => !Boolean(values.retailer_id),
                        content: (
                            <>
                                <strong>Note: </strong>
                                Please select a retailer first to populate this list.
                            </>
                        )
                    },
                    {
                        type: "radio",
                        value: "any",
                        valueType: "boolean",
                        name: "ProductInStoreAssessmentEligible",
                        label: "Product in store assessment eligible",
                        visible: values => Boolean(values.retailer_id),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "Yes",
                                value: true
                            },
                            {
                                label: "No",
                                value: false
                            },
                            {
                                label: "Any",
                                value: "any"
                            }
                        ]
                    },
                    {
                        type: "radio",
                        value: "any",
                        valueType: "boolean",
                        name: "ProductDamageAssessmentEligible",
                        label: "Product damage assessment eligible",
                        visible: values => Boolean(values.retailer_id),
                        disabled: values => values.id !== undefined && values.replicate === false,
                        values: [
                            {
                                label: "Yes",
                                value: true
                            },
                            {
                                label: "No",
                                value: false
                            },
                            {
                                label: "Any",
                                value: "any"
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

export default config;