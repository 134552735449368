import React, { Component } from "react";
import Box from "../../../components/Box";
import Tabs from "../../../components/Tabs";
import Form from "../../../components/Form";
import Alert from "../../../components/Alert";
import NotFound from "../../../components/404";
import { PageService } from "../../../services";
import Confirm from "../../../components/Confirm";
import Spinner from "../../../components/Spinner";
import { Validator } from "../../../utils/Validator";
import Changelog from "../../../components/Changelog";

class Add extends Component {
    constructor(props) {
        super(props);
        this.services = {
            page: new PageService(this)
        }

        this.state = {
            id: this.props.match.params.id,
            isLoading: this.props.match.params.id !== undefined,
            error: null,
            variant: "danger",
            message: null,
            data: {},
            preventUnload: true,
            confirmModal: {
                show: false,
                message: "Are you sure want to delete the page ?",
                onConfirm: () => this.deletePage(),
                onCancel: () => this.closeConfirmModal()
            }
        }
    }

    componentDidMount() {
        if (this.state.id !== undefined) {
            this.services.page.get(this.state.id);
        }
    }

    submitHandler = (values, setSubmitting, setErrors) => {
        delete values.uid;
        if (this.state.id !== undefined) {
            this.services.page.update(this.state.id, values, setSubmitting, setErrors);
        } else {
            this.services.page.create(values, setSubmitting, setErrors);
        }
    }

    closeConfirmModal = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: false
            }
        }));
    }

    deleteHandler = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: true
            }
        }));
    }

    // Delete page finally
    deletePage = () => {
        this.setState(prev => ({
            preventUnload: false,
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            this.services.page.delete(this.state.id);
        });
    }

    render() {
        if (this.state.pageNotFound) {
            return <NotFound />
        }

        if (this.state.error) {
            return (
                <Alert variant="danger">
                    {this.state.error}
                </Alert>
            )
        }

        return (
            <Spinner isLoading={this.state.isLoading}>
                <Box
                    allowFullScreen
                    title={this.state.id !== undefined ? "Update Page" : "Add Page"}
                    buttons={[{
                        type: "button",
                        value: "Delete",
                        class: "btn btn-sm btn-danger",
                        show: this.state.id !== undefined && this.state.isLoading === false,
                        clicked: this.deleteHandler
                    }, {
                        type: "link",
                        href: "/ui/pages/add",
                        value: "Add new",
                        class: "btn btn-sm btn-info",
                        show: this.state.id !== undefined
                    }]}>
                    <Tabs active="details">
                        <Tabs.Pane id="details" title="Details">
                            <Form
                                initialValues={{
                                    name: this.state.data.name,
                                    uid: this.state.data.uid,
                                    description: this.state.data.description
                                }}
                                onSubmit={this.submitHandler}
                                preventUnload={this.state.preventUnload}
                                validationSchema={{
                                    name: Validator.create().required('Name is required')
                                }}>
                                {this.state.message &&
                                    <Alert variant={this.state.variant} dismiss={() => {
                                        this.setState({
                                            message: null
                                        });
                                    }}>
                                        {this.state.message}
                                    </Alert>
                                }

                                <div className="row">
                                    <div className={"col-xs-12 " + (this.state.id ? 'col-md-6' : '')}>
                                        <Form.Group name="name" label="Name" required>
                                            <Form.Input type="text" placeholder="Enter name" id="name" name="name" />
                                        </Form.Group>
                                    </div>

                                    {this.state.id &&
                                        <div className="col-md-6 col-xs-12">
                                            <Form.Group name="uid" label="Unique ID" required>
                                                <Form.Input type="text" name="uid" disabled />
                                            </Form.Group>
                                        </div>
                                    }

                                    <div className="col-xs-12">
                                        <Form.Group name="description" label="Description">
                                            <Form.Textarea type="textarea" name="description" placeholder="Describe here..." rows="8" />
                                        </Form.Group>
                                    </div>

                                    <div className="col-xs-12">
                                        <Form.Submit />
                                    </div>
                                </div>

                                {(this.state.id && this.state.confirmModal.show) &&
                                    <Confirm {...this.state.confirmModal} />
                                }
                            </Form>
                        </Tabs.Pane>

                        {this.state.id &&
                            <Tabs.Pane id="changelog" title="Changelog">
                                <Changelog />
                            </Tabs.Pane>
                        }
                    </Tabs>
                </Box>
            </Spinner>
        )
    }
}

export default Add;