import axios from "axios";
import UserContext from "../contexts/user-context";

const PublicApi = axios.create({
    timeout: 30000,
    baseURL: process.env.REACT_APP_GATEWAY_URL
});

// Handle all responses other than 200 range
PublicApi.interceptors.response.use(undefined, error => {
    let message = null;
    let statusCode = 500;
    let response = null;
    if (error.response) {
        response = error.response;
        statusCode = error.response.status;
        switch (statusCode) {
            case 400:
                message = "The server could not understand the request due to invalid params or syntax."
                break;
            case 404:
                message = "Request failed with status code 404"
                break;
            case 422:
                message = "Request failed with status code 422 Unprocessable Entity"
                break;
            case 500:
                message = "500 Internal Server Error. Something went wrong. Please ask your Solvup assistance to get this sorted."
                break;
            default:
                message = "Something went wrong. Please try later or ask your Solvup assistance to get this sorted."
                break;
        }
    } else if (error.request) {
        if (window.navigator && window.navigator.onLine === false) {
            message = 'The request was made but no response was received. It might be network issue or the page you requested does not exist.';
        } else {
            statusCode = 404;
            message = "Request failed with status code 404";
        }
    } else {
        // Something happened in setting up the request that triggered an Error
        // Might be internet problem
        message = 'Check your internet connection and try again later';
        console.error(message);
    }

    return Promise.reject({ message, statusCode, response });
});

const SecuredApi = axios.create({
    timeout: 30000,
    baseURL: process.env.REACT_APP_GATEWAY_URL,
    headers: {
        post: {
            'Content-Type': 'application/json'
        },
        put: {
            'Content-Type': 'application/json'
        }
    }
});

SecuredApi.interceptors.request.use((config, error) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
});

// Handle all responses other than 200 range
SecuredApi.interceptors.response.use(undefined, error => {
    let message = null;
    let statusCode = 500;
    let response = null;
    if (error.response) {
        response = error.response;
        statusCode = error.response.status;
        switch (statusCode) {
            case 400:
                message = "The server could not understand the request due to invalid params or syntax."
                break;
            case 401:
                message = "The client must authenticate itself to get the requested response."
                break;
            case 403:
                message = "The client does not have access rights to the content."
                break;
            case 404:
                message = "Request failed with status code 404"
                break;
            case 422:
                message = "Request failed with status code 422 Unprocessable Entity"
                break;
            case 500:
                message = "500 Internal Server Error. Something went wrong. Please ask your Solvup assistance to get this sorted."
                break;
            default:
                message = "Something went wrong. Please try later or ask your Solvup assistance to get this sorted."
                break;
        }
    } else if (error.request) {
        if (window.navigator && window.navigator.onLine === false) {
            message = 'The request was made but no response was received. It might be network issue or the page you requested does not exist.';
        } else if (error.message === 'Network Error') {
            message = (
                <UserContext.Consumer>
                    {context => {
                        if (context.isLoggedIn === true) {
                            context.logout();
                        } else {
                            return "Network error";
                        }
                    }}
                </UserContext.Consumer>
            )
        } else {
            statusCode = 404;
            message = "Request failed with status code 404";
        }
    } else {
        // Something happened in setting up the request that triggered an Error
        // Might be internet problem
        message = 'Check your internet connection and try again later';
        console.error(message);
    }

    return Promise.reject({ message, statusCode, response });
});

export { PublicApi, SecuredApi };