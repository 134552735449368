import React from "react";
import { FormContext } from ".";

export const Group = ({ name, label = null, className = '', hint = null, children }) => (
    <FormContext.Consumer>
        {context => {
            const errors = context.errors[name];
            const validations = context.validationSchema[name] || null;
            const required = validations && (validations.has('required') || validations.has('requiredIf') || validations.has('requiredWith'));

            return (
                <div className={"form-group" + (errors ? ' has-error' : '') + ' ' + className}>
                    {label &&
                        <label htmlFor={name} className='col-form-label'>
                            <strong>
                                {label}
                                {required && <span className="text-danger"> *</span>}
                            </strong>
                        </label>
                    }
                    {children}
                    {errors && <span className="text-danger mt-1">{errors}</span>}
                    {hint && <p className="hint text-muted mt-1">{hint}</p>}
                </div>
            )
        }}
    </FormContext.Consumer>
)