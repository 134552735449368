import tab from "./tab.json";
import text from "./text.json";
import list from "./list.json";
import radio from "./radio.json";
import table from "./table.json";
import select from "./select.json";
import button from "./button.json";
import hidden from "./hidden.json";
import checkbox from "./checkbox.json";
import component from "./component.json";
import paragraph from "./paragraph.json";
import conditions from "./condition.json";
import navigation from "./navigation.json";
import datePicker from "./date-picker.json";
import fileUpload from "./file-upload.json";
import autocomplete from "./autocomplete.json";
import intervalTimer from "./interval-timer.json";
import barcodeScanner from "./barcode-scanner.json";
import copyToClipBoard from "./copy-to-clipboard.json";
import digitalSignature from "./digital-signature.json";
import inlineRadioGroup from "./inline-radio-group.json";
import multiCheckboxList from "./multi-checkbox-list.json";
import singleSelectionList from "./single-selection-list.json";
import multiSelectMultiTypeList from "./multi-select-multi-type-list.json";

export const schema = {
    tab,
    list,
    radio,
    table,
    select,
    hidden,
    button,
    checkbox,
    component,
    conditions,
    navigation,
    autocomplete,
    "text": text,
    "number": text,
    "password": text,
    "textarea": text,
    "paragraph": paragraph,
    "date-picker": datePicker,
    "file-upload": fileUpload,
    "interval-timer": intervalTimer,
    "barcode-scanner": barcodeScanner,
    "google-address-auto-complete": text,
    "copy-to-clipboard": copyToClipBoard,
    "digital-signature": digitalSignature,
    "inline-radio-group": inlineRadioGroup,
    "multi-checkbox-list": multiCheckboxList,
    "single-selection-list": singleSelectionList,
    "multi-select-multi-type-list": multiSelectMultiTypeList
};