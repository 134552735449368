import React from "react";

const Pagination = ({ totalPages = 0, currentPage = 0, clicked }) => {
    if (totalPages < 2) {
        return null;
    }

    let links = [];
    if (currentPage > 1) {
        links.push(
            <li className="page-item" key="previous">
                <button className="page-link" onClick={() => clicked(currentPage - 1)}>
                    <i className='fa fa-arrow-left'></i> Previous
                </button>
            </li>
        );
    }

    for (let page = currentPage - 2; page <= currentPage + 2; page++) {
        if (page > 0 && page <= totalPages) {
            links.push(
                <li key={page} className={currentPage === page ? 'page-item active' : 'page-item'}>
                    <button className="page-link" onClick={() => clicked(page)}>
                        {page}
                    </button>
                </li>
            );
        }
    }

    if (currentPage < totalPages) {
        links.push(
            <li className="page-item" key="next">
                <button className="page-link" onClick={() => clicked(currentPage + 1)}>
                    Next <i className='fa fa-arrow-right'></i>
                </button>
            </li>
        );
    }

    return links ? (
        <nav aria-label="Navigation" className="float-end">
            <ul className="pagination">
                {links}
            </ul>
        </nav>
    ) : null;
}

export default Pagination;