import React, { memo } from "react";
import { Handle, Position } from "react-flow-renderer";

const EndNode = ({
  data,
  isConnectable,
  sourcePosition = Position.Bottom
}) => (
  <>
    <div className="react-flow__label">{data.label}</div>
    <Handle id="input" type="target" position={Position.Top} isConnectable={isConnectable} />
  </>
)

EndNode.displayName = "EndNode";

export default memo(EndNode);