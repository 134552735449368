import React, { Component } from "react";
import { Link } from "react-router-dom";
import List from "../../../../components/List";
import Alert from "../../../../components/Alert";
import { RuleService } from "../../../../services";
import Confirm from "../../../../components/Confirm";
import SeeMore from "../../../../components/SeeMore";
import ContentEditable from "../../../../components/ContentEditable";
import PrintObject from "../../../../components/Solvup/PrintObject";
import { retailerList } from "../../../../contexts/retailer-context";

class Actions extends Component {
    static defaultProps = {
        access: true,
        actions: [],
        onDelete: (deletedId, actions) => { }
    }

    constructor(props) {
        super(props);
        this.services = {
            rule: new RuleService(this)
        }

        this.state = {
            message: null,
            variant: "success",
            timestamp: + new Date(),
            actions: this.props.actions,
            confirmModal: {
                show: false,
                message: "Are you sure want to delete the return value ?",
                onConfirm: () => { },
                onCancel: () => this.closeConfirmModal()
            }
        }
    }

    // Call this using ref from outside
    updateActions = (actions = [], message = null) => {
        this.setState({
            actions,
            message,
            variant: "success",
            timestamp: + new Date()
        });
    }

    closeConfirmModal = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: false
            }
        }));
    }

    deleteHandler = id => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: true,
                onConfirm: () => this.deleteAction(id)
            }
        }));
    }

    // Delete action finally
    deleteAction = id => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            this.services.rule.deleteReturnValue(this.props.ruleID, id);
        });
    }

    renderValue = (type, value) => {
        switch (type.toLowerCase()) {
            case 'html':
                value = value ? (
                    <div className="card">
                        <div className="card-body dangerous-html" dangerouslySetInnerHTML={{ __html: value }}></div>
                    </div>
                ) : '';
                break;
            case 'api':
                value = (
                    <Link to={"/api/update/" + value}>
                        {'API Call - ' + value}
                    </Link>
                )
                break;
            case 'function':
                value = 'Function Call - ' + value;
                break;
            case 'object':
                let data = {};
                if (Array.isArray(value) && value.length) {
                    value.forEach(obj => {
                        const type = obj.value_type;
                        if (type === 'number') {
                            data[obj.key] = Number(obj.value);
                        } else if (type === 'boolean') {
                            data[obj.key] = obj.value === 'true';
                        } else {
                            data[obj.key] = obj.value;
                        }
                    });
                }

                value = <PrintObject data={data} disabled />;
                break;
            case 'array':
                let array = [];
                if (Array.isArray(value) && value.length) {
                    value.forEach(obj => {
                        const type = obj.value_type;
                        if (type === 'number') {
                            array.push(Number(obj.value));
                        } else if (type === 'boolean') {
                            array.push(obj.value === 'true');
                        } else {
                            array.push(obj.value);
                        }
                    });
                }

                value = (
                    <ContentEditable disabled>
                        <SeeMore>
                            <pre>{JSON.stringify(array)}</pre>
                        </SeeMore>
                    </ContentEditable>
                )
                break;
            case 'boolean':
                value = value === true ? 'true' : 'false';
                break;
            case 'string':
            default:
                break;
        }

        return value;
    }

    render() {
        return (
            <>
                {this.state.message &&
                    <Alert variant={this.state.variant} dismiss={() => {
                        this.setState({
                            message: null
                        });
                    }}>
                        {this.state.message}
                    </Alert>
                }

                <List
                    key={this.state.timestamp}
                    data={this.state.actions}
                    columns={[
                        {
                            name: "Retailer",
                            filterKey: "retailer_id",
                            width: 100,
                            selector: row => row.retailer_id ? retailerList[row.retailer_id] : 'ALL',
                            sortable: true
                        },
                        {
                            name: "Type",
                            filterKey: "user_type",
                            width: 70,
                            selector: row => row.user_type || 'ALL',
                        },
                        {
                            name: "Category",
                            filterKey: "category_detail.name",
                            width: 120,
                            selector: row => row.category_detail.name,
                            sortable: true
                        },
                        {
                            name: "Key",
                            filterKey: "key_column",
                            width: 100,
                            selector: row => row.key_column,
                            sortable: true
                        },
                        {
                            name: "Value",
                            filterKey: "value_column",
                            selector: row => this.renderValue(row.value_type, row.value_column)
                        },
                        {
                            name: "Sandbox",
                            width: 100,
                            selector: row => <i className={`fa fa-${row.is_sandbox_mode ? 'check-circle text-success' : 'times-circle text-danger'}`}></i>
                        },
                        {
                            name: "Created",
                            width: 130,
                            title: row => new Date(row.created_at).toString(),
                            selector: row => new Date(row.created_at).toDateString(),
                            sortable: true
                        },
                        {
                            name: "Updated",
                            width: 130,
                            title: row => new Date(row.updated_at).toString(),
                            selector: row => new Date(row.updated_at).toDateString(),
                            sortable: true
                        },
                        {
                            name: "Actions",
                            width: 110,
                            selector: row => (
                                <>
                                    <button className="btn btn-default btn-xs m-r-5" title="Edit" onClick={e => this.props.editHandler(row)}>
                                        Edit
                                    </button>
                                    <button className="btn btn-danger btn-xs m-r-5" title="Delete" onClick={() => this.deleteHandler(row.id)}>
                                        Delete
                                    </button>
                                </>
                            )
                        }
                    ]} />

                {this.state.confirmModal.show &&
                    <Confirm {...this.state.confirmModal} />
                }
            </>
        )
    }
}

export default Actions;