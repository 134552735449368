import React, { Component } from "react";
import { Link } from "react-router-dom";
import List from "../../../components/List";
import Box from "../../../components/Box";
import Spinner from "../../../components/Spinner";
import { EventService } from "../../../services/";

class Event extends Component {
    constructor(props) {
        super(props);
        this.services = {
            event: new EventService(this)
        }

        this.state = {
            data: [],
            error: null,
            isLoading: true,
            perPage: 300
        }
    }

    componentDidMount() {
        this.services.event.list({ "limit": this.state.perPage, "offset": 0 });
    }

    getColumns = () => [
        {
            name: "ID",
            width: 50,
            selector: row => row.id,
            sortable: true
        },
        {
            name: "Name",
            selector: row => row.event,
            sortable: true
        },
        {
            name: "Tags",
            selector: row => {
                if (row.tags && typeof row.tags === 'string') {
                    const tagsArray = row.tags.split(',');
                    return tagsArray.map((tag, index) => {
                        tag = tag.trim();

                        return tag ? (
                            <span key={index} className="badge badge-primary badge-pill m-r-5 m-b-5"> {tag} </span>
                        ) : null;
                    });
                }
            }
        },
        {
            name: "Actions",
            width: 80,
            selector: row => (
                <Link className="btn btn-default btn-xs m-r-5" to={'/event/' + row.id}>
                    Edit
                </Link>
            )
        }
    ];

    filterHandler = (search) => {
        this.setState({ isLoading: true });
        this.services.event.list({ "event": search, "limit": this.state.perPage, "offset": 0 });
    }

    render() {
        return (
            <Box
                title='Events'
                buttons={{
                    value: '+ Add',
                    type: 'link',
                    class: 'btn btn-success',
                    href: '/event/add'
                }}>
                <Spinner isLoading={this.state.isLoading}>
                    {this.state.error
                        ? <div className="alert alert-danger alert-bordered">
                            {this.state.error}
                        </div>
                        : <List columns={this.getColumns()} data={this.state.data} onFilter={this.filterHandler} />
                    }
                </Spinner>
            </Box>
        );
    }
}

export default Event;