import { SecuredApi as axios } from "../services/axios";

const useProcessManagement = () => {
	async function processList() {
		const response = await axios.get("/process");
		return response.data;
	}
	async function getProcess(id, type = "process") {
		const url = `/${type}/${id}`;
		const response = await axios.get(url);
		return response.data;
	}

	async function create(data, type = "process") {
		const url = `/${type}`;
		const response = await axios.post(url, data);
		return response.data.data;
	}

	async function update(id, data, type = "process") {
		const url = `/${type}/${id}`;
		const response = await axios.put(url, data);
		return response.data.data;
	}

	async function remove(id, type = "process") {
		const url = `/${type}/${id}`;
		try {
			const response = await axios.delete(url);
			return response.data.data;
		} catch ({ message, statusCode }) {
			return { Status: statusCode };
		}
	}

	async function pageList() {
		try {
			const response = await axios.get("/pages");
			return response.data;
		} catch ({ message, statusCode }) {
			return { Status: statusCode, message };
		}
	}

	async function externalActionsList() {
		try {
			const response = await axios.get(
				"/apiconfiguration?is_external_action=1"
			);
			return response.data;
		} catch ({ message, statusCode }) {
			return { Status: statusCode, message };
		}
	}

	async function flowList() {
		try {
			const response = await axios.get("/flows");
			return response.data;
		} catch ({ message, statusCode }) {
			return { Status: statusCode, message };
		}
	}

	return {
		processList,
		create,
		pageList,
		externalActionsList,
		getProcess,
		update,
		flowList,
		remove,
	};
};

export default useProcessManagement;
