import React, { Component } from "react";
import { RuleService } from "../../../../services";
import Box from "../../../../components/Box";
import Form from "../../../../components/Form";
import { Validator } from "../../../../utils/Validator";
import { SecuredApi as axios } from "../../../../services/axios";
import RetailerContext from "../../../../contexts/retailer-context";
import KeyValueType from "../../../../components/KeyValueType";
import Alert from "../../../../components/Alert";

class AddAction extends Component {
    static defaultProps = {
        actions: [],
        onAdd: (action, actions) => { },
        onUpdate: (action, actions) => { }
    }

    constructor(props) {
        super(props);
        this.services = {
            rule: new RuleService(this)
        }

        this.formRef = React.createRef();

        this.keyValueData = {};

        this.values = {
            "string": ""
        };

        this.options = {
            string: "String",
            number: "Number",
            boolean: "Boolean",
            array: "Array",
            object: "Object",
            html: "HTML",
            null: "NULL",
            twig: "Twig",
            api: "API",
            function: "Function",
            business_term: "Business Term",
        }

        this.state = {
            error: null,
            data: {},
            timestamp: + new Date(),
            action: {
                value_type: "string",
                is_sandbox_mode: 0
            },
            actions: this.props.actions
        }
    }

    // Get all required data to add/update action
    componentDidMount() {
        Promise.all([
            axios.get('/rules/categories'),
            axios.get('/apiconfiguration'),
            axios.get('/api/supported-functions')
        ]).then(values => {
            this.setState({
                data: {
                    categories: values[0].data.data.Data.items,
                    apis: values[1].data.data.Data.items,
                    functions: values[2].data.data.Data.DerivedHelper
                }
            });
        }).catch(error => {
            console.error(error);
            let message = null;
            if (error instanceof Error) {
                message = "Something went wrong. Check console for detailed error. Please ask your Solvup assistance to fix that.";
            } else {
                message = "API with endpoint " + error.response.config.url + ' failed with ' + error.response.status + ' ' + error.response.statusText;
            }

            this.setState({
                error: message
            });
        });
    }

    // Call this using ref from outside
    setActionForUpdate = (action = {}) => {
        this.values[action.value_type] = action.value_column;

        this.keyValueData = {};
        if (action.value_type === 'array' || action.value_type === 'object') {
            this.keyValueData[action.value_type] = action.value_column;
        } else if (action.value_type === 'function') {
            this.keyValueData['function'] = action.func_args;
        }

        const data = {
            id: action.id,
            key_column: action.key_column,
            value_type: action.value_type,
            value_column: action.value_column,
            category_id: action.category_detail.id,
            is_sandbox_mode: action.is_sandbox_mode || 0,
            retailer_id: action.retailer_id || '',
            user_type: action.user_type || '',
        }

        if (action.value_type === 'function') {
            data.func_args = action.func_args;
        }

        this.setState({
            action: data,
            type: action.value_type
        });
    }

    // Call this using ref from outside
    updateActions = (actions = []) => {
        this.setState({ actions });
    }

    renderOptions = () => {
        let options = [];
        for (const o in this.options) {
            options.push((
                <option key={o} value={o}>
                    {this.options[o]}
                </option>
            ))
        }

        return options;
    }

    submitHandler = (values, setSubmitting, setErrors) => {
        // Patch. Need to remove
        if (!('retailer_id' in values)) {
            values.retailer_id = 0;
        }

        // Patch. Need to remove
        if (!('user_type' in values)) {
            values.user_type = '';
        }

        const alreadyAdded = this.state.actions.filter(action => {
            if (this.state.action.id === action.id) {
                return false;
            }

            return action.retailer_id === values.retailer_id && action.user_type === values.user_type && action.category_detail.id === values.category_id && action.key_column.toLowerCase() === values.key_column.toLowerCase()
        });

        if (alreadyAdded.length > 0) {
            setSubmitting(false);
            this.setState({
                error: "Return value already exists for the combination of inputs"
            });
        } else {
            // Patch. Need to remove
            if (values.retailer_id === 0) {
                values.retailer_id = '';
            }

            const type = values['value_type'];
            if (type === 'function') {
                values['func_args'] = this.keyValueData['function'] || [];
            } else if (type === 'array') {
                values['value_column'] = this.keyValueData['array'] || [];
            } else if (type === 'object') {
                values['value_column'] = this.keyValueData['object'] || [];
            }

            if (this.state.action.id) {
                this.services.rule.updateReturnValue(this.props.ruleID, this.state.action.id, values, setSubmitting, setErrors);
            } else {
                this.services.rule.createReturnValue(this.props.ruleID, values, setSubmitting, setErrors);
            }
        }
    }

    render() {
        return (
            <>
                <Alert variant="secondary">
                    <strong><u>Points to consider:</u></strong>
                    <ul className='mt-2'>
                        <li className='mb-2'>Category, Retailer, User Type and Key can't be updated once saved. You need to delete return value and add again.</li>
                        <li className='mb-2'>Same Combination of Category, Retailer, User Type and Key can not be added again within same rule.</li>
                        <li className='mb-2'>User type matters only when Retailer is selected.</li>
                        <li className='mb-2'>No details provided when Array, Object or Function value type is selected, would be considered as empty arguments/key list.</li>
                    </ul>

                    {this.state.action.id &&
                        <div className="mt-2">
                            <hr />
                            <strong className="text-danger">Note:</strong> You are editing return value &nbsp;
                            <span className="badge bg-secondary"> {this.state.action.id}</span>
                            <span> Double click <strong>Cancel Editing</strong> button to cancel.</span>
                        </div>
                    }
                </Alert>

                <Form
                    id="addReturnValueForm"
                    key={this.state.action.id || this.state.timestamp}
                    ref={this.formRef}
                    showErrorList={true}
                    initialValues={this.state.action}
                    onSubmit={this.submitHandler}
                    validationSchema={{
                        category_id: Validator.create().required('Category is required'),
                        key_column: Validator.create().required('Key column is required'),
                        value_type: Validator.create().required('Value type is required'),
                        value_column: Validator.create().required('Value column is required'),
                        retailer_id: Validator.create().requiredWith('user_type', 'Retailer is required when user type is selected'),
                        user_type: Validator.create().requiredWith('retailer_id', 'User type is required when retailer is selected')
                    }}>
                    {({ values, setValues }) => (
                        <>
                            {this.state.error &&
                                <Alert variant="danger" dismiss={() => {
                                    this.setState({
                                        error: null
                                    });
                                }}>
                                    {this.state.error}
                                </Alert>
                            }

                            <div className="row">
                                <div className="col-xs-12">
                                    <Form.Group name="is_sandbox_mode" label="Sandbox Mode ?" required hint="If yes, Return value will not get executed for live stores and callcentres">
                                        <Form.Radio name="is_sandbox_mode" values={[
                                            {
                                                "label": "No",
                                                "value": 0
                                            },
                                            {
                                                "label": "Yes",
                                                "value": 1
                                            }
                                        ]} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-xs-12">
                                    <Form.Group name="category_id" label="Category" required>
                                        <Form.Select id="category_id" name="category_id" disabled={this.state.action.id}>
                                            {this.state.data.categories && this.state.data.categories.map(category => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-xs-12">
                                    <Form.Group name="retailer_id" label="Retailer">
                                        <Form.Select id="retailer_id" name="retailer_id" disabled={this.state.action.id}>
                                            <RetailerContext.Consumer>
                                                {value => (
                                                    value.map(option => (
                                                        <option key={option.id} value={option.id}>{option.name}</option>
                                                    ))
                                                )}
                                            </RetailerContext.Consumer>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-xs-12">
                                    <Form.Group name="user_type" label="User Type">
                                        <Form.Select id="user_type" name="user_type" disabled={this.state.action.id}>
                                            <option value="all">All</option>
                                            <option value="store">Store</option>
                                            <option value="callcentre">Callcentre</option>
                                            <option value="online">Online</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-xs-12">
                                    <Form.Group name="key_column" label="Key">
                                        <Form.Input type="text" placeholder="Start typing here..." id="key_column" name="key_column" disabled={this.state.action.id} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 col-xs-12">
                                    <Form.Group name="value_type" label="Type">
                                        <Form.Select id="value_type" name="value_type" onChange={e => {
                                            const type = e.target.value;
                                            const value = values.value_column;

                                            if (!this.state.action.id && (type === 'array' || type === 'object')) {
                                                setValues({
                                                    value_type: type,
                                                    value_column: [],
                                                });
                                            }

                                            if (type === 'number') {
                                                setValues({
                                                    value_type: type,
                                                    value_column: ""
                                                });
                                            }

                                            if (type === 'boolean' && (value !== 'true' && value !== 'false')) {
                                                setValues({
                                                    value_type: type,
                                                    value_column: "true"
                                                });
                                            }

                                            if (type === 'null') {
                                                setValues({
                                                    value_type: type,
                                                    value_column: "null"
                                                });
                                            }
                                        }}>
                                            {this.renderOptions()}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className={"col-xs-12 " + (values.value_type === 'html' ? 'col-md-12' : 'col-md-6')}>
                                    {(values.value_type === 'string' || values.value_type === 'business_term') &&
                                        <Form.Group name="value_column" label="Value">
                                            <Form.Input type="text" name="value_column" id="value_column" placeholder="Enter value" />
                                        </Form.Group>
                                    }

                                    {values.value_type === 'number' &&
                                        <Form.Group name="value_column" label="Value">
                                            <Form.Input type="number" name="value_column" id="value_column" placeholder="Enter value" />
                                        </Form.Group>
                                    }

                                    {(values.value_type === 'twig') &&
                                        <Form.Group name="value_column" label="Value">
                                            <Form.Textarea name="value_column" id="value_column" placeholder="Enter value" />
                                            <p className="hint text-muted mt-1">
                                                For detailed information about Twig, visit <a href="https://twig.symfony.com/doc/3.x/templates.html" target="_blank" rel="noopener noreferrer">this link</a>
                                            </p>
                                        </Form.Group>
                                    }

                                    {values.value_type === 'html' &&
                                        <Form.Group name="value_column" label="Value">
                                            <Form.HTMLEditor name="value_column" />
                                        </Form.Group>
                                    }

                                    {values.value_type === 'boolean' &&
                                        <Form.Group name="value_column" label="Value">
                                            <Form.Select name="value_column" id="value_column">
                                                <option value={true}>true</option>
                                                <option value={false}>false</option>
                                            </Form.Select>
                                        </Form.Group>
                                    }

                                    {values.value_type === 'api' &&
                                        <Form.Group name="value_column" label="Value">
                                            <Form.Select placeholder="---Select API---" name="value_column" id="value_column">
                                                {this.state.data.apis && this.state.data.apis.map(option => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    }

                                    {values.value_type === 'function' &&
                                        <Form.Group name="value_column" label="Value">
                                            <Form.Select placeholder="---Select Function---" name="value_column" id="value_column">
                                                {this.state.data.functions && this.state.data.functions.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    }
                                </div>
                                <div className="col-xs-12 mt-2">
                                    <Box title="Function Details" className={"ibox-child " + (values.value_type === 'function' ? '' : 'd-none')}>
                                        <KeyValueType
                                            variant="function"
                                            data={values.value_type === 'function' ? this.state.action.func_args : []}
                                            onUpdate={data => {
                                                this.keyValueData['function'] = data;
                                            }} />
                                    </Box>

                                    <Box title="Array Details" className={"ibox-child " + (values.value_type === 'array' ? '' : 'd-none')}>
                                        <KeyValueType
                                            variant="array"
                                            data={values.value_type === 'array' ? this.state.action.value_column : []}
                                            onUpdate={data => {
                                                this.keyValueData['array'] = data;
                                            }} />
                                    </Box>

                                    <Box title="Object Details" className={"ibox-child " + (values.value_type === 'object' ? '' : 'd-none')}>
                                        <KeyValueType
                                            variant="object"
                                            data={values.value_type === 'object' ? this.state.action.value_column : []}
                                            onUpdate={data => {
                                                this.keyValueData['object'] = data;
                                            }} />
                                    </Box>
                                </div>

                                <div className="col-xs-12 mt-2">
                                    <button type="submit" className="btn btn-success m-r-5">
                                        Save
                                    </button>
                                    {this.state.action.id &&
                                        <button type="button" className="btn btn-danger" onDoubleClick={() => {
                                            this.keyValueData = {};
                                            this.setState({
                                                error: null,
                                                action: {
                                                    value_type: "string",
                                                    is_sandbox_mode: 0
                                                }
                                            });
                                        }}>
                                            Cancel Editing
                                        </button>
                                    }
                                </div>
                            </div>
                        </>
                    )}
                </Form>
            </>
        )
    }
}

export default AddAction;