import React from 'react';
import Select from 'react-select';
import { HTMLEditor } from '../../../../components/Form/HTMLEditor';
import { SecuredApi as axios } from "../../../../services/axios";

const PAPERWORKContent = (props) => {
    const [paperwork, setPaperwork] = React.useState({
        loading: false, 
        searchInput: "", 
        loadingMessage: "Searching paperwork content...", 
        noOptionsMessage: "No paperwork options available", 
        create: {
            show: false, 
            isLoad: false, 
            fields: {
                value: "xyz", 
                label: "", 
                content: ""
            },
        }, 
        selectedOptions: [], 
        searchedOptions: [],
        defaultOptions: [
          
        ], 
        fullOptions: [
           
        ]
    });

    const savePaperworkTemplate = (e) => {

        setPaperwork({...paperwork, create: {...paperwork.create, isLoad: true}});

        console.log(paperwork.create)
        const values = {};
        values.html_content = paperwork.create.fields.content;
        values.is_enabled = 1;
        const response = axios.post('commpack/paperwork',values);
        response
        .then(res => {
            const selected = {'value' : res.data.data.Data.id, 'label' : res.data.data.Data.html_content};
            const newSelectedOptions = [...paperwork.selectedOptions, selected];

            setPaperwork({
                ...paperwork, 
                selectedOptions: newSelectedOptions, 
                create: {
                    show: false, 
                    isLoad: false, 
                    fields: {
                        value: "xyz", 
                        label: "", 
                        content: ""
                    }
                }
            });
            props.pluckPaperworks(newSelectedOptions);
        })
        .catch(err => {
            // console.log(err);
            setPaperwork({...paperwork, create: {...paperwork.create, isLoad: false}});
        });
    }

    React.useEffect(() => {
        // console.log(paperwork.searchInput);
        const debounceSearch = setTimeout(() => {
            if (paperwork.searchInput.trim() !== "") {
                // console.log(paperwork.searchInput);
                setPaperwork({...paperwork, loading: true});
                // Axios call to SEARCH paperwork templates by Names
                const response = new Promise(resolve => {
                    setTimeout(() => {
                        const filterSearch = paperwork.fullOptions.filter(opt => opt.label.match(paperwork.searchInput));
                        resolve(filterSearch);
                    }, 3000);
                });

                response
                // axios.get(URL)
                .then(res => {
                    // console.log(res);
                    setPaperwork({...paperwork, searchedOptions: res, loading: false});
                })
                .catch(err => {
                    // console.log(err);
                    setPaperwork({...paperwork, searchedOptions: [], loading: false});
                });
            }
        }, 1000);

        return () => clearTimeout(debounceSearch);
    }, [paperwork.searchInput]);

    React.useEffect(() => setPaperwork({...paperwork, selectedOptions: props.assignedPaperworks}), [props.assignedPaperworks]);

    React.useEffect(() => setPaperwork({...paperwork, defaultOptions: props.fullOptions.defaultList}), [props.fullOptions]);

    return (
        <>
            <div className="col-12 mb-2">
                <Select 
                    isMulti 
                    isSearchable 
                    closeMenuOnSelect={false} 
                    onSelectResetsInput={false} 
                    isLoading={paperwork.loading} 
                    value={paperwork.selectedOptions} 
                    inputValue={paperwork.searchInput} 
                    loadingMessage={() => paperwork.loadingMessage} 
                    noOptionsMessage={() => paperwork.noOptionsMessage} 
                    onChange={selectedOptions => {
                        setPaperwork({...paperwork, selectedOptions});
                        props.pluckPaperworks(selectedOptions);
                    }} 
                    onInputChange={(value, reason) => {
                        if (
                            reason.action === "set-value" ||
                            reason.action === "input-blur" ||
                            reason.action === "menu-close"
                        ) {
                            return;
                        }

                        setPaperwork({...paperwork, searchInput: value});
                    }} 
                    options={paperwork.searchInput !== "" ? paperwork.searchedOptions : paperwork.defaultOptions} 
                />
            </div>
            {
                paperwork.create.show 
                    ? <div className="col-12">
                        <div className="card">
                            <div className="card-header fw-bold fs-2">Add Paperwork Content</div>
                            <div className="card-body">
                                <div>
                                    <label htmlFor="paperWorkName" className="form-label">Name</label>
                                    <input 
                                        type="text" 
                                        id="paperWorkName" 
                                        name="paperWorkName" 
                                        className="form-control" 
                                        value={paperwork.create.fields.label} 
                                        onChange={e => {
                                            setPaperwork({
                                                ...paperwork, 
                                                create: {
                                                    ...paperwork.create, 
                                                    fields: {
                                                        ...paperwork.create.fields, 
                                                        label: e.target.value
                                                    }
                                                }
                                            });
                                        }} 
                                    />
                                </div>
                                <div>
                                    <label htmlFor="paperworkContent" className="form-label">Content</label>
                                    <HTMLEditor 
                                        id="paperworkContent" 
                                        name="paperworkContent" 
                                        className="form-control" 
                                        value={paperwork.create.fields.content} 
                                        disabled={props.mode === "preview" && true} 
                                        onChange={value => {
                                            setPaperwork({
                                                ...paperwork, 
                                                create: {
                                                    ...paperwork.create, 
                                                    fields: {
                                                        ...paperwork.create.fields, 
                                                        content: value
                                                    }
                                                }
                                            });
                                        }} 
                                    />
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-end gap-3">
                                <button 
                                    onClick={savePaperworkTemplate} 
                                    disabled={
                                        paperwork.create.isLoad 
                                        || (
                                            paperwork.create.fields.label === "" 
                                            || paperwork.create.fields.content === ""
                                        )
                                    } 
                                    className="btn btn-primary fw-bold py-1 px-4"
                                >{paperwork.create.isLoad ? "ADDING..." : "ADD"}</button>
                                <button 
                                    disabled={paperwork.create.isLoad} 
                                    className="btn btn-secondary fw-bold py-1 px-4" 
                                    onClick={() => setPaperwork({...paperwork, create: {...paperwork.create, show: false}})}
                                >CANCEL</button>
                            </div>
                        </div>
                    </div>
                    : <div className="col-auto text-muted">
                        Did not find the desired template?&nbsp;
                        <small 
                            className="link-info" 
                            onClick={() => {
                                setPaperwork({
                                    ...paperwork, 
                                    create: {
                                        ...paperwork.create, 
                                        show: true
                                    }
                                });
                            }} 
                        >Add Paperwork</small>
                    </div>
            }
        </>
    );
}

export default PAPERWORKContent;