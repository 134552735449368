import React, { useEffect } from "react";
import { Prompt } from "react-router";

const PreventUnload = ({ when = true, message = 'Changes that you made may not be saved.', children }) => {
    useEffect(() => {
        window.onbeforeunload = e => when ? false : null;
        return () => {
            window.onbeforeunload = null;
        }
    }, [when]);

    return (
        <>
            {children}
            <Prompt when={when} message={message} />
        </>
    )
}

export default PreventUnload;