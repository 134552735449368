import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "./config";
import inputs from "./inputs";
import outputs from "./outputs";
import Box from "../../../components/Box";
import Tabs from "../../../components/Tabs";
import Form from "../../../components/Form";
import List from "../../../components/List";
import Alert from "../../../components/Alert";
import NotFound from "../../../components/404";
import Confirm from "../../../components/Confirm";
import Spinner from "../../../components/Spinner";
import Renderer from "../../../components/Renderer";
import { ScenarioService } from "../../../services";
import { Validator } from "../../../utils/Validator";
import ComingSoon from "../../../components/ComingSoon";
import PrintObject from "../../../components/Solvup/PrintObject";

class AddScenario extends Component {
    constructor(props) {
        super(props);

        this.rule = React.createRef(null);

        this.configuration = [...inputs, ...outputs];
        this.validationSchema = this.getValidationSchema(this.configuration);
        this.validationSchema.is_sandbox_mode = Validator.create().required('Sandbox mode is required');

        this.state = {
            message: null,
            variant: "success",
            id: this.props.match.params.id,
            secret: this.props.match.params.secret,
            isLoading: this.props.match.params.id !== undefined,
            initialValues: this.getInitialValues(this.configuration),
            replicate: this.props.history.location.pathname.includes('replicate'),
            confirmModal: {
                show: false,
                message: "Are you sure want to delete the scenario ?",
                onConfirm: () => this.deleteScenario(),
                onCancel: () => this.closeConfirmModal()
            }
        }

        // Show loader and wait until secret values are decoded and loaded
        if (!this.state.id && this.state.secret) {
            this.state.isLoading = true;
        }

        this.services = {
            scenario: new ScenarioService(this)
        }
    }

    getInitialValues = data => {
        let values = {};
        data.forEach(value => {
            const key = value['name'];
            if ('value' in value) {
                values[key] = value.value;
            } else if (value.useRenderer === true) {
                values[key] = this.getInitialValues(value.config);
            } else if (value.type === 'tab') {
                value.values.forEach(item => {
                    if (item.useRenderer === true) {
                        values = { ...values, ...this.getInitialValues(item.config) };
                    }
                });
            }
        });

        return values;
    }

    getValidationSchema = data => {
        let schema = {};
        data.forEach(value => {
            const key = value['name'];
            if ('validations' in value) {
                schema[key] = value.validations;
            } else if (value.useRenderer === true) {
                schema[key] = this.getValidationSchema(value.config);
            } else if (value.type === 'tab') {
                value.values.forEach(item => {
                    if (item.useRenderer === true) {
                        schema = { ...schema, ...this.getValidationSchema(item.config) };
                    }
                });
            }
        });

        return schema;
    }

    getUpdatedRule = (values = {}) => {
        let rule = {
            tags: [],
            conditions: {
                AND: []
            },
            return_values: {}
        };

        Object.entries(config.inputs).forEach(([key, value]) => {
            if (key in values && values[key] !== 'any') {
                const row = new value().getCondition(values[key], values);
                if (row !== null) {
                    if (Array.isArray(row[1])) {
                        rule.conditions.AND = rule.conditions.AND.concat(row[1]);
                    } else {
                        rule.conditions.AND.push(row[1]);
                    }

                    rule.tags.push(row[0]);
                }
            }
        });

        config.outputs.forEach(item => {
            if (item in values) {
                rule.return_values[item] = values[item];
            }
        });

        this.rule.current = rule;
        return rule;
    }

    getValues = (tags, values = {}) => {
        tags = tags.split(', ');
        Object.entries(config.inputs).forEach(([key, value]) => {
            tags.forEach(tag => {
                const list = new value().tags;
                const found = Object.keys(list).find(key => list[key] === tag);
                if (found) {
                    values[key] = found || 'any';
                }
            });
        });
    }

    getColumns = () => [
        {
            name: "Tags",
            selector: row => {
                if (row.tags && typeof row.tags === 'string') {
                    const tagsArray = row.tags.split(',');
                    return tagsArray.map((tag, index) => {
                        tag = tag.trim();
                        return tag ? (
                            <span key={index} className="badge badge-primary badge-pill m-r-5 m-b-5"> {tag} </span>
                        ) : null;
                    });
                }
            }
        },
        {
            name: "Sandbox",
            width: 100,
            selector: row => <i className={`fa fa-${row.is_sandbox_mode ? 'check-circle text-success' : 'times-circle text-danger'}`}></i>
        },
        {
            name: "Created",
            width: 130,
            title: row => new Date(row.created_at).toString(),
            selector: row => new Date(row.created_at).toDateString(),
            sortable: true
        },
        {
            name: "Updated",
            width: 130,
            title: row => new Date(row.updated_at).toString(),
            selector: row => new Date(row.updated_at).toDateString(),
            sortable: true
        },
        {
            name: "Actions",
            width: 160,
            selector: row => (
                <>
                    <Link className="btn btn-default btn-xs m-r-5" to={this.state.scenario ? '/case-creation/scenarios/update/' + row.id : '/rules/update/' + row.id}>
                        Edit
                    </Link>
                    <button className="btn btn-default btn-xs m-r-5" onClick={e => {
                        e.target.closest('td').querySelector('.conditions').classList.toggle('d-none')
                    }}>
                        View conditions
                    </button>
                    <div className="conditions mt-2 d-none">
                        <PrintObject data={row.conditions} disabled />
                    </div>
                </>
            )
        }
    ]

    renderTags = (tags = [], filterSection = false) => (
        <div className="tags m-t-10">
            {tags.length > 0 &&
                <>
                    <label className="col-form-label d-none">
                        Tags
                    </label>
                    <p>
                        {tags.map((tag, index) => (
                            <span key={index} className="badge bg-primary badge-pill m-r-5 m-b-5"> {tag} </span>
                        ))}
                    </p>
                </>
            }
        </div>
    )

    submitHandler = (values, setSubmitting, setErrors) => {
        const data = {
            user_type: values.user_type,
            tags: this.rule.current.tags,
            retailer_id: values.retailer_id,
            is_sandbox_mode: values.is_sandbox_mode,
            conditions: this.rule.current.conditions,
            configuration: {
                status: values.status,
                closed: values.closed,
                liability: values.liability,
                RequestOutcome: values.RequestOutcome,
                CaseSummaryText: values.CaseSummaryText,
                CaseCompletionText: values.CaseCompletionText
            },
            // TODO: Need to handle this
            managerOverride: this.state.managerOverride
        };

        if (this.state.id && this.state.replicate === false) {
            delete data.tags;
            delete data.conditions;
            this.services.scenario.update(this.state.id, data, setSubmitting, setErrors);
        } else {
            this.services.scenario.create(data, setSubmitting, setErrors);
        }
    }

    closeConfirmModal = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: false
            }
        }));
    }

    deleteHandler = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: true
            }
        }));
    }

    // Delete scenario finally
    deleteScenario = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            const info = atob(this.state.secret).split('-');
            this.services.scenario.delete(this.state.id, {
                retailer_id: info[0],
                user_type: info[1]
            });
        });
    }

    componentDidMount() {
        if (this.state.id) {
            try {
                const [retailer_id, user_type] = atob(this.state.secret).split('-');
                this.services.scenario.get(this.state.id, {
                    user_type,
                    retailer_id
                });
            } catch (e) {
                this.setState({
                    isLoading: false,
                    pageNotFound: true
                });
            }
        } else if (this.state.secret) {
            try {
                const params = new URLSearchParams(atob(this.state.secret));
                const values = Object.fromEntries(params);
                this.setState({
                    isLoading: false,
                    initialValues: { ...this.state.initialValues, ...values }
                });
            } catch (e) {
                // Do nothing
            }
        }
    }

    render() {
        if (this.state.pageNotFound) {
            return <NotFound />
        }

        return (
            <Spinner isLoading={this.state.isLoading}>
                <Box
                    allowFullScreen
                    title="Configure scenario"
                    buttons={[
                        {
                            type: 'link',
                            value: '+ Add',
                            class: 'btn btn-sm btn-success',
                            show: this.state.id !== undefined && this.state.replicate === false,
                            href: '/case-creation/scenarios/add'
                        },
                        {
                            type: 'link',
                            value: 'Priortize scenarios',
                            class: 'btn btn-sm btn-warning',
                            show: this.state.id !== undefined && this.state.replicate === false,
                            href: '/rules/categories/priortize/' + btoa(this.state.initialValues.retailer_id + '-' + this.state.initialValues.user_type + '-scenario') + '#' + this.state.id
                        },
                        {
                            type: 'link',
                            value: 'Replicate',
                            class: 'btn btn-sm btn-primary',
                            show: this.state.id !== undefined && this.state.replicate === false,
                            href: '/case-creation/scenarios/replicate/' + this.state.id + '/' + this.state.secret
                        },
                        {
                            type: "button",
                            value: "Delete",
                            class: "btn btn-sm btn-danger",
                            show: this.state.id !== undefined && this.state.replicate === false,
                            clicked: this.deleteHandler
                        }
                    ]}>

                    {this.state.replicate &&
                        <Alert variant="info">
                            <i className="fa fa-info-circle"></i>&nbsp;
                            You need to change at least one input to replicate the scenario else you will get error stating that scenario already exists.
                        </Alert>
                    }

                    {this.state.message &&
                        <Alert variant={this.state.variant} dismiss={() => {
                            this.setState({
                                message: null
                            });
                        }}>
                            {this.state.message}
                        </Alert>
                    }

                    <Form
                        showErrorList
                        initialValues={this.state.initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.submitHandler}>
                        {({ values }) => {
                            const { tags, ...rule } = this.getUpdatedRule(values);
                            return (
                                <div className="row">
                                    <div className="col-md-8 col-xs-12 border-end">
                                        <Tabs active="input">
                                            <Tabs.Pane id="instructions" title="Instructions" message="Read me carefully before starting">
                                                <ComingSoon />
                                            </Tabs.Pane>
                                            <Tabs.Pane id="input" title="Input">
                                                <Renderer config={inputs} data={values} />
                                            </Tabs.Pane>
                                            <Tabs.Pane id="output" title="Output">
                                                <Renderer config={outputs} data={values} />
                                            </Tabs.Pane>

                                            {this.state.id &&
                                                <Tabs.Pane id="override" title="Override">
                                                    <ComingSoon />
                                                </Tabs.Pane>
                                            }

                                            {(this.state.id && false) &&
                                                <Tabs.Pane id="similar" title="Similar scenarios">
                                                    <Alert variant="secondary">
                                                        <i className="fa fa-info-circle"></i>&nbsp;
                                                        Select the tags below and click on Filter.
                                                    </Alert>
                                                    <div className="m-b-10">
                                                        {this.renderTags(tags, true)}
                                                        <button type="button" className="btn btn-primary">
                                                            Filter
                                                        </button>
                                                    </div>
                                                    <List columns={this.getColumns()} data={this.state.similar} />
                                                </Tabs.Pane>
                                            }
                                        </Tabs>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <Tabs active="ruleDetails">
                                            <Tabs.Pane id="ruleDetails" title="Scenario">
                                                <Form.Group name="is_sandbox_mode" label="Sandbox mode">
                                                    <Form.Radio name="is_sandbox_mode" valueType="number" values={[
                                                        {
                                                            "label": "No",
                                                            "value": 0
                                                        },
                                                        {
                                                            "label": "Yes",
                                                            "value": 1
                                                        }
                                                    ]} />
                                                </Form.Group>

                                                {this.renderTags(tags)}

                                                <PrintObject data={rule} maxLines={50} disabled />

                                                {this.state.id &&
                                                    <div className="d-flex justify-content-between text-muted mt-2 fs-6">
                                                        <div title={new Date(this.state.created_at).toString()}>
                                                            <b>Created:</b> <br />
                                                            {new Date(this.state.created_at).toDateString()}
                                                        </div>
                                                        <div title={new Date(this.state.updated_at).toString()}>
                                                            <b>Last modified:</b> <br />
                                                            {new Date(this.state.updated_at).toDateString()}
                                                        </div>
                                                    </div>
                                                }
                                            </Tabs.Pane>
                                        </Tabs>
                                        <Form.Submit className="btn btn-success m-t-10" />
                                    </div>
                                </div>
                            )
                        }}
                    </Form>

                    {(this.state.id && this.state.confirmModal.show) &&
                        <Confirm {...this.state.confirmModal} />
                    }
                </Box>
            </Spinner>
        )
    }
}

export default AddScenario;