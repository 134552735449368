import { RunValidation } from "./RunValidation";

export class Validator {
    validations = {};
    stopAfterFirstError = false;

    // Stop running validation rules for the field after the first validation failure
    bail() {
        this.stopAfterFirstError = true;
        return this;
    }

    types(types = [], error = null) {
        const length = types.length;
        if (length === 0) {
            return this;
        }

        error = error || (length > 1 ? 'Must be one of the types ' + types.join(', ') : 'Must  be of ' + types[0] + ' type');
        this.validations['types'] = { types, error };
        return this;
    }

    required(error = 'Required') {
        this.validations['required'] = { error };
        return this;
    }

    // The field under validation must be present and not empty if the anotherfield field is equal to any value
    requiredIf(key, value, error) {
        error = error || ('Required if ' + key + ' is ' + value);
        this.validations['requiredIf'] = { key, value, error };
        return this;
    }

    // The field under validation must be present and not empty only if the other specified field is present and not empty.
    requiredWith(key, error) {
        error = error || ('Required when non empty ' + key + ' is present');
        this.validations['requiredWith'] = { key, error };
        return this;
    }

    number(error = 'Must be a number') {
        this.validations['number'] = { error };
        return this;
    }

    // The field under validation must be numeric and must have an exact length of length param.
    digits(length, error) {
        error = error || ('Must be numeric and have an exact length of ' + length);
        this.validations['digits'] = { length, error };
        return this;
    }

    // The field under validation must be numeric and must have a length between the given min and max
    digitsBetween(min, max, error) {
        error = error || ('Must be numeric and have a length between the given ' + min + ' and ' + max);
        this.validations['digitsBetween'] = { min, max, error };
        return this;
    }

    boolean(error = 'Must be a boolean value') {
        this.validations['boolean'] = { error };
        return this;
    }

    // The given field must match the field under validation
    same(field, error = '') {
        error = error || ('Must be same as ' + field);
        this.validations['same'] = { field, error };
        return this;
    }

    // The field under validation must have a different value than field
    different(field, error) {
        error = error || ('Must have a different value than ' + field);
        this.validations['different'] = { field, error };
        return this;
    }

    date(error = 'Must be a valid Date') {
        this.validations['date'] = {
            required: true,
            error
        };

        return this;
    }

    url(error = 'Must be a valid URL') {
        this.validations['url'] = {
            required: true,
            error
        };

        return this;
    }

    email(error = 'Must be a valid email') {
        this.validations['email'] = {
            required: true,
            error
        };

        return this;
    }

    ip(error = 'Must be a valid IP address') {
        this.validations['ip'] = {
            required: true,
            error
        };

        return this;
    }

    json(error = 'Must be a valid JSON string') {
        this.validations['json'] = {
            required: true,
            error
        };

        return this;
    }

    min(limit, error = null) {
        this.validations['min'] = {
            min: limit,
            error: error || `Must be ${limit} characters or more`
        }

        return this;
    }

    max(limit, error = null) {
        this.validations['max'] = {
            max: limit,
            error: error || `Must be ${limit} characters or less`
        }

        return this;
    }

    static create() {
        return new this();
    }

    // Check if field has some validation applied or not
    has(validation) {
        return validation in this.validations;
    }

    run(key, values, valueType = 'string') {
        let errors = [];
        for (const validation in this.validations) {
            const validator = new RunValidation(this.validations[validation], key, values, valueType);
            if (typeof validator[validation] === 'function') {
                const error = validator[validation]();
                if (error !== null) {
                    errors.push(error);
                    if (this.stopAfterFirstError === true) {
                        break;
                    }
                }
            }
        }

        return errors.length ? errors.join(". ") : null;
    }
}