import React from 'react';
import Modal from '../../../components/Modal';
import SMSContent from './template-content/SMSContent';
import EMAILContent from './template-content/EMAILContent';
import INTEGRATIONContent from './template-content/INTEGRATIONContent';
import { SecuredApi as axios } from "../../../services/axios";

const AddOrPreviewTemplate = props => {
    const submitTemplate = (values) => {
        if (values.paperworks) {
            values.paperwork = values.paperworks.map(paperwork => paperwork.value);
        }

        if (props.templateName == 'integration') {

            values.configuration_class = values.name;

            let obj;
            if(values.authorization_type == 'bearer-token'){
                obj = {
                    "type" : values.authorization_type,
                    "data" : {
                        "bearer_token" : values.bearerToken
                    }
                }
            } else if(values.authorization_type == 'basic-auth'){
                obj = {
                    "type" : values.authorization_type,
                    "data" : values.basic
                }
            } else {
                obj = {
                    "type" : values.authorization_type,
                }
            }
            values.json_template = {"data" : {"params" : {
                "method" : values.method,
                "domain" : values.domain,
                "rel_url" : values.url,
                "authorization" : obj,
                "params" : values.params,
                "headers" : values.headers
            }}};
            console.log(values);
        }

        values.is_enabled = 1;
        const response = axios.post('commpack/' + props.templateName, values);

        response
            .then(res => {
                console.log(res);
                props.appendTemplate(res.data.data.Data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <Modal keyboard={false} backdrop="static" scrollContent={true} modalSize="xl">
            <Modal.Header close={false}>
                <Modal.Title>{props.mode.toLocaleUpperCase()} TEMPLATE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.templateName === 'sms' &&
                    <SMSContent {...props} submitTemplate={submitTemplate}>
                        {props.children}
                    </SMSContent>
                }
                {
                    props.templateName === 'email' &&
                    <EMAILContent {...props} submitTemplate={submitTemplate} paperworkList={props.paperworkList} >
                        {props.children}
                    </EMAILContent>
                }
                {
                    props.templateName === 'integration' &&
                    <INTEGRATIONContent {...props} submitTemplate={submitTemplate}>
                        {props.children}
                    </INTEGRATIONContent>
                }
            </Modal.Body>
        </Modal>
    );
}

export default AddOrPreviewTemplate;