import { SecuredApi as axios } from "./axios";

export default class API {
    constructor(component) {
        this.component = component;
    }

    async list() {
        try {
            const response = await axios.get('/apiconfiguration');
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data.Data.items
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message
            });
        }
    }

    async get(id) {
        try {
            const response = await axios.get('/apiconfiguration/' + id);
            const data = await response.data;

            this.component.setState({
                isLoading: false,
                error: null,
                data: data.data
            });
        } catch ({ message, statusCode }) {
            this.component.setState({
                isLoading: false,
                error: message,
                pageNotFound: statusCode === 404
            });
        }
    }

    async create(params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.post('/apiconfiguration', params);
            const data = await response.data;
            setSubmitting(false);

            this.component.props.history.replace('/api/update/' + data.data.Data.id);

        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message || response.data.data.message
                });
            }
        }
    }

    async update(id, params = {}, setSubmitting, setErrors) {
        try {
            const response = await axios.put('/apiconfiguration/' + id, params);
            const data = await response.data;
            setSubmitting(false);

            this.component.setState({
                data: data.data.Data,
                variant: "success",
                message: "API configuration updated successfully"
            });
        } catch ({ message, statusCode, response }) {
            setSubmitting(false);
            if (statusCode === 422 || statusCode === 500) {
                setErrors(response.data.data.Errors);
                this.component.setState({
                    variant: "danger",
                    message: response.data.data.Message || response.data.data.message
                });
            }
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete('/apiconfiguration/' + id);

            // eslint-disable-next-line
            const data = await response.data;
            this.component.props.history.replace('/api');
        } catch ({ message, statusCode, response }) {
            if (statusCode === 422 || statusCode === 500) {
                this.component.setState(prev => ({
                    variant: "danger",
                    preventUnload: true,
                    message: response.data.data.Message,
                    confirmModal: {
                        ...prev.confirmModal,
                        isLoading: false,
                        show: false
                    }
                }));
            }
        }
    }
}