import React, { Component } from "react";
import Box from "../../../components/Box";
import Spinner from "../../../components/Spinner";
import Form from "../../../components/Form";
import Toast from '../../../components/Toast';
import { EmailTemplateService, PaperworkService } from "../../../services";
import { Validator } from "../../../utils/Validator";
import AutoSelect from '../Event/template-content/AutoSelect';

class ManageEmail extends Component {
    constructor(props) {
        super(props);
        this.services = {
            email: new EmailTemplateService(this),
            paperwork: new PaperworkService(this)
        }
    }

    state = {
        id: this.props.match.params.email,
        eventId: this.props.match.params.event,
        isLoading: this.props.match.params.email !== undefined,
        touched: false,
        error: null,
        data: {},
        toastShow:false
    }

    componentDidMount() {
        if (this.state.id !== undefined) {
            this.services.email.get(this.state.id);
            this.services.email.optionList(this.state.eventId);
        } else {
            this.services.email.optionList();
        }
    }


    submitHandler = (values, setSubmitting) => {
        if(values.is_connote_doc_use == 'on'){
            values.is_connote_doc_use = 1;
        }
        if(values.is_enabled == 'on'){
            values.is_enabled = 1;
        }
        if(values.is_connote_label_use == 'on'){
            values.is_connote_label_use = 1;
        }

        if(this.state.id !== undefined) {
            for (var value in values) {
                if (values[value] === null || values[value] === undefined) {
                  delete values[value];
                }
              }
            this.services.email.update(this.state.id, values, setSubmitting);    
        } else {
            this.services.email.save(values, setSubmitting);
        }
    }

    deleteHandler = () => {
        let confirm = window.confirm("Do you want to delete?");
        if(confirm){
            this.services.email.deleteAction(this.state.id, this.state.data.event_id); 
        }
    }

    updateToast = () => {
        this.setState({
            toastShow: false
        });
    }

    render() {
        if (this.state.error) {
            return (
                <div className="alert alert-danger alert-bordered">
                    {this.state.error}
                </div>
            )
        }

        return (
            <Spinner isLoading={this.state.isLoading}>
                <Toast 
                    message={this.state.message}
                    title={this.state.error ? 'Error' : 'Message'}
                    position="top-right"
                    action={this.state.toastShow ? 'show' : false}
                    handleClick={() => this.updateToast()}
                />
                <Form
                    initialValues={this.state.data}
                    onSubmit={this.submitHandler}
                    validationSchema={{
                        subject: Validator.create().required(),
                        html_content: Validator.create().required()
                    }}>
                    <Box title="Email Template" buttons={[{
                        type: "submit",
                        value: "Save",
                        class: "btn btn-success",
                        show: this.state.isLoading === false
                    }, {
                        type: "button",
                        value: "Delete",
                        class: "btn btn-danger",
                        show: this.state.id !== undefined && this.state.isLoading === false,
                        clicked: () => this.deleteHandler()
                    }]}>
                        <div className="row">

                            <Form.Group name="subject" label="Subject" required>
                                <Form.Input type="text" placeholder="Subject" id="subject" name="subject" />
                            </Form.Group>

                            <Form.Group name="text_content" label="Text Content" required>
                                <Form.Textarea rows="10" placeholder="Text Content" id="text_content" name="text_content" />
                            </Form.Group>

                            <Form.Group name="html_content" label="Html Template" required>
                                <Form.HTMLEditor rows="15"  placeholder="Html Template" id="html_content" name="html_content" />
                            </Form.Group>

                            <Form.Group name="from" label="From" >
                                <Form.Input type="text" placeholder="From" id="from" name="from" />
                            </Form.Group>
                            
                            <Form.Group name="from_whom" label="EFrom Whom" >
                                <Form.Input type="text" placeholder="From Whom" id="from_whom" name="from_whom" />
                            </Form.Group>

                            <Form.Group name="fallback_from_whom" label="Fallback From Whom" >
                                <Form.Input type="text" placeholder="Fallback From Whom" id="fallback_from_whom" name="fallback_from_whom" />
                            </Form.Group>

                            <Form.Group name="to" label="To" >
                                <Form.Input type="text" placeholder="To" id="to" name="to" />
                            </Form.Group>

                            <Form.Group name="bcc" label="Bcc" >
                                <Form.Input type="text" placeholder="Bcc" id="bcc" name="bcc" />
                            </Form.Group>

                            <Form.Group name="cc" label="Cc" >
                                <Form.Input type="text" placeholder="Cc" id="cc" name="cc" />
                            </Form.Group>

                            <Form.Group name="reply_to" label="Reply To" >
                                <Form.Input type="text" placeholder="Reply To" id="reply_to" name="reply_to" />
                            </Form.Group>

                            <Form.Group name="return" label="Return" >
                                <Form.Input type="text" placeholder="Return" id="return" name="return" />
                            </Form.Group>

                            <Form.Group name="to_whom" label="To Whom" >
                                <Form.Input type="text" placeholder="To Whom" id="to_whom" name="to_whom" />
                            </Form.Group>

                            <Form.Group name="is_enabled" label="Status" for="is_enabled" >
                                <Form.Checkbox  value="1" id="is_enabled" name="is_enabled" />
                            </Form.Group>

                            <Form.Group name="is_connote_doc_use" label="Connote Document" for="is_connote_doc_use" >
                                <Form.Checkbox  value="1" id="is_connote_doc_use" name="is_connote_doc_use" />
                            </Form.Group>

                            <Form.Group name="is_connote_label_use" label="Connote Label" for="is_connote_label_use" >
                                <Form.Checkbox value="1" id="is_connote_label_use" name="is_connote_label_use" />
                            </Form.Group>

                            { this.state.paperworkList !== undefined && 
                            <Form.Group name="email_template_id" label="Paperwork Template" required>
                                <AutoSelect paperworkList = {this.state.paperworkList}/>
                            </Form.Group>}

                        </div>
                    </Box>
                </Form>
            </Spinner>
        )
    }
}

export default ManageEmail;