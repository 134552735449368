import React, { Component } from "react";
import BasicDetails from "./Basic";
import Actions from "./Action/List";
import AddAction from "./Action/Add";
import Box from "../../../components/Box";
import Tabs from "../../../components/Tabs";
import Alert from "../../../components/Alert";
import NotFound from "../../../components/404";
import { RuleService } from "../../../services";
import Confirm from "../../../components/Confirm";
import Spinner from "../../../components/Spinner";
import Changelog from "../../../components/Changelog";
import MagicConstants from "../../../components/MagicConstants";

class AddRule extends Component {
    constructor(props) {
        super(props);
        this.tabsRef = React.createRef();
        this.listRef = React.createRef();
        this.addActionRef = React.createRef();

        this.services = {
            rules: new RuleService(this)
        }

        this.state = {
            id: this.props.match.params.id,
            isLoading: this.props.match.params.id !== undefined,
            error: null,
            message: null,
            data: {
                conditions: {}
            },
            preventUnload: true,
            confirmModal: {
                show: false,
                message: "Are you sure want to delete the rule ?",
                onConfirm: () => this.deleteRule(),
                onCancel: () => this.closeConfirmModal()
            }
        }
    }

    componentDidMount() {
        if (this.state.id !== undefined) {
            this.services.rules.get(this.state.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                id: this.props.match.params.id,
                isLoading: true,
                error: null,
                message: null,
                data: {
                    conditions: {}
                }
            });

            this.services.rules.get(this.props.match.params.id);
        }
    }

    closeConfirmModal = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: false
            }
        }));
    }

    deleteHandler = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: true
            }
        }));
    }

    // Delete rule finally
    deleteRule = () => {
        this.setState(prev => ({
            preventUnload: false,
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            this.services.rules.delete(this.state.id);
        });
    }

    editHandler = data => {
        this.tabsRef.current.setActive('addReturnValue');

        // Update action only if another action is selected for update  
        if (this.addActionRef.current.state.action.id !== data.id) {
            this.addActionRef.current.setActionForUpdate({ ...data });
        }
    }

    render() {
        if (this.state.pageNotFound) {
            return <NotFound />
        }

        if (this.state.error) {
            return (
                <Alert variant="danger">
                    {this.state.error}
                </Alert>
            )
        }

        return (
            <Spinner isLoading={this.state.isLoading}>
                <Box allowFullScreen title={this.state.id ? "Update rule" : "Add rule"} buttons={[{
                    type: "button",
                    value: "Delete",
                    class: "btn btn-sm btn-danger",
                    show: this.state.id !== undefined && this.state.isLoading === false,
                    clicked: this.deleteHandler
                }, {
                    type: "link",
                    href: "/rules/add",
                    value: "Add new",
                    class: "btn btn-sm btn-info",
                    show: this.state.id !== undefined
                }]}>
                    {this.state.message &&
                        <Alert variant="danger" dismiss={() => {
                            this.setState({
                                message: null
                            });
                        }}>
                            {this.state.message}
                        </Alert>
                    }

                    <Tabs ref={this.tabsRef} active="basicDetails">
                        <Tabs.Pane id="basicDetails" title="Basic Details">
                            <BasicDetails
                                id={this.state.id}
                                data={this.state.data}
                                preventUnload={this.state.preventUnload} />
                        </Tabs.Pane>
                        <Tabs.Pane id="listReturnValues" title="Return values" disabled={!this.state.id}>
                            {this.state.id &&
                                <Actions
                                    ref={this.listRef}
                                    ruleID={this.state.id}
                                    actions={this.state.data.return_values || []}
                                    editHandler={this.editHandler}
                                    onDelete={(deletedId, actions) => {
                                        this.addActionRef.current.updateActions(actions);
                                    }} />
                            }
                        </Tabs.Pane>
                        <Tabs.Pane id="addReturnValue" title="Add return value" disabled={!this.state.id}>
                            {this.state.id &&
                                <AddAction
                                    ref={this.addActionRef}
                                    ruleID={this.state.id}
                                    actions={this.state.data.return_values || []}
                                    onAdd={(action, actions) => {
                                        this.listRef.current.updateActions(actions, 'Return value added successfully');
                                        this.tabsRef.current.setActive('listReturnValues');
                                    }}
                                    onUpdate={(action, actions) => {
                                        this.listRef.current.updateActions(actions, 'Return value updated successfully');
                                        this.tabsRef.current.setActive('listReturnValues');
                                    }} />
                            }
                        </Tabs.Pane>

                        <Tabs.Pane id="magicConstants" title="Magic constants" disabled={!this.state.id}>
                            <MagicConstants />
                        </Tabs.Pane>

                        {this.state.id &&
                            <Tabs.Pane id="changelog" title="Changelog">
                                <Changelog />
                            </Tabs.Pane>
                        }
                    </Tabs>
                </Box>

                {(this.state.id && this.state.confirmModal.show) &&
                    <Confirm {...this.state.confirmModal} />
                }
            </Spinner>
        )
    }
}

export default AddRule;