import React from "react";
import "./index.css";

const Spinner = ({ isLoading = true, placeholder = 'Loading', children }) => {
    if (isLoading) {
        return (
            <div className="spinner">
                <div className="sidenav-backdrop backdrop"></div>
                <div className="preloader-backdrop">
                    <div className="page-preloader">{placeholder}</div>
                </div>
            </div>
        );
    }

    return children;
}

export default Spinner;