import React, { useEffect, useState } from "react";

const Fullscreen = ({ element }) => {
    const [mode, changeMode] = useState(false);

    useEffect(() => {
        document.addEventListener("fullscreenchange", resetStateOnFullScreenExit);

        return () => {
            document.removeEventListener("fullscreenchange", resetStateOnFullScreenExit);
        }
    }, [mode]);

    const resetStateOnFullScreenExit = e => {
        if (document.fullscreenElement === null && mode === true) {
            changeMode(false);
        }
    }

    const clickHandler = () => {
        if (mode === false) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Mozilla */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            }
        }

        changeMode(!mode);
    }

    return (
        <button type="button" className="btn btn-sm btn-info" onClick={clickHandler} title={'Click to ' + (mode ? 'exit' : 'enter') + ' fullscreen mode'}>
            <i className={`fa fa-${mode ? 'compress' : 'expand'}`} ></i>
        </button>
    )
}

export default Fullscreen;