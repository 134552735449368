import React from "react";
import Box from "../Box";
import Form from "../Form";
import Tabs from "../Tabs";
import List from "../List";
import Alert from "../Alert";
import Clock from "../Clock";
import Modal from "../Modal";
import Timeline from "../Timeline";

const handleRendererProp = (item, data) => {
    return item.useRenderer === true
        ? <Renderer config={item.config} data={data} />
        : item.content;
}

const Renderer = ({ config = {}, data = {} }) => {
    let elements = [];
    config.forEach((item, index) => {
        const { name: key, ...value } = item;
        if (('visible' in value) && value.visible(data) === false) {
            return;
        }

        let element = null;
        switch (value.type) {
            case "tel":
            case "url":
            case "text":
            case "date":
            case "time":
            case "week":
            case "email":
            case "color":
            case "month":
            case "number":
            case "search":
            case "hidden":
            case "password":
            case "datetime-local":
                element = (
                    <Form.Input
                        name={key}
                        type={value.type}
                        id={value.id || key}
                        maxLength={value.maxLength}
                        placeholder={value.placeholder}
                        autoComplete={value.autoComplete}
                        readOnly={typeof value.readOnly === 'function' ? value.readOnly(data) : value.readOnly}
                        disabled={typeof value.disabled === 'function' ? value.disabled(data) : value.disabled} />
                )
                break;
            case "textarea":
                element = (
                    <Form.Textarea
                        name={key}
                        rows={value.rows}
                        cols={value.cols}
                        id={value.id || key}
                        placeholder={value.placeholder}
                        readOnly={typeof value.readOnly === 'function' ? value.readOnly(data) : value.readOnly}
                        disabled={typeof value.disabled === 'function' ? value.disabled(data) : value.disabled} />
                )
                break;
            case "select":
                const values = typeof value.values === 'function' ? value.values(data) : value.values;
                element = (
                    <Form.Select
                        name={key}
                        value={value.value}
                        id={value.id || key}
                        valueType={value.valueType}
                        placeholder={value.placeholder}
                        readOnly={typeof value.readOnly === 'function' ? value.readOnly(data) : value.readOnly}
                        disabled={typeof value.disabled === 'function' ? value.disabled(data) : value.disabled}>
                        {values.map((value, index) => (
                            <option key={index} value={value.value}>{value.label}</option>
                        ))}
                    </Form.Select>
                )
                break;
            case "radio":
                element = (
                    <Form.Radio
                        name={key}
                        value={value.value}
                        inline={value.inline}
                        valueType={value.valueType}
                        values={typeof value.values === 'function' ? value.values(data) : value.values}
                        readOnly={typeof value.readOnly === 'function' ? value.readOnly(data) : value.readOnly}
                        disabled={typeof value.disabled === 'function' ? value.disabled(data) : value.disabled} />
                )
                break;
            case "checkbox":
                element = (
                    <Form.Checkbox
                        name={key}
                        value={value.value}
                        id={value.id || key}
                        values={value.values}
                        valueType={value.valueType}
                        uncheckedValue={value.uncheckedValue}
                        readOnly={typeof value.readOnly === 'function' ? value.readOnly(data) : value.readOnly}
                        disabled={typeof value.disabled === 'function' ? value.disabled(data) : value.disabled} />
                )
                break;
            case "html-editor":
                element = (
                    <Form.HTMLEditor name={key} id={key} />
                )
                break;
            case "json-editor":
                element = (
                    <Form.JSONEditor
                        schema={value.schema || {}}
                        allowedModes={value.allowedModes || []}
                        htmlElementProps={{
                            name: key
                        }} />
                )
                break;
            case "button":
                element = (
                    <button
                        type={value.type}
                        id={value.id || key}
                        disabled={value.disabled}
                        className={value.className} />
                )
                break;
            case "html":
                element = handleRendererProp(value, data)
                break;
            case "clock":
                element = <Clock timezone={value.timezone} />
                break;
            case "renderer":
                element = (
                    <Renderer config={value.config} data={value.data} />
                )
                break;
            case "alert":
                element = (
                    <Alert variant={value.variant} border={value.border} dismiss={value.dismiss}>
                        {handleRendererProp(value, data)}
                    </Alert>
                )
                break;
            case "timeline":
                element = (
                    <Timeline data={value.data} collapsible={value.collapsible} collapsed={value.collapsed} className={value.className} />
                )
                break;
            case "modal":
                element = (
                    <Modal keyboard={value.keyboard} backdrop={value.backdrop}>
                        <Modal.Header close={value.keyboard}>
                            <Modal.Title>
                                {value.header}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {handleRendererProp(value, data)}
                        </Modal.Body>
                        <Modal.Footer>
                            {value.footer}
                        </Modal.Footer>
                    </Modal>
                )
                break;
            case "box":
                element = (
                    <Box
                        id={value.id}
                        title={value.title}
                        variant={value.variant}
                        className={value.className}
                        buttons={value.buttons || []}
                        collapse={value.collapse}
                        collapsed={value.collapsed}
                        allowFullScreen={value.allowFullScreen}>
                        {handleRendererProp(value, data)}
                    </Box>
                )
                break;
            case "list":
                element = (
                    <List
                        data={value.data}
                        columns={value.columns}
                        showFilter={value.showFilter}
                        responsive={value.responsive} />
                )
                break;
            case "form":
                const initialValues = {};
                const validationSchema = {};
                element = (
                    <Form
                        debug={value.debug}
                        initialValues={initialValues}
                        submitHandler={value.submitHandler}
                        showErrorList={value.showErrorList}
                        validationSchema={validationSchema}>
                        {handleRendererProp(value, data)}
                    </Form>
                )
                break;
            case "tab":
                element = (
                    <Tabs active={value.active}>
                        {value.values.map((item, index) => {
                            if ('visible' in item && item.visible(data) === false) {
                                return;
                            }

                            let disabled = false;
                            if ('disabled' in item) {
                                disabled = typeof item.disabled === 'boolean' ? item.disabled : item.disabled(data);
                            }

                            return (
                                <Tabs.Pane key={index} id={item.id} title={item.title} disabled={disabled}>
                                    {handleRendererProp(item, data)}
                                </Tabs.Pane>
                            )
                        })}
                    </Tabs>
                )
                break;
            default:
                break;
        }

        // Only form elements require Form group
        if (!['form', 'tab', 'html', 'button', 'box', 'modal', 'alert', 'renderer', 'timeline', 'list'].includes(value.type)) {
            const required = ('validations' in value) && (value.validations.has('required') || value.validations.has('requiredIf') || value.validations.has('requiredWith'));
            element = (
                <Form.Group name={key} label={value.label} required={required} hint={value.hint}>
                    {element}
                </Form.Group>
            )
        }

        // Allow modification of element using render callback in configuration
        element = 'render' in value ? value.render(element) : element;

        // Grid container not required for Modal
        if (value.type !== 'modal') {
            element = (
                <div key={index} className={"col-xs-12 " + (value.layout === 'full' ? 'col-md-12' : 'col-md-6')}>
                    {element}
                </div>
            )
        }

        elements.push(element);
    });

    return (
        <div className="row">
            {elements}
        </div>
    )
}

export default Renderer;