import React, { Component } from "react";
import { withRouter } from "react-router";
import Alert from "../../../components/Alert";
import Form from "../../../components/Form";
import { Tags } from "../../../components/Tags";
import { RuleService } from "../../../services";
import { Validator } from "../../../utils/Validator";
import conditionSchema from "../../../config/schema/condition.json";

class BasicDetails extends Component {
    constructor(props) {
        super(props);
        this.tags = React.createRef();
        this.services = {
            rules: new RuleService(this)
        }

        this.state = {
            variant: "success",
            message: null,
        }
    }

    submitHandler = (values, setSubmitting, setErrors) => {
        values.tags = this.tags.current ? this.tags.current.join(', ') : '';
        if (this.props.id) {
            this.services.rules.update(this.props.id, values, setSubmitting, setErrors);
        } else {
            this.services.rules.create(values, setSubmitting, setErrors);
        }
    }

    componentDidMount() {
        this.tags.current = this.props.data.tags ? this.props.data.tags.split(', ') : [];
    }

    render() {
        return (
            <Form
                id="addBasicDetailsForm"
                initialValues={{
                    name: this.props.data.name,
                    is_sandbox_mode: this.props.data.is_sandbox_mode || 0,
                    description: this.props.data.description,
                    conditions: this.props.data.conditions
                }}
                onSubmit={this.submitHandler}
                preventUnload={this.props.preventUnload}
                validationSchema={{
                    name: Validator.create().required("Name is required"),
                    conditions: Validator.create().required("Conditions are required").types(['object'])
                }}>

                {!this.props.id &&
                    <Alert variant="secondary">
                        Note: You need to add basic details first. After that, you will be able to add return values as well.
                    </Alert>
                }

                {this.state.message &&
                    <Alert variant={this.state.variant} dismiss={() => {
                        this.setState({
                            message: null
                        });
                    }}>
                        {this.state.message}
                    </Alert>
                }

                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <Form.Group name="name" label="Name" required>
                            <Form.Input type="text" placeholder="Enter name" id="name" name="name" />
                        </Form.Group>
                        <Form.Group name="is_sandbox_mode" label="Sandbox Mode ?" required hint="If yes, Rule will not run for live stores and callcentres">
                            <Form.Radio name="is_sandbox_mode" values={[
                                {
                                    "label": "No",
                                    "value": 0
                                },
                                {
                                    "label": "Yes",
                                    "value": 1
                                }
                            ]} />
                        </Form.Group>
                        <Form.Group name="conditions" label="Conditions" required>
                            <Form.JSONEditor
                                schema={conditionSchema}
                                htmlElementProps={{
                                    name: "conditions"
                                }} />
                        </Form.Group>
                        <Form.Group name="description" label="Description">
                            <Form.Textarea name="description" placeholder="Describe rule here..." rows="4" id="description" />
                        </Form.Group>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <Form.Group name="tags" label="Tags">
                            <Tags selected={this.props.data.tags} onChange={tags => {
                                this.tags.current = tags;
                            }} />
                        </Form.Group>
                    </div>
                    <div className="col-xs-12">
                        <Form.Submit />
                    </div>
                </div>
            </Form>
        )
    }
}

export default withRouter(BasicDetails);