import Helper from "./Helper";

export class RunValidation {
    constructor(validation, key = '', values = {}, valueType = 'string') {
        this.key = key;
        this.values = values;
        this.value = values[key];
        this.valueType = valueType;
        this.validation = validation;
    }

    validateValue = () => {
        if (!(this.key in this.values)) {
            return false;
        } else if (this.valueType === 'string' && !this.value) {
            return false;
        }

        return true;
    }

    types = () => this.validation.types.includes(typeof this.value) ? null : this.validation.error;

    required = (error = null) => this.validateValue() === false ? (error || this.validation.error) : null;

    requiredIf = () => this.values[this.validation.key] === this.validation.value ? this.required(this.validation.error) : null;

    requiredWith = () => this.values[this.validation.key] ? this.required(this.validation.error) : null;

    number = () => isNaN(Number(this.value)) ? this.validation.error : null;

    digits = () => {
        if (isNaN(this.value) || this.value.length !== this.validation.length) {
            return this.validation.error;
        }

        return null;
    }

    digitsBetween = () => {
        if (isNaN(this.value) || !(this.value.length >= this.validation.min && this.value.length <= this.validation.max)) {
            return this.validation.error;
        }

        return null;
    }

    boolean = () => (this.value == 'true' || this.value == 'false') ? null : this.validation.error;

    same = () => this.values[this.validation.field] === this.value ? null : this.validation.error;

    different = () => this.values[this.validation.field] !== this.value ? null : this.validation.error;

    date = () => Helper.isValidDate(this.value) ? null : this.validation.error;

    url = () => Helper.isValidURL(this.value) ? null : this.validation.error;

    email = () => Helper.isValidEmail(this.value) ? null : this.validation.error;

    ip = () => Helper.isValidIP(this.value) ? null : this.validation.error;

    json = () => Helper.isValidJSON(this.value) ? null : this.validation.error;

    min = () => this.value.length < this.validation.min ? this.validation.error : null;

    max = () => this.value.length > this.validation.max ? this.validation.error : null;
}