import React, { Component } from "react";
import Form from "../../components/Form";
import Alert from "../../components/Alert";
import { RuleService } from "../../services";
import { Validator } from "../../utils/Validator";

class TextForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dates: {},
            message: null,
            touched: false,
            returnValue: {},
            variant: "success"
        }

        this.services = {
            rule: new RuleService(this)
        }
    }

    updateRule = rule => {
        this.props.updateRuleList(rule);
    }

    submitHandler = (values, setSubmiting, setErrors) => {
        if (values.returnValueId) {
            this.services.rule.updateOnscreenText(values.ruleId, values.returnValueId, values, setSubmiting, setErrors);
        } else {
            this.services.rule.saveOnscreenText(values.ruleId, values, setSubmiting, setErrors);
        }
    }

    render() {
        return (
            <>
                <Alert variant="secondary">
                    <strong><u>Points to consider:</u></strong>
                    <ul className="mt-2">
                        <li className='mb-2'>Don't use font size more than 16px.</li>
                        <li className='mb-2'>Tags such as script, iframe, link will be removed before saving.</li>
                        {this.props.type === 'CaseCompletionText' &&
                            <li className='mb-2'>Use <b>SOLVUP_CASE_ID</b> as placeholder for using the final Solvup case ID in text.</li>
                        }
                    </ul>
                </Alert>

                <Form
                    initialValues={() => {
                        let values = {
                            value_type: 'html',
                            ruleId: this.props.rule.id,
                            key_column: this.props.type,
                            user_type: this.props.user_type,
                            retailer_id: this.props.retailer_id,
                            category_id: this.props.category_id
                        }

                        if (this.props.rule.return_values) {
                            const value = this.props.rule.return_values.find(item => (
                                item.key_column == this.props.type &&
                                item.retailer_id == this.props.retailer_id &&
                                item.user_type == this.props.user_type
                            ));

                            if (value) {
                                values.returnValueId = value.id;
                                values.value_column = value.value_column;

                                this.setState({
                                    returnValue: value,
                                    dates: {
                                        created_at: value.created_at,
                                        updated_at: value.updated_at
                                    }
                                });

                                // Remove unwanted keys
                                ['category_id', 'retailer_id', 'user_type'].forEach(key => delete values[key]);
                            } else {
                                if (Object.keys(this.state.dates).length > 0) {
                                    this.setState({
                                        dates: {},
                                        returnValue: {}
                                    });
                                }
                            }
                        }

                        return values;
                    }}
                    onSubmit={this.submitHandler}
                    key={this.props.type + '-' + this.props.rule.id}
                    preventUnload={this.state.touched}
                    validationSchema={{
                        value_column: Validator.create().required()
                    }}>
                    {this.state.message &&
                        <Alert variant={this.state.variant} dismiss={() => {
                            this.setState({
                                message: null
                            });
                        }}>
                            {this.state.message}
                        </Alert>
                    }

                    {Object.keys(this.state.dates).length > 0 &&
                        <div className="d-flex justify-content-between text-muted mt-1 fs-6 border-bottom">
                            <div title={new Date(this.state.dates.created_at).toString()}>
                                <b>Created:</b> <br />
                                {new Date(this.state.dates.created_at).toDateString()}
                            </div>
                            <div title={new Date(this.state.dates.updated_at).toString()}>
                                <b>Last modified:</b> <br />
                                {new Date(this.state.dates.updated_at).toDateString()}
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-xs-12">
                            <Form.Group name="value_column" label="Text" required>
                                <Form.HTMLEditor name="value_column" height="400" />
                            </Form.Group>
                        </div>
                        <div className="col-xs-12">
                            <button type="submit" className="btn btn-success">Save</button>
                        </div>
                    </div>
                </Form>
            </>
        )
    }
}

export default TextForm;