import React, { Component, useState, useEffect } from "react";
import Filter from "./Filter";
import PerPage from "./PerPage";
import Spinner from "../Spinner";
import Pagination from "./Pagination";
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { SortableHandle, SortableContainer, SortableElement } from 'react-sortable-hoc';

const TableRow = ({ row, index, options = {} }) => {
    const [hidden, setHidden] = useState(options.expandableRowsExpanded || true);

    useEffect(() => {
        setHidden(options.expandableRowsExpanded);
    }, [options.expandableRowsExpanded]);

    return (
        <>
            <tr>
                {options.expandableRows &&
                    <td width="30" className="cursor-pointer text-center" onClick={e => {
                        setHidden(!hidden);
                    }} title={hidden ? 'Click to expand' : 'Click to collapse'}>
                        <i className={hidden ? 'fa fa-angle-right' : 'fa fa-angle-down'}></i>
                    </td>
                }

                {options.columns.map((column, index2) => {
                    if (column.omit === true) {
                        return null;
                    }

                    return (
                        <td
                            key={index2}
                            style={{ textAlign: column.align }}
                            title={column.title ? column.title(row, index) : ''} className={column.class}>
                            {(options.draggable && index2 == 0) && <DragHandle />}
                            <span>
                                {column.selector(row, index)}
                            </span>
                        </td>
                    )
                })}
            </tr>

            {(options.expandableRows && !hidden) &&
                <tr>
                    <td colSpan={options.columns.length + 1}>
                        {options.expandableRowsComponent(row)}
                    </td>
                </tr>
            }
        </>
    )
}

const DragHandle = SortableHandle(() => (
    <i className="fa fa-bars me-3 text-secondary cursor-grabbing" title="Click and drag to move "></i>
))

const SortableItem = SortableElement(TableRow);

const SortableList = SortableContainer(({ rows, options = {} }) => {
    return (
        <tbody>
            {rows.map((row, index) => (
                <SortableItem
                    row={row}
                    key={index}
                    index={index}
                    options={options} />
            ))}
        </tbody>
    )
});

class List extends Component {
    static defaultProps = {
        data: [],
        columns: [],
        draggable: false,
        responsive: true,
        showFilter: true,
        pagination: true,
        expandableRows: false,
        expandableRowsExpanded: false
    }

    state = {
        offset: 0,
        perPage: 10,
        currentPage: 0,
        totalPages: 0,
        totalRecords: 0,
        filterTerm: '',
        isLoading: false,
        columnsToFilter: [],
        data: this.props.data
    }

    constructor(props) {
        super(props);
        this.state.totalRecords = this.props.columns.length ? this.state.data.length : 0;
        if (this.state.totalRecords) {
            this.state.currentPage = 1;
            this.state.totalPages = this.calculateTotalPages(this.state.perPage);
            for (const column of this.props.columns.values()) {
                if (column.filterKey) {
                    this.state.columnsToFilter.push(column.filterKey);
                }
            }
        }
    }

    paginationHandler = page => {
        const offset = (page - 1) * this.state.perPage;
        this.setState({
            offset: offset,
            currentPage: page
        });
    }

    perPageChangeHandler = e => {
        const perPage = Number(e.target.value);
        this.setState(prevState => {
            return {
                offset: 0,
                currentPage: 1,
                perPage: perPage,
                totalPages: this.calculateTotalPages(perPage)
            }
        });
    }

    calculateTotalPages = perPage => Math.ceil(this.state.totalRecords / perPage);

    filterHandler = filterTerm => {
        if ('onFilter' in this.props) {
            this.props.onFilter(filterTerm);
        } else {
            if (this.state.columnsToFilter.length && this.state.filterTerm !== filterTerm) {
                const filteredData = this.props.data.filter(row => {
                    const filtered = this.state.columnsToFilter.filter(column => String(row[column]).toLowerCase().includes(filterTerm.toLowerCase()));

                    return Boolean(filtered.length);
                });

                this.setState({
                    offset: 0,
                    currentPage: 1,
                    filterTerm: filterTerm,
                    data: filteredData,
                    totalRecords: filteredData.length,
                    totalPages: Math.ceil(filteredData.length / this.state.perPage)
                });
            }
        }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ data }) => ({
            data: arrayMove(data, oldIndex, newIndex),
        }), () => {
            this.props.onSort != undefined && this.props.onSort(this.state.data);
        });
    }

    renderTableHeader = () => {
        if (this.props.columns.length === 0) {
            return null;
        }

        let headers = [];

        if (this.props.expandableRows) {
            headers.push(<th width="30">&nbsp;</th>);
        }

        headers.push(this.props.columns.map((column, index) => {
            if (column.omit === true) {
                return null;
            }

            return (
                <th key={index} style={{
                    minWidth: column.width,
                    textAlign: column.align
                }}>
                    {column.name}
                    {(column.sortable && this.state.data.length > 0) &&
                        <a>
                            <i className="fa fa-sort float-end sort"></i>
                        </a>
                    }
                </th>
            );
        }));

        return <tr>{headers}</tr>
    }

    renderTableBody = () => {
        if (this.props.columns.length === 0) {
            return null;
        }

        if (this.state.data.length === 0) {
            let length = this.props.expandableRows ? this.props.columns.length + 1 : this.props.columns.length;
            return (
                <tbody>
                    <tr>
                        <td colSpan={length}>
                            No {this.state.filterTerm && 'matching '} records found.
                        </td>
                    </tr>
                </tbody>
            )
        }

        const data = this.props.pagination ? this.state.data.slice(this.state.offset, this.state.offset + this.state.perPage) : this.state.data;

        if (this.props.draggable) {
            return <SortableList
                rows={data}
                lockAxis='y'
                useDragHandle={true}
                options={this.props}
                onSortEnd={this.onSortEnd}
                lockToContainerEdges={true} />
        }

        return (
            <tbody>
                {data.map((row, key) => (
                    <TableRow
                        key={key}
                        row={row}
                        index={key}
                        options={this.props} />
                ))}
            </tbody>
        )
    }

    renderTableFooter = () => this.renderTableHeader()

    renderRecordStatus = () => {
        let from = 0;
        let to = 0;
        if (this.state.totalRecords) {
            from = this.state.offset + 1;
            to = this.state.offset + this.state.perPage;
            if (to > this.state.totalRecords) {
                to = this.state.totalRecords;
            }
        }

        return (
            <strong>
                Showing {from} to {to} of {this.state.totalRecords} entries &nbsp;
                {this.state.filterTerm &&
                    "(filtered from " + this.props.data.length + " total entries)"
                }
            </strong>
        )
    }

    render() {
        return (
            <Spinner isLoading={this.state.isLoading}>
                <div className="list">
                    <div className="d-flex justify-content-between">
                        {this.props.pagination &&
                            <PerPage changed={this.perPageChangeHandler} perPage={this.state.perPage} disabled={!Boolean(this.state.totalRecords)} />
                        }

                        {this.props.showFilter &&
                            <Filter filterHandler={this.filterHandler} disabled={this.props.data.length === 0} />
                        }
                    </div>

                    <div className={"m-t-10 " + (this.props.responsive === true ? 'table-responsive' : '')}>
                        <table className='table'>
                            <thead>
                                {this.renderTableHeader()}
                            </thead>
                            {this.renderTableBody()}
                            {this.props.data.length > 0 &&
                                <tfoot>
                                    {this.renderTableFooter()}
                                </tfoot>
                            }
                        </table>
                    </div>

                    <div className="d-flex justify-content-between">
                        {this.renderRecordStatus()}
                        {this.props.pagination &&
                            <Pagination
                                totalPages={this.state.totalPages}
                                currentPage={this.state.currentPage}
                                clicked={this.paginationHandler} />
                        }
                    </div>
                </div >
            </Spinner>
        )
    }
}

export default List;