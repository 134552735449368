import React from "react";

const Footer = () => (
    <footer className="page-footer">
        <div className="font-13">
            Copyright &copy; 2021 
            <strong>
                <a href="https://www.solvup.com/" target="_blank" rel="noopener noreferrer">Solvup</a>
            </strong>.
            All rights reserved.
        </div>
        <div className="to-top">
            <i className="fa fa-angle-double-up"></i>
        </div>
    </footer>
);

export default Footer;