import React, { Component } from "react";
import Box from "../../components/Box";
import Form from "../../components/Form";
import Tabs from "../../components/Tabs";
import Alert from "../../components/Alert";
import NotFound from "../../components/404";
import Confirm from "../../components/Confirm";
import Spinner from "../../components/Spinner";
import { CategoryService } from "../../services";
import { Validator } from "../../utils/Validator";
import Changelog from "../../components/Changelog";

class AddCategory extends Component {
    constructor(props) {
        super(props);
        this.services = {
            category: new CategoryService(this)
        }

        this.state = {
            id: this.props.match.params.id,
            isLoading: this.props.match.params.id !== undefined,
            error: null,
            variant: "danger",
            message: null,
            data: {},
            preventUnload: true,
            confirmModal: {
                show: false,
                title: "Confirm Delete",
                message: "Are you sure want to delete category ?",
                onConfirm: () => this.deleteCategory(),
                onCancel: () => this.closeConfirmModal()
            }
        }
    }

    componentDidMount() {
        if (this.state.id !== undefined) {
            this.services.category.get(this.state.id);
        }
    }

    submitHandler = (values, setSubmitting, setErrors) => {
        if (this.state.id !== undefined) {
            this.services.category.update(this.state.id, values, setSubmitting, setErrors);
        } else {
            this.services.category.create(values, setSubmitting, setErrors);
        }
    }

    closeConfirmModal = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: false
            }
        }));
    }

    // Click handler of delete button
    deleteHandler = () => {
        this.setState(prev => ({
            confirmModal: {
                ...prev.confirmModal,
                show: true
            }
        }));
    }

    // Delete category finally
    deleteCategory = () => {
        this.setState(prev => ({
            preventUnload: false,
            confirmModal: {
                ...prev.confirmModal,
                isLoading: true
            }
        }), () => {
            this.services.category.delete(this.state.id);
        });
    }

    render() {
        if (this.state.pageNotFound) {
            return <NotFound />
        }

        if (this.state.error) {
            return (
                <Alert variant="danger">
                    {this.state.error}
                </Alert>
            )
        }

        return (
            <Spinner isLoading={this.state.isLoading}>
                <Box
                    allowFullScreen
                    title={(this.state.id ? "Update" : "Add") + " rule category"}
                    buttons={[
                        {
                            type: "button",
                            value: "Delete",
                            class: "btn btn-sm btn-danger",
                            show: this.state.id !== undefined && this.state.isLoading === false,
                            clicked: this.deleteHandler
                        }, {
                            type: "link",
                            href: "/rules/categories/add",
                            value: "Add new",
                            class: "btn btn-sm btn-info",
                            show: this.state.id !== undefined
                        }]}>
                    <Tabs active="details">
                        <Tabs.Pane id="details" title="Details">
                            <Form
                                initialValues={{
                                    uid: this.state.data.uid,
                                    name: this.state.data.name,
                                    sort_order: this.state.data.sort_order,
                                    description: this.state.data.description,
                                    business_terms: this.state.data.business_terms
                                }}
                                onSubmit={this.submitHandler}
                                preventUnload={this.state.preventUnload}
                                validationSchema={{
                                    name: Validator.create().required('Name is required'),
                                    sort_order: Validator.create().required('Sort order is required')
                                }}>
                                {({ values }) => (
                                    <>
                                        {this.state.message &&
                                            <Alert variant={this.state.variant} dismiss={() => {
                                                this.setState({
                                                    message: null
                                                });
                                            }}>
                                                {this.state.message}
                                            </Alert>
                                        }

                                        <div className="row">
                                            <div className="col-md-6 col-xs-12">
                                                <Form.Group name="name" label="Name" required>
                                                    <Form.Input type="text" placeholder="Enter name" id="name" name="name" />
                                                </Form.Group>
                                            </div>

                                            {this.state.id &&
                                                <div className="col-md-6 col-xs-12">
                                                    <Form.Group name="uid" label="Unique ID" required>
                                                        <Form.Input type="text" name="uid" disabled />
                                                    </Form.Group>
                                                </div>
                                            }

                                            <div className={"col-xs-12 " + (!this.state.id ? 'col-md-6' : '')}>
                                                <Form.Group name="sort_order" label="Sort Order" required>
                                                    <Form.Input type="number" placeholder="Enter sort order" id="sort_order" name="sort_order" valueType="number" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-xs-12">
                                                <Form.Group name="business_terms" label="Business Terms" hint="Note: Business terms are those which can be used as return values in rules. Enter comma separated list.">
                                                    <Form.Input type="text" placeholder="Enter Business terms" id="business_terms" name="business_terms" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-xs-12">
                                                <Form.Group name="description" label="Description">
                                                    <Form.Textarea type="textarea" name="description" placeholder="Describe here..." rows="8" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-xs-12">
                                                <hr />
                                                <button type="submit" className="btn btn-success m-r-5">Save</button>
                                            </div>
                                        </div>

                                        {(this.state.id && this.state.confirmModal.show) &&
                                            <Confirm {...this.state.confirmModal} />
                                        }
                                    </>
                                )}
                            </Form>
                        </Tabs.Pane>

                        {this.state.id &&
                            <Tabs.Pane id="changelog" title="Changelog">
                                <Changelog />
                            </Tabs.Pane>
                        }
                    </Tabs>
                </Box>
            </Spinner>
        )
    }
}

export default AddCategory;